import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class GetcategoryServiceService {

  constructor(private httpService: HttpClient) { }
  GetcategoryServiceService() {
     const href = environment.api.getcategory
     ;
    return this.httpService.get<any>(href, 
      { 'observe': 'response' })
  }
 } 