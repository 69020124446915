import { Component, OnInit ,Input} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar-scheduler',
  templateUrl: './calendar-scheduler.component.html',
  styleUrls: ['./calendar-scheduler.component.css']
})
export class CalendarSchedulerComponent {

  @Input('button') button:RouterLink;
  // @Input('min') min: number;
  @Input('StartDate') StartDate: string;
  @Input('EndDate') EndDate: string;

constructor(private router:Router) { }

ngOnInit() {
}

}
