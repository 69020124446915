import { Component, Inject, OnInit, EventEmitter, NgZone } from '@angular/core';
import { ProfileServiceService } from '../../../services/getprofile/profile-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FavLikeService } from '../../../services/like/fav-like.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { GetcategoryServiceService } from '../../../services/getcategory/getcategory-service.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GetproviderholidaysService } from '../../../services/getproviderholidays/getproviderholidays.service';
import * as moment from 'moment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ImageGalleryService } from './../../../services/image-gallery/imagegallery.service';
import { EventService } from '@crystalui/angular-lightbox';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { GetavailabletimeslotService } from '../../../services/getavailabletimeslot/getavailabletimeslot.service';
import { ConfirmationService, Message } from 'primeng/api';
import { GetAllAddressesService } from '../../../services/getAllAddresses/get-all-addresses.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css'],
  providers: [ConfirmationService]
})
export class ProfileDetailsComponent implements OnInit {

  boolean = {
    false: "2",
    true: "1"

  };
  selected: boolean;
  baseUrl = environment.baseUrl;
  subCategoriesService = [];
  provider_id = "";
  like_status = 1
  category = [];
  serviceCards = [];
  fbIcon = faFacebookSquare;
  l_userid;
  userid;
  sub: any;
  // selected: boolean;
  // lat: number =18.50989;
  // long:number= 73.807182;
  zoom: number;
  lat: number;
  long: number;
  selectedChange = new EventEmitter<boolean>();
  catId = '';
  intro;
  specialization;
  work_place;
  provider_name;
  holiday_list;
  personal_leaves;
  no_leaves;
  no_holiday;
  working_days = [];
  provider_lang: string = '';
  providerHolidays;
  qualifications = [];
  locationDisabled = true;
  closeResult: string;
  // holidays:string[] = [];
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  providerid: string;
  Items: any = [];
  _albums = [];
  imageAvailable = false;
  catName;
  metaBusinessName;
  date1;
  date2;
  date3;
  dateValid = false;
  three_month_valid;
  datePicker = moment().add(2, 'day');
  today = moment();
  getavailabletimeslot = [];
  morningArray = [];
  afternoonArray = [];
  eveningArray = [];
  nightArray = [];
  online_consultation_type;
  show = true;
  format = 'h:mm A';
  morningBefore = moment('5:30 AM', this.format);
  morningAfter = moment('12:00 PM', this.format);
  afternoonBefore = moment('11:30 AM', this.format);
  afternoonAfter = moment('4:00 PM', this.format);
  eveningBefore = moment('3:30 PM', this.format);
  eveningAfter = moment('8:00 PM', this.format);
  nightBefore = moment('7:30 PM', this.format);
  nightAfter = moment('12:00 AM', this.format);
  date = '';
  date1Selected = true;
  date2Selected = false;
  date3Selected = false;
  providerUserId: any;
  msgs: Message[] = [];
  appoint_id: any;
  showOverlay = false;
  addresses = [];
  multipleAddress = false;
  online_consultation = false;
  defaultSelected = true;
  address_id;
  selectedDate = moment().format('YYYY-MM-DD');
  defaultAddress = '';
  addressForm: FormGroup;

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private ProfileServiceService: ProfileServiceService,
    public dialog: MatDialog,
    private FavLikeService: FavLikeService,
    private route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private httpClient: HttpClient,
    private getcategoryServiceService: GetcategoryServiceService,
    private Router: Router,
    private getProviderHolidayService: GetproviderholidaysService,
    private modalService: NgbModal,
    private galleryService: ImageGalleryService,
    private eventService: EventService,
    private metaTagService: Meta,
    private server: JwtTestService,
    private getavailabletimeslotService: GetavailabletimeslotService,
    private confirmationService: ConfirmationService,
    private getAllAddressesService: GetAllAddressesService,
  ) {
    //dislike at begining
    this.selected = false;
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }

  }

  ngOnInit() {
    this.getCurrentLocation();
    this.getProviderImages();
    this.sub = this.route
      .params
      .subscribe(params => {
        console.log(params);
        // Defaults to 0 if no query param provided.
        // this.provider_id = params.provider_id || 1;
        // this.provider_name = params.provider_name.replace(/-/g, ' ') || '';
        this.provider_name = params.provider_name || '';
        console.log('provider_id query param', this.provider_name);
        // console.log('model  query param', this.model);
      });
    this.l_userid = localStorage.getItem('userid');
    this.userid = localStorage.getItem('userid');
    this.catId = localStorage.getItem('catId');
    this.catName = localStorage.getItem('catChildName');
    this.providerUserId = sessionStorage.getItem('providerUserId');

    this.getProviderHolidayService.GetproviderholidaysService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.providerHolidays = _response.body;
          }
        },
        (_error) => {

        });

        this.addressForm = new FormGroup({
          addressOption: new FormControl('')
        });

    this.getProvider();

    this.date1 = moment().format('ddd, D MMM');
    this.date2 = moment().add(1, 'day').format('ddd, D MMM');
    this.date3 = moment().add(2, 'day').format('ddd, D MMM');

    if (moment(this.date1, 'ddd, D MMM').isSame(moment(), 'day')) {
      this.date1 = 'Today';
    }
    if (moment(this.date2, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
      this.date2 = 'Tomorrow';
    }

    if (moment(this.today).isSameOrBefore(moment())) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    if (moment().isSameOrAfter(moment().add(90, 'day'), 'day')) {
      this.three_month_valid = false;
    } else {
      this.three_month_valid = true;
    }

    this.showAddress();
    this.getTimeSlots(moment(this.datePicker).subtract(2, 'day').format('YYYY-MM-DD'));
  }

  openModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getProviderImages() {
    this.providerid = sessionStorage.getItem('provider_id');
    console.log("MyprovID", this.providerid);
    let queryParam = `?provider_id=${this.providerid}`;
    this.galleryService.getProviderImages(queryParam).subscribe(res => {
      console.log("Provider api:", res);
      this.Items = res.body;
      console.log("Response Items", this.Items);
      this.Items.docUrl = [];
      for (let j = 0; j < res.body.length; j++) {
        this.Items.docUrl.push(res.body[j].cdn_image_url);
        this._albums.push(res.body[j].cdn_image_url);
      }
      while (this._albums.length > 3) {
        this._albums.pop();
      }
      this.imageAvailable = true;
      console.log("docURL", this.Items);
      console.log("Provider album items", this._albums);
    }, error => {
      console.log(error);
      this.imageAvailable = false;
    });
  }

  getProvider() {
    // this.provider_id = localStorage.getItem('provider_id');
    // let queryParms = `?provider_id=${this.provider_id}`;
    // let queryParms = `?provider_name=${this.provider_name}`;
    let queryParms = `?unique_url_id=${this.provider_name}`;
    let l_userid = `&l_userid=${this.l_userid}`
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.subCategoriesService = _response.body;
              this.metaBusinessName = this.subCategoriesService[0].provider_name;
              console.log("business name for meta", this.metaBusinessName);

              this.defaultAddress = _response.body[0].address;
              this.addressForm.controls['addressOption'].setValue('0');

              this.intro = this.subCategoriesService[0].introduction.slice(this.subCategoriesService[0].introduction.indexOf('intro: '), this.subCategoriesService[0].introduction.indexOf('specialization: '));
              this.intro = this.intro.slice(6);

              this.specialization = this.subCategoriesService[0].introduction.slice(this.subCategoriesService[0].introduction.indexOf('specialization: '), this.subCategoriesService[0].introduction.indexOf('workplace: '));
              this.specialization = this.specialization.slice(15);

              this.work_place = this.subCategoriesService[0].introduction.slice(this.subCategoriesService[0].introduction.indexOf('workplace: '));
              this.work_place = this.work_place.slice(10);

              if (this.subCategoriesService[0].qualification.includes("*")) {
                this.qualifications = this.subCategoriesService[0].qualification.split('*');
              }
              else {
                this.qualifications = this.subCategoriesService[0].qualification.split(' ');
              }
              this.qualifications.pop();

              this.provider_lang = this.subCategoriesService[0].provider_lang
              this.provider_lang = this.provider_lang.replace(/ /g, ', ').replace(/,\s*$/, "");

              this.working_days = this.subCategoriesService[0].working_days.split(' ');
              // this.working_days = this.working_days.replace(/ /g, ', ');

              this.metaForProviders();
              if (_response.body[0].fav_status == '1') {
                this.selected = true;
              } else {
                this.selected = false;
              }

              // this.holiday_list = this.subCategoriesService[0].holiday_list.split(' ');
              // this.holiday_list.pop();
              // if (this.holiday_list.length == 0) {
              //   this.no_holiday = true;
              // } else {
              //   this.no_holiday = false;
              //   // for (let i = 0; i < this.holiday_list.length; i++) {
              //   //   this.holidays.push(this.providerHolidays.find(element => element.holiday_date === this.holiday_list[i]));
              //   // }
              // }

              // this.personal_leaves = this.subCategoriesService[0].personal_leaves.split(' ');
              // this.personal_leaves.pop();
              // if (this.personal_leaves.length == 0) {
              //   this.no_leaves = true;
              // } else {
              //   this.no_leaves = false;
              // }

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      )
  }

  private getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationDisabled = false;
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        this.zoom = 8;
      },
        (error) => {
          this.locationDisabled = true;
        });
    }
  }
  placeMarker() {
    navigator.geolocation.getCurrentPosition((position) => {
      //this.lat=($event.coords.latitude);
      //this.long=($event.coords.longitude);
      this.lat = position.coords.latitude;
      this.long = position.coords.longitude;
      //this.Router[("http://maps.google.com/?lat,long")];
      // return this.http.get("http://maps.google.com/?lat,long");
      // this.Router.navigate(['http://maps.google.com/?q=<lat>,<long>']);
      let url = this.baseUrl + + '/lat/long';
      return this.httpClient.get<JSON>(url);
    });

  }

  openDialog(unique_url_id): void {
    const dialogRef = this.dialog.open(ProfileDialog, {
      width: '250px',
      scrollStrategy: new NoopScrollStrategy(),
      data: { url: environment.shareBaseURL + 'customer-dashboard/profile-details/' + unique_url_id.replace(/ /g, "-"), name: 'animal' }

    });
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  public toggleSelected() {
    this.selected = !this.selected
    if (this.selected) {
      this.like_status = 1
    } else {
      this.like_status = 2
    }
    let queryparams = {
      userid: localStorage.getItem('userid'),
      // provider_id: localStorage.getItem('provider_id'),
      provider_id: sessionStorage.getItem('provider_id'),
      provider_like_status: this.like_status
    }
    this.server.request('POST', '/providers/like', queryparams)
      .subscribe(
        (_response: any) => {
          console.log(_response);
          try {
            this.serviceCards = _response.body;
            // shop_id => this.shop_id = shop_id
          } catch (err) {

          }
        },
        (_error) => {

        });
    // this.selected = !this.selected;
    // this.selectedChange.emit(this.selected);
  }

  metaForProviders() {
    if (this.catName == 'Orthopaedic') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Orthopaedic online appointment booking, Orthopaedic online consultation, Orthopaedic near me' }
      );
    } else if (this.catName == 'Gynecologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Gynecologist online appointment booking, Gynecologist online consultation, Gynecologist near me' }
      );
    } else if (this.catName == 'Orthodontics ') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Orthodontics online appointment booking, Orthodontics online consultation, Orthodontics near me' }
      );
    } else if (this.catName == 'Neurologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Neurologist online appointment booking, Neurologist online consultation, Neurologist near me' }
      );
    } else if (this.catName == 'Psychologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Psychologist online appointment booking, Psychologist online consultation, Psychologist near me' }
      );
    } else if (this.catName == 'Dermatologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Dermatologist online appointment booking, Dermatologist online consultation, Dermatologist near me' }
      );
    } else if (this.catName == 'Dentist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Dentist online appointment booking, Dentist online consultation, Dentist near me' }
      );
    } else if (this.catName == 'Ophthalmologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Ophthalmologist online appointment booking, Ophthalmologist online consultation, Ophthalmologist near me' }
      );
    } else if (this.catName == 'Anesthesiologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Anesthesiologist online appointment booking, Anesthesiologist online consultation, Anesthesiologist near me' }
      );
    } else if (this.catName == 'Gastroenterologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Gastroenterologist online appointment booking, Gastroenterologist online consultation, Gastroenterologist near me' }
      );
    } else if (this.catName == 'Cardiologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Cardiologist online appointment booking, Cardiologist online consultation, Cardiologist near me' }
      );
    } else if (this.catName == 'Trial') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Trial lawyer online booking, Trial lawyer online consultation, Trial lawyer near me' }
      );
    } else if (this.catName == 'Immigration') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Immigration lawyer online booking, Immigration lawyer online consultation, Immigration lawyer near me' }
      );
    } else if (this.catName == 'Personal Injury') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Personal injury lawyer online booking, personal injury lawyer online consultation, Personal injury lawyer near me' }
      );
    } else if (this.catName == 'Criminal Law') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Criminal lawyer online booking, Criminal lawyer online consultation, Criminal lawyer near me' }
      );
    } else if (this.catName == 'Real Estate') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Real estate lawyer online booking, real estate lawyer online consultation, Real Estate lawyer near me' }
      );
    } else if (this.catName == 'Entertainment Lawyer') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Entertainment lawyer online booking, Entertainment lawyer online consultation' }
      );
    } else if (this.catName == 'Internet Lawyers') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Internet lawyer online booking, Intenet lawyer online consultation' }
      );
    } else if (this.catName == 'Makeup ') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Makeup salon near me, Makeup salon online booking' }
      );
    } else if (this.catName == 'Hair designer') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Hair salon near me' }
      );
    } else if (this.catName == 'Barbering') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'barber salon near me, barbers near me' }
      );
    } else if (this.catName == 'Nail technicians') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'nail salon online consultation, online appointment nail salon, nail salon near me' }
      );
    } else if (this.catName == 'Cosmetology') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Cosmetician near me' }
      );
    } else if (this.catName == 'Chartered Accountant Audit') {
      this.metaTagService.updateTag(
        { name: 'description', content: `Book an online appointment now at ${this.metaBusinessName} for expert advice and consultation.` },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'CA near me, CA firms near me, CA services' }
      );
    }
  }

  getDirections(address) {
    address = address.replace(/ /g, '+').replace(/,/g, "%2C");
    window.open('https://www.google.com/maps/search/?api=1&query=' + address, "_blank");
  }

  handleClick(selected, datePicker) {
    let startDate;
    this.date1Selected = false;
    this.date2Selected = false;
    this.date3Selected = false;

    console.log('before change: ', moment(this.datePicker).format('DD-MM-YYYY'));
    if (selected == 'sub') {
      startDate = moment(datePicker).subtract(1, 'days').format("YYYY-MM-DD") || moment();
      this.datePicker = moment(datePicker).subtract(1, 'days');
      this.date1 = moment(this.datePicker).subtract(2, 'day').format('ddd, D MMM');
      this.date2 = moment(this.datePicker).subtract(1, 'day').format('ddd, D MMM');
      this.date3 = moment(this.datePicker).format('ddd, D MMM');

      this.selectedDate = moment(this.date1, 'ddd, D MMM').format('YYYY-MM-DD');
      this.getTimeSlots(moment(this.date1, 'ddd, D MMM').format('YYYY-MM-DD'), this.defaultSelected ? '' : this.address_id);

      // console.log('after sub: ', moment(this.datePicker).format('DD-MM-YYYY'));

      if (moment(this.date1, 'ddd, D MMM').isSame(moment(), 'day')) {
        this.date1 = 'Today';
      } else if (moment(this.date1, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
        this.date1 = 'Tomorrow';
      }
      if (moment(this.date2, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
        this.date2 = 'Tomorrow';
      }
    }
    if (selected == 'add') {
      startDate = moment(datePicker).add(1, 'days').format("YYYY-MM-DD") || moment();
      this.datePicker = moment(datePicker).add(1, 'days');
      this.date1 = moment(this.datePicker).subtract(2, 'day').format('ddd, D MMM');
      this.date2 = moment(this.datePicker).subtract(1, 'day').format('ddd, D MMM');
      this.date3 = moment(this.datePicker).format('ddd, D MMM');

      this.selectedDate = moment(this.date1, 'ddd, D MMM').format('YYYY-MM-DD');
      this.getTimeSlots(moment(this.date1, 'ddd, D MMM').format('YYYY-MM-DD'), this.defaultSelected ? '' : this.address_id);

      // console.log('after add: ', moment(this.datePicker).format('DD-MM-YYYY'));

      if (moment(this.date1, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
        this.date1 = 'Tomorrow';
      }
    }

    if (moment(this.datePicker, 'ddd, D MMM').subtract(2, 'day').isSameOrBefore(moment())) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    if (moment(this.datePicker, 'ddd DD MMM').isSameOrAfter(moment().add(90, 'day'), 'day')) {
      this.three_month_valid = false;
    } else {
      this.three_month_valid = true;
    }
  }

  getTimeSlots(startDate, address_id?) {
    this.morningArray = [];
    this.afternoonArray = [];
    this.eveningArray = [];
    this.nightArray = [];
    this.online_consultation_type = [];

    let queryParms = `?provider_id=${this.providerid}&start_date=${moment(startDate).format('YYYY-MM-DD')}&year_month=${moment(startDate).format('YYYYMM')}`;
    address_id && address_id != '0' ? queryParms += `&address_id=${address_id}` : ``;

    address_id && address_id != '0' ? this.address_id = address_id : this.address_id = '';

    this.getavailabletimeslotService.GetavailabletimeslotService(queryParms)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            try {
              if (_response.body) {
                this.online_consultation_type = _response.body.data.online_consultation_type;

                if (typeof (this.online_consultation_type) == 'number') {
                  if (this.online_consultation_type == 0) {
                    // this.showAddress(this.online_consultation_type);
                  }
                } else if (typeof (this.online_consultation_type) == 'string') {
                  let online = this.online_consultation_type.split(' ');
                  online.pop();
                  if (online.length == 1 && online[0] == '0') {
                    // this.showAddress(online[0]);
                  }
                }

                if (!_response.body.data.unionArray) {
                  this.show = false;
                } else {
                  this.show = true;
                  this.getavailabletimeslot = _response.body.data.unionArray;
                  this.date = moment(startDate).format("YYYY-MM-DD");

                  for (let i = 0; i < this.getavailabletimeslot.length; i++) {
                    let time = moment(this.getavailabletimeslot[i].StartTime, this.format);

                    if (time.isBetween(this.morningBefore, this.morningAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.morningArray.push(this.getavailabletimeslot[i]);

                    } else if (time.isBetween(this.afternoonBefore, this.afternoonAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.afternoonArray.push(this.getavailabletimeslot[i]);

                    } else if (time.isBetween(this.eveningBefore, this.eveningAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.eveningArray.push(this.getavailabletimeslot[i]);

                    } else {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.nightArray.push(this.getavailabletimeslot[i]);
                    }
                  }
                }
              }
            } catch (err) {

            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            this.show = false;
          }
        });
  }

  selectDate(date) {
    if (date == 'Today') {
      this.selectedDate = moment().format('YYYY-MM-DD');
      this.getTimeSlots(moment().format('YYYY-MM-DD'), this.defaultSelected ? '' : this.address_id);
    } else if (date == 'Tomorrow') {
      this.selectedDate = moment().add(1, 'day').format('YYYY-MM-DD');
      this.getTimeSlots(moment().add(1, 'day').format('YYYY-MM-DD'), this.defaultSelected ? '' : this.address_id);
    } else {
      this.selectedDate = moment(date, 'ddd, D MMM').format('YYYY-MM-DD');
      this.getTimeSlots(moment(date, 'ddd, D MMM').format('YYYY-MM-DD'), this.defaultSelected ? '' : this.address_id);
    }
  }

  confirm1(date, startTime, endTime) {
    this.userid = localStorage.getItem('userid');
    this.provider_id = sessionStorage.getItem('provider_id');
    if (this.userid && (this.userid != this.providerUserId)) {
      this.bookApointment(date, startTime, endTime);
    } else if (this.userid && (this.userid == this.providerUserId)) {
      this.confirmationService.confirm({
        message: "Please select another provider (you cannot take appointment from same provider account)",
        header: 'Alert',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'OK',
        rejectLabel: 'Cancel',
        accept: () => {
          this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        },
        reject: () => {
          this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected ' }];
          sessionStorage.removeItem('appointmentDate');
          sessionStorage.removeItem('appointmentStart');
          sessionStorage.removeItem('appointmentEnd');
        }
      });
    } else {
      this.Router.navigate(['quicksignin/', this.provider_id]);
      sessionStorage.setItem('appointmentDate', date);
      sessionStorage.setItem('appointmentStart', startTime);
      sessionStorage.setItem('appointmentEnd', endTime);
    }

  }

  bookApointment(date, startTime, endTime) {
    this.userid = localStorage.getItem('userid');
    let apointment = {
      provider_id: this.providerid,
      userid: this.userid,
      start_datetime: moment(date).format('YYYY-MM-DD') + " " + moment(startTime, 'h:mm A').format('hh:mm A'),
      end_datetime: moment(date).format('YYYY-MM-DD') + " " + endTime,
      consultation_type: this.online_consultation ? '1' : !this.online_consultation ? '0' : localStorage.getItem('consultation_type'),
      address_id: this.defaultSelected ? '' : this.address_id ? this.address_id : localStorage.getItem('address_id'),
    }

    this.server.request('POST', '/appointment/setApointt', apointment)
      .subscribe(
        (_response: any) => {
          console.log("apointment booked");
          this.appoint_id = _response.appoint_id;
          localStorage.setItem('appoint_id', _response.appoint_id);
          localStorage.setItem('consultation_type', apointment.consultation_type);

          this.Router.navigate(['customer-dashboard/payment-method']);

          sessionStorage.removeItem('appointmentDate');
          sessionStorage.removeItem('appointmentStart');
          sessionStorage.removeItem('appointmentEnd');

          let notificationtype = {
            userid: this.userid,
            intended_userid: this.providerUserId,
            appoint_id: this.appoint_id,
            n_type: '0'
          };

          this.server.request('POST', '/notification/notificationType', notificationtype)
            .subscribe((_response) => {
              // console.log('notification type: ', notificationtype.n_type);
            },
              (_error) => {

              });
        },
        (_error) => {

        });
  }

  closeOverlay() {
    let overlay = document.getElementById('overlay') as HTMLElement;
    overlay.style.display = 'none';
    this.showOverlay = false;
  }

  showAddress() {
    let query = `?provider_id=${this.providerid}&online_consultation_flag=0`;
    this.getAllAddressesService.GetAllAddressesService(query)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.multipleAddress = true;
            this.addresses = _response.body;

            for (let i = this.addresses.length - 1; i >= 0; i--) {
              if (this.addresses[i].address === null) {
                this.addresses.splice(i, 1);
              }
            }

            for (let i = 0; this.addresses.length; i++) {
              let queryParams = `?provider_id=${this.providerid}&start_date=${moment().format('YYYY-MM-DD')}&month=${moment().format('MM')}&address_id=${this.addresses[i].address_id}`;
              this.getavailabletimeslotService.GetavailabletimeslotService(queryParams)
                .subscribe(
                  (_response) => {
                    if (_response.status === 200) {
                      this.addresses[i].working_start_time = _response.body.data.workingTime[0].StartTime;
                      this.addresses[i].working_end_time = _response.body.data.workingTime[0].EndTime;
                    }
                  },
                  (_error) => {

                  });
            }
          }
        },
        (_error) => {
          this.multipleAddress = false;
        });
  }

  getMonthSlots(startDate, address_id?) {
    let month = moment(startDate).format('MM');

    let queryParms = `?provider_id=${this.provider_id}&start_date=${startDate}&month=${month}`;
    address_id && address_id != '0' ? queryParms += `&address_id=${address_id}` : ``;

    this.getavailabletimeslotService.GetavailabletimeslotService(queryParms)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
          }
        },
        (_error) => {

        });
  }

  selectAddress(event) {
    this.address_id = event.value;
    if (event.value == '0') {
      this.getTimeSlots(moment(this.selectedDate).format('YYYY-MM-DD'));
      this.getMonthSlots(moment(this.selectedDate).format('YYYY-MM-DD'));
      this.defaultSelected = true;
    } else {
      this.getTimeSlots(moment(this.selectedDate).format('YYYY-MM-DD'), event.value);
      this.getMonthSlots(moment(this.selectedDate).format('YYYY-MM-DD'), event.value);
      localStorage.setItem('address_id', this.address_id);
      this.defaultSelected = false;
    }
  }

}
@Component({
  selector: 'profile-dialog',
  templateUrl: 'profile-dialog.html'


})
export class ProfileDialog {
  shareURL = '';
  constructor(
    public dialogRef: MatDialogRef<ProfileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    try {
      this.shareURL = data.url
    } catch (err) {
      console.log(err);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }


}


