import { Component, OnInit } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-partner-home',
  templateUrl: './partner-home.component.html',
  styleUrls: ['./partner-home.component.css']
})
export class PartnerHomeComponent implements OnInit {

  constructor(
    private metaTagService: Meta,
  ) { }

  ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Want to grow by easy earning with zero investment and efforts from the first day? Partner with us!' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'partner easy income, no investment, zero effort, earn easily,' }
    );
  }

}
