import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AgmServiceService } from 'src/app/services/agm/agm-service.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { debounceTime, tap, switchMap, finalize, flatMap } from 'rxjs/operators';
import { GetpartnerdetailsService } from '../../../services/getpartnerdetails/getpartnerdetails.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { query } from '@angular/animations';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-partner-detail',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.css']
})
export class PartnerDetailComponent implements OnInit {
  PartnerDetails: any = [{
    full_name: '', mobile_number: '', patner_id: '', email_address: '', address_text: '', postcode: ''
  }
  ];
  partneravailable = true;
  getSearchArray: [];
  searchForm: FormGroup;
  isLoading = false;
  patner_id: number;
  displayedColumns: string[] = ['full_name', 'mobile_number', 'patner_id'];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  // @ViewChild('searchName', { static: false })
  // public searchElementRef: ElementRef;

  constructor(
    private GetpartnerdetailsService: GetpartnerdetailsService,
    private router: Router,
    private agmServiceService: AgmServiceService,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.server.request('POST', '/admin/getAllPatnerDetails')
    .subscribe(
      (_response) => {
        this.partneravailable = true;
        this.PartnerDetails = _response;
        console.log(this.PartnerDetails);
      },
      (_error) => {
        this.partneravailable = false;
      });

    this.searchForm = this.formBuilder.group({
      search: [''],
    });

    this.searchForm
      .get('search')
      .valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => this.isLoading = true),
        switchMap(value => this.GetpartnerdetailsService.GetfilterService({ patner_id: value })
          .pipe(
            finalize(() => this.isLoading = false),
          ))
      )
      .subscribe(
        (_response => {
          if (_response.status === 200) {
            this.PartnerDetails = _response.body;
          } else {
            this.PartnerDetails = [];

          }
        }),
        (_error => {
          this.getSearchArray = [];

        })
      );
  }
  displaySearch(search: any) {
    if (search) { return search.patner_id; }
  }

  viewSettlements() {
    this.router.navigate(['/admin/settlements']);
    localStorage.setItem('related_type', '2');
  }

  viewEnquiryDetails() {
    this.router.navigate(['/admin/enquiry-details']);
  }
}
