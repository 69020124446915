import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
// import { ApiService } from '../../services/image-gallery/api.service';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { ImageGalleryService } from '../../../services/image-gallery/imagegallery.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Lightbox } from 'ngx-lightbox';
import { EventService } from '@crystalui/angular-lightbox';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';

@Component({
  selector: 'app-my-gallery',
  templateUrl: './my-gallery.component.html',
  styleUrls: ['./my-gallery.component.css']
})
export class MyGalleryComponent implements OnInit {
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef; files = [];
  test: string;

  constructor(
    private galleryService: ImageGalleryService,
    private modalService: NgbModal,
    private http: HttpClient,
    private toastr: ToastrService,
    private _lightbox: Lightbox,
    private eventService: EventService,
    private server: JwtTestService) {
    this.eventService.emitter.subscribe(
      (event) => {
        console.log(event);
      }
    );
  }

  Items: any = [];
  closeResult: string;
  fileToUpload: File = null;
  imageUrl: string = "";
  provider_id = '';
  image_id = '';
  _albums = [];
  imageAvailable = false;
  document = 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/';
  // document = 'https://api.apointt.com:8082/';

  // open(content: any) {
  //   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    //image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  // onUpload() {
  //   const data = new FormData();
  //   data.append('fileKey', this.fileToUpload, this.fileToUpload.name);
  //   this.http.post('https://api.unsplash.com/photos/?page=1&per_page=21&client_id=8DAdp18fEvW5RTAMz_PL4d8EoQO7cE33DM-kwTL1vds', data).subscribe(res => {
  //     console.log(res);
  //   }, error => {
  //     console.log(error);
  //   });
  // }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    console.log("Myproviderid=", this.provider_id);
    this.getProviderImages();
  }

  // getProviderImages(){
  //   let queryParam = `?provider_id=${this.provider_id}`;
  //   this.apiService.getProviderImages(queryParam).subscribe(res => {
  //     console.log("Provider api:",res);
  //     this.Items = res.body;
  //     console.log("Response Items", this.Items);
  //     for (let i = 0; i < this.Items.length; i++){
  //       this.Items[i].docUrl = [];
  //       for(let j = 0; j < res.body.length; j++){

  //         this.Items[i].docUrl.push(this.document + res.body[j].server_image_url.slice(20));

  //       }
  //     }
  //     console.log("docURL",this.Items);
  //     this._albums.push(this.Items);
  //     console.log("Provider album items", this._albums);
  //   }, error => {
  //     console.log(error);
  //   });
  // }

  getProviderImages() {
    let queryParam = `?provider_id=${this.provider_id}`;
    this.galleryService.getProviderImages(queryParam).subscribe(res => {
      if (res.status === 200) {
        try {
          console.log("Provider api:", res);
          this.Items = res.body;
          console.log("Response Items", this.Items);
          this.Items.docUrl = [];
          for (let j = 0; j < res.body.length; j++) {
            // this.Items[i].docUrl.push(this.document + res.body[j].server_image_url.slice(20));
            // this.Items.docUrl.push(this.document + res.body[j].server_image_url.slice(20));
            this.Items.docUrl.push(res.body[j].cdn_image_url);
          }
          this.imageAvailable = true;

          console.log("docURL", this.Items);
          this._albums.push(this.Items);
          console.log("Provider album items", this._albums);
        } catch (err) {
          console.log(err);
        }
      }

    }, error => {
      console.log(error);
      this.imageAvailable = false;
      this.Items.docUrl = [];
    });
  }

  getId(items) {
    let proceed = confirm('Are you sure you want to delete the picture?');
    if (proceed) {
      console.log(proceed);
      this.image_id = this.Items[items].provider_photo_id;
      console.log(this.image_id);
      this.deleteImage(this.image_id);
      // this.getProviderImages();
    } else {
      console.log(proceed);
    }

  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('avatar', file.data);
    file.inProgress = true;
    let queryParms = `?provider_id=${this.provider_id}`;
    this.galleryService.upload(queryParms, formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            // this.getProviderImages();

            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          console.log(event.body);
          this.galleryService.UploadImageGalleryToCloudService({ dt: JSON.stringify(event.body.data.details) })
            .subscribe(
              (_response) => {
                if (_response.status === 200) {
                  console.log('image added');
                  this.getProviderImages();
                }
              },
              (_error) => {

              }
            );
        }
      });
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
      this.files = [];
      this.toastr.success('Images uploaded successfully');
    });
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  deleteImage(imageid) {
    let imgid = {
      provider_photo_id: imageid
    };

    this.server.request('POST', '/providers/deleteProviderPhoto', imgid)
      .subscribe(
        (_response: any) => {
          try {
            console.log(_response);
            this.getProviderImages();
            this.toastr.success('Image has been deleted');
          } catch (err) {
            console.log(err);
          }
        },
        (_error) => {
          console.log(_error);
        });

    // this.apiService.deleteGalleryImages(imgid).subscribe((res) => {
    //   console.log(res);
    //   this.toastr.success('Photo deleted successfully');
    // }, (err) => {
    //   console.log(err);
    // });
  }

  // open(index: number): void {
  // open lightbox
  // this._lightbox.open(this.Items[index].docUrl, index);
  // const selectedimg: any = this._albums.find((item) =>{
  //   return item.id === id;
  //   console.log(selectedimg);
  // })
  // }

  //close(): void {
  // close lightbox programmatically
  //this._lightbox.close();
  //}

}
