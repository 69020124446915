import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder,FormControl,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { from } from 'rxjs';
import { WizardComponent } from 'src/app/ui-components/initial-wizard/wizard/wizard.component';
@Component({
 selector: 'app-sign-up',
 templateUrl: './sign-up.component.html',
 styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent {
 isActive;

 constructor(
   private Router :Router,
   private formBuilder: FormBuilder,
  
   private location: Location) { }
 
   ngOnInit() {
    
          
 }
 
 
   
  
   selectType(selectedTab) {
    console.log('selectType click', selectedTab);
    if (selectedTab.type == 'business') {
     
      this.Router.navigate(['']);
    } else if (selectedTab.type == 'customer') {
      this.Router.navigate(['/signup-admin'])
     
    }
  }
  }

