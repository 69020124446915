import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// const baseUrl = 'https://api.apointt.com:8082';
const baseUrl = 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081';
// const baseUrl = 'http://localhost:8081';
@Injectable({
  providedIn: 'root'
})
export class JwtTestService {
  private loggedIn = false;
  private token: string;
  header;

  constructor(private http: HttpClient) { }

  setLoggedIn(loggedIn: boolean, token?: string) {
    this.loggedIn = loggedIn;
    this.token = token;
  }

  request(method: string, route: string, data?: any) {
    var t = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjYxNTkyNzE4MDcwOTgxLCJqdGkiOiI2ZjA4YzZmMy0yNTJkLTRlOTUtYjJlMS0xMzU0OTU4NGY5NTQiLCJpYXQiOjE1OTI3MTgwODgsImV4cCI6MTU5MjgwNDQ4OH0.vy8ix11VlGYwthVsR-Zlm9Slyj11lGK3qt9_E6BydFg`;
    if (method === 'GET') {
      return this.get(route, data);
    }
    
    if (route == '/users/signupProviderWithPromise' || route == '/users/signUpApointt') {
      this.header = { Authorization: `Bearer ${t}` };
    } else {
      this.header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    }
    // const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    return this.http.request(method, baseUrl + route, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: this.header
    });
  }

  get(route: string, data?: any) {
    const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    let params = new HttpParams();
    if (data !== undefined) {
      Object.getOwnPropertyNames(data).forEach(key => {
        params = params.set(key, data[key]);
      });
    }

    return this.http.get(baseUrl + route, {
      responseType: 'json',
      headers: header,
      observe: 'response',
      params
    });
  }
}
