import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartnerloginService {

  constructor(private httpService: HttpClient) { }

  PartnerloginService(user) {
    
   return this.httpService.post<any>(environment.api.loginpartnerAPI,user,{'observe':'response'})
 } 
}
