import '../polyfills';
import { enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { IntroductionPageComponent } from './ui-components/introduction-page/introduction-page/introduction-page.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './ui-components/navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import { MatSnackBarModule } from '@angular/material';
import { MatTreeModule } from '@angular/material';
import { MatGridListModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { MatRippleModule } from '@angular/material';
import { MatDividerModule } from '@angular/material/divider';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
//import {MatButtonModule} from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { HomePageComponent } from './modules/home-page/home-page.component';
import { WizardComponent } from './ui-components/initial-wizard/wizard/wizard.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { SignupAdminComponent } from './modules/signup-admin/signup-admin.component';
import { DtSchedulerComponent } from './ui-components/initial-wizard/dt-scheduler/dt-scheduler.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DtDaysComponent } from './ui-components/initial-wizard/dt-days/dt-days.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { CategoryServiceComponent } from './ui-components/category-service/category-service.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { from } from 'rxjs';
//import {MatTooltipModule} from '@angular/material/tooltip';
// import {MatTooltipModule} from '@angular/material/tooltip';
import { FooterComponent } from './ui-components/footer/footer.component';
import { DsNavbarComponent } from './ui-components/navbar/ds-navbar/ds-navbar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CreateneweventComponent } from './ui-components/createnewevent/createnewevent.component';
import { OneOnOneComponent } from './ui-components/one-on-one/one-on-one.component';
import { DtSelectionComponent } from './ui-components/dt-selection/dt-selection.component';
import { AccSettingsComponent } from './modules/home-page/acc-settings/acc-settings.component';
import { MatMenuModule } from '@angular/material/menu';
import { AgmCoreModule } from '@agm/core';
import { CalenderSchedulerComponent } from './ui-components/calender-scheduler/calender-scheduler.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter, CalendarDateFormatter, CalendarMomentDateFormatter, MOMENT, } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatSliderModule } from '@angular/material';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AppointTableComponent } from './ui-components/appoint-table/appoint-table.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { AgmComponentComponent } from './ui-components/agm-component/agm-component.component';
import { MomentModule } from 'ngx-moment';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GMapModule } from 'primeng/gmap';
//import { NgScrollCalendarModule } from 'ng-scroll-calendar';
import { CategoriesViewComponent } from './ui-components/customer-dashboard/categories-view/categories-view.component';
import { ChildCategoryViewComponent } from './ui-components/customer-dashboard/child-category-view/child-category-view.component';
import { ProviderDetailsViewComponent } from './ui-components/customer-dashboard/provider-details-view/provider-details-view.component';
import { ServiceDetailsComponent } from './ui-components/customer-dashboard/service-details/service-details.component';
import { CategoryComponent } from './ui-components/card-services/category/category.component';
import { ChildCategoryComponent } from './ui-components/card-services/child-category/child-category.component';
import { ProviderDetailsComponent } from './ui-components/card-services/provider-details/provider-details.component';
import { DetailsComponent } from './ui-components/card-services/details/details.component';
import { CalendarSchedulerComponent } from './ui-components/card-services/calendar-scheduler/calendar-scheduler.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { AppointSuccessComponent } from './ui-components/customer-dashboard/appoint-success/appoint-success.component';
//import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { GetLocationComponent } from './ui-components/get-location/get-location.component';
//import { ProfileDetailsViewComponent } from './ui-components/customer-dashboard/profile-details-view/profile-details-view.component';
import { ProfileDetailsComponent } from './ui-components/customer-dashboard/profile-details/profile-details.component';
import { BusinessWizardComponent } from './ui-components/business-wizard/business-wizard.component';
import { SignupBusinessComponent } from './modules/signup-business/signup-business.component';
import { ContactInfoComponent } from './ui-components/business-wizard/contact-info/contact-info.component';
import { C } from '@angular/cdk/keycodes';
import { CompanyProfileComponent } from './business-wizard/company-profile/company-profile.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CustomerDashboardComponent } from './ui-components/customer-dashboard/customer-dashboard.component';
import { ShareButtonModule } from '@ngx-share/button';
import { ShareDialogComponent } from './ui-components/card-services/share-dialog/share-dialog.component';
import { DialogOverviewExampleDialog } from './ui-components/card-services/provider-details/provider-details.component'
import { ProfileDialog } from './ui-components/customer-dashboard/profile-details/profile-details.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoginNavbarComponent } from './ui-components/login-navbar/login-navbar.component';
import { QuicksigninComponent } from './modules/quicklogin/quicksignin/quicksignin.component';
import { QuicksignupComponent } from './modules/quicklogin/quicksignup/quicksignup.component';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { library } from '@fortawesome/fontawesome-svg-core';
//import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProviderDashboardComponent } from './ui-components/provider-dashboard/provider-dashboard.component';
import { BookedAppointDetailsComponent } from './ui-components/booked-appoint-details/booked-appoint-details.component';
import { ProviderNavbarComponent } from './ui-components/provider-navbar/provider-navbar.component';
import { ProviderProfileComponent } from './ui-components/provider-profile/provider-profile.component';
import { CustomerProfileComponent } from './ui-components/customer-dashboard/customer-profile/customer-profile.component';
import { AppointmentsComponent } from './ui-components/provider-dashboard/appointments/appointments.component';
import { DashboardComponent } from './ui-components/provider-dashboard/dashboard/dashboard.component';
import { ChangepasswordComponent } from './ui-components/provider-dashboard/changepassword/changepassword.component';
import { ChangepasswordCustomerComponent } from './ui-components/customer-dashboard/changepassword-customer/changepassword-customer.component';
import { MyclientsComponent } from './ui-components/provider-dashboard/myclients/myclients.component';
import { MatBadgeModule } from '@angular/material/badge';
import { GlobalsService } from './services/globals/globals.service';
import { MatListModule } from '@angular/material/list';
import { SchedulerComponent } from './ui-components/provider-dashboard/scheduler/scheduler.component';
import { AccountSettingsComponent } from './ui-components/provider-dashboard/account-settings/account-settings.component';
// import { CalendarDialog } from './ui-components/calender-scheduler/calender-scheduler.component';
import { PaymentMethodComponent } from './ui-components/calender-scheduler/payment-method/payment-method.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { FlickityModule } from 'ngx-flickity';
import { CancellationRefundComponent } from './ui-components/introduction-page/cancellation-refund/cancellation-refund.component';
import { TermsOfServiceComponent } from './ui-components/introduction-page/terms-of-service/terms-of-service.component';
import { PrivacyStatementComponent } from './ui-components/introduction-page/privacy-statement/privacy-statement.component';
import { HelpPageComponent } from './ui-components/introduction-page/help-page/help-page.component';
import { FeedbackFormComponent } from './ui-components/feedback-form/feedback-form.component';
import { HeaderNavbarComponent } from './ui-components/header-navbar/header-navbar.component';
import { WebFooterComponent } from './ui-components/web-footer/web-footer.component';
import { PageFooterComponent } from './ui-components/page-footer/page-footer.component';
import { CalenderViewComponent } from './ui-components/provider-dashboard/calender-view/calender-view.component';
import { CalendarHeaderComponent } from './ui-components/provider-dashboard/calender-view/calendar-header.component';
import { PaymentDetailsComponent } from './ui-components/provider-dashboard/payment-details/payment-details.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { BusinessAnalyticsComponent } from './ui-components/provider-dashboard/business-analytics/business-analytics.component';
import { SubscriptionComponent } from './ui-components/introduction-page/subscription/subscription.component';
import { SubscriptionsComponent } from './ui-components/provider-dashboard/subscriptions/subscriptions.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ReschedulerComponent } from './ui-components/provider-dashboard/rescheduler/rescheduler.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReschedulerCalendarHeaderComponent } from './ui-components/provider-dashboard/rescheduler/calendar-header.component';
import { AddclientsComponent } from './ui-components/provider-dashboard/addclients/addclients.component';
import { HelpComponent } from './ui-components/introduction-page/help/help.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QrCodeModule } from 'ng-qrcode';
import { AdminComponent } from './ui-components/admin/admin.component';
import { AdminLoginComponent } from './modules/admin-login/admin-login.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import {ProgressBarModule} from "angular-progress-bar";
import { CustomerDetailComponent } from './ui-components/admin/customer-detail/customer-detail.component';
import { AdminDashboardComponent } from './ui-components/admin/admin-dashboard/admin-dashboard.component';
import { QrDownloadComponent } from './ui-components/provider-dashboard/qr-download/qr-download.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { OverlayTourComponent } from './ui-components/overlay-tour/overlay-tour.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { SignupPartnerComponent } from './modules/signup-partner/signup-partner.component';
import { PartnerLoginComponent } from './modules/partner-login/partner-login.component';
import { PartnerDashboardComponent } from './ui-components/partner/partner-dashboard/partner-dashboard.component';
import { PartnerComponent } from './ui-components/partner/partner.component';
import { PartnerProviderDetailsComponent } from './ui-components/partner/partner-provider-details/partner-provider-details.component';
import { PartnerSettingsComponent } from './ui-components/partner/partner-settings/partner-settings.component';
import { ProviderDetailComponent } from './ui-components/admin/provider-detail/provider-detail.component';
import { PartnerDetailComponent } from './ui-components/admin/partner-detail/partner-detail.component';
import { ReferralComponent } from './ui-components/provider-dashboard/referral/referral.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CryptoServicesService } from './services/crypto-services/crypto-services.service';
import { SettlementCaseComponent } from './ui-components/provider-dashboard/settlement-case/settlement-case.component';
// import {Ng7DynamicBreadcrumbModule} from "ng7-dynamic-breadcrumb";
import { CalendarModule as PrimeCalendar } from 'primeng/calendar';
import { MonthlyInvoiceComponent } from './ui-components/provider-dashboard/monthly-invoice/monthly-invoice.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PartnerSettlementComponent } from './ui-components/partner/partner-settlement/partner-settlement.component';
import { VideoCallTestComponent } from './ui-components/video-call-test/video-call-test.component';
import { CalendarDemoComponent } from './ui-components/calendar-demo/calendar-demo.component';
import { ScheduleManagementComponent } from './ui-components/provider-dashboard/schedule-management/schedule-management.component';
import { ProviderVideoCallComponent } from './ui-components/provider-dashboard/provider-video-call/provider-video-call.component';
import { MobileFooterComponent } from './ui-components/mobile-footer/mobile-footer.component';
import { BusinessSignupComponent } from './ui-components/business-signup/business-signup.component';
import { ArticleService } from './services/article/article.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { TabDirective } from './ui-components/business-wizard/tab.directive';
import { FeaturesComponent } from './ui-components/introduction-page/features/features.component';
// import {MatDialogModule} from '@angular/material/dialog';
import { NguiOverlayModule } from '@ngui/overlay';
import { AppDialogBoxComponent } from './ui-components/app-dialog-box/app-dialog-box.component';
// import {AndroidDialog} from './ui-components/introduction-page/introduction-page/introduction-page.component';
// import {AndroidbDialog} from './ui-components/business-signup/business-signup.component';
import { DialogComponent } from './ui-components/introduction-page/dialog/dialog.component';
import { SettlementDetailComponent } from './ui-components/admin/settlement-detail/settlement-detail.component';
import { SettlementDetailByIdComponent } from './ui-components/admin/settlement-detail-by-id/settlement-detail-by-id.component';
import { PartnerReportComponent } from './ui-components/partner/partner-report/partner-report/partner-report.component';
import { PartnerRefferalComponent } from './ui-components/partner/partner-refferal/partner-refferal.component';
import { PartnerProviderProfileDetailsComponent } from './ui-components/partner/partner-provider-profile-details/partner-provider-profile-details.component';
import { PartnerHomeComponent } from './ui-components/partner/partner-home/partner-home.component';
import { PartnerProviderReferDetailsComponent } from './ui-components/partner/partner-provider-refer-details/partner-provider-refer-details.component';
import { PartnerProviderAppointmentDetailsComponent } from './ui-components/partner/partner-provider-appointment-details/partner-provider-appointment-details.component';
import { PartnerAllAppointmentDetailsComponent } from './ui-components/partner/partner-all-appointment-details/partner-all-appointment-details.component';
import { PartnerDialog } from './ui-components/partner/partner-provider-refer-details/partner-provider-refer-details.component';
import { ViewDocumentComponent } from './ui-components/provider-dashboard/view-document/view-document.component';
import { MyGalleryComponent } from './ui-components/provider-dashboard/my-gallery/my-gallery.component';
import { LightboxModule } from 'ngx-lightbox';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { ImageDialogComponent } from './ui-components/customer-dashboard/profile-details/image-dialog/image-dialog.component';
import { DocumentListDialog } from './ui-components/provider-dashboard/calender-view/calender-view.component';
import { DocumentUploadDialog } from './ui-components/booked-appoint-details/booked-appoint-details.component';
import {  Meta } from '@angular/platform-browser';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { EnquiryFormComponent } from './ui-components/enquiry-form/enquiry-form.component';
import { EnquiryDetailsComponent } from './ui-components/admin/enquiry-details/enquiry-details.component';
import { AboutUsComponent } from './ui-components/introduction-page/about-us/about-us.component';
import { ContactUsComponent } from './ui-components/introduction-page/contact-us/contact-us.component';
import { HomeSearchComponent } from './ui-components/home-search/home-search.component';
import { ChoosePaymentComponent } from './ui-components/calender-scheduler/choose-payment/choose-payment.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "black",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-scale-multiple",
  "blur": 5,
  // "delay": 0,
  "fgsColor": "#5bcdf0",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-scale-multiple",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "black",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  // "maxTime": -1,
  // "minTime": 500
};

// const icons = [
//   // ... other icons
//   //faFacebookSquare,
//   faWhatsapp
// ];

// library.add(...icons);

//const shareProp = {
//facebook: {
// icon: ['fab', 'facebook-square']
// }

// };


// import {NgDynamicBreadcrumbModule} from "ng-dynamic-breadcrumb";
@NgModule({

  // entryComponents: [ DialogOverviewExampleDialog],
  declarations: [
    AppComponent,
    NavbarComponent,
    SignUpComponent,
    HomePageComponent,
    WizardComponent,
    DtSchedulerComponent,
    DtDaysComponent,
    SignupAdminComponent,
    SignUpComponent,
    CategoryServiceComponent,
    FooterComponent,
    DsNavbarComponent,
    CreateneweventComponent,
    OneOnOneComponent,
    DtSelectionComponent,
    //  MatTooltipModule,
    //MatTooltipModule,
    AccSettingsComponent,
    CalenderSchedulerComponent,
    AppointTableComponent,
    AgmComponentComponent,
    CategoriesViewComponent,
    ChildCategoryViewComponent,
    //ProfileDetailsViewComponent,
    ProviderDetailsViewComponent,
    ServiceDetailsComponent,
    CategoryComponent,
    ChildCategoryComponent,
    ProviderDetailsComponent,
    DetailsComponent,
    CalendarSchedulerComponent,
    AppointSuccessComponent,
    GetLocationComponent,
    //ProfileDetailsViewComponent,
    ProfileDetailsComponent,
    BusinessWizardComponent,
    SignupBusinessComponent,
    ContactInfoComponent,
    // CustomerDashboardComponent,
    CompanyProfileComponent,
    CustomerDashboardComponent,
    ShareDialogComponent,
    DialogOverviewExampleDialog,
    ProfileDialog,
    LoginNavbarComponent,
    QuicksigninComponent,
    QuicksignupComponent,
    ProviderDashboardComponent,
    BookedAppointDetailsComponent,
    ProviderNavbarComponent,
    ProviderProfileComponent,
    CustomerProfileComponent,
    AppointmentsComponent,
    DashboardComponent,
    ChangepasswordComponent,
    ChangepasswordCustomerComponent,
    MyclientsComponent,
    SchedulerComponent,
    AccountSettingsComponent,
    // CustomerDashboardComponent,
    //  CalendarDialog,
    PaymentMethodComponent,
    ForgotPasswordComponent,
    IntroductionPageComponent,
    CancellationRefundComponent,
    TermsOfServiceComponent,
    PrivacyStatementComponent,
    HelpPageComponent,
    FeedbackFormComponent,
    HeaderNavbarComponent,
    WebFooterComponent,
    PageFooterComponent,
    CalenderViewComponent,
    CalendarHeaderComponent,
    PaymentDetailsComponent,
    BusinessAnalyticsComponent,
    // CustomerDashboardComponent,
    //  CalendarDialog,
    PaymentMethodComponent,
    ForgotPasswordComponent,
    IntroductionPageComponent,
    CancellationRefundComponent,
    TermsOfServiceComponent,
    PrivacyStatementComponent,
    HelpPageComponent,
    FeedbackFormComponent,
    HeaderNavbarComponent,
    WebFooterComponent,
    PageFooterComponent,
    SubscriptionComponent,
    SubscriptionsComponent,
    ReschedulerComponent,
    ReschedulerCalendarHeaderComponent,
    AddclientsComponent,
    HelpComponent,
    AdminComponent,
    AdminLoginComponent,
    CustomerDetailComponent,
    AdminDashboardComponent,
    QrDownloadComponent,
    OverlayTourComponent,
    SignupPartnerComponent,
    PartnerLoginComponent,
    PartnerDashboardComponent,
    PartnerComponent,
    PartnerProviderDetailsComponent,
    PartnerSettingsComponent,
    ProviderDetailComponent,
    PartnerDetailComponent,
    ReferralComponent,
    SettlementCaseComponent,
    MonthlyInvoiceComponent,
    PartnerSettlementComponent,
    VideoCallTestComponent,
    CalendarDemoComponent,
    ScheduleManagementComponent,
    ProviderVideoCallComponent,
    MobileFooterComponent,
    BusinessSignupComponent,
    TabDirective,
    FeaturesComponent,
    AppDialogBoxComponent,
    // AndroidDialog,
    // AndroidbDialog,
    DialogComponent,
    SettlementDetailComponent,
    SettlementDetailByIdComponent,
    PartnerReportComponent,
    PartnerRefferalComponent,
    PartnerProviderProfileDetailsComponent,
    PartnerHomeComponent,
    PartnerProviderReferDetailsComponent,
    PartnerProviderAppointmentDetailsComponent,
    PartnerAllAppointmentDetailsComponent,
    PartnerDialog,
    ViewDocumentComponent,
    MyGalleryComponent,
    ImageDialogComponent,
    DocumentListDialog,
    DocumentUploadDialog,
    EnquiryFormComponent,
    EnquiryDetailsComponent,
    AboutUsComponent,
    ContactUsComponent,
    HomeSearchComponent,
    ChoosePaymentComponent,
  ],
  imports: [
    [
      NguiOverlayModule,
      AutocompleteLibModule,
      MatExpansionModule,
      MatSortModule,
      MatRippleModule,
      MatSnackBarModule,
      MatGridListModule,
      MatPaginatorModule,
      CommonModule,
      FormsModule,
      MatProgressBarModule,
      // ProgressBarModule,
      Ng2SearchPipeModule,
      BreadcrumbModule,
      AutocompleteLibModule,
      MatButtonModule,
      MatDividerModule,
      FontAwesomeModule,
      ConfirmDialogModule,
      ShareButtonModule,
      LightboxModule,
      CrystalLightboxModule,
      //ShareButtonModule.withConfig({ prop: shareProp }),
      //NgxFullCalendarModule,
      NgbModalModule,
      BrowserModule,
      ToastrModule.forRoot({
        positionClass: 'toast-top-right',
        preventDuplicates: true,

      }),
      AutoCompleteModule,
      MatProgressSpinnerModule,
      ToastrModule.forRoot({
        positionClass: 'toast-top-right',
        preventDuplicates: true,

      }),
      FlatpickrModule.forRoot(),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
    ],
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCworH01spWCraF-mva5mYey1WJdTEu_ng',
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatInputModule,
    NgSelectModule,
    MatTableModule,
    MatSliderModule,
    MatCardModule,
    GMapModule,
    MatRadioModule,
    MatCheckboxModule,
    HttpModule,
    MatFormFieldModule,
    MatMenuModule,
    MatIconModule,
    NgOptionHighlightModule,
    Ng2SearchPipeModule,
    MatButtonModule,
    MatButtonToggleModule,
    BreadcrumbModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    MatStepperModule,
    HttpClientModule,
    OwlDateTimeModule,
    NgxMaterialTimepickerModule,
    OwlNativeDateTimeModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTabsModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    NgbModalModule,
    MatNativeDateModule,
    SchedulerModule,
    AngularEditorModule,
    MatBadgeModule,
    MatListModule,
    MatSidenavModule,
    // NgDynamicBreadcrumbModule,
    FlickityModule,
    ChartsModule,
    FileUploadModule,
    DragDropModule,
    MatTreeModule,
    MatTooltipModule,
    QrCodeModule,
    PortalModule,
    CdkTableModule,
    CdkTreeModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ClickOutsideModule,
    NgxDocViewerModule,
    // Ng7DynamicBreadcrumbModule,
    PrimeCalendar,
    NgbModule,
    DialogModule,
    ButtonModule,
    NgxPaginationModule,
    DeferLoadModule,
  ],
  entryComponents: [
    DialogOverviewExampleDialog,
    ProfileDialog,
    PartnerDialog,
    DocumentListDialog,
    DocumentUploadDialog,
    // AndroidDialog,
    // AndroidbDialog
    // AppDialogBoxComponent
    //  CalendarDialog
  ],
  providers: [
    GlobalsService,
    CryptoServicesService, ArticleService, ThemeService, Meta
  ],
  bootstrap: [AppComponent]


  // ],
  // providers: [],
  // bootstrap: [AppComponent]
})
export class AppModule {

}

