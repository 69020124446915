import { Component, OnInit } from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as moment from 'moment';
import { JwtTestService } from '../../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject, from } from 'rxjs';
import { GetproviderdetailspartnerService } from '../../../../services/getproviderdetailspartner/getproviderdetailspartner.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileServiceService } from '../../../../services/getprofile/profile-service.service';
import { GetpartnerdetailbypartneridService } from '../../../../services/getpartnerdetailbypartnerid/getpartnerdetailbypartnerid.service';



@Component({
  selector: 'app-partner-report',
  templateUrl: './partner-report.component.html',
  styleUrls: ['./partner-report.component.css']
})
export class PartnerReportComponent implements OnInit {
  partner_payment_report = [];
  provider_id;
  userid;
  provider_details;
  years;
  patner_id;
  partnerDetails = [];
  partner;
  totalPartnerAmount = '';
  total_provider = '';
  active_flag;
  rangeDates: Date[];
  invoice_total = 0;
  total_cost;
  months = [
    { value: '01', month: 'January' },
    { value: '02', month: 'February' },
    { value: '03', month: 'March' },
    { value: '04', month: 'April' },
    { value: '05', month: 'May' },
    { value: '06', month: 'June' },
    { value: '07', month: 'July' },
    { value: '08', month: 'August' },
    { value: '09', month: 'September' },
    { value: '10', month: 'October' },
    { value: '11', month: 'November' },
    { value: '12', month: 'December' }];
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private GetproviderdetailspartnerService: GetproviderdetailspartnerService,
    private server: JwtTestService,
    private toaster: ToastrService,
    private ProfileService: ProfileServiceService,
    private GetpartnerdetailbypartneridService: GetpartnerdetailbypartneridService,

  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {

    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    // let query = `?provider_id=${this.provider_id}`;
    // let l_userid = ``
    // this.ProfileService.ProfileServiceService(query, l_userid)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.provider_details = _response.body;
    //       }
    //     },
    //     (_error) => {

    //     });
    // this.years.push(moment().subtract(1, 'year').format('YYYY'));
    // this.years.push(moment().format('YYYY'));
    // this.years.push(moment().add(1, 'year').format('YYYY'));


    this.patner_id = localStorage.getItem('patner_id');

    // let query = `?patner_id=${this.patner_id}`;
    let query = {
      patner_id: this.patner_id
    };

    this.server.request('GET', '/patner/getPatnerDetailByPatnetId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
              this.partnerDetails = _response.body.results;
            }
          },
        (_error) => {
        });

        this.server.request('GET', '/patner/getPatnerDetailByPatnetId', query)
        .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.totalPartnerAmount = _response.body.totalPatnerAmount ? _response.body.totalPatnerAmount : 0;
            this.total_provider = _response.body.total_provider ? _response.body.total_provider : 0;
          }
        },
        (_error) => {

        });

    this.server.request('GET', '/patner/getPatnerDetail', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.partner = _response.body;
            console.log('_response', this.partner)
            // this.active_flag = _response.body.active_flag;
          }
        },
        (_error) => {

        });

        this.getPayment();
  }

  getDates(event) {
    console.log('range: ', this.rangeDates);
    this.getPayment();
  }

  getPayment(){
    // this.GetproviderdetailspartnerService.getInvoice()
    // .subscribe((_response) => {
            // if (_response.status === 304) {
    //           this.partner_payment_report = _response;
              // console.log('_response', _response);
    //   }
    // });
    this.GetproviderdetailspartnerService.getInvoice()
    .subscribe((_response) => {
      if (_response) {
      this.partner_payment_report = _response;
      console.log('_response', _response);
      }
    })
  }

  generatePdf(action) {
    let docDefinition = {
      content: [
        {
          text: 'APOINTT',
          fontSize: 16,
          alignment: 'center',
          color: '#047886'
        },
        {
          text: 'INVOICE',
          fontSize: 20,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          color: 'skyblue'
        },
        {
          text: 'Partner Details',
          style: 'sectionHeader'
        },
        {
          columns: [
            [
              // {
              //   text: this.partner[0].full_name,
              //   bold: true
              // },
              // { text: this.partner[0].mobile_number },
              // { text: this.partner[0].address_text }

            ],
            [
              { text: `Date: ${moment().format('DD/MM/YYYY hh:mm A')}`, alignment: 'right' }
            ]
          ]
        },
        {
          text: this.partner_payment_report.length > 0 ? 'details' : '',
          style: 'sectionHeader'
        },
        this.partner_payment_report.length > 0 ? {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Name', 'Contact', 'Date', 'Cost'],
              ...this.partner_payment_report.map(p => ([p.full_name, p.mobile_no, moment(p.start_datetime).format('DD/MM/YY'), p.total_cost])),
              [{ text: 'Total Amount', colSpan: 3 }, {}, {}, this.invoice_total ? this.invoice_total : 60]
            ]
          }
        } : '',

      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        }
      }
    };

    if (action === 'download') {
      pdfMake.createPdf(docDefinition).download(moment(this.rangeDates[0]).format('YYYY-MM-DD') + '-to-' +  moment(this.rangeDates[1]).format('YYYY-MM-DD') + '.pdf');
    } else if (action === 'open') {
      pdfMake.createPdf(docDefinition).open();
    }
  }

}
