import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ScheduletimingsService } from '../../../services/scheduletimings/scheduletimings.service';
import * as moment from 'moment';
import { GetscheduletimingsService } from '../../../services/getscheduletimings/getscheduletimings.service';
import { ToastrService } from 'ngx-toastr';
import { GetproviderholidaysService } from '../../../services/getproviderholidays/getproviderholidays.service';
import { MatOption } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject, Subject } from 'rxjs';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

function durationValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value !== null && (control.value % 5 !== 0)) {
    return { 'durationNotValid': true }
  }
  return null;
}

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class SchedulerComponent implements OnInit {
  provider_id = '';
  userid = '';
  ownerForm: FormGroup;
  timeForm: FormGroup;
  duration = '';
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  days: string[] = [];
  allDays: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  working_days = new FormControl('');
  scheduleTimings = [];
  start_time = '';
  end_time = '';
  default_time_duration = '';
  durationValue = new FormControl('');
  working;
  holiday_list = [];
  personal_leaves = [];
  providerHolidays: string[] = [];
  break_start_time = '';
  break_end_time = '';
  minDate: Date;
  errorMatcher = new CrossFieldErrorMatcher();
  errorMatcher2 = new CrossFieldErrorMatcher();
  errorMatcher3 = new CrossFieldErrorMatcher();
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years = [];
  showSelectAll = true;

  daysSelected: any[] = [];
  event: any;
  isSelected = (event: any) => {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    return this.daysSelected.find(x => x == date) ? "selected" : null;
  };

  @ViewChild('dayInput', { static: true }) dayInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @ViewChild('allSelected', { static: true }) allSelected: MatOption;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private scheduletimingsService: ScheduletimingsService,
    private getScheduleTimingsService: GetscheduletimingsService,
    private toaster: ToastrService,
    private getProviderHolidayService: GetproviderholidaysService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    this.minDate = new Date();

    this.ownerForm = new FormGroup({
      working_days: new FormControl('', [Validators.required]),
      durationValue: new FormControl('', [Validators.min(5), durationValidator, Validators.required]),
      holiday_list: new FormControl(''),
      personal_leaves: new FormControl(''),
      holiday_year: new FormControl(moment().format('YYYY')),
      holiday_month: new FormControl(''),
    });

    this.timeForm = new FormGroup({
      start_time: new FormControl(''),
      end_time: new FormControl(''),
      break_start_time: new FormControl(''),
      break_end_time: new FormControl(''),
    },
      { validators: [this.breakTimeValidator, this.timeValidator] }
    );


    for (let i = this.months.length - 1; i >= 0; i--) {
      if (moment(this.months[i], 'MMMM').isBefore(moment(), 'month')) {
        this.months.splice(i, 1);
      }
    }

    // this.years.push(moment().subtract(1, 'years').format('YYYY'));
    this.years.push(moment().format('YYYY'));
    // this.years.push(moment().add(1, 'year').format('YYYY'));

    this.checkValue

    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getScheduleTimings', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.scheduleTimings = _response.body;
            this.start_time = moment(_response.body.start_time, 'hh:mm A').format('HH:mm');
            this.end_time = moment(_response.body.end_time, 'hh:mm A').format('HH:mm');
            this.break_start_time = moment(_response.body.break_start_time, 'hh:mm A').format('HH:mm');
            this.break_end_time = moment(_response.body.break_end_time, 'hh:mm A').format('HH:mm');

            if (this.break_start_time == '12:00:00' && this.break_end_time == '12:00:00') {
              this.break_start_time = '12:00:00';
              this.break_end_time = '12:00:00';
            }

            this.days = _response.body.working_days.split(' ');
            this.days.pop();
            this.default_time_duration = _response.body.default_time_duration;

            if (_response.body.holiday_list) {
              this.providerHolidays = _response.body.holiday_list.split(' ');
              this.providerHolidays.pop();
              console.log('holiday:', this.providerHolidays);
              for (let i = 0; i < this.providerHolidays.length; i++) {
                this.providerHolidays[i] = moment(this.providerHolidays[i]).format('ddd, DD MMM YYYY');
              }
            }

            if (_response.body.personal_leaves) {
              this.personal_leaves = _response.body.personal_leaves.split(' ');
              this.personal_leaves.pop();
              for (let i = 0; i < this.personal_leaves.length; i++) {
                this.personal_leaves[i] = moment(this.personal_leaves[i]).format('YYYY-MM-DD');
                // this.personal_leaves[i] = moment(this.personal_leaves[i]).toDate();
              }
              this.ownerForm.controls['personal_leaves'].setValue(this.personal_leaves);
            }

            this.timeForm.controls['start_time'].setValue(this.start_time);
            this.timeForm.controls['end_time'].setValue(this.end_time);
            this.ownerForm.controls['durationValue'].setValue(this.default_time_duration);
            this.ownerForm.controls['working_days'].setValue(this.days);
            this.ownerForm.controls['holiday_list'].setValue(this.providerHolidays);
            this.timeForm.controls['break_start_time'].setValue(this.break_start_time);
            this.timeForm.controls['break_end_time'].setValue(this.break_end_time);
            this.ownerForm.controls['personal_leaves'].setValue(this.personal_leaves);
          }
        },
        (_error) => {

        });

    this.getProviderHolidayService.GetproviderholidaysService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.holiday_list = _response.body;
            for (let i = 0; i < this.holiday_list.length; i++) {
              this.holiday_list[i].holiday_date = moment(this.holiday_list[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          }
        },
        (_error) => {

        });
  }

  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid && this.timeForm.valid) {
      this.changeSchedule(ownerFormValue);
    } else if (!this.timeForm.valid) {
      this.toaster.error('Please check working hours');
    } else {
      this.toaster.error('Please enter all details');
    }
  }

  changeSchedule(ownerFormValue) {
    let days_str = '';
    let holiday_str = '';
    let personal_leaves_str = '';
    for (var i = 0; i < ownerFormValue.working_days.length; i++) {
      days_str += ownerFormValue.working_days[i] + ' ';
    }

    if (ownerFormValue.holiday_list[ownerFormValue.holiday_list.length - 1] == 0) {
      ownerFormValue.holiday_list.pop();
    }
    for (let i = 0; i < ownerFormValue.holiday_list.length; i++) {
      holiday_str += moment(ownerFormValue.holiday_list[i], 'ddd, DD MMM YYYY').format('YYYY-MM-DD') + ' ';
    }

    for (let i = 0; i < ownerFormValue.personal_leaves.length; i++) {
      personal_leaves_str += moment(ownerFormValue.personal_leaves[i], 'YYYY-MM-DD').format('YYYY-MM-DD') + ' ';
    }

    let user: any = {
      provider_id: this.provider_id,
      userid: this.userid,
      working_start_time: moment(this.timeForm.controls['start_time'].value, 'hh:mm').format('HH:mm:SS'),
      working_end_time: moment(this.timeForm.controls['end_time'].value, 'hh:mm').format('HH:mm:SS'),
      working_days: days_str,
      default_time_duration: ownerFormValue.durationValue,
      holiday_list: holiday_str,
      break_start_time: this.timeForm.controls['break_start_time'].value == 'Invalid date' || this.timeForm.controls['break_start_time'].value == '' ? '' : moment(this.timeForm.controls['break_start_time'].value, 'hh:mm').format('HH:mm:SS'),
      break_end_time: this.timeForm.controls['break_end_time'].value == 'Invalid date' || this.timeForm.controls['break_end_time'].value == '' ? '' : moment(this.timeForm.controls['break_end_time'].value, 'hh:mm').format('HH:mm:SS'),
      personal_leaves: personal_leaves_str
    };

    this.server.request('POST', '/providers/scheduleTimings', user)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Schedule changed');
        },
        (_error) => {

        });
  }

  select(event: any, calendar: any) {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    const index = this.daysSelected.findIndex(x => x == date);
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);

    calendar.updateTodaysDate();
    this.ownerForm.controls['personal_leaves'].setValue(this.daysSelected);
  }

  togglePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.ownerForm.controls.holiday_list.value.length == this.holiday_list.length)
      this.allSelected.select();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.ownerForm.controls.holiday_list
        .patchValue([...this.holiday_list.map(item => item.holiday_date), 0]);
    } else {
      this.ownerForm.controls.holiday_list.patchValue([]);
    }
  }

  breakTimeValidator: ValidatorFn = (timeForm: FormGroup) => {
    if (timeForm.dirty) {
      const start = timeForm.get('start_time').value;
      const end = timeForm.get('end_time').value;
      const break_start = timeForm.get('break_start_time').value;
      const break_end = timeForm.get('break_end_time').value;

      if (timeForm.controls['break_start_time'].value !== '' && timeForm.controls['break_end_time'].value !== '') {
        let startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
        let endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));
        let timeCondition = moment(break_end, 'hh:mm').diff(moment(break_start, 'hh:mm'), 'minute') <= 240 && moment(break_end, 'hh:mm').diff(moment(break_start, 'hh:mm'), 'minute') > 0;

        if (!startCondition || !endCondition || !timeCondition) {
          return { 'breakNotValid': true };
        }
      }

      // let startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
      // let endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));

      // if (!startCondition || !endCondition) {
      //   return { 'breakNotValid': true };
      // }
    }
    return null;
  }

  timeValidator: ValidatorFn = (timeForm: FormGroup) => {
    const start = timeForm.get('start_time').value;
    const end = timeForm.get('end_time').value;
    const break_start = timeForm.get('break_start_time').value;
    const break_end = timeForm.get('break_end_time').value;
    let startCondition, endCondition;

    const duration = this.ownerForm.controls['durationValue'].value;

    let condition = start !== null && end !== null && (moment(end, 'hh:mm').diff(moment(start, 'hh:mm'), 'minute') >= duration);

    console.log("condition:", condition);
    if (timeForm.controls['break_start_time'].value !== '' && timeForm.controls['break_end_time'].value !== '' && timeForm.controls['break_start_time'].value !== timeForm.controls['break_end_time'].value) {
      startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
      endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));
    }

    this.breakTimeValidator;
    if (!condition) {
      return { 'timeNotValid': true };
    }
    // if (!startCondition || !endCondition) {
    //   return { 'breakNotValid': true };
    // }
    return null;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.timeForm.controls[controlName].hasError(errorName);
  }

  selectHolidayMonth(event) {
    if (event.value == 'none') {
      this.getProviderHolidayService.GetproviderholidaysService()
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.showSelectAll = true;
            this.holiday_list = _response.body;
            for (let i = 0; i < this.holiday_list.length; i++) {
              this.holiday_list[i].holiday_date = moment(this.holiday_list[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          } else {
            this.showSelectAll = false;
            this.holiday_list = [];
          }
        },
          (_error) => {
            this.showSelectAll = false;
            this.holiday_list = [];
          });
    } else {
      let query = `?month=${event.value}`;
      if (this.ownerForm.controls['holiday_year'].value !== 'none') {
        query += `&year=${this.ownerForm.controls['holiday_year'].value}`;
      } else {
        query += `&year=${moment().format('YYYY')}`;
      }
      this.getProviderHolidayService.GetproviderholidaysService(query)
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.showSelectAll = true;
            this.holiday_list = _response.body;
            for (let i = 0; i < this.holiday_list.length; i++) {
              this.holiday_list[i].holiday_date = moment(this.holiday_list[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          } else {
            this.showSelectAll = false;
            this.holiday_list = [];
          }
        },
          (_error) => {
            this.showSelectAll = false;
            this.holiday_list = [];
          });
    }
  }

  selectHolidayYear(event) {
    if (event.value == 'none') {
      this.getProviderHolidayService.GetproviderholidaysService()
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.showSelectAll = true;
            this.holiday_list = _response.body;
            for (let i = 0; i < this.holiday_list.length; i++) {
              this.holiday_list[i].holiday_date = moment(this.holiday_list[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          } else {
            this.showSelectAll = false;
            this.holiday_list = [];
          }
        },
          (_error) => {
            this.showSelectAll = false;
            this.holiday_list = [];
          });
    } else {
      let query = `?year=${event.value}`;
      if (this.ownerForm.controls['holiday_month'].value !== 'none') {
        query += `&month=${this.ownerForm.controls['holiday_month'].value}`;
      }
      // let query = `?year=${this.ownerForm.controls['holiday_year'].value}`;
      this.getProviderHolidayService.GetproviderholidaysService(query)
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.showSelectAll = true;
            this.holiday_list = _response.body;
            for (let i = 0; i < this.holiday_list.length; i++) {
              this.holiday_list[i].holiday_date = moment(this.holiday_list[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          } else {
            this.showSelectAll = false;
            this.holiday_list = [];
          }
        },
          (_error) => {
            this.showSelectAll = false;
            this.holiday_list = [];
          });
    }
  }

  checkValue() {
    if (this.ownerForm.controls['personal_leaves'].value.length == 0) {
      this.ownerForm.controls['personal_leaves'].setValue('');
    }
  }

}
