import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ForgotpasswordService } from '../../services/forgotpassword/forgotpassword.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { ErrorStateMatcher } from '@angular/material/core';
import { CryptoServicesService } from '../../services/crypto-services/crypto-services.service';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  hide = true;
  confirmPasswordHide = true;
  mobileValid = false;
  mobile_number: ""
  otpValid = false;
  clicked = false;
  mobileQueryParam = '';
  otpQueryParam = '';
  user_id = '';
  public ownerForm: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  isActive;
  button_text = 'Send OTP';

  constructor(private formBuilder: FormBuilder,
    private forgotPasswordService: ForgotpasswordService,
    private location: Location,
    private Router: Router,
    private toastr: ToastrService,
    private cryptoService: CryptoServicesService) { }

  ngOnInit() {
    this.ownerForm = new FormGroup({
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
      otp: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]),
      confirmpassword: new FormControl('')
    },
      // passwordMatchValidator 
      {
        validators: this.passwordValidator
      });
  }

  verifyMobile() {
    this.mobileValid = true;
    this.mobileQueryParam = `?mobile_number=${this.ownerForm.value.mobile_number}`;
    this.forgotPasswordService.forgotPasswordByMobile(this.mobileQueryParam)
    .subscribe(
      (_response) => {
        if (_response.status === 200) {
          this.toastr.success('', 'OTP sent to mobile number');
          this.mobileValid = true;
          this.clicked = true;
          this.button_text = 'Resend OTP';
        } else {
          this.toastr.error('', 'Mobile number is not registered');
          this.clicked = false;
          this.button_text = 'Resend OTP';
        }
      },
      (_error) => {
        if (_error.status === 409) {
          this.toastr.error('', 'Mobile number is not registered');
          this.clicked = false;
          this.button_text = 'Resend OTP';
        }
      });
  }

  verifyOtp() {
    this.otpQueryParam = `?mobile=${this.ownerForm.value.mobile_number}&otp_value=${this.ownerForm.value.otp}`;
    this.forgotPasswordService.verifyOtpByOtp(this.otpQueryParam)
    .subscribe(
      (_response) => {
        if (_response.status === 200) {
          this.toastr.success('', 'OTP verified');
          this.user_id = _response.body.userid;
          this.otpValid = true;
          this.mobileValid = false;
        }
      },
      (_error) => {
        this.toastr.error('Invalid OTP, please try again');
        this.clicked = false;
        this.button_text = 'Resend OTP';
      });
  }

  changePassword() {
    let password = {
      user_id: this.user_id,
      // new_password: this.ownerForm.value.password
      new_password: this.cryptoService.set('secret key 123', this.ownerForm.value.password)
    };
    this.toastr.success('', 'Password Changed');
    this.forgotPasswordService.generateNewPassword(password)
    .subscribe(
      (_response) => {
        if (_response.status === 200) {
          this.toastr.success('', 'Password Changed');
          this.Router.navigate(['']);
        }
      },
      (_error) => {

      });
      this.Router.navigate(['']);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }

  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('password').value !== ownerForm.get('confirmpassword').value;
    return condition ? { passwordsDoNotMatch: true } : null;
  }

}
