import { Component, OnInit, ViewChild } from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ProfileServiceService } from '../../../services/getprofile/profile-service.service';
import { GetuserbypaystatusService } from '../../../services/getuserbypaystatus/getuserbypaystatus.service';
import * as moment from 'moment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-monthly-invoice',
  templateUrl: './monthly-invoice.component.html',
  styleUrls: ['./monthly-invoice.component.css']
})
export class MonthlyInvoiceComponent implements OnInit {
  provider_id;
  userid;
  provider_details;
  cov_details;
  pay_online_details;
  advance_pay_details;
  cov_total;
  pay_online_total;
  pay_advance_total;
  month;
  month_name;
  year;
  rangeDates: Date[];
  invoice_cov_total = 0;
  invoice_online_total = 0;
  invoice_advance_total = 0;
  invoiceForm: FormGroup;
  details = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['name', 'mobile_number', 'date', 'total_cost', 'pay_status', 'appoint_status'];
  detailsAvailable;
  cov_amount = 0;
  gst_total_full_amount;
  month_gst_total_online_amount;
  gst_total_advance_amount;
  start_date;
  end_date;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private ProfileService: ProfileServiceService,
    private getUsersByPayStatusService: GetuserbypaystatusService,
    private server: JwtTestService,
    private toaster: ToastrService
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngAfterViewInit() {
    this.details.paginator = this.paginator;
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    this.invoiceForm = new FormGroup({
      start_date: new FormControl(''),
      end_date: new FormControl('')
    });

    let query = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${this.userid}`
    this.ProfileService.ProfileServiceService(query, l_userid)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.provider_details = _response.body;
          }
        },
        (_error) => {

        });

    let queryParam = {
      provider_id: this.provider_id,
      appoint_status: 1
    };

    this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
      .subscribe(
        (_response: any) => {

          if (_response.status === 200) {
            this.detailsAvailable = true;
            this.details.data = _response.body.results;
            this.cov_amount = _response.body.cov_amount;
            this.month_gst_total_online_amount = _response.body.month_gst_total_online_amount;
            this.gst_total_advance_amount = _response.body.gst_total_advance_amount.toFixed(2);
            this.gst_total_full_amount = _response.body.gst_total_full_amount;

            this.start_date = moment(this.details.data[0].start_datetime).format('DD MMM YYYY');
            this.end_date = moment(this.details.data[this.details.data.length - 1].end_datetime).format('DD MMM YYYY');

            for (var i = 0; i < this.details.data.length; i++) {
              this.details.data[i].date = moment(this.details.data[i].start_datetime).format('DD MMM YYYY');

              if (this.details.data[i].pay_status == '0') {
                this.details.data[i].pay_status = 'COV';
              }
              if (this.details.data[i].pay_status == '1') {
                this.details.data[i].pay_status = 'Full Payment';
              }
              if (this.details.data[i].pay_status == '2') {
                this.details.data[i].pay_status = 'Advance Payment';
              }

              if (this.details.data[i].appoint_status == '3') {
                this.details.data[i].appoint_status = 'Refunded';
              }
              if (this.details.data[i].appoint_status == '2') {
                this.details.data[i].appoint_status = 'Rejected';
              }
              if (this.details.data[i].appoint_status == '1') {
                this.details.data[i].appoint_status = 'Accepted';
              }
              if (this.details.data[i].appoint_status == '0') {
                this.details.data[i].appoint_status = 'Pending';
              }
            }
          }
        },
        (_error) => {
          this.detailsAvailable = false;
        });

    this.getCovPayments();
    this.getOnlinePayments();
    this.getAdvancePayments();
  }

  getDates(event) {
    console.log('range: ', event);

    this.start_date = moment(event.start_date, 'YYYY-MM-DD').format('DD MMM YYYY');
    this.end_date = moment(event.end_date, 'YYYY-MM-DD').format('DD MMM YYYY');

    this.getCovPayments(event);
    this.getOnlinePayments(event);
    this.getAdvancePayments(event);
    this.getAllPayments(event);
  }

  getCovPayments(event?, month?, month_name?) {
    let cov_payment_query = {
      provider_id: this.provider_id,
      pay_status: 0,
      appoint_status: 1,
      // month: this.month ? this.month : ``,
      // month_name: this.month_name ? this.month_name : ``,
      // year: this.year ? this.year : ``
      // start_date: this.rangeDates ? moment(this.rangeDates[0]).format('YYYY-MM-DD') : ``,
      // end_date: this.rangeDates ? moment(this.rangeDates[1]).format('YYYY-MM-DD') : ``
      start_date: event ? moment(event.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``,
      end_date: event ? moment(event.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``
    };

    this.server.request('GET', '/providers/getUsersByPayStatus', cov_payment_query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.cov_details = _response.body.results;
            this.cov_total = _response.body.month_cov_amount;
            this.invoice_cov_total = 0;

            for (var i = 0; i < this.cov_details.length; i++) {
              this.cov_details[i].date = moment(this.cov_details[i].start_datetime).format('DD MMM YYYY');

              if (this.cov_details[i].appoint_status == '3') {
                this.cov_details[i].appoint_status = 'Refunded';
              }
              if (this.cov_details[i].appoint_status == '2') {
                this.cov_details[i].appoint_status = 'Rejected';
              }
              if (this.cov_details[i].appoint_status == '1') {
                this.cov_details[i].appoint_status = 'Accepted';
              }
              if (this.cov_details[i].appoint_status == '0') {
                this.cov_details[i].appoint_status = 'Pending';
              }

              if (this.start_date && this.end_date) {
                this.invoice_cov_total += this.cov_details[i].total_cost;
              }
            }
          }
        },
        (_error) => {
          this.cov_total = 0;
          this.invoice_cov_total = 0;
          this.cov_details = [];
        });
  }

  getOnlinePayments(event?, month?, month_name?) {
    let online_payment_query = {
      provider_id: this.provider_id,
      pay_status: 1,
      appoint_status: 1,
      // month: this.month ? this.month : ``,
      // month_name: this.month_name ? this.month_name : ``,
      // year: this.year ? this.year : ``
      // start_date: this.rangeDates ? moment(this.rangeDates[0]).format('YYYY-MM-DD') : ``,
      // end_date: this.rangeDates ? moment(this.rangeDates[1]).format('YYYY-MM-DD') : ``
      start_date: event ? moment(event.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``,
      end_date: event ? moment(event.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``
    };

    this.server.request('GET', '/providers/getUsersByPayStatus', online_payment_query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.pay_online_details = _response.body.results;
            this.pay_online_total = _response.body.gst_total_full_amount;

            for (var i = 0; i < this.pay_online_details.length; i++) {
              this.pay_online_details[i].date = moment(this.pay_online_details[i].start_datetime).format('DD MMM YYYY');

              if (this.pay_online_details[i].appoint_status == '3') {
                this.pay_online_details[i].appoint_status = 'Refunded';
              }
              if (this.pay_online_details[i].appoint_status == '2') {
                this.pay_online_details[i].appoint_status = 'Rejected';
              }
              if (this.pay_online_details[i].appoint_status == '1') {
                this.pay_online_details[i].appoint_status = 'Accepted';
              }
              if (this.pay_online_details[i].appoint_status == '0') {
                this.pay_online_details[i].appoint_status = 'Pending';
              }

              if (this.start_date && this.end_date) {
                this.invoice_online_total += this.pay_online_details[i].total_cost - (this.pay_online_details[i].total_cost * 0.03);
              }
            }
          }
        },
        (_error) => {
          this.pay_online_details = [];
          this.pay_online_total = 0;
          this.invoice_online_total = 0;
        });
  }

  getAdvancePayments(event?, month?, month_name?) {
    let advance_payment_query = {
      provider_id: this.provider_id,
      pay_status: 2,
      appoint_status: 1,
      // month: this.month ? this.month : ``,
      // month_name: this.month_name ? this.month_name : ``,
      // year: this.year ? this.year : ``
      // start_date: this.rangeDates ? moment(this.rangeDates[0]).format('YYYY-MM-DD') : ``,
      // end_date: this.rangeDates ? moment(this.rangeDates[1]).format('YYYY-MM-DD') : ``
      start_date: event ? moment(event.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``,
      end_date: event ? moment(event.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``
    };

    this.server.request('GET', '/providers/getUsersByPayStatus', advance_payment_query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.advance_pay_details = _response.body.results;
            this.pay_advance_total = _response.body.gst_total_advance_amount.toFixed(2);

            for (var i = 0; i < this.advance_pay_details.length; i++) {
              this.advance_pay_details[i].date = moment(this.advance_pay_details[i].start_datetime).format('DD MMM YYYY');

              if (this.advance_pay_details[i].appoint_status == '3') {
                this.advance_pay_details[i].appoint_status = 'Refunded';
              }
              if (this.advance_pay_details[i].appoint_status == '2') {
                this.advance_pay_details[i].appoint_status = 'Rejected';
              }
              if (this.advance_pay_details[i].appoint_status == '1') {
                this.advance_pay_details[i].appoint_status = 'Accepted';
              }
              if (this.advance_pay_details[i].appoint_status == '0') {
                this.advance_pay_details[i].appoint_status = 'Pending';
              }

              if (this.start_date && this.end_date) {
                this.invoice_advance_total += this.advance_pay_details[i].total_cost - (this.advance_pay_details[i].total_cost * 0.03);
              }
            }
          }
        },
        (_error) => {
          this.pay_advance_total = 0;
          this.advance_pay_details = [];
          this.invoice_advance_total = 0;
        });
  }

  getAllPayments(event?) {
    let queryParam = {
      provider_id: this.provider_id,
      pay_status: ``,
      appoint_status: 1,
      // month: this.month ? this.month : ``,
      // month_name: this.month_name ? this.month_name : ``,
      // year: this.year ? this.year : ``
      // start_date: this.rangeDates ? moment(this.rangeDates[0]).format('YYYY-MM-DD') : ``,
      // end_date: this.rangeDates ? moment(this.rangeDates[1]).format('YYYY-MM-DD') : ``
      start_date: event ? moment(event.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``,
      end_date: event ? moment(event.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ``
    };

    this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
      .subscribe(
        (_response: any) => {

          if (_response.status === 200) {
            this.detailsAvailable = true;
            this.details.data = _response.body.results;
            this.cov_amount = _response.body.cov_amount;
            this.month_gst_total_online_amount = _response.body.month_gst_total_online_amount;
            this.gst_total_advance_amount = _response.body.gst_total_advance_amount.toFixed(2);
            this.gst_total_full_amount = _response.body.gst_total_full_amount;

            for (var i = 0; i < this.details.data.length; i++) {
              this.details.data[i].date = moment(this.details.data[i].start_datetime).format('DD MMM YYYY');

              if (this.details.data[i].pay_status == '0') {
                this.details.data[i].pay_status = 'COV';
              }
              if (this.details.data[i].pay_status == '1') {
                this.details.data[i].pay_status = 'Full Payment';
              }
              if (this.details.data[i].pay_status == '2') {
                this.details.data[i].pay_status = 'Advance Payment';
              }

              if (this.details.data[i].appoint_status == '3') {
                this.details.data[i].appoint_status = 'Refunded';
              }
              if (this.details.data[i].appoint_status == '2') {
                this.details.data[i].appoint_status = 'Rejected';
              }
              if (this.details.data[i].appoint_status == '1') {
                this.details.data[i].appoint_status = 'Accepted';
              }
              if (this.details.data[i].appoint_status == '0') {
                this.details.data[i].appoint_status = 'Pending';
              }
            }
          }
        },
        (_error) => {
          this.detailsAvailable = false;
          this.details.data = [];
        });
  }

  selectMonth() {
    let event = {
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD')
    };

    this.start_date = moment().startOf('month').format('DD MMM YYYY');
    this.end_date = moment().endOf('month').format('DD MMM YYYY');

    this.getCovPayments(event);
    this.getOnlinePayments(event);
    this.getAdvancePayments(event);
    this.getAllPayments(event);
  }

  selectPrevMonth() {
    let event = {
      start_date: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      end_date: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    };

    this.start_date = moment().subtract(1, 'month').startOf('month').format('DD MMM YYYY');
    this.end_date = moment().subtract(1, 'month').endOf('month').format('DD MMM YYYY');

    this.getCovPayments(event);
    this.getOnlinePayments(event);
    this.getAdvancePayments(event);
    this.getAllPayments(event);
  }

  selectThreeMonths() {
    let event = {
      start_date: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD')
    };

    this.start_date = moment().subtract(3, 'month').startOf('month').format('DD MMM YYYY');
    this.end_date = moment().endOf('month').format('DD MMM YYYY');

    this.getCovPayments(event);
    this.getOnlinePayments(event);
    this.getAdvancePayments(event);
    this.getAllPayments(event);
  }

  selectFinancialYear() {
    let event = {
      start_date: '',
      end_date: ''
    };

    console.log('today: ', moment().format('YYYY-MM-DD'))
    console.log('before: ', moment().month(2).endOf('month').format('YYYY-MM-DD'))
    console.log('bedROre: ', moment().isBefore(moment().month(2).endOf('month')))
    if (moment().isAfter(moment().month(2).endOf('month'))) {
      event = {
        start_date: moment().month(3).startOf('month').format('YYYY-MM-DD'),
        end_date: moment().add(1, 'year').month(2).endOf('month').format('YYYY-MM-DD')
      };

      this.start_date = moment().month(3).startOf('month').format('DD MMM YYYY');
      this.end_date = moment().add(1, 'year').month(2).endOf('month').format('DD MMM YYYY');
    } else if (moment().isBefore(moment().month(2).endOf('month'))) {
      event = {
        start_date: moment().subtract(1, 'year').month(3).startOf('month').format('YYYY-MM-DD'),
        end_date: moment().month(2).endOf('month').format('YYYY-MM-DD')
      };

      this.start_date = moment().subtract(1, 'year').month(3).startOf('month').format('DD MMM YYYY');
      this.end_date = moment().month(2).endOf('month').format('DD MMM YYYY');
    }

    this.getCovPayments(event);
    this.getOnlinePayments(event);
    this.getAdvancePayments(event);
    this.getAllPayments(event);
  }

  generatePdf(action) {
    let docDefinition = {
      content: [
        {
          text: 'APOINTT',
          fontSize: 16,
          alignment: 'center',
          color: '#047886'
        },
        // {
        //   // image: '../../../../assets/image/final-removebg-preview.png',
        //   image: 'https://ik.imagekit.io/zi19k9ks6j/pexels-gustavo-fring-3873853_8UX4QEEZT.jpg',
        //   width: 150
        // },
        {
          text: 'INVOICE',
          fontSize: 20,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          color: 'skyblue'
        },
        {
          text: 'Provider Details',
          style: 'sectionHeader'
        },
        {
          columns: [
            [
              {
                text: this.provider_details[0].p_name,
                bold: true
              },
              { text: this.provider_details[0].address },
              { text: this.provider_details[0].provider_mobile_no }
            ],
            [
              { text: `Date: ${moment().format('DD/MM/YYYY hh:mm A')}`, alignment: 'right' }
            ]
          ]
        },
        {
          text: this.cov_details.length > 0 ? 'Cash On Visit Appointments' : '',
          style: 'sectionHeader'
        },
        this.cov_details.length > 0 ? {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Name', 'Contact', 'Date', 'Cost'],
              ...this.cov_details.map(p => ([p.name, p.mobile_number, moment(p.start_datetime).format('DD/MM/YY'), p.total_cost])),
              [{ text: 'Total Amount', colSpan: 3 }, {}, {}, this.invoice_cov_total ? this.invoice_cov_total : 0]
            ]
          }
        } : '',
        {
          text: this.pay_online_details.length > 0 ? 'Online Payment Appointments' : '',
          style: 'sectionHeader'
        },
        this.pay_online_details.length > 0 ? {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Name', 'Contact', 'Date', 'Cost'],
              ...this.pay_online_details.map(p => ([p.name, p.mobile_number, moment(p.start_datetime).format('DD/MM/YY'), p.total_cost])),
              [{ text: 'Total Amount (3% Service charge and GST get deducted from total amount)', colSpan: 3 }, {}, {}, this.invoice_online_total ? this.invoice_online_total : 0]
            ]
          }
        } : '',
        {
          text: this.advance_pay_details.length > 0 ? 'Advance Payment Appointments' : '',
          style: 'sectionHeader'
        },
        this.advance_pay_details.length > 0 ? {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Name', 'Contact', 'Date', 'Cost'],
              ...this.advance_pay_details.map(p => ([p.name, p.mobile_number, moment(p.start_datetime).format('DD/MM/YY'), p.total_cost])),
              [{ text: 'Total Amount (3% Service charge and GST get deducted from total amount)', colSpan: 3 }, {}, {}, this.invoice_advance_total ? this.invoice_advance_total.toFixed(2) : 0]
            ]
          }
        } : '',
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        }
      }
    };

    if (action === 'download') {
      this.toaster.success('Invoice downloaded');
      pdfMake.createPdf(docDefinition).download(moment(this.start_date, 'DD MMM YYYY').format('YYYY-MM-DD') + '-to-' + moment(this.end_date, 'DD MMM YYYY').format('YYYY-MM-DD') + '.pdf');
    } else if (action === 'open') {
      pdfMake.createPdf(docDefinition).open();
    }
  }

}
