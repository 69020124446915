import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder,FormControl,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location, WeekDay } from '@angular/common';
import { from } from 'rxjs';;
import {DtServicesService}from '../../../services/dt-services/dt-services.service'

@Component({
  selector: 'app-dt-scheduler',
  templateUrl: './dt-scheduler.component.html',
  styleUrls: ['./dt-scheduler.component.css']
})
export class DtSchedulerComponent {
  public ownerForm: FormGroup;
 
  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private DtServicesService:DtServicesService) { }

  ngOnInit() {
    this.ownerForm = new FormGroup({
      OpenTime: new FormControl(''),
      ClosedTime: new FormControl(''),
     });
    }
    public onCancel = () => {
      this.location.back();
    }
    public createOwner = (ownerFormValue) => {
      if (this.ownerForm.valid) {
        this.Timing(ownerFormValue);
      }
    }
   
    Timing(ownerFormValue) {
      let user: any = { 
        OpenTime: ownerFormValue.OpenTime,
        ClosedTime: ownerFormValue.ClosedTime,
  
      };

      this.DtServicesService.DtServicesService(user)
      .subscribe(
       (_response) => {
         if (_response.status === 200) {  
         }
       },
       (_error) => {
         alert('User already exist or failure');
       }
     );
   console.log('data', user);
   }
 }
