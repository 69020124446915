import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GetsubcategoryserviceService } from '../../services/getsubcategory/getsubcategoryservice.service';
import { PartnerenquirydetailsService } from '../../services/partnerenquirydetails/partnerenquirydetails.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.css']
})
export class EnquiryFormComponent implements OnInit {
  enquiry_form: FormGroup;
  subCatId = '';
  category = [];
  specialization = '';

  constructor(
    private getSubCategoryService: GetsubcategoryserviceService,
    private partnerEnquiryDetailsService: PartnerenquirydetailsService,
    private toaster: ToastrService,
  ) { }

  ngOnInit() {
    this.enquiry_form = new FormGroup({
      full_name: new FormControl(''),
      category: new FormControl(''),
      sub_category: new FormControl(''),
      address_text: new FormControl(''),
      pincode: new FormControl(''),
      partner_code: new FormControl('')
    });
  }

  selectSubCat(event) {
    this.subCatId = event;
    console.log('event: ', event);

    let query = `?category_name=${event}`;
    this.getSubCategoryService.GetsubcategoryserviceService(query)
      .subscribe((_response) => {
        if (_response.status === 200) {
          this.category = _response.body;
        } else {
          this.category = [];
        }
      }, (_error) => {
        this.category = [];
      });
  }

  selectSpecialization(event) {
    this.specialization = event.value;
  }

  submitEnquiry(enquiryFormValue) {
    let enquiry = {
      patner_id: enquiryFormValue.partner_code,
      provider_fullname: enquiryFormValue.full_name,
      category_id: (this.subCatId == 'Doctor' ? 1
        : this.subCatId == 'Lawyer' ? 2
          : this.subCatId == 'Beautician' ? 3
            : this.subCatId == 'Chartered Accountant' ? 4
              : 5),
      address_text: enquiryFormValue.address_text,
      pincode: enquiryFormValue.pincode
    };

    this.partnerEnquiryDetailsService.addPartnerEnquiryDetails(enquiry)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.toaster.success('Enquiry submitted successfully');
          }
        },
        (_error) => {
          this.toaster.error('Could not submit enquiry');
        });
  }

}
