import { Component, OnInit, OnDestroy, NgZone, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormControlDirective, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { debounceTime, switchMap, tap, finalize } from 'rxjs/operators';
import { ContactInfoService } from '../../services/contact-info/contact-info.service';
import { GetContactInfoService } from '../../services/contact-info/get-contact-info.service';
import { CompanyProfileService } from '../../services/company-profile.service';
import { CategoryLevelService } from '../../services/categoryByLevel/category-level.service';
import { ConfirmAccountService } from '../../services/confirm-account/confirm-account.service';
import { MoreinfoService } from '../../services/moreinfo/moreinfo.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AddproviderimagesService } from '../../services/addproviderimages/addproviderimages.service';
import { GetsettingsService } from '../../services/getsettings/getsettings.service';
import { GetsubcategoryserviceService } from '../../services/getsubcategory/getsubcategoryservice.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UploadcertificationdocService } from '../../services/uploadcertificationdoc/uploadcertificationdoc.service';
import { UploadpersonalidentitydocService } from '../../services/uploadpersonalidentitydoc/uploadpersonalidentitydoc.service';
import { GetproviderholidaysService } from '../../services/getproviderholidays/getproviderholidays.service';
import { MatOption } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { GetscheduletimingsService } from '../../services/getscheduletimings/getscheduletimings.service';
import { GetAllLanguagesService } from '../../services/getAllLanguages/get-all-languages.service';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';
import PlaceResult = google.maps.places.PlaceResult;
import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';
import { TabService } from '../../services/tab-service/tab-service.service';
import { BrowserModule, Meta } from '@angular/platform-browser';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

export interface Language {
  name: String
}

export interface Degree {
  name: String
}

export interface Leaves {
  name: String
}

interface salutation {
  value: string,
  viewValue: string
}

function durationValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value !== null && (control.value % 5 !== 0)) {
    return { 'durationNotValid': true }
  }
  return null;
}

@Component({
  selector: 'app-business-wizard',
  templateUrl: './business-wizard.component.html',
  styleUrls: ['./business-wizard.component.css'],
  // encapsulation: ViewEncapsulation.None
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  },
    TabService]
})
export class BusinessWizardComponent implements OnInit, OnDestroy {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  timeFormGroup: FormGroup;
  salutations: string[] = ['Mr', 'Mrs', 'Adv', 'Dr', 'Miss'];
  mobile_number = '';
  isLoading = false;
  category = [];
  filtered_category = [];
  business_name = '';
  companyprofile = [];
  contactinfo = [];
  citycountry = [];
  moreinfo = [];
  provider_id = '';
  userid = '';
  duration = '';
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [COMMA];
  readonly degreeSeparatorKeysCodes: number[] = [ENTER, COMMA];
  languages = [];
  degrees = [];
  // days: string[] = [];
  allDays: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  working_days = new FormControl('');
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  filesToUpload: Array<File> = [];
  urls: any[] = [];
  pay_methods = [
    { value: 0, option: 'Cash on Visit' },
    { value: 1, option: 'Full Payment' },
    { value: 2, option: 'Advance Payment' }
  ];
  specific_address;
  gender;
  button_text = 'Send OTP';
  show_advance = false;
  specialization = '';
  subCatId = '';
  personalFilesToUpload: Array<File> = [];
  personalUrls: any[] = [];
  certificationFilesToUpload: Array<File> = [];
  certificationUrls: any[] = [];
  providerHolidays = [];
  minDate: Date;
  postcode;
  personalLeaves = [];
  errorMatcher = new CrossFieldErrorMatcher();
  disableOtp = false;
  intervalId;
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years = [];
  daysSelected: any[] = [];
  event: any;
  address_id;
  isSelected = (event: any) => {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    return this.daysSelected.find(x => x == date) ? "selected" : null;
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['strikeThrough', 'subscript', 'superscript']
    ]
  };

  editorConfigMobile: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['strikeThrough', 'subscript', 'superscript', 'undo', 'redo', 'indent', 'outdent', 'insertUnorderedList', 'insertOrderedList',],
      ['backgroundColor', 'customClasses', 'link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode']
    ]
  };

  scheduleTimings = [];
  getHolidays;
  getPersonalLeaves;
  final_address;
  timer;
  getLanguages = [];
  category_array = [];
  showOnlineNote = false;
  showCertificate = true;
  step1 = true;
  step2 = false;
  imageFileName = 'No File Chosen';
  certificateFileName = 'No File Chosen';
  personalFileName = 'No File Chosen';

  filteredLanguages: Observable<string[]>;
  languageCtrl = new FormControl();
  @ViewChild('languageInput', { static: true }) languageInput: ElementRef<HTMLInputElement>;
  @ViewChild('languageAuto', { static: true }) languageAuto: MatAutocomplete;
  @ViewChild('chipList', { static: true }) chipList: MatChipList;
  @ViewChild('degreeChipList', { static: true }) degreeChipList: MatChipList;

  @ViewChild('searchLocation', { static: true })
  public searchElementRef: ElementRef;

  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('personalFileInput', { static: true }) personalFileInput: ElementRef;
  @ViewChild('certificationFileInput', { static: true }) certificationFileInput: ElementRef;

  @ViewChild('allSelected', { static: true }) private allSelected: MatOption;
  // @ViewChild('allSelectedWorkingDays', { static: true }) private allSelectedWorkingDays: MatOption;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private formBuilder: FormBuilder,
    private contactInfoService: ContactInfoService,
    private getContactInfoService: GetContactInfoService,
    private companyProfileService: CompanyProfileService,
    private categoryLevelService: CategoryLevelService,
    private confirmAccountService: ConfirmAccountService,
    private moreInfoService: MoreinfoService,
    private toastr: ToastrService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private addProviderImagesService: AddproviderimagesService,
    private getSettingsService: GetsettingsService,
    private getSubCategoryService: GetsubcategoryserviceService,
    private ngxService: NgxUiLoaderService,
    private uploadCertificationDocService: UploadcertificationdocService,
    private uploadPersonalIdentityDocService: UploadpersonalidentitydocService,
    private getProviderHolidays: GetproviderholidaysService,
    private getScheduleTimingsService: GetscheduletimingsService,
    private getAllLanguagesService: GetAllLanguagesService,
    private server: JwtTestService,
    private metaTagService: Meta,
  ) {
    // this.filteredLanguages = this.languageCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((language: string | null) => language ? this._filter(language) : this.getLanguages.slice()));

    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.mobile_number = localStorage.getItem('mobile_number');
    this.business_name = localStorage.getItem('business_name');
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    this.minDate = moment().toDate();

    this.firstFormGroup = new FormGroup({
      // provider_id: new FormControl(''),
      // userid: new FormControl(''),
      first_name: new FormControl('', [Validators.required]),
      middle_name: new FormControl('',),
      last_name: new FormControl('', [Validators.required]),
      // title: new FormControl('', [Validators.required]),
      address_text: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
      salutation: new FormControl('', [Validators.required]),
      city: new FormControl({ value: '', disabled: true }, [Validators.required]),
      country: new FormControl({ value: '', disabled: true }, [Validators.required]),
      mobile_no: new FormControl({ value: '', disabled: true }),
      gender: new FormControl('1', [Validators.required]),
      // languages: new FormControl('', [Validators.required]),
      experience: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")]),
      image: new FormControl(''),
      lang: new FormControl(this.languages, this.validateLanguages),
      languageCtrl: new FormControl(null),
      email_id: new FormControl(''),
      address_text_2: new FormControl(''),
      state: new FormControl('')
    });

    this.secondFormGroup = new FormGroup({
      provider_id: new FormControl({ value: '', disabled: true }),
      main_category: new FormControl('', [Validators.required]),
      // userid: new FormControl(''),
      type: new FormControl('', [Validators.required]),
      // year_established: new FormControl('', [Validators.required]),
      legal_number: new FormControl('', [Validators.required]),
      education_degree: new FormControl(null),
      // experience: new FormControl(''),
      // serve_into: new FormControl(''),
      consulting_fees: new FormControl('', [Validators.required, Validators.min(10)]),
      working_days: new FormControl('', [Validators.required]),
      pay_methods: new FormControl('', [Validators.required]),
      durationValue: new FormControl('30', [Validators.required, Validators.min(5), durationValidator]),
      advances_payment_cost: new FormControl('', [Validators.min(20), Validators.max(70), Validators.pattern("^[0-9]*$")]),
      holiday_list: new FormControl(''),
      personal_leaves: new FormControl(''),
      // personal_leaves: new FormControl({ value: '', disabled: true }),
      certification_image: new FormControl('', [Validators.required]),
      personal_info_image: new FormControl('', [Validators.required]),
      holiday_year: new FormControl(moment().format('YYYY')),
      holiday_month: new FormControl(''),
      online_consultation_type: new FormControl(''),
      degree: new FormControl(this.degrees, this.validateDegrees)
    }
    );

    this.secondFormGroup
      .get('degree')
      .statusChanges.subscribe(
        status => (this.degreeChipList.errorState = status === 'INVALID')
      );

    this.firstFormGroup.controls['salutation'].valueChanges.subscribe(() => {
      console.log('salutaion: ', this.firstFormGroup.controls['salutation'].value);
      if (this.firstFormGroup.controls['salutation'].value == 'Dr') {
        this.secondFormGroup.controls['main_category'].setValue('doctor');
        this.selectSubCat('doctor');
      } else if (this.firstFormGroup.controls['salutation'].value == 'Adv') {
        this.secondFormGroup.controls['main_category'].setValue('lawyer');
        this.selectSubCat('lawyer');
      }
    });

    // this.secondFormGroup
    //   .get('type')
    //   .valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => {
    //       this.isLoading = true;
    //       console.log('busi category: ', this.secondFormGroup.controls['main_category'].value)
    //     }),
    //     switchMap(value => this.getSubCategoryService.GetsubcategoryserviceService({ category_name: this.secondFormGroup.controls['main_category'].value, cat_name: value })
    //       .pipe(
    //         finalize(() => this.isLoading = false),
    //       ))
    //   )
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.category = _response.body;
    //         console.log(this.category);
    //       } else {
    //         this.category = [];
    //       }
    //     }
    //   ),
    //   (_error) => {
    //     this.category = [];
    //   };

    // this.secondFormGroup
    //   .get('type')
    //   .valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => this.isLoading = true),
    //     switchMap(value => this.categoryLevelService.CategoryLevelService({ sterm: value })
    //       .pipe(
    //         finalize(() => this.isLoading = false),
    //       ))
    //   )
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.category = _response.body;
    //         console.log(this.category);
    //       } else {
    //         this.category = [];
    //       }
    //     }
    //   ),
    //   (_error) => {
    //     this.category = [];
    //   };

    this.timeFormGroup = new FormGroup({
      break_start_time: new FormControl('12:00', [Validators.required]),
      break_end_time: new FormControl('12:00', [Validators.required]),
      start_time: new FormControl('09:00', [Validators.required]),
      end_time: new FormControl('17:00', [Validators.required]),
    },
      { validators: [this.breakTimeValidator, this.timeValidator] });

    this.thirdFormGroup = new FormGroup({
      mobile_number: new FormControl({ value: '', disabled: true }, [Validators.required]),
      confirmation_code: new FormControl('', [Validators.required])
    });

    this.thirdFormGroup.controls['confirmation_code'].disable();
    this.thirdFormGroup.controls['mobile_number'].setValue(this.mobile_number);

    this.fourthFormGroup = new FormGroup({
      introduction: new FormControl('', [Validators.required]),
      specialization: new FormControl('', [Validators.required]),
      work_place: new FormControl('', [Validators.required]),
    });

    this.getAllLanguagesService.GetAllLanguagesService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getLanguages = _response.body;
          } else {
            this.getLanguages = [];
          }
        },
        (_error) => {
          this.getLanguages = [];
        }
      );


    this.firstFormGroup
      .get('lang')
      .statusChanges.subscribe(
        status => (this.chipList.errorState = status === 'INVALID')
      );

    this.filteredLanguages = this.firstFormGroup.controls['languageCtrl'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.getSettings(this.provider_id);
    this.getScheduleTimings(this.provider_id);

    this.setCurrentLocation();

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    for (let i = this.months.length - 1; i >= 0; i--) {
      if (moment(this.months[i], 'MMMM').isBefore(moment(), 'month')) {
        this.months.splice(i, 1);
      }
    }

    this.years.push(moment().subtract(1, 'year').format('YYYY'));
    this.years.push(moment().format('YYYY'));
    this.years.push(moment().add(1, 'year').format('YYYY'));

    this.getProviderHolidays.GetproviderholidaysService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.providerHolidays = _response.body;
            for (let i = 0; i < this.providerHolidays.length; i++) {
              this.providerHolidays[i].holiday_date = moment(this.providerHolidays[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          }
        },
        (_error) => {

        });

    this.metaTagService.updateTag(
      { name: 'description', content: 'Sign up & setup your business profile to start managing customer online/offline bookings, online consultations, payments to grow my business online!' },

    );
  }

  changeImage(event) {
    if (event.selectedIndex == 0) {
      this.step1 = true;
      this.step2 = false;
    } else if (event.selectedIndex == 1) {
      this.step1 = false;
      this.step2 = true;
    }
  }

  getSettings(provider_id) {
    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getSettings', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            // this.settings = _response.body;
            this.firstFormGroup.controls['first_name'].setValue(_response.body.first_name);
            this.firstFormGroup.controls['middle_name'].setValue(_response.body.middle_name);
            this.firstFormGroup.controls['last_name'].setValue(_response.body.last_name);
            this.firstFormGroup.controls['experience'].setValue(_response.body.Provider_experience);
            this.firstFormGroup.controls['salutation'].setValue(_response.body.salutation);
            this.firstFormGroup.controls['postcode'].setValue(_response.body.postcode);
            this.firstFormGroup.controls['mobile_no'].setValue(_response.body.mobile_number);
            this.address_id = _response.body.address_id;

            let provider_languages = _response.body.provider_languages.split(' ');
            provider_languages.pop();

            for (let i = 0; i < provider_languages.length; i++) {
              this.languages.push(provider_languages[i]);
            }
            if (this.languages.length > 0) {
              this.firstFormGroup.controls['lang'].clearValidators();
              this.firstFormGroup.controls['lang'].updateValueAndValidity();
            }

            this.specific_address = _response.body.address_text.slice(0, _response.body.address_text.indexOf(','));
            this.gender = _response.body.gender;

            if (this.gender > 0) {
              this.firstFormGroup.controls['gender'].clearValidators();
              this.firstFormGroup.controls['gender'].updateValueAndValidity();
            }

            let provider_qualification = _response.body.qualification_name.split(' ');
            provider_qualification.pop();
            for (let i = 0; i < provider_qualification.length; i++) {
              this.degrees.push(provider_qualification[i]);
            }
            if (this.degrees.length > 0) {
              this.secondFormGroup.controls['degree'].clearValidators();
              this.secondFormGroup.controls['degree'].updateValueAndValidity();
            }

            this.secondFormGroup.controls['consulting_fees'].setValue(_response.body.cost);
            this.firstFormGroup.controls['address_text'].setValue(this.specific_address);
            this.getCityCountry();
          }
        },
        (_error) => {

        });
  }

  getCityCountry() {
    let postcode = this.firstFormGroup.controls['postcode'].value;
    let queryParam = `?postcode=${postcode}`;
    this.getContactInfoService.GetContactInfoService(queryParam)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.citycountry = _response.body;
            this.firstFormGroup.controls['city'].setValue(this.citycountry[0].city);
            this.firstFormGroup.controls['country'].setValue(this.citycountry[0].country);
          }
        }
      )
  }

  getScheduleTimings(provider_id) {
    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getScheduleTimings', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.scheduleTimings = _response.body;
            this.secondFormGroup.controls['durationValue'].setValue(_response.body.default_time_duration);

            if (_response.body.working_days) {
              let days = _response.body.working_days.split(' ');
              days.pop();
              this.secondFormGroup.controls['working_days'].setValue(days);
            }

            let start_time = moment(_response.body.start_time, 'hh:mm A').format('HH:mm:ss');
            let end_time = moment(_response.body.end_time, 'hh:mm A').format('HH:mm:ss');
            let break_start_time = moment(_response.body.break_start_time, 'hh:mm A').format('HH:mm:ss');
            let break_end_time = moment(_response.body.break_end_time, 'hh:mm A').format('HH:mm:ss');

            this.timeFormGroup.controls['start_time'].setValue(start_time);
            this.timeFormGroup.controls['end_time'].setValue(end_time);
            this.timeFormGroup.controls['break_start_time'].setValue(break_start_time);
            this.timeFormGroup.controls['break_end_time'].setValue(break_end_time);

            if (_response.body.holiday_list) {
              this.getHolidays = _response.body.holiday_list.split(' ');
              this.getHolidays.pop();
              console.log('providerHolidays: ', this.getHolidays);
              for (let i = 0; i < this.getHolidays.length; i++) {
                this.getHolidays[i] = moment(this.getHolidays[i]).format('ddd, DD MMM YYYY');
              }
            }

            if (_response.body.personal_leaves) {
              this.getPersonalLeaves = _response.body.personal_leaves.split(' ');
              this.getPersonalLeaves.pop();
              for (let i = 0; i < this.getPersonalLeaves.length; i++) {
                this.getPersonalLeaves[i] = moment(this.getPersonalLeaves[i]).toDate();
              }
            }

            this.secondFormGroup.controls['holiday_list'].setValue(this.getHolidays);
            this.secondFormGroup.controls['personal_leaves'].setValue(this.getPersonalLeaves);
          }
        },
        (_error) => {

        });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.postcode = results[0].address_components.pop();
          this.postcode = this.postcode.long_name;
          this.firstFormGroup.controls['postcode'].setValue(this.postcode);
          this.getCityCountry();
          console.log('postcode: ', this.postcode);
          this.address = results[0].formatted_address;

          this.final_address = this.firstFormGroup.controls['address_text'].value + ', ' + this.address;

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
    this.address = result.formatted_address;
    this.postcode = result.address_components.pop();
    if (this.postcode.types[0] == "postal_code") {
      this.postcode = this.postcode.long_name;
      this.firstFormGroup.controls['postcode'].setValue(this.postcode);
      this.getCityCountry();
    } else {
      this.firstFormGroup.controls['postcode'].enable();
      this.firstFormGroup.controls['postcode'].setValidators(Validators.required);
      this.firstFormGroup.controls['postcode'].updateValueAndValidity();
    }

    this.final_address = this.firstFormGroup.controls['address_text'].value + ', ' + this.address;
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.zoom = 12;
  }

  onFileChange(event) {
    this.filesToUpload = <Array<File>>event.target.files;
    if (event.target.files && event.target.files[0]) {
      this.imageFileName = event.target.files[0].name;
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls.pop();
          this.urls.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
      }

    }
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.firstFormGroup.get('image').setValue(event.target.files);
    }
  }

  removeImage() {
    this.fileInput.nativeElement.value = '';
    this.urls = [];
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    //Add language
    if ((value || '').trim()) {
      if (!this.languages.includes(value.trim())) {
        for (let i = 0; i < this.getLanguages.length; i++) {
          if (this.getLanguages[i].language_name == value) {
            this.languages.push(value.trim());
            this.firstFormGroup.get('lang').setValue(this.languages);
            this.firstFormGroup.get('languageCtrl').setValue('');
            break;
          } else {
            this.toastr.error('Please select a different language');
          }
        }
      }
    }

    //Reset input value
    if (input) {
      input.value = '';
    }
  }

  remove(language: Language): void {
    const index = this.languages.indexOf(language);

    if (index >= 0) {
      this.languages.splice(index, 1);
      this.firstFormGroup.get('lang').setValue(this.languages);
      this.firstFormGroup.get('lang').setValidators(this.validateLanguages);
      this.firstFormGroup.get('lang').updateValueAndValidity()
      this.firstFormGroup.get('languageCtrl').setValue('');
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.languages.includes(event.option.viewValue.trim())) {
      this.languages.push(event.option.viewValue);
      this.firstFormGroup.get('lang').setValue(this.languages);
      this.firstFormGroup.get('languageCtrl').setValue('');
    }
    this.languageInput.nativeElement.value = '';
    // this.languageCtrl.setValue(null);
  }

  displayLanguage(language: any) {
    if (language) { return language.language_name; }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.getLanguages.filter(language => language.language_name.toLowerCase().indexOf(filterValue) === 0);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.firstFormGroup.controls[controlName].hasError(errorName);
  }

  private validateLanguages(lang: FormControl) {
    if (lang.value && lang.value.length === 0) {
      return {
        validateLangArray: { valid: false }
      };
    }

    return null;
  }

  public createOwner = (firstFormValue) => {
    if (this.firstFormGroup.valid && this.languages.length > 0) {
      this.contactInfo(firstFormValue);
    } else if (this.languages.length == 0) {
      this.toastr.error('Please select a language');
    } else {
      this.toastr.error('Please fill in the details');
    }
  }

  contactInfo(firstFormValue) {
    let language_str = '';
    for (var i = 0; i < this.languages.length; i++) {
      language_str += this.languages[i] + ' ';
    }
    let address_text = firstFormValue.address_text;
    this.address === 'undefined' || this.address === undefined ? '' : address_text += ', ' + this.address;

    let user: any = {
      provider_id: this.provider_id,
      userid: this.userid,
      gender: (this.gender ? this.gender : firstFormValue.gender),
      first_name: firstFormValue.first_name,
      middle_name: firstFormValue.middle_name,
      last_name: firstFormValue.last_name,
      // title: firstFormValue.title,
      address_text: address_text,
      // postcode: firstFormValue.postcode,
      postcode: this.firstFormGroup.controls['postcode'].value,
      salutation: firstFormValue.salutation,
      address_lng: this.longitude,
      address_lat: this.latitude,
      provider_languages: language_str,
      city_name: this.firstFormGroup.controls['city'].value,
      Provider_experience: firstFormValue.experience,
      address_id: this.address_id ? this.address_id : ''
    };

    this.ngxService.start();

    this.contactInfoService.ContactInfoService(user)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_response.status === 200) {
            try {
              this.contactinfo = _response.body;

              const formData: any = new FormData();
              const files: Array<File> = this.filesToUpload;

              for (let i = 0; i < files.length; i++) {
                formData.append("avatar", files[i], files[i]['name']);
              }
              let queryParam = `?provider_id=${this.provider_id}`;
              this.addProviderImagesService.AddproviderimagesService(formData, queryParam)
                .subscribe(
                  (_response) => {
                    this.addProviderImagesService.UploadImageToCloudService({ dt: JSON.stringify(_response.body.details) })
                      .subscribe(
                        (_response) => {
                          if (_response.status === 200) {
                            console.log('image added');
                          }
                        },
                        (_error) => {

                        }
                      );
                  },
                  (_error) => {

                  }
                );

              let query = {
                provider_id: this.provider_id
              };

              this.server.request('GET', '/providers/getSettings', query)
                .subscribe(
                  (_response: any) => {
                    if (_response.status === 200) {
                      this.address_id = _response.body.address_id;
                    }
                  },
                  (_error) => {

                  });

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
        }
      );
  }

  selectSubCat(event) {
    this.subCatId = event;
    console.log('event: ', event);

    if (event == 'beautician') {
      this.showCertificate = false;
      this.secondFormGroup.controls['certification_image'].clearValidators();
      this.secondFormGroup.controls['certification_image'].updateValueAndValidity();
      this.secondFormGroup.controls['legal_number'].clearValidators();
      this.secondFormGroup.controls['legal_number'].updateValueAndValidity();
    } else {
      this.showCertificate = true;
      this.secondFormGroup.controls['certification_image'].setValidators([Validators.required]);
      this.secondFormGroup.controls['certification_image'].updateValueAndValidity();
      this.secondFormGroup.controls['legal_number'].setValidators([Validators.required]);
      this.secondFormGroup.controls['legal_number'].updateValueAndValidity();
    }

    let query = `?category_name=${event}`;
    this.getSubCategoryService.GetsubcategoryserviceService(query)
      .subscribe((_response) => {
        if (_response.status === 200) {
          this.category = _response.body;
          this.filtered_category = _response.body;
        } else {
          this.category = [];
        }
      }, (_error) => {
        this.category = [];
      });
  }

  selectSpecialization(event) {
    this.specialization = event.value;
  }

  displayFn(type: any) {
    if (type) { return type.catChildName; }
  }

  addDegree(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    //Add degree
    if ((value || '').trim()) {
      if (!this.degrees.includes(value.trim())) {
        this.degrees.push(value.trim());
        this.secondFormGroup.get('degree').setValue(this.degrees);
        this.secondFormGroup.get('education_degree').setValue('');
      }
    }

    //Reset input value
    if (input) {
      input.value = '';
    }
  }

  removeDegree(degree: Degree): void {
    const index = this.degrees.indexOf(degree);

    if (index >= 0) {
      this.degrees.splice(index, 1);
      this.secondFormGroup.get('degree').setValue(this.degrees);
      this.secondFormGroup.get('degree').setValidators(this.validateDegrees);
      this.secondFormGroup.get('degree').updateValueAndValidity();
      this.secondFormGroup.get('education_degree').setValue('');
    }
  }

  validateDegrees(degree: FormControl) {
    if (degree.value && degree.value.length === 0) {
      return {
        validateDegreeArray: { valid: false }
      };
    }

    return null;
  }

  getPayMethods(event) {
    if (event.value.length == 0) {
      this.show_advance = false;
      this.secondFormGroup.controls['advances_payment_cost'].clearValidators();
      this.secondFormGroup.controls['advances_payment_cost'].updateValueAndValidity();
    }
    for (let i = 0; i < event.value.length; i++) {
      if (event.value[i] == 2) {
        this.show_advance = true;
        this.secondFormGroup.controls['advances_payment_cost'].setValidators([Validators.required, Validators.min(20), Validators.max(70), Validators.pattern("^[0-9]*$")]);
        this.secondFormGroup.controls['advances_payment_cost'].updateValueAndValidity();

      } else {
        this.show_advance = false;
        this.secondFormGroup.controls['advances_payment_cost'].clearValidators();
        this.secondFormGroup.controls['advances_payment_cost'].updateValueAndValidity();
      }
    }
  }

  checkOnline() {
    if (this.secondFormGroup.controls['pay_methods'].value.includes(0) && this.secondFormGroup.controls['online_consultation_type'].value == '1') {
      this.showOnlineNote = true;
      console.log('payment: ', this.secondFormGroup.controls['pay_methods'].value);
    } else if (this.secondFormGroup.controls['online_consultation_type'].value == '0') {
      this.showOnlineNote = false;
    }
  }

  onPersonalFileChange(event) {
    this.personalFileName = event.target.files[0].name;
    this.personalFilesToUpload = <Array<File>>event.target.files;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.personalUrls.pop();
          if (this.personalFileName.endsWith('jpg') || this.personalFileName.endsWith('jpeg') || this.personalFileName.endsWith('png') || this.personalFileName.endsWith('PNG')) {
            this.personalUrls.push(event.target.result);

          } else {
            this.personalUrls.push('https://ik.imagekit.io/zi19k9ks6j/document_SNqkQY2YF.png')
          }
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }

    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.firstFormGroup.get('personal_info_image').setValue(event.target.files);
    }
  }

  removePersonalFileImage() {
    this.personalFileInput.nativeElement.value = '';
    this.personalUrls = [];
  }

  onCertificationFileChange(event) {
    this.certificateFileName = event.target.files[0].name;
    this.certificationFilesToUpload = <Array<File>>event.target.files;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.certificationUrls.pop();
          // jpg, pdf, png, jpeg
          if (this.certificateFileName.endsWith('jpg') || this.certificateFileName.endsWith('jpeg') || this.certificateFileName.endsWith('png') || this.certificateFileName.endsWith('PNG')) {
            this.certificationUrls.push(event.target.result);

          } else {
            this.certificationUrls.push('https://ik.imagekit.io/zi19k9ks6j/document_SNqkQY2YF.png')
          }
        }

        reader.readAsDataURL(event.target.files[i]);
      }

    }
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.firstFormGroup.get('certification_image').setValue(event.target.files);
    }
  }

  removeCertificationImage() {
    this.certificationFileInput.nativeElement.value = '';
    this.certificationUrls = [];
  }

  breakTimeValidator: ValidatorFn = (timeForm: FormGroup) => {
    const start = timeForm.get('start_time').value;
    const end = timeForm.get('end_time').value;
    const break_start = timeForm.get('break_start_time').value;
    const break_end = timeForm.get('break_end_time').value;

    if (timeForm.controls['break_start_time'].value !== '' && timeForm.controls['break_end_time'].value !== '' && timeForm.controls['break_start_time'].value !== timeForm.controls['break_end_time'].value) {
      let startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
      let endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));
      let timeCondition = moment(break_end, 'hh:mm').diff(moment(break_start, 'hh:mm'), 'minute') <= 240 && moment(break_end, 'hh:mm').diff(moment(break_start, 'hh:mm'), 'minute') > 0;

      if (!startCondition || !endCondition || !timeCondition) {
        return { 'breakNotValid': true };
      }
    }

    return null;
  }

  timeValidator: ValidatorFn = (timeForm: FormGroup) => {
    const start = timeForm.get('start_time').value;
    const end = timeForm.get('end_time').value;
    const break_start = timeForm.get('break_start_time').value;
    const break_end = timeForm.get('break_end_time').value;
    let startCondition, endCondition;

    const duration = this.secondFormGroup.controls['durationValue'].value;

    let condition = start !== null && end !== null && (moment(end, 'hh:mm').diff(moment(start, 'hh:mm'), 'minute') >= duration);

    if (timeForm.controls['break_start_time'].value !== '' && timeForm.controls['break_end_time'].value !== '' && timeForm.controls['break_start_time'].value !== timeForm.controls['break_end_time'].value) {
      startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
      endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));
    }

    this.breakTimeValidator;
    if (!condition) {
      return { 'timeNotValid': true };
    }
    // if (!startCondition || !endCondition) {
    //   return { 'breakNotValid': true };
    // }
    return null;
  }

  addPersonalLeaves(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    //Add language
    if ((value || '').trim()) {
      this.personalLeaves.push(value.trim());
    }

    //Reset input value
    if (input) {
      input.value = '';
    }
  }

  removePersonalLeaves(leaves: Leaves): void {
    const index = this.personalLeaves.indexOf(leaves);

    if (index >= 0) {
      this.personalLeaves.splice(index, 1);
      this.daysSelected.splice(index, 1);
    }
  }

  select(event: any, calendar: any) {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    const index = this.daysSelected.findIndex(x => x == date);
    if (index < 0) {
      this.daysSelected.push(date);
      this.personalLeaves.push(date);
    }
    else {
      this.daysSelected.splice(index, 1);
      this.personalLeaves.splice(index, 1);
    }

    calendar.updateTodaysDate();
    this.secondFormGroup.controls['personal_leaves'].setValue(this.daysSelected);
  }

  checkValue() {
    console.log('personal: ', this.secondFormGroup.controls['personal_leaves'].value);
    if (this.secondFormGroup.controls['personal_leaves'].value.length == 0) {
      this.secondFormGroup.controls['personal_leaves'].setValue('');
    }
  }

  togglePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.secondFormGroup.controls.working_days.value.length == this.allDays.length)
      this.allSelected.select();
  }

  toggleAllSelection(allSelected) {
    if (allSelected._selected) {
      this.secondFormGroup.controls['working_days'].setValue(this.allDays);
      allSelected._selected = true;
      // this.secondFormGroup.controls.working_days
      //   .patchValue([...this.allDays.map(item => item), 0]);
    }
    if (allSelected._selected == false) {
      this.secondFormGroup.controls['working_days'].setValue([]);
      // this.secondFormGroup.controls.working_days.patchValue([]);
    }
  }

  selectHolidayMonth(event) {
    if (event.value == 'none') {
      this.getProviderHolidays.GetproviderholidaysService()
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.providerHolidays = _response.body;
            for (let i = 0; i < this.providerHolidays.length; i++) {
              this.providerHolidays[i].holiday_date = moment(this.providerHolidays[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          }
        },
          (_error) => {
            this.providerHolidays = [];
          });
    } else {
      let query = `?year=${this.secondFormGroup.controls['holiday_year'].value}&month=${event.value}`;
      this.getProviderHolidays.GetproviderholidaysService(query)
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.providerHolidays = _response.body;
            for (let i = 0; i < this.providerHolidays.length; i++) {
              this.providerHolidays[i].holiday_date = moment(this.providerHolidays[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          }
        },
          (_error) => {
            this.providerHolidays = [];
          });
    }
  }

  selectHolidayYear(event) {
    if (event.value == 'none') {
      this.getProviderHolidays.GetproviderholidaysService()
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.providerHolidays = _response.body;
            for (let i = 0; i < this.providerHolidays.length; i++) {
              this.providerHolidays[i].holiday_date = moment(this.providerHolidays[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          }
        },
          (_error) => {
            this.providerHolidays = [];
          });
    } else {
      let query = `?year=${event.value}`;
      this.getProviderHolidays.GetproviderholidaysService(query)
        .subscribe((_response) => {
          if (_response.status === 200) {
            this.providerHolidays = _response.body;
            for (let i = 0; i < this.providerHolidays.length; i++) {
              this.providerHolidays[i].holiday_date = moment(this.providerHolidays[i].holiday_date).format('ddd, DD MMM YYYY');
            }
          }
        },
          (_error) => {
            this.providerHolidays = [];
          });
    }
  }

  public hasError2 = (controlName: string, errorName: string) => {
    return this.secondFormGroup.controls[controlName].hasError(errorName);
  }

  onKey(value) {
    this.filtered_category = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    this.filtered_category = [];
    return this.category.filter((option) =>
      option.catChildName.toLowerCase().startsWith(filter)
    );
  }

  public createOwner2 = (secondFormValue) => {
    if (this.secondFormGroup.valid && this.timeFormGroup.valid) {
      this.companyProfile(secondFormValue);
    } else if (this.personalFilesToUpload.length == 0 || (this.subCatId != 'beautician' && this.certificationFilesToUpload.length == 0)) {
      this.toastr.error('Attachment missing');
      console.log('personal: ', this.secondFormGroup.controls['personal_info_image'].status)
      console.log('certificate: ', this.secondFormGroup.controls['certification_image'].status)
    } else {
      this.toastr.error('Please fill all the details');
    }
  }

  companyProfile(secondFormValue) {
    let days_str = '';
    let options_str = '';
    let holidays_str = '';
    let personal_leaves_str = '';
    let degrees_str = '';
    let subCatName = '';

    // if (secondFormValue.working_days[secondFormValue.working_days.length - 1] == 0) {
    //   secondFormValue.working_days.pop();
    // }
    for (var i = 0; i < secondFormValue.working_days.length; i++) {
      days_str += secondFormValue.working_days[i] + ' ';
    }

    for (var i = 0; i < secondFormValue.pay_methods.length; i++) {
      options_str += secondFormValue.pay_methods[i] + ' ';
    }

    // if (secondFormValue.holiday_list[secondFormValue.holiday_list.length - 1] == 0) {
    //   secondFormValue.holiday_list.pop();
    // }
    // if (secondFormValue.holiday_list) {
    //   for (var i = 0; i < secondFormValue.holiday_list.length; i++) {
    //     holidays_str += moment(secondFormValue.holiday_list[i], 'ddd, DD MMM YYYY').format('YYYY-MM-DD') + ' ';
    //   }
    // }

    // if (secondFormValue.personal_leaves) {
    //   for (var i = 0; i < secondFormValue.personal_leaves.length; i++) {
    //     personal_leaves_str += moment(secondFormValue.personal_leaves[i], 'YYYY-MM-DD').format('YYYY-MM-DD') + ' ';
    //   }
    // }


    for (var i = 0; i < this.degrees.length; i++) {
      degrees_str += this.degrees[i] + '*';
    }

    for (var i = 0; i < this.specialization.length; i++) {
      let cat = this.category.find(name => name.catId === this.specialization[i]);
      subCatName += cat.catChildName + '-';
    }
    subCatName = subCatName.slice(0, -1);

    this.category_array = [];

    for (let i = 0; i < secondFormValue.type.length; i++) {

      let attrArray = {
        sub_category_id: secondFormValue.type[i],
      };
      this.category_array.push(attrArray);
    }

    let user: any = {
      provider_id: this.provider_id,
      userid: this.userid,
      // category_id: secondFormValue.type.catId,
      // sub_category_id: this.specialization,
      category_id: (this.subCatId == 'doctor' ? 1
        : this.subCatId == 'lawyer' ? 2
          : this.subCatId == 'beautician' ? 3
            : this.subCatId == 'chartered accountant' ? 4
              : 5),
      // year_established: secondFormValue.year_established,
      legal_number: secondFormValue.legal_number,
      default_cost: secondFormValue.consulting_fees,
      qualification_name: degrees_str,
      working_start_time: moment(this.timeFormGroup.controls['start_time'].value, 'hh:mm').format('HH:mm'),
      working_end_time: moment(this.timeFormGroup.controls['end_time'].value, 'hh:mm').format('HH:mm'),
      working_days: days_str,
      default_time_duration: secondFormValue.durationValue,
      pay_methods: options_str,
      advances_payment_cost: secondFormValue.advances_payment_cost,
      break_start_time: this.timeFormGroup.controls['break_start_time'].value == 'Invalid date' || this.timeFormGroup.controls['break_start_time'].value == '' || this.timeFormGroup.controls['break_start_time'].value == this.timeFormGroup.controls['break_end_time'].value ? '' : moment(this.timeFormGroup.controls['break_start_time'].value, 'hh:mm').format('HH:mm'),
      break_end_time: this.timeFormGroup.controls['break_end_time'].value == 'Invalid date' || this.timeFormGroup.controls['break_end_time'].value == '' || this.timeFormGroup.controls['break_start_time'].value == this.timeFormGroup.controls['break_end_time'].value ? '' : moment(this.timeFormGroup.controls['break_end_time'].value, 'hh:mm').format('HH:mm'),
      // holiday_list: holidays_str,
      // personal_leaves: personal_leaves_str,
      city_name: this.firstFormGroup.controls['city'].value,
      first_name: this.firstFormGroup.controls['first_name'].value,
      last_name: this.firstFormGroup.controls['last_name'].value,
      mobile_number: this.mobile_number,
      sub_category_name: subCatName,
      salutation: this.firstFormGroup.controls['salutation'].value,
      category_id_array: this.category_array,
      online_consultation_type: secondFormValue.online_consultation_type,
    };
    console.log('user: ', user);

    this.ngxService.start();

    this.companyProfileService.CompanyProfileService(user)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_response.status === 200) {
            try {
              this.companyprofile = _response.body;

              const certificationFormData: any = new FormData();
              const certificationFiles: Array<File> = this.certificationFilesToUpload;

              for (let i = 0; i < certificationFiles.length; i++) {
                certificationFormData.append("avatar", certificationFiles[i], certificationFiles[i]['name']);
              }
              let queryParam = `?provider_id=${this.provider_id}`;
              this.uploadCertificationDocService.UploadcertificationdocService(certificationFormData, queryParam)
                .subscribe(
                  (_response) => {
                    // this.addProviderImagesService.UploadImageToCloudService({ dt: JSON.stringify(_response.body.data.details) })
                    //   .subscribe(
                    //     (_response) => {
                    //       if (_response.status === 200) {
                    //         console.log('image added');
                    //       }
                    //     },
                    //     (_error) => {

                    //     }
                    //   );
                  },
                  (_error) => {

                  }
                );

              const personalFormData: any = new FormData();
              const personalFiles: Array<File> = this.personalFilesToUpload;

              for (let i = 0; i < personalFiles.length; i++) {
                personalFormData.append("avatar", personalFiles[i], personalFiles[i]['name']);
              }
              let personalQueryParam = `?provider_id=${this.provider_id}`;
              this.uploadPersonalIdentityDocService.UploadpersonalidentitydocService(personalFormData, personalQueryParam)
                .subscribe(
                  (_response) => {
                    // this.addProviderImagesService.UploadImageToCloudService({ dt: JSON.stringify(_response.body.data.details) })
                    //   .subscribe(
                    //     (_response) => {
                    //       if (_response.status === 200) {
                    //         console.log('image added');
                    //       }
                    //     },
                    //     (_error) => {

                    //     }
                    //   );
                  },
                  (_error) => {

                  }
                );

            } catch (err) {

            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
        }
      );
  }

  public createOwner4 = (fourthFormValue) => {
    if (this.fourthFormGroup.valid) {
      this.moreInfo(fourthFormValue);
    } else {
      this.toastr.error('Please fill in the details');
    }
  }

  moreInfo(fourthFormValue) {
    let moreInfo: any = {
      userid: this.userid,
      provider_id: this.provider_id,
      extra_for_future: 'intro: ' + fourthFormValue.introduction + ' specialization: ' + fourthFormValue.specialization + ' workplace: ' + fourthFormValue.work_place
    };

    this.ngxService.start();

    this.moreInfoService.MoreinfoService(moreInfo)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_response.status === 200) {
            this.moreinfo = _response.body;
          }
        }
      ),
      (_error) => {
        setTimeout(() => {
          this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 0);
        this.toastr.error('Please select smaller images');
      };
  }

  sendOtp() {
    let user: any = {
      mobile_number: this.mobile_number,
      userid: localStorage.getItem('userid')
    };

    let eventTime = moment().add(1, 'minute').unix();
    let currentTime = moment().unix();
    let diffTime = eventTime - currentTime;
    let duration = moment.duration(diffTime * 1000, 'milliseconds');
    let interval = 1000;

    if (diffTime > 0) {
      this.intervalId = setInterval(() => {
        if (duration.asSeconds() <= 0) {
          clearInterval(this.intervalId);
          this.button_text = "Resend OTP";
          this.disableOtp = false;
        } else {
          let sec = '';
          duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');
          if (moment.duration(duration).seconds().toString().length == 2) {
            sec = moment.duration(duration).seconds().toString();
          } else {
            sec = '0' + moment.duration(duration).seconds().toString();
          }
          let min = moment.duration(duration).minutes().toString.length === 1 ? '0' + moment.duration(duration).minutes() : moment.duration(duration).minutes();
          this.timer = min + ':' + sec;
          this.disableOtp = true;
        }
      }, interval);
    }

    this.confirmAccountService.sendOtp(user)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.toastr.success('OTP has been sent on registered mobile number');
            this.thirdFormGroup.controls['confirmation_code'].enable();
          }
        }
      ),
      (_error) => {
        this.toastr.error('Could not send OTP');
      };
  }

  verifyOtp() {
    try {
      this.ngxService.start();

      let otp_value = this.thirdFormGroup.get('confirmation_code').value;
      let queryParam = `?mobile=${this.mobile_number}&otp_value=${otp_value}&userid=${this.userid}`;
      this.confirmAccountService.verifyOtp(queryParam)
        .subscribe(
          (_response) => {
            setTimeout(() => {
              this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
            }, 0);
            try {
              if (_response.status === 200) {
                this.toastr.success('Business profile created');
                localStorage.setItem('active', '1');
                this.router.navigate(['/home-page']);
              } else {
                this.toastr.error('Wrong OTP, please try again');
              }
            } catch (err) {
              console.log('err : ', err)
            }
          },
          (_error) => {
            setTimeout(() => {
              this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
            }, 0);
            if (_error.status === 409) {
              this.toastr.error('Wrong OTP, please try again');
              this.button_text = 'Resend OTP';
            }
          });
    } catch (err) {
      console.log(err);
    }
  }
}
