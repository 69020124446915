import { Injectable } from '@angular/core';
// import {Http, Response} from '@angular/http';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs/observable';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import {IPartner} from '../../ui-components/partner/partner-report/report';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GetproviderdetailspartnerService {

  constructor(private http: HttpClient) { }

  private _url: string = "/assets/getProviderDetailsPartner.json";

  getInvoice(): Observable<IPartner[]>{
       return this.http.get<IPartner[]>(this._url);
   }
}
