import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GetpaymentidbyapointtidService {

  constructor(private httpService: HttpClient) { }

  GetpaymentidbyapointtidService(query) {
    const href = environment.api.getpaymentidbyapointtid;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }
}
