import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GetProvideServiceService } from '../../../services/getProvider/get-provide-service.service'
//  import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
//  import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
// import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { FavLikeService } from '../../../services/like/fav-like.service';
import { environment } from '../../../../environments/environment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ImageGalleryService } from './../../../services/image-gallery/imagegallery.service';
import { EventService } from '@crystalui/angular-lightbox';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.css']
})
export class ProviderDetailsComponent {
  viewCalendar = false;
  sub: any
  serviceCards = [];
  num: number;
  date: string;
  l_userid;
  queryParms
  like_status = '1'
  //fav_status =2 ;
  category_id;
  sub_category_id;
  closeResult: string;
  Items: any = [];
  _albums = [];
  document = 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/';
  // document = 'https://api.apointt.com:8082/';
  imageAvailable = false;

  //   fbIcon = faFacebookSquare;
  //  pinIcon = faPinterest;
  //    tweetIcon = faTwitterSquare;
  subCategoriesService: []
  @Input() selected: boolean;
  @Output() selectedChange = new EventEmitter<boolean>();

  @Input('provider_name') provider_name: string;
  @Input('qualification') qualication: string;
  @Input('provider_id') provider_id: string;
  @Input('providerUserId') providerUserId: string;
  //@Input('img') cardImage: string;
  @Input('cost') cost: number;
  @Input('experience') experience: string;
  @Input('city') city: string;
  @Input('provider_url') provider_url: string;
  @Input('provider_mobile_no') provider_mobile_no: string;
  @Input('fav_status') fav_status: string;
  @Input('admin_status') admin_status: string;
  @Input('unique_url_id') unique_url_id: string;
  @Input('p_name') p_name: string;
  @Input('online_consultation_type') online_consultation_type: string;
  @Input('online_cost') online_cost: string;
  @Input('show') show: boolean;
  online = false;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private getProvideServiceService: GetProvideServiceService,
    private FavLikeService: FavLikeService,
    private server: JwtTestService,
    private modalService: NgbModal,
    private galleryService: ImageGalleryService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {

    this.sub = this.route
      .params
      .subscribe(params => {
        // console.log(params);
        // Defaults to 0 if no query param provided.
        // this.category_id = params.catId || 1;
        // console.log('provider_id query param', this.category_id);
        // console.log('model  query param', this.model);
      });

    this.l_userid = localStorage.getItem('userid');
    this.category_id = localStorage.getItem('catId');
    this.sub_category_id = localStorage.getItem('catChildId');
    
    this.getProfile();
    this.getProviderImages(this.provider_id);
  }

  // getProfile(){
  //   let queryParms = `?category_id=${localStorage.getItem('catChildId')}`
  //   let l_userid = `&l_userid=${this.l_userid}`

  //   this.getProvideServiceService.GetProvideServiceService(queryParms, l_userid)
  //     .subscribe(
  //       (_response) => {
  //         console.log(_response);
  //         if (_response.status === 200) {
  //           try {
  //             this.subCategoriesService = _response.body;

  //             if (_response.body[0].fav_status == '1') {
  //               this.selected = true;
  //             } else {
  //               this.selected = false;
  //             }
  //           } catch (err) {

  //           }
  //         }
  //       },
  //       (_error) => {

  //       }
  //     )
  //   console.log('data', 'response');


  // }
  getProfile() {
    let queryParms = `?category_id=${this.category_id}&l_userid=${this.l_userid}`;
    this.sub_category_id ? queryParms += `&sub_category_id=${localStorage.getItem('catChildId')}` : ``;

    // let l_userid=  `&l_userid=${this.l_userid}`

    this.getProvideServiceService.GetProvideServiceService(queryParms)
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.subCategoriesService = _response.body;

              if (_response.body[0].fav_status == '1') {
                this.selected = true;
              } else {
                this.selected = false;
              }
            } catch (err) {

            }
          }
        },
        (_error) => {

        }
      )
    console.log('data', 'response');


  }

  openDialog(unique_url_id): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { url: environment.shareBaseURL + 'customer-dashboard/profile-details/' + unique_url_id.replace(/ /g, "-"), name: 'animal' },
      panelClass: 'custom-dialog-box',
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe(result => {


    });

  }
  viewProfileDetails() {
    this.router.navigate(['/customer-dashboard/profile-details/', this.unique_url_id.replace(/ /g, "-")]);
    // localStorage.setItem('provider_id', this.provider_id);
    // localStorage.setItem('providerUserId', this.providerUserId);
    sessionStorage.setItem('provider_id', this.provider_id);
    sessionStorage.setItem('providerUserId', this.providerUserId);
  }

  setProviderUserId(userid) {
    // localStorage.setItem('providerUserId', this.providerUserId);
    sessionStorage.setItem('providerUserId', userid);
  }
  toggleCalendar(appointment_type) {
    this.viewCalendar = !this.viewCalendar;
    if (appointment_type == 'walk-in') {
      this.online = false;
    } else if (appointment_type == 'online') {
      this.online = true;
    }
  }

  public toggleSelected() {
    // if (this.selected) {
    //   this.like_status = 1
    // } else {
    //   this.like_status = 2
    // }
    // this.like_status = this.fav_status;
    // this.selected = !this.selected
    if (this.fav_status == '1') {
      this.fav_status = '2';
    } else {
      this.fav_status = '1';
    }

    let queryParam = {
      userid: localStorage.getItem('userid'),
      provider_id: this.provider_id,
      provider_like_status: this.fav_status
    }
    this.server.request('POST', '/providers/like', queryParam)
      .subscribe(
        (_response: any) => {
          console.log(_response);
          try {
            this.serviceCards = _response.body;
            // shop_id => this.shop_id = shop_id
          } catch (err) {

          }
        },
        (_error) => {

        });
    // this.FavLikeService.FavLikeService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       console.log(_response);
    //       if (_response.status === 200) {
    //         try {
    //           this.serviceCards = _response.body;
    //           // shop_id => this.shop_id = shop_id
    //         } catch (err) {

    //         }
    //       }
    //     },
    //     (_error) => {

    //     }
    //   )
    //this.selected = !this.selected;
    //this.selectedChange.emit(this.selected);
  }

  getProviderImages(provider_id) {
    let queryParam = `?provider_id=${provider_id}`;
    this.galleryService.getProviderImages(queryParam).subscribe(res => {
      this.Items = res.body;
      this.Items.docUrl = [];
      for (let j = 0; j < res.body.length; j++) {
        // this.Items[i].docUrl.push(this.document + res.body[j].server_image_url.slice(20));
        // this.Items.docUrl.push(this.document + res.body[j].server_image_url.slice(20));
        this.Items.docUrl.push(res.body[j].cdn_image_url);
      }
      console.log("docURL", this.Items);
      this._albums.push(this.Items);
      console.log("Provider album items", this._albums);
      this.imageAvailable = true;
    }, error => {
      console.log(error);
      this.imageAvailable = false;
    });
  }

  openModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}




@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html'
})


export class DialogOverviewExampleDialog {
  shareURL = '';
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    try {
      this.shareURL = data.url
    } catch (err) {
      console.log(err);
    }


  }
  onNoClick(): void {
    this.dialogRef.close();
  }



}


