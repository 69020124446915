import { Injectable } from '@angular/core';
import {Http, Response} from '@angular/http';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs/observable';
import 'rxjs/add/operator/map';
import {IArticle} from '../../ui-components/introduction-page/help/help.component';
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
// export class ArticleService {
//   private _url: string = "/assets/article.json";


//   constructor( private http: HttpClient) { }
  // getArticle(){
  //   return [
  //     {"question" : "How to create an customer account?", "answer": "this is answer"},
  //     {"question" : "What if provider cancels an booked appointment?", "answer": "this is not answer"},
  //     {"question" : "How I can get refund?", "answer": "this is answer"},
  //     {"question" : "Online payment issues", "answer": "this is answer"},
  //     {"question" : "How to book online consultation appointment?", "answer": "this is answer"},
  //     {"question" : "How to update profile?", "answer": "this is answer"}
  //   ]
  // }
//   getArticle(): Observable<IArticle[]>{
//    return this.http.get<IArticle[]>(this._url);
//   }
// }
export class ArticleService  {

  constructor(private httpService: HttpClient) { }
  ArticleService() {
     const href = environment.api.getarticle;
    return this.httpService.get<any>(href, 
      { 'observe': 'response' })
  }
 } 
