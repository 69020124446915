import { Injectable } from '@angular/core';
import{environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class ProfileServiceService {

  constructor(private httpService: HttpClient) { }
  ProfileServiceService(queryParams,l_userid){
  const href = environment.api.getprofile + queryParams +l_userid;
     ;
    return this.httpService.get<any>(href, { 'observe': 'response' })
}
}