import { Component, OnInit } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-cancellation-refund',
  templateUrl: './cancellation-refund.component.html',
  styleUrls: ['./cancellation-refund.component.css']
})
export class CancellationRefundComponent implements OnInit {

  constructor(private metaTagService: Meta,) { }

  ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Apointt is a product of Blacktix Technology LLP allowing users to enroll their business and manage customers online as well as offline bookings, payments, prescription documents & online consultations anytime. kindly go through Refund Policy regarding returns, refunds, missing refunds, sale item refund, exchanges & shipping before using the services. ' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'refund policy, return policy, Apointt refund policy, product return, cancellation and refund policy' }
    );
  }

}
