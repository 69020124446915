import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetsearchService {

  constructor(private httpService: HttpClient) { }
  GetsearchService(query) {
    const href = environment.api.getSearch + `?sterm=${query.sterm}`;
    return this.httpService.get<any>(href, { 'observe': 'response' })
  }

  getSearchForCategory(query?) {
    let href = environment.api.getSearchForCategory;
    query ? href += `?sterm=${query.sterm}` : ``;
    return this.httpService.get<any>(href, { 'observe': 'response' });
  }

  getSearchProviderAndSubCategory(category_id?, postcode?, sterm?) {
    let href = environment.api.getSearchProviderAndSubCategory + `?`;
    category_id ? href += `&category_id=${category_id}` : ``;
    postcode ? href += `&postcode=${postcode}` : ``;
    sterm ? href += `&sterm=${sterm}` : ``;
    return this.httpService.get<any>(href, { 'observe': 'response' });
  }
}