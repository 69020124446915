import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as range from 'lodash.range';
import { GetproviderholidaysService } from '../../services/getproviderholidays/getproviderholidays.service';

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

@Component({
  selector: 'app-calendar-demo',
  templateUrl: './calendar-demo.component.html',
  styleUrls: ['./calendar-demo.component.css']
})
export class CalendarDemoComponent implements OnInit {
  public currentDate: moment.Moment;
  public namesOfDays = ['Sun', 'Mon', ' Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public weeks: Array<CalendarDate> = [];

  public selectedDate;
  public show: boolean;

  providerHolidays = [];
  timeSlots = ['10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM'];

  @ViewChild('calendar', { static: true }) calendar;
  constructor(
    private getProviderHolidaysService: GetproviderholidaysService,
  ) { }

  ngOnInit() {
    this.currentDate = moment();
    this.selectedDate = moment(this.currentDate).format('DD-MM-YYYY');
    this.generateCalendar();

    // this.getProviderHolidaysService.GetproviderholidaysService()
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.providerHolidays = _response.body;
    //       }
    //     },
    //     (_error) => {
    //       this.providerHolidays = [];
    //     });
  }

  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  private fillDates(currentMoment: moment.Moment) {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const lastOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'day');
    const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'day').add(7, 'day');
    const startCalendar = firstDayOfGrid.date();

    return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'day')).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        mDate: newDate,
      };
    });
  }

  private isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  private isSelected(date: moment.Moment): boolean {
    return this.selectedDate === moment(date).format('DD-MM-YYYY');
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'month');
    this.generateCalendar();
  }

  public nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'month');
    this.generateCalendar();
  }

  public isDisabledMonth(currentDate): boolean {
    const today = moment();
    return moment(currentDate).isAfter(today, 'month');
  }

  public isSelectedMonth(date: moment.Moment): boolean {
    const today = moment();
    return moment(date).isSame(this.currentDate, 'month');
    // && moment(date).isSameOrAfter(today, 'date');
  }

  public selectDate(date: CalendarDate) {
    this.selectedDate = moment(date.mDate).format('DD-MM-YYYY');
    this.generateCalendar();
    this.show = !this.show;
    console.log('selected date: ', moment(date.mDate).format('DD-MM-YYYY'));
  }

  public isHoliday(date: moment.Moment): boolean {
    // for (let i = 0; i < this.providerHolidays.length; i++) {
    //   if (moment(date).isSame(moment(this.providerHolidays[i].holiday_date, 'YYYY-MM-DD'), 'date')) {
    //     return true;
    //   }
    // }
    return false;
  }

  public checkBooked50(date: moment.Moment): boolean {
    let appt = { '2020-10-05': 10, '2020-10-07': 50, '2020-10-12': 70 };
    // console.log('appt: ', date.format('YYYY-MM-DD'), ' ', appt[date.format('YYYY-MM-DD')]);

    if (appt[date.format('YYYY-MM-DD')] >= 50 && appt[date.format('YYYY-MM-DD')] < 80) {
      return true;
    }
    
    // let bookedSlots = ['10:00 AM', '10:30 AM', '11:00 AM',' 11:30 AM'];
    // if ((bookedSlots.length == (this.timeSlots.length / 2)) && moment(date).add(1, 'day').isSame(moment(), 'date')) {
    //   return true;
    // }
    return false;
  }

  public checkBooked80(date: moment.Moment): boolean {
    let appt = { '2020-10-05': 10, '2020-10-07': 50, '2020-10-12': 80 };

    if (appt[date.format('YYYY-MM-DD')] >= 80) {
      return true;
    }
    // let length = this.timeSlots.length * 0.8;
    // let bookedSlots = ['10:00 AM', '10:30 AM', '11:00 AM',' 11:30 AM', '12:00 PM', '12:30 PM', '1:00 PM'];
    // if (bookedSlots.length >= length && (moment(date).subtract(4, 'day').isSame(moment(), 'date'))) {
    //   return true;
    // }
    return false;
  }

}
