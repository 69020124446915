import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { JwtTestService } from './jwt-test.service';
import { ToastrService } from 'ngx-toastr';
// import { url } from 'inspector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CryptoServicesService } from '../crypto-services/crypto-services.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  provider_id;
  appoint_id;

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private router: Router,
    private server: JwtTestService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private cryptoService: CryptoServicesService
  ) {
    console.log('Auth Service');
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  login(user) {
    this.provider_id = sessionStorage.getItem('provider_id');
    if (user.mobile_number !== '' && user.password !== '') {
      this.ngxService.start();

      return this.server.request('POST', '/users/logInApointt', {
        mobile_number: user.mobile_number,
        // password: user.password,
        password: this.cryptoService.set('secret key 123', user.password)
      }).subscribe((response: any) => {
        setTimeout(() => {
          this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 0);
        if (response.auth === true && response.token !== undefined) {
          this.token = response.token;
          this.server.setLoggedIn(true, this.token);
          this.loggedIn.next(true);
          const userData = {
            token: this.token,
          };
          localStorage.setItem('user', JSON.stringify(userData));
          localStorage.setItem('userid', response.userid);
          localStorage.setItem('user_type', response.user_type);
          let catChildName = localStorage.getItem('catChildName');

          // -----------------------------------------------------------
          // let startTime = sessionStorage.getItem('appointmentStart');
          // let endTime = sessionStorage.getItem('appointmentEnd');
          // let date = sessionStorage.getItem('appointmentDate');
          // date = moment(date).format('DD-MMM-YYYY');
          // let userid = localStorage.getItem('userid');
          // let apointment = {
          //   provider_id: this.provider_id,
          //   userid: userid,
          //   start_datetime: startTime,
          //   end_datetime: endTime,

          //   consultation_type: localStorage.getItem('consultation_type'),
          //   address_id: localStorage.getItem('address_id'),
          // }

          // this.server.request('POST', '/appointment/setApointt', apointment)
          //   .subscribe(
          //     (_response: any) => {
          //       this.appoint_id = _response.appoint_id;
          //       console.log("the apoint id is ", this.appoint_id);
          //       localStorage.setItem('appoint_id', this.appoint_id);
          //   }
          // );
          // --------------------------------------------------------------

          if (response.user_type == 1) {
            localStorage.setItem('first_name', response.first_name);
            if (this.provider_id) {
              // this.router.navigate(['/customer-dashboard/calender-scheduler/', this.provider_id]);
              this.router.navigate(['/customer-dashboard/provider-details-view/', catChildName]);
              // this.router.navigate(['/customer-dashboard/payment-method/']);
            } else {
              // this.router.navigateByUrl('/customer-dashboard');
              this.router.navigateByUrl('/');
            }
          } else if (response.user_type == 2) {
            localStorage.setItem('active', response.active_flag);
            localStorage.setItem('provider_id', response.provider_id);
            localStorage.setItem('business_name', response.business_name);
            this.router.navigateByUrl('/provider-dashboard');
          }
          else if (response.user_type == 3) {
            this.toastr.error('Please login using customer or business credentials');
          }
        } else {
          this.toastr.error('Invalid mobile number or password');
        }
      }, (error) => {
        setTimeout(() => {
          this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 0);
        if (error.status === 404) {
          this.toastr.error('Invalid mobile number or password');
        }
      });
    }
  }

  logout() {
    this.server.setLoggedIn(false);
    delete this.token;

    this.loggedIn.next(false);
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  loginadmin(user) {
    if (user.id !== '' && user.password !== '') {
      return this.server.request('POST', '/admin/logInAdmin', {
        id: user.id,
        // password: user.password
        password: this.cryptoService.set('secret key 123', user.password)
      }).subscribe((response: any) => {
        if (response.auth === true && response.token !== undefined) {
          this.token = response.token;
          this.server.setLoggedIn(true, this.token);
          this.loggedIn.next(true);
          const userData = {
            token: this.token,
          };
          localStorage.setItem('user', JSON.stringify(userData));
          localStorage.setItem('admin_status', response.admin_status);
          this.router.navigateByUrl('admin/admin-dashboard');

        } else {
          this.toastr.error('Invalid id or password');
        }
      }, (error) => {
        if (error.status === 409) {
          this.toastr.error('Invalid id or password');
        }
      });
    }
  }
  loginpartner(user) {
    if (user.mobile_number !== '' && user.password !== '') {
      this.ngxService.start();

      return this.server.request('POST', '/users/logInApointt', {
        mobile_number: user.mobile_number,
        // password: user.password
        password: this.cryptoService.set('secret key 123', user.password)
      }).subscribe((response: any) => {
        setTimeout(() => {
          this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 0);
        if (response.auth === true && response.token !== undefined) {
          this.token = response.token;
          this.server.setLoggedIn(true, this.token);
          this.loggedIn.next(true);
          const userData = {
            token: this.token,
          };
          if (response.user_type == 3) {
            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('patner_id', response.patner_id);
            localStorage.setItem('userid', response.userid);
            this.router.navigateByUrl('/partner');
          } else {
            this.toastr.error('Please login using partner credentials');
          }

        } else {
          this.toastr.error('Invalid mobile number or password');
        }
      }, (error) => {
        setTimeout(() => {
          this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 0);
        if (error.status === 404) {
          this.toastr.error('Invalid mobile number or password');
        }
      });
    }
  }




}

