import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class ContactInfoService {
  constructor(private httpService: HttpClient) { }
  ContactInfoService(data)
  {
     return this.httpService.post<any>(environment.api.addcontactinfo,data, { 'observe': 'response' })
 }
 
 } 

