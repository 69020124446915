import { Component, OnInit } from '@angular/core';
export interface PeriodicElement {
 
  position: number;
  name: string;
  weight: string;
  Time:string;
  symbol: string;
  Status:String;
  
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Ajay', weight: '21 March 2019',Time: '12.00pm', symbol:'',Status:''},
  {position: 2, name: 'Teju', weight: '1 April 2019',   Time:  '11.00am', symbol: '',Status:''},
  {position: 3, name: 'Mandip', weight: '22 March 2019',  Time: '10.00am', symbol: '',Status:''},
  {position: 4, name: 'Mrunali', weight: '20 April 2019', Time:   '10.00am', symbol: '',Status:''},
  {position: 5, name: 'Paresh', weight: '22 sep 2019',   Time:   '10.00am', symbol: '',Status:''},
 
];


@Component({
  selector: 'app-appoint-table',
  templateUrl: './appoint-table.component.html',
  
  styleUrls: ['./appoint-table.component.css']
})

export class AppointTableComponent {
 public name="";
  displayedColumns: string[] = ['position', 'name', 'weight','Time', 'symbol','Status'];
  dataSource = ELEMENT_DATA;
  onSave(){    
    console.log("accept appoint");
    this.name="accept";
    
     
  }    
  onDelete($event){    
    console.log("delete appoint", $event);    
  }    
  onDifferent($event){    
    console.log("different slot appoint", $event);    
  }    
  constructor() { }

  ngOnInit() {
    
  }

}
