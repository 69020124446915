import { AfterViewInit, Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';
import { SignupBusinessService } from '../../services/signup-business/signup-business.service';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CryptoServicesService } from '../../services/crypto-services/crypto-services.service';
import { GetcategoryServiceService } from '../../services/getcategory/getcategory-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserModule, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-business-signup',
  templateUrl: './business-signup.component.html',
  styleUrls: ['./business-signup.component.css']
})
export class BusinessSignupComponent implements OnInit {
  isActive;
  refer_provider_id;
  sub: any
  categories;
  isShowDiv = false;
  images = [
    { name: 'https://ik.imagekit.io/zi19k9ks6j/hands-unrecognizable-female-doctor-writing-form-typing-laptop-keyboard_PrtHDD17V.jpg', show: false },
    { name: 'https://ik.imagekit.io/zi19k9ks6j/judge-holding-pen-checking-document-wooden-desk_WUTZtcGOP.jpg', show: false },
    { name: 'https://ik.imagekit.io/zi19k9ks6j/brunette-girl-getting-her-hair-done_SaEQbxFtO.jpg', show: false },
    { name: 'https://ik.imagekit.io/zi19k9ks6j/business-people-discussing-results-successful-teamwork_-8iSBHk76.jpg', show: false }
  ];

  constructor(
    private Router: Router,
    private signupBusiness: SignupBusinessService,
    private location: Location,
    private toastr: ToastrService,
    private server: JwtTestService,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private cryptoService: CryptoServicesService,
    private getCategoryService: GetcategoryServiceService,
    private viewportScroller: ViewportScroller,
    private metaTagService: Meta,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {

    // setTimeout(() => {
    //   this.openDialog()
    // }, 500)

    this.sub = this.route
      .params
      .subscribe(params => {
        console.log(params);
        // Defaults to 0 if no query param provided.
        this.refer_provider_id = params.provider_id || '';
        console.log('refer_provider_id', this.refer_provider_id);
      });

    this.getCategoryService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.categories = _response.body;
          }
        },
        (_error) => {

        });

    this.metaTagService.updateTag(
      { name: 'description', content: 'Grow business by managing client appointments online, organised payment structure & monthly growth reports & analytics insights. Sign up Now!' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'business signup, apointt business signup, apointt business register, grow business, ' }
    );
  }

  public onCancel = () => {
    this.location.back();
  }

  public scrollToSignUp(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.querySelector('.navbar');
    if (window.pageYOffset > element.clientHeight) {
      element.className = 'navbar navbar-expand-md navbar-light fixed-top navbar-scrolled';
    } else {
      element.className = 'navbar navbar-expand-md navbar-light fixed-top';
    }

  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(AndroidbDialog, {
  //     width: '300px',
  //     panelClass: 'custom-dialog-box',
  //     // autoFocus: false,
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //   });

  // }

}

// @Component({
// selector: 'androidb-dialog',
// templateUrl: 'androidb-dialog.html'
// })


// export class AndroidbDialog {
// shareURL = '';
// constructor(
//   public dialogRef: MatDialogRef<AndroidbDialog>,
//   @Inject(MAT_DIALOG_DATA) public data: any
// ) {
//   try {
//     this.shareURL = data.url
//   } catch (err) {
//     console.log(err);
//   }


// }
// onNoClick(): void {
//   this.dialogRef.close();
// }



// }
