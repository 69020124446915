import { Component, OnInit } from '@angular/core';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {FormControl} from '@angular/forms'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-partner-all-appointment-details',
  templateUrl: './partner-all-appointment-details.component.html',
  styleUrls: ['./partner-all-appointment-details.component.css']
})
export class PartnerAllAppointmentDetailsComponent implements OnInit {
  patner_id;
  appointmentDetails = [];
  settlement_array = [];
  appointmentAvailable = true;
  displayedColumns: string[] = ['provider_name', 'date', 'total_cost', 'pay_status', 'settlement_status'];
  months = [
    { value: '01', month: 'January' },
    { value: '02', month: 'February' },
    { value: '03', month: 'March' },
    { value: '04', month: 'April' },
    { value: '05', month: 'May' },
    { value: '06', month: 'June' },
    { value: '07', month: 'July' },
    { value: '08', month: 'August' },
    { value: '09', month: 'September' },
    { value: '10', month: 'October' },
    { value: '11', month: 'November' },
    { value: '12', month: 'December' }];
  month;
  month_name;
  year;
  years = [];
  settlement = false;
  total_cost = 0;
  month_Filter = new FormControl("");
  year_Filter = new FormControl("");

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private server: JwtTestService,
    private router: Router,
    private toaster: ToastrService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.patner_id = localStorage.getItem('patner_id');

    this.getAppointments();
    this.month_Filter.setValue(moment().format('MM'));

    this.years.push(moment().subtract(2, 'year').format('YYYY'));
    this.years.push(moment().subtract(1, 'year').format('YYYY'));
    this.years.push(moment().format('YYYY'));

    this.year_Filter.setValue(moment().format('YYYY'));
  }

  getAppointments() {
    let query = {
      patner_id: this.patner_id,
      month: this.month ? this.month : moment().format('MM'),
      year: this.year ? this.year : moment().format('YYYY'),
      // month_name: this.month ? this.month_name : ''
    };

    this.server.request('GET', '/patner/getAppointmentDetailForPatnerbByPatnerId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.appointmentAvailable = true;
            this.appointmentDetails = _response.body.results;
            this.settlement_array = _response.body.results;
            this.total_cost = _response.body.only_gst;
            localStorage.setItem('gst_totalPatnerAmount', _response.body.only_gst);

            for (let i = 0; i < this.appointmentDetails.length; i++) {
              this.appointmentDetails[i].date = moment(this.appointmentDetails[i].start_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
              this.appointmentDetails[i].start = moment(this.appointmentDetails[i].start_datetime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A');
              this.appointmentDetails[i].end = moment(this.appointmentDetails[i].end_datetime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A');

              if (this.appointmentDetails[i].pay_status == '0') {
                this.appointmentDetails[i].pay_status = 'COV';
              }
              if (this.appointmentDetails[i].pay_status == '1') {
                this.appointmentDetails[i].pay_status = 'Full Payment';
              }
              if (this.appointmentDetails[i].pay_status == '2') {
                this.appointmentDetails[i].pay_status = 'Advance Payment';
              }
            }

            let settlement_query = {
              related_id: this.patner_id,
              // settlement_array: JSON.stringify(this.settlement_array)
              start_datetime: this.settlement_array[0].start_datetime,
            };
            localStorage.setItem('settlement_array', JSON.stringify(this.settlement_array));

            this.server.request('GET', '/providers/isSettlementActive', settlement_query)
              .subscribe(
                (_response: any) => {
                  if (_response.status === 200) {
                    this.settlement = true;
                  }
                },
                (_error) => {
                  this.settlement = false;
                });
          }
        },
        (_error) => {
          this.appointmentAvailable = false;
        });
  }

  monthFilter(event) {
    this.month = event.value;
    this.month_name = (event.value - 1).toString().length == 1 ? '0'.concat((event.value - 1).toString()) : (event.value - 1);

    this.getAppointments();

    if (moment(this.month, 'MM').isSame(moment(), 'month')) {
      this.toaster.error('Settlement for current month is not possible');
    }
  }

  yearFilter(event) {
    this.year = event.value;

    this.getAppointments();
  }

}
