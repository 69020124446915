import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged, reduce } from 'rxjs/operators';
import { from, Observable } from "rxjs";
import { GetbookedappointfornotifService } from '../../services/getbookedappointfornotif/getbookedappointfornotif.service';
import { CancelapointfornotificationService } from '../../services/cancelapointfornotification/cancelapointfornotification.service';
import { NotificationcountService } from '../../services/notificationcount/notificationcount.service';
import { ChangeviewstatusService } from '../../services/changeviewstatus/changeviewstatus.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { GetuserprofileService } from '../../services/getuserprofile/getuserprofile.service';
import { BehaviorSubject } from 'rxjs'
import { JwtTestService } from '../../services/jwt-test/jwt-test.service'
import { GetcategoryServiceService } from '../../services/getcategory/getcategory-service.service';

export interface IBreadCrumb {
  label: string,
  url: string
}

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit, OnDestroy {
  over: '';
  public breadcrumbs: IBreadCrumb[];

  users: "";
  navbarOpen = false;
  userid = '';
  showNotif = false;
  today = moment().format('YYYY-MM-DD');
  appointmentavailable = true;
  cancelappointment = true;
  appointments = [];
  canceledAppointments = [];
  notif_count = 0;
  prev_notif_count = 0;
  accept_count = 0;
  prev_accept_count = 0;
  cancel_count = 0;
  prev_cancel_count = 0;
  intervalId;
  viewText = 'View All';
  expand = false;
  userName = '';
  category: [];
  playStoreLink = 'https://play.google.com/store/apps/details?id=org.nativescript.Blank';
  logoScrolled = '../../../assets/image/White-text-logo.png';
  user_type;
  showCustomerName = false;
  customerName = '';
  // breadcrumbConfig: object = {
  //   bgColor: 'white',
  //   fontSize: '1em',
  //   fontColor: 'darkgrey',
  //   lastLinkColor: 'darkgrey',
  //   symbol: ' > ',
  // };

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cancelApointForNotificationService: CancelapointfornotificationService,
    private changeViewStatusService: ChangeviewstatusService,
    private notificationCountService: NotificationcountService,
    private getBookedAppointForNotif: GetbookedappointfornotifService,
    private toaster: ToastrService,
    private getuserprofileService: GetuserprofileService,
    private server: JwtTestService,
    private GetcategoryServiceService: GetcategoryServiceService,
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);

    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');
    this.user_type = localStorage.getItem('user_type');
    if (this.user_type == 1) {
      this.showCustomerName = true;
      this.customerName = localStorage.getItem('first_name');
    }

    this.GetcategoryServiceService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.category = _response.body;
          }
        },
        (_error) => {

        });

    if (this.userid) {
      this.getCancelledAppointments(this.today);
      this.getAppointments(this.today);

      // let countQuery = `?userid=${this.userid}`;
      let countQuery = {
        userid: this.userid
      };
      this.server.request('GET', '/notification/notificationCount', countQuery)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              this.notif_count = _response.body.notification_count ? _response.body.notification_count : 0;
            }
          },
          (_error) => {

          }
        );

      // this.notificationCountService.NotificationcountService(countQuery)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         this.notif_count = _response.body.notification_count ? _response.body.notification_count : 0;
      //       }
      //     },
      //     (_error) => {

      //     }
      //   );

      // let queryParams = `?userid=${this.userid}`;
      // this.getuserprofileService.GetuserprofileService(queryParams)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         this.userName = _response.body[0].user_name;
      //       }
      //     },
      //     (_error) => {

      //     }
      //   );

      let queryParams = {
        userid: this.userid
      };
      this.server.request('GET', '/users/getUserProfile', queryParams).subscribe((_response: any) => {
        if (_response.status === 200) {
          this.userName = _response.body[0].user_name;
        }
      });

      this.intervalId = setInterval(() => {
        // let queryParam = `?intended_userid=${this.userid}&date=${this.today}`;
        let queryParam = {
          intended_userid: this.userid,
          date: this.today
        };

        this.server.request('GET', '/notification/getCancelAppointmentForNotication', queryParam)
          .subscribe(
            (_response: any) => {
              if (_response.status === 200) {
                try {
                  this.cancelappointment = true;
                  this.canceledAppointments = _response.body.data.results;
                  this.prev_cancel_count = this.cancel_count;
                  this.cancel_count = _response.body.data.total ? _response.body.data.total : 0;
                  // console.log('prev: ', this.prev_cancel_count, ' cancel: ', this.cancel_count);
                  if (this.cancel_count > this.prev_cancel_count) {
                    this.toaster.error('Appointment has been rejected');
                  }
                  // console.log(this.canceledAppointments);

                  if (this.canceledAppointments) {
                    for (let i = 0; i < this.canceledAppointments.length; i++) {
                      this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
                      this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
                      this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
                    }
                  } else {
                    return;
                  }
                } catch (err) {
                  console.log('Error: ', err);
                }
              }
            },
            (_error) => {
              if (_error.status === 409) {
                this.cancelappointment = false;
              }
            }
          );

        // this.cancelApointForNotificationService.CancelapointfornotificationService(queryParam)
        //   .subscribe(
        //     (_response) => {
        //       if (_response.status === 200) {
        //         try {
        //           this.cancelappointment = true;
        //           this.canceledAppointments = _response.body.data.results;
        //           this.prev_cancel_count = this.cancel_count;
        //           this.cancel_count = _response.body.data.total ? _response.body.data.total : 0;
        //           // console.log('prev: ', this.prev_cancel_count, ' cancel: ', this.cancel_count);
        //           if (this.cancel_count > this.prev_cancel_count) {
        //             this.toaster.error('Appointment has been rejected');
        //           }
        //           // console.log(this.canceledAppointments);

        //           if (this.canceledAppointments) {
        //             for (let i = 0; i < this.canceledAppointments.length; i++) {
        //               this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
        //               this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
        //               this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
        //             }
        //           } else {
        //             return;
        //           }
        //         } catch (err) {
        //           console.log('Error: ', err);
        //         }
        //       }
        //     },
        //     (_error) => {
        //       if (_error.status === 409) {
        //         this.cancelappointment = false;
        //       }
        //     }
        //   );

        // this.getAppointments(this.today);

        // let acceptQueryParam = `?intended_userid=${this.userid}&date=${this.today}`;
        let acceptQueryParam = {
          intended_userid: this.userid,
          date: this.today
        };

        this.server.request('GET', '/notification/getBookedAppointmentForNotication', acceptQueryParam)
          .subscribe(
            (_response: any) => {
              if (_response.status === 200) {
                try {
                  this.prev_accept_count = this.accept_count;
                  this.accept_count = _response.body.data.total ? _response.body.data.total : 0;
                  // console.log('prev-accept: ', this.prev_accept_count, ' accept: ', this.accept_count);
                  if (this.accept_count > this.prev_accept_count) {
                    this.toaster.success('Appointment has been accepted');
                  }

                  this.appointments = _response.body.data.results;
                  // console.log(this.appointments);

                  if (this.appointments) {
                    for (let i = 0; i < this.appointments.length; i++) {
                      this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
                      this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
                      this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
                    }
                  } else {
                    return;
                  }
                } catch (err) {
                  console.log('Error: ', err);
                }
              }
            },
            (_error) => {

            });

        // this.getBookedAppointForNotif.GetbookedappointfornotifService(acceptQueryParam)
        //   .subscribe(
        //     (_response) => {
        //       if (_response.status === 200) {
        //         try {
        //           this.prev_accept_count = this.accept_count;
        //           this.accept_count = _response.body.data.total ? _response.body.data.total : 0;
        //           // console.log('prev-accept: ', this.prev_accept_count, ' accept: ', this.accept_count);
        //           if (this.accept_count > this.prev_accept_count) {
        //             this.toaster.success('Appointment has been accepted');
        //           }

        //           this.appointments = _response.body.data.results;
        //           // console.log(this.appointments);

        //           if (this.appointments) {
        //             for (let i = 0; i < this.appointments.length; i++) {
        //               this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
        //               this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
        //               this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
        //             }
        //           } else {
        //             return;
        //           }
        //         } catch (err) {
        //           console.log('Error: ', err);
        //         }
        //       }
        //     },
        //     (_error) => {

        //     });

        this.server.request('GET', '/notification/notificationCount', countQuery)
          .subscribe(
            (_response: any) => {
              if (_response.status === 200) {
                this.prev_notif_count = this.notif_count;
                this.notif_count = _response.body.notification_count ? _response.body.notification_count : 0;
                // console.log('prev: ', this.prev_notif_count, ' book: ', this.notif_count);
                // if (this.notif_count > this.prev_notif_count) {
                //   this.toaster.success('Appointment has been accepted');
                // }
              }
            },
            (_error) => {

            }
          );

        // this.notificationCountService.NotificationcountService(countQuery)
        //   .subscribe(
        //     (_response) => {
        //       if (_response.status === 200) {
        //         this.prev_notif_count = this.notif_count;
        //         this.notif_count = _response.body.notification_count ? _response.body.notification_count : 0;
        //         // console.log('prev: ', this.prev_notif_count, ' book: ', this.notif_count);
        //         // if (this.notif_count > this.prev_notif_count) {
        //         //   this.toaster.success('Appointment has been accepted');
        //         // }
        //       }
        //     },
        //     (_error) => {

        //     }
        //   );
      }, 5000);
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    })
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    // if (isDynamicRoute && !!route.snapshot) {
    //   const paramName = lastRoutePart.split(':')[1];
    //   path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
    //   label = route.snapshot.params[paramName];
    // }
    if (isDynamicRoute && !!route.params) {
      let parameter = '';
      const paramName = lastRoutePart.split(':')[1];
      route.params.subscribe(params => {
        parameter = params[paramName];
      });
      if (!paramName.endsWith('id')) {
        path = path.replace(lastRoutePart, parameter);
        // console.log(parameter.slice(0, -10));
        label = parameter.replace(/-/g, ' ');
      } else {
        path = path.replace(lastRoutePart, parameter);
      }
    }

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl
    };

    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

  notificationCount(appoint_id, id, n_type) {
    let appointment = {
      appoint_id: appoint_id,
      is_viewed: '1',
      n_type: n_type
    };

    this.server.request('POST', '/notification/changeViewStatus', appointment)
      .subscribe(
        (_response) => {
          // alert('notification read');
          let elem: HTMLElement = document.getElementById(id);
          elem.setAttribute("style", "background-color: white; display: block;");
          this.router.navigate(['/customer-dashboard/booked-appointments']);
        },
        (_error) => {

        }
      );

    // this.changeViewStatusService.ChangeviewstatusService(appointment)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         // alert('notification read');
    //         let elem: HTMLElement = document.getElementById(id);
    //         elem.setAttribute("style", "background-color: white; display: block;");
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
  }

  getAppointments(startDate) {
    // let queryParam = `?intended_userid=${this.userid}&date=${startDate}`;
    let queryParam = {
      intended_userid: this.userid,
      date: startDate
    };

    this.server.request('GET', '/notification/getBookedAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.appointments = _response.body.data.results;
              this.accept_count = _response.body.data.total ? _response.body.data.total : 0;
              // console.log(this.appointments);

              if (this.appointments) {
                for (let i = 0; i < this.appointments.length; i++) {
                  this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
                  this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
                  this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        },
        (_error) => {

        });

    // this.getBookedAppointForNotif.GetbookedappointfornotifService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         try {
    //           this.appointments = _response.body.data.results;
    //           this.accept_count = _response.body.data.total ? _response.body.data.total : 0;
    //           // console.log(this.appointments);

    //           if (this.appointments) {
    //             for (let i = 0; i < this.appointments.length; i++) {
    //               this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
    //               this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
    //               this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
    //             }
    //           } else {
    //             return;
    //           }
    //         } catch (err) {
    //           console.log('Error: ', err);
    //         }
    //       }
    //     },
    //     (_error) => {

    //     });
  }

  getCancelledAppointments(startDate) {
    // let queryParam = `?intended_userid=${this.userid}&date=${startDate}`;
    let queryParam = {
      intended_userid: this.userid,
      date: startDate
    };

    this.server.request('GET', '/notification/getCancelAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.cancelappointment = true;
              this.canceledAppointments = _response.body.data.results;
              this.cancel_count = _response.body.data.total ? _response.body.data.total : 0;
              // console.log(this.canceledAppointments);
              if (this.canceledAppointments) {
                for (let i = 0; i < this.canceledAppointments.length; i++) {
                  this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
                  this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
                  this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            this.cancelappointment = false;
          }
        }
      );

    // this.cancelApointForNotificationService.CancelapointfornotificationService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         try {
    //           this.cancelappointment = true;
    //           this.canceledAppointments = _response.body.data.results;
    //           this.cancel_count = _response.body.data.total ? _response.body.data.total : 0;
    //           // console.log(this.canceledAppointments);
    //           if (this.canceledAppointments) {
    //             for (let i = 0; i < this.canceledAppointments.length; i++) {
    //               this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
    //               this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
    //               this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
    //             }
    //           } else {
    //             return;
    //           }
    //         } catch (err) {
    //           console.log('Error: ', err);
    //         }
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         this.cancelappointment = false;
    //       }
    //     }
    //   );
  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("container").style.maxWidth = "83.333333%";
    this.navbarOpen = true;
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
    this.navbarOpen = false;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['']);
  }

  toggleNotification(state: boolean) {
    this.showNotif = state;
    if (state == false) {
      this.viewText = 'View All';
      this.expand = false;
    }
  }

  viewAll() {
    var viewMore = document.getElementsByClassName('content');
    let notifElem: HTMLElement = document.getElementById('notification');
    for (let i = 0; i < viewMore.length; i++) {
      let elem: HTMLElement = document.getElementById(viewMore[i].id);
      if (elem.style.display === 'block') {
        elem.style.display = 'none';
        notifElem.style.height = 'auto';
        notifElem.style.overflowY = 'hidden';
        this.viewText = 'View All';
        this.expand = false;
      } else {
        elem.style.display = 'block';
        notifElem.style.height = '300px';
        notifElem.style.overflowY = 'scroll';
        this.viewText = 'View Less';
        this.expand = true;
      }
    }
  }

  shareAppLink(link: string) {
    let customer = document.getElementById('customer-btn') as HTMLElement;
    let business = document.getElementById('business-btn') as HTMLElement;

    if (link === 'customer') {
      this.playStoreLink = 'https://play.google.com/store/apps/details?id=org.nativescript.Blank';
      business.classList.remove('app-link-button-bg');
      customer.classList.add('app-link-button-bg');
    }
    else if (link === 'business') {
      this.playStoreLink = 'https://play.google.com/store/apps/details?id=com.blacktix.apointtBusiness';
      customer.classList.remove('app-link-button-bg');
      business.classList.add('app-link-button-bg');
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    // this.scrollFunction();
    let element = document.querySelector('.navbar') as HTMLElement;
    let nav1 = document.getElementById('nav1') as HTMLElement;
    let nav2 = document.getElementById('nav2') as HTMLElement;
    let nav3 = document.getElementById('nav3') as HTMLElement;
    let nav4 = document.getElementById('nav4') as HTMLElement;
    let customerName = document.getElementById('customerName') as HTMLElement;

    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-scrolled');
      nav1.classList.remove('links');
      nav2.classList.remove('links');
      nav3.classList.remove('links');
      nav4.classList.remove('links');
      if (customerName !== null) customerName.classList.remove('links');
      this.logoScrolled = "../../../assets/image/apointt-logo.png";
    } else {
      element.classList.remove('navbar-scrolled');
      nav1.classList.add('links');
      nav2.classList.add('links');
      nav3.classList.add('links');
      nav4.classList.add('links');
      if (customerName !== null) customerName.classList.add('links');
      this.logoScrolled = "../../../assets/image/White-text-logo.png";
    }
  }

  setCategory(catName, catId) {
    localStorage.removeItem('catChildId');
    localStorage.removeItem('catChildName');
    localStorage.setItem('catName', catName);
    localStorage.setItem('catId', catId);
  }
}
