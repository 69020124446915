import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder,FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-category-service',
  templateUrl: './category-service.component.html',
  styleUrls: ['./category-service.component.css']
})
export class CategoryServiceComponent  {
  states: string[] = [
    'Doctor', 'Law', 'Beauty parlour', 'CA/CS'
  ];
  services: string[] = [
    'Dental service','Cancer Service','Anesthesiology & Perioperative Medicine'
  ];

  public ownerForm: FormGroup;
  disableSelect = new FormControl(false);
  
  constructor() { }

  ngOnInit() {
    this.ownerForm = new FormGroup({
     ShopName : new FormControl(''),
      Address : new FormControl(''),
      City : new FormControl(''),
      state : new FormControl(''),
      pin : new FormControl(''),
      email : new FormControl(''),
   
      
    });
    
  }
  onSubmit(arg){
    console.warn(this.ownerForm.value);

  }
  
}
