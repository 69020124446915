import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OverlayService } from '../../services/overlay/overlay.service';
import { GetpartnerdetailService } from '../../services/getpartnerdetail/getpartnerdetail.service';
import { GetpartnerdetailbypartneridService } from '../../services/getpartnerdetailbypartnerid/getpartnerdetailbypartnerid.service';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { GetcategoryServiceService } from '../../services/getcategory/getcategory-service.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit, AfterViewInit {
  patner_id;
  totalPartnerAmount = '';
  total_provider = '';
  active_flag;
  navbarOpen = false;
  category: [];
  partner_name = '';

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router,
    private toaster: ToastrService,
    private service: OverlayService,
    private getPartnerDetailService: GetpartnerdetailService,
    private getPartnerDetailByPartnerId: GetpartnerdetailbypartneridService,
    private server: JwtTestService,
    private GetcategoryServiceService: GetcategoryServiceService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngAfterViewInit() {
    this.service.showOverlay(1);
  }

  restartOnboarding() {
    this.service.showOverlay(1);
  }

  ngOnInit() {
    this.patner_id = localStorage.getItem('patner_id');

    this.GetcategoryServiceService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.category = _response.body;
          }
        },
        (_error) => {

        });

    // let query = `?patner_id=${this.patner_id}`;
    let query = {
      patner_id: this.patner_id
    };

    this.server.request('GET', '/patner/getPatnerDetail', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.partner_name = _response.body.full_name;
          }
        },
        (_error) => {

        });

    // this.getPartnerDetailService.GetpartnerdetailService(query)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.totalPartnerAmount = _response.body.totalPatnerAmount ? _response.body.totalPatnerAmount : 0;
    //         this.total_provider = _response.body.total_provider ? _response.body.total_provider : 0;
    //       }
    //     },
    //     (_error) => {

    //     });

    // this.server.request('GET', '/patner/getPatnerDetailByPatnetId', query)
    //   .subscribe(
    //     (_response: any) => {
    //       if (_response.status === 200) {
    //         this.totalPartnerAmount = _response.body.totalPatnerAmount ? _response.body.totalPatnerAmount : 0;
    //         this.total_provider = _response.body.total_provider ? _response.body.total_provider : 0;
    //       }
    //     },
    //     (_error) => {

    //     });

    // this.server.request('GET', '/patner/getPatnerDetail', query)
    //   .subscribe(
    //     (_response: any) => {
    //       if (_response.status === 200) {
    //         this.active_flag = _response.body.active_flag;
    //       }
    //     },
    //     (_error) => {

    //     });

    // this.getPartnerDetailByPartnerId.GetpartnerdetailbypartneridService(query)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.active_flag = _response.body.active_flag;
    //       }
    //     },
    //     (_error) => {

    //     });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/partner-login']);
  }

  openNav() {
    document.getElementById("myNav").style.display = "block";
  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("container").style.maxWidth = "83.333333%";
    this.navbarOpen = true;
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
    this.navbarOpen = false;
  }

  goToLink() {
    window.open('/customer-dashboard/categories-view', "_blank");
  }

}
