import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class MyclientsService {

  constructor(private httpService: HttpClient) { }

  MyclientsService(query) {
    const href = environment.api.myclients;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }
}
