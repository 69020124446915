import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { GetsearchService } from '../../services/getserachservice/getsearch.service';
import { MapsAPILoader } from '@agm/core';
import { GetContactInfoService } from '../../services/contact-info/get-contact-info.service';

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.css']
})
export class HomeSearchComponent implements OnInit {
  catId = '';
  catChildId = '';
  isLoading = false;
  searchForm: FormGroup;
  latitude: number;
  longitude: number;
  zoom: number = 12;
  address: string;
  private geoCoder;
  postcode;
  cityArea = [];
  cityName = '';
  url = '';
  getSearchCategories = [];
  getSearchSubCat = [];
  searchCatId;
  formatted_address = '';
  selectedCategory;

  @ViewChild('searchLocation', { static: false })
  public searchElementRef: ElementRef;

  @ViewChild('searchLocation2', { static: false })
  public searchElementRef2: ElementRef;

  @Output() areaSelectEvent = new EventEmitter();

  @Output() citySelectEvent = new EventEmitter();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private getSearch: GetsearchService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private getContactInfoService: GetContactInfoService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    this.catId = localStorage.getItem('catId');
    this.catChildId = localStorage.getItem('catChildId');
    this.cityName = localStorage.getItem('cityName');
    this.formatted_address = localStorage.getItem('formatted_address');

    this.latitude = Number(localStorage.getItem('latitude'));
    this.longitude = Number(localStorage.getItem('longitude'));

    this.url = this.router.url;

    this.getSearch.getSearchForCategory()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getSearchCategories = _response.body;
            if (this.catId && this.url != '/customer-dashboard') {
              let category = this.getSearchCategories.find(cat => cat.id == this.catId);
              this.searchForm.controls['search_category'].setValue(category.id);
            }
          }
        },
        (_error) => {
          this.getSearchCategories = [];
        });

    this.getSearch.getSearchProviderAndSubCategory(this.catId ? this.catId : '')
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getSearchSubCat = _response.body;
            if (this.catChildId && (!this.url.includes('/customer-dashboard/child-category-view') && this.url != '/customer-dashboard')) {
              let subcat = this.getSearchSubCat.find(sub => sub.id == this.catChildId);
              this.searchForm.controls['search_provider'].setValue(subcat);
            }
          }
        },
        (_error) => {
          this.getSearchSubCat = [];
        });

    this.searchForm = this.formBuilder.group({
      search_category: [''],
      search_provider: [''],
    });

    this.searchForm
      .get('search_provider')
      .valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => this.isLoading = true),
        switchMap(value => this.getSearch.getSearchProviderAndSubCategory(this.searchCatId ? this.searchCatId : this.catId, this.postcode ? this.postcode.long_name.substr(0, 3) : '', value)
          .pipe(
            finalize(() => this.isLoading = false),
          ))
      )
      .subscribe(
        (_response => {
          if (_response.status === 200) {
            this.getSearchSubCat = _response.body;
          } else {
            this.getSearchSubCat = [];
          }
        }),
        (_error => {
          this.getSearchSubCat = [];
        })
      );

    if (this.cityName === null) {
      this.setCurrentLocation();
    }

    this.mapsAPILoader.load().then(() => {
      if (this.cityName === null) {
        this.setCurrentLocation();
      }
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });

      let autocomplete2 = new google.maps.places.Autocomplete(this.searchElementRef2.nativeElement, {
        types: ["address"]
      });

      if (autocomplete) {
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            localStorage.setItem('formatted_address', place.formatted_address);

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;

            localStorage.setItem('latitude', this.latitude.toString());
            localStorage.setItem('longitude', this.longitude.toString());

            this.getAddress(this.latitude, this.longitude);
          });
        });
      } else if (autocomplete2) {
        autocomplete2.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            localStorage.setItem('formatted_address', place.formatted_address);

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;

            localStorage.setItem('latitude', this.latitude.toString());
            localStorage.setItem('longitude', this.longitude.toString());

            this.getAddress(this.latitude, this.longitude);
          });
        });
      }

    });
  }

  addressChange(event) {
    if (this.searchElementRef.nativeElement.value == '' || this.searchElementRef2.nativeElement.value == '') {
      localStorage.removeItem('formatted_address');
      localStorage.removeItem('cityName');
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 10;

        localStorage.setItem('latitude', this.latitude.toString());
        localStorage.setItem('longitude', this.longitude.toString());

        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log('address: ', results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.postcode = results[0].address_components.pop();
          this.citySelectEvent.emit(this.postcode);
          this.searchElementRef.nativeElement.value = this.address;
          this.searchElementRef2.nativeElement.value = this.address;
          localStorage.setItem('formatted_address', this.address);

          if (this.cityName !== '') {
            let cityQuery = `?postcode=${this.postcode.long_name.slice(0, 3)}`;
            this.getContactInfoService.GetContactInfoService(cityQuery)
              .subscribe(
                (_response) => {
                  if (_response.status === 200) {
                    this.cityArea = _response.body;
                    this.cityName = _response.body[0].city;
                    localStorage.setItem('cityName', this.cityName);
                  }
                },
                (_error) => {

                });
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  clearLocation() {
    this.searchElementRef.nativeElement.value = '';
    this.searchElementRef2.nativeElement.value = '';
    localStorage.removeItem('formatted_address');
    localStorage.removeItem('cityName');
  }

  displaySearch(search: any) {
    if (search && search.type !== 'Provider') { return search.name; }
    else { return search.name; }
  }

  selectSubCategory(search) {
    console.log('selectSubCategory: ', search);
    if (search.type === 'Category') {
      localStorage.setItem('catId', search.id);
    } else if (search.type === 'Sub Category') {
      localStorage.setItem('catChildId', search.id);
      localStorage.setItem('catId', search.parent_id);
    } else if (search.type === 'Provider') {
      localStorage.setItem('catChildId', search.parent_id);
      let id = localStorage.getItem('catChildId');
      localStorage.setItem('catId', id.charAt(0));
      sessionStorage.setItem('provider_id', search.id);
    }
    this.selectedCategory = search;
  }

  jumpToPage(search) {
    console.log('search: ', search);
    if (search) {
      if (search.type === 'Category') {
        this.router.navigated = false;
        this.router.navigate(['/customer-dashboard/child-category-view/', search.name]);
        localStorage.setItem('catId', search.id);
      } else if (search.type === 'Sub Category') {
        this.router.navigated = false;
        this.router.navigate(['/customer-dashboard/provider-details-view/', search.name]);
        localStorage.setItem('catChildId', search.id);
        localStorage.setItem('catId', search.parent_id);
      } else if (search.type === 'Provider') {
        this.router.navigated = false;
        this.router.navigate(['/customer-dashboard/profile-details', search.unique_url_id]);
        localStorage.setItem('catChildId', search.parent_id);
        let id = localStorage.getItem('catChildId');
        localStorage.setItem('catId', id.charAt(0));
        sessionStorage.setItem('provider_id', search.id);
      }
    }
  }

  selectCategory(event) {
    console.log('select: ', this.searchForm.controls['search_category'].value);
    this.searchCatId = this.searchForm.controls['search_category'].value;
    let catName = this.getSearchCategories[this.searchForm.controls['search_category'].value - 1].name;
    localStorage.setItem('catName', catName);
    // let query = `?category_id=${event.id}`;
    // this.postcode ? query += `&postcode=${this.postcode.long_name}` : ``;

    this.getSearch.getSearchProviderAndSubCategory(this.searchCatId, this.postcode ? this.postcode.long_name : '')
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getSearchSubCat = _response.body;
            this.searchForm.controls['search_provider'].setValue('');
          }
        },
        (_error) => {
          this.getSearchSubCat = [];
        });
  }

}
