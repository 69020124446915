import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { GetuserprofileService } from '../../../services/getuserprofile/getuserprofile.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpdateUserProfileService } from '../../../services/updateUserProfile/update-user-profile.service';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader, MouseEvent } from '@agm/core';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})
export class CustomerProfileComponent implements OnInit {
  userid = '';
  userDetails = [];
  userForm: FormGroup;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  markerChange = false;
  locationDisabled = true;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getuserprofileService: GetuserprofileService,
    private server: JwtTestService,
    private router: Router,
    private updateUserProfileService: UpdateUserProfileService,
    private toaster: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');
    // let queryParams = `?userid=${this.userid}`;
    let queryParams = {
      userid: this.userid
    };

    this.server.request('GET', '/users/getUserProfile', queryParams)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.userDetails = _response.body;

            this.userForm.controls['mobile_number'].setValue(this.userDetails[0].user_mobile_no);
            this.userForm.controls['email'].setValue(this.userDetails[0].user_email_address);
            this.userForm.controls['address'].setValue(this.userDetails[0].user_address);
          }
        },
        (_error) => {

        });

    // this.getuserprofileService.GetuserprofileService(queryParams)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.userDetails = _response.body;

    //         this.userForm.controls['mobile_number'].setValue(this.userDetails[0].user_mobile_no);
    //         this.userForm.controls['email'].setValue(this.userDetails[0].user_email_address);
    //         this.userForm.controls['address'].setValue(this.userDetails[0].user_address);
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );

    this.userForm = new FormGroup({
      mobile_number: new FormControl({ value: '', disabled: true }, [Validators.pattern(/^\d{10}$/)]),
      email: new FormControl('', [Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/), Validators.required]),
      address: new FormControl('', [Validators.required])
    });

    // let queryParams = {
    //   userid: this.userid
    // };

    // this.server.request('GET', '/users/getUserProfile', queryParams).subscribe((user: any) => {
    //   if (user) {
    //     this.userDetails = user;
    //   }
    // });

    this.setCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationDisabled = false;
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      },
        (error) => {
          console.log('error', error);
          this.locationDisabled = true;
        });
    }
  }

  markerDragEnd($event: MouseEvent) {
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.markerChange = true;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          if (this.markerChange) {
            this.userForm.controls['address'].setValue(this.address);
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  checkUserProfile(userFormValue) {
    if (this.userForm.valid) {
      this.updateUserProfile(userFormValue);
    } else {
      this.toaster.error('Please fill in all details');
    }
  }

  updateUserProfile(userFormValue) {
    let user = {
      userid: this.userid,
      email_address: userFormValue.email,
      address: this.userForm.controls['address'].value,
      mobile_number: this.userForm.controls['mobile_number'].value
    };

    this.server.request('POST', '/users/updateUserProfile', user)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Profile has been updated successfully');
          this.router.navigate(['/customer-dashboard']);
        },
        (_error) => {
          this.toaster.error('Could not update profile');
        });

    // this.updateUserProfileService.UpdateUserProfileService(user)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.toaster.success('Profile has been updated successfully');
    //       }
    //     },
    //     (_error) => {
    //       this.toaster.error('Could not update profile');
    //     });
  }

}
