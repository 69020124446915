import { AfterViewInit, Component, OnInit, HostListener, Inject } from '@angular/core';
import { GetsubcategoryserviceService } from 'src/app/services/getsubcategory/getsubcategoryservice.service';
import { Router } from '@angular/router';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GetcategoryServiceService } from '../../../services/getcategory/getcategory-service.service';
import { NguiOverlayManager } from '@ngui/overlay';
import { AppDialogBoxComponent } from '../../app-dialog-box/app-dialog-box.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialog, MatDialogConfig } from "@angular/material";
import { fromEvent } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';

@Component({
  selector: 'app-introduction-page',
  templateUrl: './introduction-page.component.html',
  styleUrls: ['./introduction-page.component.css']
})
export class IntroductionPageComponent implements OnInit {
  title = 'Apointt Search Schedule & Visit';
  logoScrolled = '../../../assets/image/White-text-logo.png';
  playStoreLink = 'https://play.google.com/store/apps/details?id=org.nativescript.Blank';
  subcategoryDoctor = [];
  subcategoryBeautician = [];
  subcategoryLawyer = [];
  subcategoryCa = [];
  category: [];
  doctorImage = { src: '', show: false };
  beauticianImage = { src: '', show: false };
  lawyerImage = { src: '', show: false };
  caImage = { src: '', show: false };
  showDoctors = false;
  showLawyers = false;
  showBeauticians = false;
  showCa = false;
  doctorName;
  beauticianName;
  lawyerName;
  caName;
  isShowDiv = false;
  business_name = '';
  showName = false;
  provider_url;
  provider_id;
  showCustomerName = false;
  customerName = '';
  user_type;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private subCategoryService: GetsubcategoryserviceService,
    private router: Router,
    private GetcategoryServiceService: GetcategoryServiceService,
    private route: ActivatedRoute,
    private _NgbModal: NgbModal,
    private dialog: MatDialog,
    private server: JwtTestService,
    // public  _overlayManager : NguiOverlayManager
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    localStorage.removeItem('catId');
    localStorage.removeItem('catChildId');

    this.user_type = localStorage.getItem('user_type');
    if (this.user_type == 1) {
      this.showCustomerName = true;
      this.showName = false;
      this.customerName = localStorage.getItem('first_name');
    } else if (this.user_type == 2) {
      this.showCustomerName = false;
      this.showName = true;

      this.business_name = localStorage.getItem('business_name');
      this.provider_id = localStorage.getItem('provider_id');

      let queryParam = {
        provider_id: this.provider_id
      };

      this.server.request('GET', '/providers/getSettings', queryParam)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              this.provider_url = _response.body.provider_url;
            }
          },
          (_error) => {

          });
    } else {
      this.showName = false;
      this.showCustomerName = false;
    }

    this.GetcategoryServiceService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.category = _response.body;
            console.log('category: ', this.category);
            this.doctorImage = { src: _response.body[0].catUrl, show: false };
            this.beauticianImage = { src: _response.body[2].catUrl, show: false };
            this.lawyerImage = { src: _response.body[1].catUrl, show: false };
            this.caImage = { src: _response.body[3].catUrl, show: false };

            this.doctorName = _response.body[0].catName;
            this.beauticianName = _response.body[2].catName;
            this.lawyerName = _response.body[1].catName;
            this.caName = _response.body[3].catName;
          }
        },
        (_error) => {

        });

    let queryDoctor = `?category_name=Doctor`;
    this.subCategoryService.GetsubcategoryserviceService(queryDoctor)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.subcategoryDoctor = _response.body;
            for (let i = 0; i < this.subcategoryDoctor.length; i++) {
              this.subcategoryDoctor[i].show = false
            }
            while (this.subcategoryDoctor.length > 5) {
              this.subcategoryDoctor.pop();
            }
          }
        },
        (_error) => {

        });

    let queryBeautician = `?category_name=Beautician`;
    this.subCategoryService.GetsubcategoryserviceService(queryBeautician)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.subcategoryBeautician = _response.body;
            while (this.subcategoryBeautician.length > 5) {
              this.subcategoryBeautician.pop();
            }
          }
        },
        (_error) => {

        });

    let queryLawyer = `?category_name=Lawyer`;
    this.subCategoryService.GetsubcategoryserviceService(queryLawyer)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.subcategoryLawyer = _response.body;
            while (this.subcategoryLawyer.length > 5) {
              this.subcategoryLawyer.pop();
            }
          }
        },
        (_error) => {

        });

    let queryCa = `?category_name=Chartered Accountant`;
    this.subCategoryService.GetsubcategoryserviceService(queryCa)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.subcategoryCa = _response.body;
          }
        },
        (_error) => {

        });

    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Apointt is used to Search Doctors, Lawyers, CA, CS,Beauticians and other professionals nearby to your location, to book appointment from schedule and Visit' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'schedule doctor appointment, doctor appointment or doctors appointment, schedule doctor appointment online, online doctor appointment, schedule an appointment, schedule appointment, appointment scheduler free,' }
    );
  }

  showSubCategory(category) {
    let background = document.getElementById('cat-background');
    background.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
    if (category == 'doctor') {
      this.showDoctors = true;
      this.showLawyers = false;
      this.showBeauticians = false;
      this.showCa = false;
    } else if (category == 'lawyer') {
      this.showDoctors = false;
      this.showLawyers = true;
      this.showBeauticians = false;
      this.showCa = false;
    } else if (category == 'beautician') {
      this.showDoctors = false;
      this.showLawyers = false;
      this.showBeauticians = true;
      this.showCa = false;
    } else if (category == 'ca') {
      this.showDoctors = false;
      this.showLawyers = false;
      this.showBeauticians = false;
      this.showCa = true;
    }
  }

  redirectTo(catChildName, catId, catChildId) {
    this.router.navigate(['/customer-dashboard/provider-details-view/', catChildName]);
    localStorage.setItem('catId', catId);
    localStorage.setItem('catChildId', catChildId);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    // this.scrollFunction();
    let element = document.querySelector('.navbar') as HTMLElement;
    let nav1 = document.getElementById('nav1') as HTMLElement;
    let nav2 = document.getElementById('nav2') as HTMLElement;
    let nav3 = document.getElementById('nav3') as HTMLElement;
    let nav4 = document.getElementById('nav4') as HTMLElement;
    let name = document.getElementById('name') as HTMLElement;
    let customerName = document.getElementById('customerName') as HTMLElement;

    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-scrolled');
      nav1.classList.remove('links');
      nav2.classList.remove('links');
      nav3.classList.remove('links');
      nav4.classList.remove('links');
      if (customerName !== null) customerName.classList.remove('links');
      if (name !== null) name.classList.remove('links');
      this.logoScrolled = "../../../assets/image/apointt-logo.png";
    } else {
      element.classList.remove('navbar-scrolled');
      nav1.classList.add('links');
      nav2.classList.add('links');
      nav3.classList.add('links');
      nav4.classList.add('links');
      if (customerName !== null) customerName.classList.add('links');
      if (name !== null) name.classList.add('links');
      this.logoScrolled = "../../../assets/image/White-text-logo.png";
    }
  }
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }


  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  // openPopUp()
  // {
  //     this._overlayManager.open('popup-modal', 1);
  // }
  // closePopUp()
  // {
  //     this._overlayManager.close('popup-modal');
  // }
  openModal() {
    this._NgbModal.open(AppDialogBoxComponent, {
      windowClass: 'modal-job-scrollable'
    });

    // upwrap the "app-ng-modal" data to enable the "modal-dialog-scrollable"
    // and make the modal scrollable
    (() => {
      const node: HTMLElement | null = document.querySelector('app-ng-modal');
      if (node) {
        while (node.firstChild) {
          (node.parentNode as HTMLElement).insertBefore(node.firstChild, node);
        }
      }
      // make the modal scrollable by adding the class .modal-dialog-scrollable
      // here wait for one second so that we can find the .modal-dialog
      setTimeout(() => {
        const modalDialog = document.querySelector('.modal-job-scrollable .modal-dialog');
        if (modalDialog) {
          modalDialog.classList.add('modal-dialog-scrollable');
        }
      }, 1000)
    })();
  }
  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  goToYoutube() {
    window.open('https://www.youtube.com/watch?v=RbuufS1fLL8', '_blank');
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
    this.showName = false;
    this.showCustomerName = false;
  }

  shareAppLink(link: string) {
    let customer = document.getElementById('customer-btn') as HTMLElement;
    let business = document.getElementById('business-btn') as HTMLElement;

    if (link === 'customer') {
      this.playStoreLink = 'https://play.google.com/store/apps/details?id=org.nativescript.Blank';
      business.classList.remove('app-link-button-bg');
      customer.classList.add('app-link-button-bg');
    }
    else if (link === 'business') {
      this.playStoreLink = 'https://play.google.com/store/apps/details?id=com.blacktix.apointtBusiness';
      customer.classList.remove('app-link-button-bg');
      business.classList.add('app-link-button-bg');
    }
  }

  setCategory(catName, catId) {
    localStorage.removeItem('catChildId');
    localStorage.removeItem('catChildName');
    localStorage.setItem('catName', catName);
    localStorage.setItem('catId', catId);
  }
  // openbox(){
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.width = "500px";

  //   this.dialog.open(AppDialogBoxComponent, dialogConfig);
  // }
  // openDialog(): void {
  //   const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //     width: '250px',
  //     data: {name: this.name, animal: this.animal}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }
  // PopUp(){
  //   document.getElementById('ac-wrapper').style.display="none";
  // }
}

