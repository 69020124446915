import { Component, OnInit } from '@angular/core';
import { AddclientService } from '../../../services/addclient/addclient.service';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

export interface Client {
  name: string;
  mobile_number: string;
}

@Component({
  selector: 'app-addclients',
  templateUrl: './addclients.component.html',
  styleUrls: ['./addclients.component.css']
})
export class AddclientsComponent implements OnInit {
  provider_id = '';
  ownerForm: FormGroup;
  clients = [];
  clientsAvailable;
  displayedColumns: string[] = ['name', 'mobile_number', 'contact'];
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private addClientsService: AddclientService,
    private toaster: ToastrService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');

    this.ownerForm = new FormGroup({
      client_name: new FormControl('', [Validators.required]),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)])
    });

    this.getAllClients();
  }

  public createOwner = (ownerFormValue, formDirective: FormGroupDirective) => {
    if (this.ownerForm.valid) {
      this.addClient(ownerFormValue);
      formDirective.resetForm();
      this.ownerForm.reset();
    }
  }

  addClient(ownerFormValue) {
    let client = {
      mobile_number: ownerFormValue.mobile_number,
      provider_id: this.provider_id,
      first_name: ownerFormValue.client_name
    };

    this.server.request('POST', '/users/addClient', client)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Client added');
          this.getAllClients();
        },
        (_error) => {
          this.toaster.error('Client already exists');
        });

    // this.addClientsService.AddclientService(client)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.toaster.success('Client added');
    //         this.getAllClients();
    //       }
    //     },
    //     (_error) => {
    //       this.toaster.error('Client already exists');
    //     });
  }

  getAllClients() {
    // let queryParam = `?provider_id=${this.provider_id}`;
    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/users/getAllClientsByProviderId', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.clientsAvailable = true;
            this.clients = _response.body;
          }
        },
        (_error) => {
          this.clientsAvailable = false;
        });

    // this.addClientsService.getAllClientsByProviderIdService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.clientsAvailable = true;
    //         this.clients = _response.body;
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         this.clientsAvailable = false;
    //       }
    //     });
  }

  shareSms(client) {
    let shareData = {
      dt: [],
      provider_id: this.provider_id
    };

    try {
      let dt = JSON.stringify([client]);
      shareData.dt = [dt];

      console.log('shareData: ', shareData);

      this.server.request('POST', '/users/sendProviderLinkToClients', shareData)
        .subscribe(
          (_response: any) => {
            this.toaster.success('Link shared successfully');
          },
          (_error) => {
            this.toaster.error('Could not send SMS');
          });

      // this.addClientsService.shareProviderLinkService(shareData)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         this.toaster.success('Link shared successfully');
      //       } else {
      //         this.toaster.error('Could not send SMS');
      //       }
      //     },
      //     (_error) => {
      //       this.toaster.error('Could not send SMS');
      //     });
    } catch (err) {
      console.log('error: ', err);
    }
  }

}
