import { Component, OnInit } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.css']
})
export class PrivacyStatementComponent implements OnInit {

  constructor(private metaTagService: Meta,) { }

  ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'description', content: 'To more about user information, consent, payment, security, cookies & third party services kindly go through Apointt privacy policy' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'privacy statement and consent, a data privacy statement, privacy and confidentiality statement, privacy statement for ' }
    );
  }

}
