import { Component, OnInit } from '@angular/core';
import { AdminCountService } from '../../services/admin-count/admin-count.service';
import { Router } from '@angular/router';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  AdminCount: any = [
    { year_appointment: '', month_appointment: '', today_appointment: '', total_provider: '', total_customer: '', total_appointment: '' }
  ];
  admin_status;
  loginError = false;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private AdminCountService: AdminCountService,
    private router: Router,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.admin_status = localStorage.getItem('admin_status');

    if (this.admin_status == 1) {
      this.loginError = false;
    } else {
      this.loginError = true;
    }

    // this.AdminCountService.AdminCountService()
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.AdminCount = _response.body;
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );

    this.server.request('POST', '/admin/count')
    .subscribe(
      (_response) => {
        this.AdminCount = _response;
      },
      (_error) => {

      });

  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("container").style.maxWidth = "83.333333%";
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/admin-login']);
  }

}
