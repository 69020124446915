import { Component, OnInit } from '@angular/core';
import { MyclientsService } from '../../../services/myclients/myclients.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { AddclientService } from '../../../services/addclient/addclient.service';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';

export interface Client {
  name: string;
  mobile_number: string;
  userid: string;
}

@Component({
  selector: 'app-myclients',
  templateUrl: './myclients.component.html',
  styleUrls: ['./myclients.component.css']
})
export class MyclientsComponent implements OnInit {
  clients = [];
  provider_id = '';
  displayedColumns: string[] = ['name', 'mobile_number', 'type', 'details', 'contact'];
  clientsAvailable;
  ownerForm: FormGroup;
  userid;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  hideme = false;
  searchText;
  filterShow = true;
  // areaArray = [];
  filters = [
    { id: 'associatedClient', name: 'Associated Client' },
    { id: 'nonAssociatedClient', name: 'Non Associated Client'},
    { id: 'viewAll', name: 'View All'},
  ];

  constructor(
    private myClientsService: MyclientsService,
    private ngxService: NgxUiLoaderService,
    private addClientsService: AddclientService,
    private toaster: ToastrService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    this.ngxService.start();

    this.ownerForm = new FormGroup({
      client_name: new FormControl('', [Validators.required]),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)])
    });

    this.getAllClients();

  }

  getAllClients() {
    let queryParam = {
      provider_id: this.provider_id,
      userid: this.userid
    };
    this.server.request('GET', '/providers/myClients', queryParam)
      .subscribe(
        (_response: any) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);

          if (_response.status === 200) {
            if (_response.body.length == 0) {
              this.clientsAvailable = false;
            } else {
              this.clientsAvailable = true;
              this.clients = _response.body;

              for (let i = 0; i < this.clients.length; i++) {
                if (this.clients[i].type == 'associatedClient') {
                  this.clients[i].type = 'Associated Client';
                } else {
                  this.clients[i].type = 'Non Associated Client';
                }
              }
            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);

          this.clientsAvailable = false;
        });
  }

  public createOwner = (ownerFormValue, formDirective: FormGroupDirective) => {
    if (this.ownerForm.valid) {
      this.addClient(ownerFormValue);
      formDirective.resetForm();
      this.ownerForm.reset();
    }
    this.hideme = false;
  }

  addClient(ownerFormValue) {
    let client = {
      mobile_number: ownerFormValue.mobile_number,
      provider_id: this.provider_id,
      first_name: ownerFormValue.client_name
    };

    let queryParam = {
      provider_id: this.provider_id,
      userid: this.userid,
      mobile_number: ownerFormValue.mobile_number
    };

    this.server.request('GET', '/providers/myClients', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.server.request('POST', '/users/addClient', client)
              .subscribe(
                (_response: any) => {
                  this.toaster.success('Client added');
                  this.getAllClients();
                },
                (_error) => {
                  this.toaster.error('Client already exists');
                });
          }
        },
        (_error) => {
          this.toaster.warning('Cannot add your number as a client');

        });
  }

  onChange(event) {
    let queryParam = {
      provider_id: this.provider_id,
      userid: this.userid
    };

    this.server.request('GET', '/providers/myClients', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            if (_response.body.length == 0) {
              this.clientsAvailable = false;
            } else {
              this.clientsAvailable = true;
              this.clients = _response.body;

              if (event.id !== 'viewAll') {
                for (let i = this.clients.length - 1; i >= 0; i--) {
                  if (this.clients[i].type !== event.id) {
                    this.clients.splice(i, 1);
                  }
                }
                if (this.clients.length == 0) {
                  this.clientsAvailable = false;
                } else {
                  this.clientsAvailable = true;
                }
              }

              for (let i = 0; i < this.clients.length; i++) {
                if (this.clients[i].type == 'associatedClient') {
                  this.clients[i].type = 'Associated Client';
                } else {
                  this.clients[i].type = 'Non Associated Client';
                }
              }
            }
          }
        },
        (_error) => {
          this.clientsAvailable = false;

        });
  }

  shareSms(client) {
    let shareData = {
      dt: [],
      provider_id: this.provider_id
    };

    try {
      let dt = JSON.stringify([client]);
      shareData.dt = [dt];

      console.log('shareData: ', shareData);

      this.server.request('POST', '/users/sendProviderLinkToClients', shareData)
        .subscribe(
          (_response: any) => {
            this.toaster.success('Link shared successfully');
          },
          (_error) => {
            this.toaster.error('Could not send SMS');
          });
    } catch (err) {
      console.log('error: ', err);
    }
  }

}
