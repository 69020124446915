import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { GetappointmentbycustomerService } from '../../services/getappointmentbycustomer/getappointmentbycustomer.service';
import * as moment from 'moment';
import { ChangeAppointStatusService } from '../../services/change-appoint-status/change-appoint-status.service';
import { NotificationtypeService } from '../../services/notificationtype/notificationtype.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GetpaymentidbyapointtidService } from '../../services/getpaymentidbyapointtid/getpaymentidbyapointtid.service';
import { RazorpayService } from '../../services/razorpay/razorpay.service';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UploadAttachmentService } from '../../services/upload-attachment/upload-attachment.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ProfileServiceService } from '../../services/getprofile/profile-service.service';

@Component({
  selector: 'app-booked-appoint-details',
  templateUrl: './booked-appoint-details.component.html',
  styleUrls: ['./booked-appoint-details.component.css'],
  providers: [ConfirmationService]
})
export class BookedAppointDetailsComponent implements OnInit, OnDestroy {
  appointments = [
    // {
    //   userid: '',
    //   appoint_id: '',
    //   start_datetime: '',
    //   end_datetime: '',
    //   name: '',
    //   cost: '',
    //   date: '',
    //   apoint_status:
    // }
  ];
  userid = '';
  provider_id = '';
  unique_url_id = '';
  providerUserId = '';
  today = moment().format('YYYY-MM-DD');
  currentdatetime = moment();
  current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
  dateValid = false;
  appointmentsAvailable = true;
  intervalId;
  clickIntervalId;
  msgs: Message[] = [];
  videoIntervalId;
  catId;

  payment_id;
  refund_id;
  refundAppts = [];
  doc_upload: FormControl = new FormControl('');
  documentsToUpload: Array<File> = [];
  document_list;
  document_status = '';
  page: Number = 1

  @ViewChild('docUploadInput', { static: true }) docUploadInput: ElementRef;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getappointmentbycustomerService: GetappointmentbycustomerService,
    private changeAppointStatus: ChangeAppointStatusService,
    private notificationType: NotificationtypeService,
    private router: Router,
    private toaster: ToastrService,
    private confirmationService: ConfirmationService,
    private ngxService: NgxUiLoaderService,
    private getPaymentIdByApointtId: GetpaymentidbyapointtidService,
    private razorPayService: RazorpayService,
    private server: JwtTestService,
    private UploadAttachmentService: UploadAttachmentService,
    public dialog: MatDialog,
    private ProfileServiceService: ProfileServiceService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.clickIntervalId);
    clearInterval(this.videoIntervalId);
  }
  ngOnInit() {
    this.userid = localStorage.getItem('userid');
    // this.providerUserId = localStorage.getItem('providerUserId');
    this.provider_id = localStorage.getItem('provider_id');
    this.providerUserId = sessionStorage.getItem('providerUserId');

    this.ngxService.start();

    this.getAppointments();

    this.intervalId = setInterval(() => {
      this.getAppointments();
      this.doc_upload.setValue('');
    }, 30000);
  }

  /* handleClick(selected, current_date_time) {
    // this.currentdatetime = moment(current_date_time).subtract(1, 'days') || moment();
    console.log('selected', selected);
    console.log('current_date_time', current_date_time);
    // console.log('StartDate', StartDate);
    // currentdatetime = moment();
    // current_date_time = moment(this.currentdatetime).format("DD-MM-YYYY");
    let startDate, endDate, min;
    if (selected == 'sub') {

      startDate = moment(current_date_time).subtract(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).subtract(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment();
      this.currentdatetime = moment(current_date_time).subtract(1, 'days') || moment();
      min = 30;

    }
    if (selected == 'add') {
      startDate = moment(current_date_time).add(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).add(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment()
      this.currentdatetime = moment(current_date_time).add(1, 'days') || moment();
      min = 30;
    }
    //Display Reasons
    this.current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }
    this.getAppointments();
    console.log(' this.current_date_time ', this.current_date_time);

    clearInterval(this.intervalId);
    this.getAppointments();
    clearInterval(this.clickIntervalId);
    this.clickIntervalId = setInterval(() => {
      this.getAppointments();
      console.log('startDate: ', startDate);
    }, 5000);

    // this.StartDate = moment().subtract(1, 'days').format("DD-MMM-YYYY,h:mm:ss A") || 1;
    // this.EndDate = moment().subtract(1, 'days').add(10, 'hours').format("DD-MMM-YYYY,h:mm:ss A") || 1;
  } */

  getProfile(pid) {
    let queryParms = `?provider_id=${pid}`;
    let l_userid = `&l_userid=${this.userid}`
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {

            let providerInfo = _response.body[0];
            this.unique_url_id = providerInfo.unique_url_id;
          }
        },
        (_error) => {

        }
      )
  }

  getAppointments() {
    this.userid = localStorage.getItem('userid');
    // let queryParam = `?userid=${this.userid}&date=${this.today}`;
    // let queryParam = `?userid=${this.userid}`;
    let queryParam = {
      userid: this.userid
    };

    this.server.request('GET', '/appointment/getBookedAppointmentByUserId', queryParam)
      .subscribe(
        (_response: any) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);

          if (_response.status === 200) {
            if (_response.body.data == 'No found') {
              this.appointmentsAvailable = false;
            } else {
              this.appointmentsAvailable = true;
              this.appointments = _response.body.data.results;
              // console.log("apointment details ",this.appointments);
              if (this.appointments) {
                for (let i = 0; i < this.appointments.length; i++) {
                  let pid = this.appointments[i].provider_id;
                  this.appointments[i].date_time = moment(this.appointments[i].start_datetime);
                  this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
                  this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
                  this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');

                  if (this.appointments[i].pay_status == 0) {
                    this.appointments[i].pay_status_text = 'COV';
                  } else if (this.appointments[i].pay_status == 1) {
                    this.appointments[i].pay_status_text = 'Online Payment';
                  } else if (this.appointments[i].pay_status == 2) {
                    this.appointments[i].pay_status_text = 'Advance Payment';
                  }

                  if (this.appointments[i].apoint_status == 3) {
                    this.refundAppts.push(this.appointments[i]);
                  }

                  if (this.appointments[i].document_status == 0) {
                    this.appointments[i].request = false;
                  } else {
                    this.appointments[i].request = true;
                  }

                  // let query = {
                  //   appoint_id: this.appointments[i].appoint_id
                  // };

                  // this.server.request('GET', '/providers/getDocumentStatus', query)
                  // .subscribe(
                  //   (_response: any) => {
                  //     if (_response.status === 200 && _response.body[0].document_status == 0) {
                  //       this.appointments[i].request = false;
                  //     } else {
                  //       this.appointments[i].request = true;
                  //     }
                  //   },
                  //   (_error) => {
                  //     this.appointments[i].request = false;
                  //   });
                  // this.getProfile(pid);
                }
                this.getRefundId();
              }
            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.appointmentsAvailable = false;
        });
  }

  checkAppointmentDate(date_time) {
    let today = moment();
    if (moment(date_time).isSameOrAfter(today)) {
      return true;
    }
    return false;
  }

  confirmCancel(appoint_id, provider_id, pay_status) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel the appointment?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        this.cancelAppoint(appoint_id, provider_id, pay_status);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    })
  }

  cancelAppoint(appoint_id, provider_id, pay_status) {
    let status = {
      appoint_id: appoint_id,
      appoint_status: pay_status == 0 ? '2' : '3',
      appoint_status_reason: 2
    };

    this.server.request('POST', '/appointment/changeApointStatus', status)
      .subscribe(
        (_response: any) => {
          this.toaster.error('Appointment cancelled');

          if ((pay_status == 1 || pay_status == 2) && (status.appoint_status == '2' || status.appoint_status == '3')) {
            this.toaster.success('Amount will be automatically refunded');
          }

          this.getAppointments();

          if (status.appoint_status == '3') {
            this.getPaymentId(appoint_id);
          }

          let notificationtype = {
            userid: this.userid,
            intended_userid: this.providerUserId,
            appoint_id: appoint_id,
            n_type: '2'
          };

          this.server.request('POST', '/notification/notificationType', notificationtype)
            .subscribe((_response) => {
              console.log('notification type: ', notificationtype.n_type);
            },
              (_error) => {

              });
        },
        (_error) => {

        });
  }

  getPaymentId(appoint_id) {
    let query = {
      appoint_id: appoint_id
    };

    this.server.request('GET', '/razorpay_purchase/getPaymentIdByApointtId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            console.log('response: ', _response.body);
            this.payment_id = _response.body.razorpay_payment_id;
            this.refund();
          }
        },
        (_error) => {

        });
  }

  getRefundId() {
    for (let i = 0; i < this.refundAppts.length; i++) {

      let query = {
        appoint_id: this.refundAppts[i].appoint_id
      };

      this.server.request('GET', '/razorpay_purchase/getPaymentIdByApointtId', query)
        .subscribe((_response: any) => {
          if (_response.status === 200) {
            this.payment_id = _response.body.razorpay_payment_id;

            let found = this.appointments.find(appointment => appointment.appoint_id === this.refundAppts[i].appoint_id);
            let index = this.appointments.indexOf(found);
            this.appointments[index].refund_id = _response.body.refund_id;
          }
        },
          (_error) => {

          });
    }
  }

  refund() {
    let payment = {
      razorpay_payment_id: this.payment_id
    };

    this.server.request('POST', '/razorpay_purchase/refund', payment)
      .subscribe((_response: any) => {
        this.refund_id = _response.body.id;
        this.toaster.success('Payment refunded successfully');

        let refund = {
          razorpay_payment_id: this.payment_id,
          refund_id: _response.body.id
        };

        this.server.request('POST', '/razorpay_purchase/refundId', refund)
          .subscribe(
            (_response: any) => {
              console.log('refund id success');
            },
            (_error) => {

            });
      },
        (_error) => {
          this.toaster.error('Unable to refund payment');
        });
  }

  trackRefund() {
    let query = {
      refund_id: this.refund_id
    };

    this.server.request('GET', '/razorpay_purchase/fetchRefundById', query)
      .subscribe((_response: any) => {
        if (_response.status === 200) {
          console.log('refund: ', _response.body);
        }
      },
        (_error) => {

        });
  }

  trackRefundLink(refund_id) {
    window.open('https://razorpay.com/support/?utm_source=customer_mailer&utm_medium=email&utm_campaign=payment_refund#refund/' + refund_id, "_blank");
  }

  rescheduleAppointment(appointment) {
    localStorage.setItem('reschedule_flag', appointment.reschedule_flag);
    localStorage.setItem('appoint_id', appointment.appoint_id);
    this.router.navigate(['/customer-dashboard/calender-scheduler/', appointment.provider_id]);
  }

  checkVideoCallTime(appointment) {
    let now = moment();
    if (now.isSame(moment(appointment.date, 'DD MMM YYYY'), 'day') && now.isBetween(moment(appointment.start_datetime, 'hh:mm A').subtract(1, 'minute'), moment(appointment.end_datetime, 'hh:mm A'))) {
      return true;
    }
    return false;
  }

  goToVideoCall(appoint_id, end_datetime) {
    localStorage.setItem('appoint_id', appoint_id);
    // this.router.navigate(['/provider-dashboard/video-call']);
    let myWindow = window.open('https://video.apointt.com:8443/' + appoint_id);

    let eventTime = moment(end_datetime, 'h:mm A').unix();
    let currentTime = moment().unix();
    let diffTime = eventTime - currentTime;
    let duration = moment(end_datetime, 'h:mm A').diff(moment(), 'second');
    let interval = 1000;

    if (diffTime > 0) {
      this.videoIntervalId = setInterval(() => {
        if (duration <= 0) {
          clearInterval(this.videoIntervalId);
          myWindow.close();
        } else {
          duration = moment(end_datetime, 'h:mm A').diff(moment(), 'second');
          // console.log('open')
          // console.log('duration: ', duration);
          // console.log('end: ', end_datetime);
        }
      }, interval);
    }

    // setTimeout(() => {
    //   myWindow.close();
    // }, 10000);
  }

  onDocumentChange(event) {
    let fileName = event.target.files[0].name;
    this.documentsToUpload = <Array<File>>event.target.files;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }

    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.doc_upload.setValue(event.target.files);
    }
  }

  uploadAttachment(appoint_id, provider_id) {
    if (this.documentsToUpload.length > 0) {
      const docFormData: any = new FormData();
      const docFiles: Array<File> = this.documentsToUpload;

      for (let i = 0; i < docFiles.length; i++) {
        docFormData.append('avatar', docFiles[i], docFiles[i]['name']);
      }

      let queryParam = `?provider_id=${provider_id}&userid=${this.userid}&appoint_id=${appoint_id}&attachment_type=1`;
      this.UploadAttachmentService.UploadAttachmentService(docFormData, queryParam)
        .subscribe(
          (_response) => {
            this.toaster.success('File uploaded successfully');

            this.doc_upload.setValue('');

            let document_status = {
              appoint_id: appoint_id,
              document_status: 2
            };

            this.server.request('POST', '/providers/changeDocumentStatus', document_status)
              .subscribe(
                (_response: any) => {
                  console.log('document status changed');
                },
                (_error) => {
                  console.log("error: ", _error);
                });
          },
          (_error) => {
            console.log('error: ', _error);
          });
    } else {
      this.toaster.error('Please select a file to upload');
    }
  }

  route(appointment) {
    localStorage.setItem('catId', appointment.category_id);

    let queryParms = `?provider_id=${appointment.provider_id}`;
    let l_userid = `&l_userid=${this.userid}`;
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            try {
              this.router.navigate(['/customer-dashboard/profile-details/', _response.body[0].unique_url_id]);

            } catch (err) {
              console.log('err: ', err);
            }
          }
        },
        (_error) => {

        });

  }

  viewRequest(appoint_id) {
    let query = {
      appoint_id: appoint_id
    };

    this.server.request('GET', '/providers/getAttachment', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.document_list = _response.body[0].document_list ? _response.body[0].document_list : 'None';
          }
        },
        (_error) => {
          console.log('error: ', _error);
        });

    this.server.request('GET', '/providers/getDocumentStatus', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            if (_response.body[0].document_status == 0) {
              this.document_status = 'Not required';
            } else if (_response.body[0].document_status == 1) {
              this.document_status = 'Document requested';
            } else if (_response.body[0].document_status == 2) {
              this.document_status = 'In review by provider';
            } else if (_response.body[0].document_status == 3) {
              this.document_status = 'Approved by provider';
            }
            this.openDialog();
          }
        },
        (_error) => {
          console.log('error: ', _error);
        });
  }

  openDialog(): void {
    const dialogRef2 = this.dialog.open(DocumentUploadDialog, {
      width: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: { docs: this.document_list, status: this.document_status }

    });
    dialogRef2.afterClosed().subscribe(result => {
      console.log('result');
    });
  }

  getDirections(address) {
    address = address.replace(/ /g, '+').replace(/,/g, "%2C");
    window.open('https://www.google.com/maps/search/?api=1&query=' + address, "_blank");
  }

}
@Component({
  selector: 'document-upload-dialog',
  templateUrl: 'document-upload-dialog.html'
})

export class DocumentUploadDialog {
  shareURL = '';
  constructor(
    public dialogRef2: MatDialogRef<DocumentUploadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // try {
    //   this.shareURL = data.url
    // } catch (err) {
    //   console.log(err);
    // }
  }
  onNoClick(): void {
    this.dialogRef2.close();
  }


}