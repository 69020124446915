import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class DeleteproviderphotoService {

  constructor(private httpService: HttpClient) { }

  DeleteproviderphotoService(data) {
    const href = environment.api.deleteProviderPhoto;

    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
