import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetproviderholidaysService {

  constructor(private httpService: HttpClient) { }

  GetproviderholidaysService(query?) {
    let href = environment.api.getproviderholidays;
    query ? href += query: href;
    return this.httpService.get<any>(href, {'observe': 'response'});
  }
}
