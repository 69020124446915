import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { PartnerloginService } from 'src/app/services/partnerlogin/partnerlogin.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/jwt-test/auth.service';
import { LoginService } from 'src/app/services/login/login.service';
import { CryptoServicesService } from '../../services/crypto-services/crypto-services.service';
import { BrowserModule, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-partner-login',
  templateUrl: './partner-login.component.html',
  styleUrls: ['./partner-login.component.css']
})
export class PartnerLoginComponent implements OnInit {
  public ownerForm: FormGroup;
  hide = true;
  isActive;
  showError = false;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;

  constructor(private formBuilder: FormBuilder,
    private PartnerloginService: PartnerloginService,
    private location: Location,
    private Router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private loginService: LoginService,
    private metaTagService: Meta,
    private cryptoService: CryptoServicesService
  ) { }

  ngOnInit() {
    this.ownerForm = new FormGroup({
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/), Validators.maxLength(10)]),
      password: new FormControl('', [Validators.required]),
    });

    localStorage.clear();

    this.metaTagService.updateTag(
      { name: 'description', content: 'Login now & earn easily from first day!' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'partner login, apointt partner login, apointt register partner, Partner login for easy income,' }
    );

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.location.back();
  }
  async createOwner(ownerFormValue) {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.ownerForm.valid) {
      try {
        await this.authService.loginpartner(this.ownerForm.value);
        localStorage.setItem('mobile_number', ownerFormValue.mobile_number);
      } catch (err) {
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

  goToUrl(): void {
    window.location.assign('https://blacktix.com');
  }

}