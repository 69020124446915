import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetappointdetailsService {

  constructor(private httpService: HttpClient) { }
  GetappointdetailsService(queryParms) {
    const href = environment.api.getappointdetails +queryParms;
    
   return this.httpService.get<any>(href, { 'observe': 'response' })
 }

 getAppointmentDetailsById(query) {
   const href = environment.api.getapppointmentdetailsbyid;
   return this.httpService.get<any>(href + query, {'observe': 'response'});
 }
}
