import { Component, OnInit } from '@angular/core';
import { AdminCountService } from '../../../services/admin-count/admin-count.service';
import { Router } from '@angular/router';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  AdminCount: any = [
    {
      year_appointment: '', month_appointment: '', today_appointment: '',
      total_provider: '', total_customer: '', total_appointment: '', total_doctor_count: '', total_lawyer_count: '',
      total_beautician_count: '', total_cacs_count: ''
    }
  ];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private AdminCountService: AdminCountService,
    private router: Router,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.server.request('POST', '/admin/count')
    .subscribe(
      (_response) => {
        this.AdminCount = _response;
      },
      (_error) => {

      });
  }

}