import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class GetsubcategoryserviceService {

  constructor(private httpService: HttpClient) { }
  GetsubcategoryserviceService(queryParams) {
    // const href = environment.api.getsubcategory + `?category_name=${queryParams.category_name}&cat_name=${queryParams.cat_name}`;
    // return this.httpService.get<any>(href, { 'observe': 'response' })
     const href = environment.api. getsubcategory +queryParams;
    return this.httpService.get<any>(href, { 'observe': 'response' })
  }
}
