import { Component, OnInit } from '@angular/core';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-partner-provider-appointment-details',
  templateUrl: './partner-provider-appointment-details.component.html',
  styleUrls: ['./partner-provider-appointment-details.component.css']
})
export class PartnerProviderAppointmentDetailsComponent implements OnInit {
  provider_id;
  provider_created_date_time;
  appointmentDetails = [];
  appointmentAvailable = true;
  total_cost;
  displayedColumns: string[] = ['date', 'total_cost', 'pay_status', 'settlement_status'];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  constructor(
    private server: JwtTestService,
    private router: Router,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.provider_created_date_time = localStorage.getItem('provider_created_date_time');

    let query = {
      provider_id: this.provider_id,
      provider_created_date_time: this.provider_created_date_time
    };

    this.server.request('GET', '/patner/getAppointmentDetailForPatner', query)
    .subscribe(
      (_response: any) => {
        if (_response.status === 200) {
          this.appointmentAvailable = true;
          this.appointmentDetails = _response.body.results;
          this.total_cost = _response.body.only_gst;

          for (let i = 0; i < this.appointmentDetails.length; i++) {
            this.appointmentDetails[i].date = moment(this.appointmentDetails[i].start_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
            this.appointmentDetails[i].start_datetime = moment(this.appointmentDetails[i].start_datetime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A');
            this.appointmentDetails[i].end_datetime = moment(this.appointmentDetails[i].end_datetime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A');

            if (this.appointmentDetails[i].pay_status == '0') {
              this.appointmentDetails[i].pay_status = 'COV';
            }
            if (this.appointmentDetails[i].pay_status == '1') {
              this.appointmentDetails[i].pay_status = 'Full Payment';
            }
            if (this.appointmentDetails[i].pay_status == '2') {
              this.appointmentDetails[i].pay_status = 'Advance Payment';
            }
          }
        }
      },
      (_error) => {
        this.appointmentAvailable = false;
      });
  }

}
