import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AddproviderbankdetailsService {

  constructor(private httpService: HttpClient) { }

  AddproviderbankdetailsService(data) {
    const href = environment.api.addProviderBankDetails;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
