import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settlement-detail',
  templateUrl: './settlement-detail.component.html',
  styleUrls: ['./settlement-detail.component.css']
})
export class SettlementDetailComponent implements OnInit {
  provider_id = "";
  displayedColumns: string[] = ['provider_id', 'settlement_id', 'start_date', 'end_date', 'cost', 'status', 'details'];
  settlement_details: any = [
    { settlement_id: '', related_id: '', cost: '', admin_flag: '', settlement_status: '', settlement_start_date_time: '', settlement_end_date_time: '' }
  ];
  settlement_available = false;
  related_type;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private Router: Router,
    private server: JwtTestService,
    private toaster: ToastrService
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.related_type = localStorage.getItem('related_type')

    let query = {
      related_type: localStorage.getItem('related_type')
    };

    this.server.request('POST', '/admin/getSettlementDetail', query)
      .subscribe(
        (_response) => {
          this.settlement_available = true;
          this.settlement_details = _response;
          for (let i = 0; i < this.settlement_details.length; i++) {
            this.settlement_details[i].settlement_start_date_time = moment(this.settlement_details[i].settlement_start_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
            this.settlement_details[i].settlement_end_date_time = moment(this.settlement_details[i].settlement_end_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
          }
        },
        (_error) => {
          this.settlement_available = false;
        });

  }

  viewSettlementDetails(settlement_id) {
    localStorage.setItem('settlement_id', settlement_id);
    this.Router.navigate(['/admin/settlement-details']);
  }

  changeStatus(admin_flag, settlement_id) {
    let status = {
      settlement_id: settlement_id,
      admin_flag: admin_flag
    };

    this.server.request('POST', '/admin/changeSettlementAdminFlag', status)
    .subscribe(
      (_response: any) => {
        this.toaster.success('Status changed successfully');
        console.log('status changed');
      },
      (_error) => {

      });
  }

}
