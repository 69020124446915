import { Component, OnInit, HostListener } from '@angular/core';
import { ArticleService } from '../../../services/article/article.service';
// import { ProviderArticleService } from '../../../services/provider-article/provider-article.service';
import {MatExpansionModule} from '@angular/material/expansion';

export interface IArticle {
  // id: "";
  question: string;
  answer: string;
  help_type: "";
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  question: string;
  answer: string;
  help_type: "";
  panelOpenState = false;

  public article: any = [];
  
  // id = "";
  // provider_article = [];
  

  constructor(private _articleService: ArticleService) { }

  ngOnInit() {
    this._articleService.ArticleService()
      // .subscribe(data => this.article = data);
      
      //  console.log(this.article) 
      .subscribe(
        (_response) => {
          this.article = _response.body;
        },
        (_error) => {
  
        });

    // this.provider_article = this._providerArticleService.getProviderArticle();
  }
  
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    // this.scrollFunction();
    let element = document.querySelector('.navbar');
    if (window.pageYOffset > element.clientHeight) {
      element.className = 'navbar navbar-expand-md navbar-light fixed-top navbar-scrolled';
    } else {
      element.className = 'navbar navbar-expand-md navbar-light fixed-top';
    }
  }
  // scrollFunction() {
  //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
  //       document.getElementById("myBtn").style.display = "block";
  //   } else {
  //       document.getElementById("myBtn").style.display = "none";
  //   }
  // }

  // topFunction() {
  //   document.body.scrollTop = 0; 
  //   document.documentElement.scrollTop = 0; 
  // } 

}
