import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SignupService } from '../../../services/signup/signup.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { from } from 'rxjs';
import { WizardComponent } from 'src/app/ui-components/initial-wizard/wizard/wizard.component';
import { NGB_DATEPICKER_18N_FACTORY } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n';
import { ToastrService } from 'ngx-toastr';
import { CryptoServicesService } from '../../../services/crypto-services/crypto-services.service';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}


@Component({
  selector: 'app-quicksignup',
  templateUrl: './quicksignup.component.html',
  styleUrls: ['./quicksignup.component.css']
})
export class QuicksignupComponent implements OnInit {

  public ownerForm: FormGroup;
  public termsForm: FormGroup;
  //name = new FormControl('', [Validators.required, Validators.name]);
  //matcher = new MyErrorStateMatcher();
  passwordHide = true;
  confirmPasswordHide = true;
  formGroup: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  provider_id;
  constructor(
    private Router: Router,
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private location: Location,
    private toastr: ToastrService,
    private cryptoService: CryptoServicesService) { }



  ngOnInit() {
    this.provider_id = sessionStorage.getItem("provider_id")

    this.ownerForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern(/[A-Za-z]/)]),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/), Validators.maxLength(10)]),
      //email_address : new FormControl('', [Validators.required, Validators.email]), 
      // password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]),
      confirmpassword: new FormControl(''),
    },
      // passwordMatchValidator 
      {
        validators: this.passwordValidator
      });

    this.termsForm = new FormGroup({
      terms: new FormControl({ value: '', disabled: true }, Validators.required)
    });

    this.ownerForm.valueChanges.subscribe(() => {
      if (this.ownerForm.valid) {
        this.termsForm.controls['terms'].enable();
      } else {
        this.termsForm.controls['terms'].disable();
      }
    });
  }


  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.location.back();
  }
  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid) {
      this.signupadmin(ownerFormValue);
    }

  }
  signupadmin(ownerFormValue) {
    let user: any = {
      name: ownerFormValue.name,
      user_type: "1",
      mobile_number: ownerFormValue.mobile_number,
      //email_address:ownerFormValue.email_address,
      // password: ownerFormValue.password,
      password: this.cryptoService.set('secret key 123', ownerFormValue.password)
      // confirmpassword: ownerFormValue.password,

    };
    this.signupService.SignupService(user)
      .subscribe(
        (_response) => {
          if ((_response.status === 200) && (_response.body.msg == 'Mobile already exists')) {
            this.toastr.warning('', 'Mobile number is already registered');
          } else if (_response.status === 200) {
            // alert("Successful")
            this.toastr.success('Registration successful');
            this.Router.navigate(['./quicksignin', this.provider_id]);
          }
        },
        (_error) => {
          this.toastr.error(' invalid username or password');
          // alert('invalid username or password');
        }
      );
    // console.log('data', user);
  }
  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('password').value !== ownerForm.get('confirmpassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }

}
