import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetpartnerdetailsService {
  private loggedIn = false;
  private token: string;

  constructor(private httpService: HttpClient) { }
  GetpartnerdetailsService(data?) {
    const href = environment.api.getpartnerdetails;
    return this.httpService.post<any>(href , data, {'observe': 'response'});
  }
  GetfilterService(query) {
    const href = environment.api.getpartnerdetails ;
    const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    return this.httpService.request('POST', href, {
      body: { patner_id: query.patner_id },
      responseType: 'json',
      observe: 'response',
      headers: header
    });
  //  return this.httpService.post<any>(href, {patner_id:query.patner_id}, { 'observe': 'response' })
 }

 setLoggedIn(loggedIn: boolean, token?: string) {
   this.loggedIn = loggedIn;
   this.token = token;
 }

}
