import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'


@Injectable({
  providedIn: 'root'
})
export class GetbookedappointfornotifService {

  constructor(private httpService: HttpClient) { }

  GetbookedappointfornotifService(query) {
    const href = environment.api.getbookedappointfornotif;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }

  // GetbookedappointfornotifService2(query){
  //   const href = environment.api.getbookedappointfornotif2;
  //   return this.httpService.get<any>(href + query, {'observe': 'response'});
  // }
}
