import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-app-dialog-box',
  templateUrl: './app-dialog-box.component.html',
  styleUrls: ['./app-dialog-box.component.css']
})
export class AppDialogBoxComponent implements OnInit {

  constructor(private _NgbActiveModal: NgbActiveModal) { }

  ngOnInit() {
  }
  get activeModal() {
    return this._NgbActiveModal;
  }

}
