import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetContactInfoService {

  constructor(private httpService: HttpClient) { }
  GetContactInfoService(query) {
   const href = environment.api.getcontactinfo ;
   
  return this.httpService.get<any>(href + query, { 'observe': 'response' })
}

getArea(query) {
  const href = environment.api.getcontactinfo + `?area_name=${query.area_name}`;
  return this.httpService.get<any>(href, {'observe': 'response'});
}

// getPostcodeByArea(query) {
//   const href = environment.api.getpostcodebyarea + `?area_name=${query.area_name}&postcode=${query.postcode}`;
//   return this.httpService.get<any>(href, {'observe': 'response'});
// }

}
