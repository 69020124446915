import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-settlement-detail-by-id',
  templateUrl: './settlement-detail-by-id.component.html',
  styleUrls: ['./settlement-detail-by-id.component.css']
})
export class SettlementDetailByIdComponent implements OnInit {
  settlement_id = '';
  payment_details = [];
  payment_available = false;
  displayedColumns: string[] = ['appointment_id', 'date', 'start', 'end', 'cost'];
  related_type;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private Router: Router,
    private server: JwtTestService,
  ) { 
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.settlement_id = localStorage.getItem('settlement_id');
    this.related_type = localStorage.getItem('related_type');

    let settlement = {
      patner_settlement_id: this.related_type == '2' ? this.settlement_id: '',
      provider_settlement_id: this.related_type == '1' ? this.settlement_id: ''
    };

    this.server.request('POST', '/admin/getPaymentDetailBysettlementId', settlement)
      .subscribe(
        (_response: any) => {
          this.payment_available = true;
          this.payment_details = _response;
          for (let i = 0; i < this.payment_details.length; i++) {
            this.payment_details[i].date = moment(this.payment_details[i].start_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
            this.payment_details[i].start_datetime = moment(this.payment_details[i].start_datetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
            this.payment_details[i].end_datetime = moment(this.payment_details[i].end_datetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
          }
        },
        (_error) => {
          this.payment_available = false;
        });
  }

}
