import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class RazorpayService {

  constructor(private httpService: HttpClient) { }

  RazorpayService(query) {
    const href = environment.api.razorpay;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }  

  signatureVerification(data) {
    const href = environment.api.signatureverification;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }

  refundPayment(data) {
    const href = environment.api.refund;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }

  trackRefund(data) {
    const href = environment.api.trackrefund;
    // return this.httpService.post<any>(href, data, {'observe': 'response'});
    return this.httpService.get<any>(href + data, {'observe': 'response'});
  }

  refundId(data) {
    const href = environment.api.refundid;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
