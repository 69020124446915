import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AgmServiceService } from 'src/app/services/agm/agm-service.service';
import { ChangeAdminStatusService } from '../../../services/change-admin-status/change-admin-status.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { debounceTime, tap, switchMap, finalize, flatMap } from 'rxjs/operators';
import { GetsearchnameService } from '../../../services/getsearchname/getsearchname.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { query } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit {
  CustomerDetails: any = [{
    name: '', user_id: '', mobile_number: '', admin_status: ''
  }];
  customeravailable = true;
  getSearchArray: [];
  searchForm: FormGroup;
  admin_status = '';
  isLoading = false;
  name: string;
  user_id: number;
  displayedColumns: string[] = ['name', 'user_id', 'mobile_number', 'admin_status'];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  // @ViewChild('searchName', { static: false })
  // public searchElementRef: ElementRef;

  constructor(
    private router: Router,
    private agmServiceService: AgmServiceService,
    private formBuilder: FormBuilder,
    private GetsearchnameService: GetsearchnameService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ChangeAdminStatusService: ChangeAdminStatusService,
    // private NotificationtypeService: NotificationtypeService,
    private toaster: ToastrService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.GetsearchnameService.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    // getCustomerDetails(startDate) {
    // this.provider_id = localStorage.getItem('provider_id');
    // let queryParam = `?user_id=${this.user_id}`;
    // this.GetsearchnameService.GetcustomerdetailService()
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.customeravailable = true;
    //         this.CustomerDetails = _response.body;
    //         console.log(this.CustomerDetails)
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         this.customeravailable = false;
    //       }
    //     }
    //   );
    // }
    this.server.request('POST','/admin/getAllCustomerDetails')
    .subscribe(
      (_response) => {
        this.customeravailable = true;
        this.CustomerDetails = _response;
        console.log(this.CustomerDetails);
      },
      (_error) => {
        this.customeravailable = false;
      });

    this.searchForm = this.formBuilder.group({
      search: [''],
    });

    this.searchForm
      .get('search')
      .valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => this.isLoading = true),
        switchMap(value => this.GetsearchnameService.GetsearchnameService({ userid: value })
          .pipe(
            finalize(() => this.isLoading = false),
          ))
      )
      .subscribe(
        (_response => {
          if (_response.status === 200) {
            this.CustomerDetails = _response.body;
          } else {
            this.CustomerDetails = [];

          }
        }),
        (_error => {
          this.getSearchArray = [];

        })
      );
  }
  displaySearch(search: any) {
    if (search) { return search.user_id; }
  }
  changeStatus(admin_status, user_id) {
    console.log('user_id ', user_id);
    console.log('status ', admin_status);

    let status = {
      user_id: user_id,
      admin_status: admin_status
    };

    this.server.request('POST', '/admin/changeAdminStatus', status)
    .subscribe(
      (_response) => {
        if (admin_status == '1') {
          // alert('Account activate');
          this.toaster.success('Account activated');
        } else if (admin_status == '2') {
          // alert('Account deactivate');
          this.toaster.error('Account deactivated');
        }
      },
      (_error) => {

      });
  }

}
