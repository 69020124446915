import { Component, OnInit } from '@angular/core';
import { GetsubscriptionproviderService } from '../../../services/getsubscriptionprovider/getsubscriptionprovider.service';
import { GetallsubscriptionService } from '../../../services/getallsubscription/getallsubscription.service';
import { SubscriptionRazorpayService } from '../../../services/subscription-razorpay/subscription-razorpay.service';
import { WindowRef } from '../../../services/windowRef/window-ref.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VerifypartnerService } from '../../../services/verifypartner/verifypartner.service';
import { GetmobilefromreferproviderService } from '../../../services/getmobilefromreferprovider/getmobilefromreferprovider.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs'
import * as moment from 'moment';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {
  provider_id = '';
  subscription: any = [
    { completed_appointment: '', max_appointment: '', number_of_day: '', plan_detail_name: '', price: '', remaining_appointment: '' }
  ];
  subscriptionSelect;
  planSelect;
  subName = '';
  planName;
  subCost: number;
  allSubscriptions = [
    { planId: '', planName: '', planSubArray: [] }
  ];
  planSubArray = [];
  plan;
  paid_amount;
  rzp1: any;
  options = {
    // "key": "rzp_live_RqunH7a3wo6Ajl",
    "key": "rzp_test_jc3xfnFgY5brGq",
    "amount": "",
    "name": "Apointt",
    "order_id": "",
    "currency": "INR",
    "handler": this.verifySignature.bind(this),
    "theme": {
      "color": "#93e0e3"
    }
  };
  subscriptionForm: FormGroup;
  planPrice;
  patner_id = '';
  partnerCode = false;
  refer_provider_id;
  refer_mobile_number;
  plan_detail_id;
  status;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getSubscriptionProvider: GetsubscriptionproviderService,
    private getAllSubcription: GetallsubscriptionService,
    private subscriptionRazorPay: SubscriptionRazorpayService,
    private winRef: WindowRef,
    private toaster: ToastrService,
    private verifyPartnerService: VerifypartnerService,
    private getMobileFromReferProvider: GetmobilefromreferproviderService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');

    this.subscriptionForm = new FormGroup({
      discount: new FormControl('', [Validators.pattern(/^\d{10}$/)])
    });
    this.getProviderSubscription();
    this.getAllSubscriptions();
  }

  getProviderSubscription() {
    // let queryParam = `?provider_id=${this.provider_id}`;
    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getsubscriptionOfProvider', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.subscription = _response.body;
            this.refer_provider_id = this.subscription.refer_provider_id;
            if (this.subscription.refer_provider_id) {
              this.subscriptionForm.controls['discount'].disable();
            }

            // let mobileQuery = `?refer_provider_id=${this.subscription.refer_provider_id}`;
            let mobileQuery = {
              refer_provider_id: this.subscription.refer_provider_id
            };

            this.server.request('GET', '/providers/getMobileNoFromReferProviderId', mobileQuery)
              .subscribe(
                (_response: any) => {
                  if (_response.status === 200) {
                    this.refer_mobile_number = _response.body.mobile_number;
                    this.subscriptionForm.controls['discount'].setValue(this.refer_mobile_number);
                  }
                }, (_error) => {

                });

            // this.getMobileFromReferProvider.GetmobilefromreferproviderService(mobileQuery)
            //   .subscribe(
            //     (_response) => {
            //       if (_response.status === 200) {
            //         this.refer_mobile_number = _response.body.mobile_number;
            //         this.subscriptionForm.controls['discount'].setValue(this.refer_mobile_number);

            //       }
            //     },
            //     (_error) => {

            //     });
          }
        },
        (_error) => {

        });
    // this.getSubscriptionProvider.GetsubscriptionproviderService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.subscription = _response.body;
    //         this.refer_provider_id = this.subscription.refer_provider_id;
    //         if (this.subscription.refer_provider_id) {
    //           this.subscriptionForm.controls['discount'].disable();
    //         }

    //         let mobileQuery = `?refer_provider_id=${this.subscription.refer_provider_id}`;
    //         this.getMobileFromReferProvider.GetmobilefromreferproviderService(mobileQuery)
    //           .subscribe(
    //             (_response) => {
    //               if (_response.status === 200) {
    //                 this.refer_mobile_number = _response.body.mobile_number;
    //                 this.subscriptionForm.controls['discount'].setValue(this.refer_mobile_number);

    //               }
    //             },
    //             (_error) => {

    //             });
    //       }
    //     },
    //     (_error) => {

    //     });
  }

  getAllSubscriptions() {
    this.getAllSubcription.GetallsubscriptionService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.allSubscriptions = _response.body;
          }
        },
        (_error) => {

        });
  }

  selectSubscription(index) {
    this.planSelect = true;
    this.planSubArray = this.allSubscriptions[index].planSubArray;
    this.planName = this.allSubscriptions[index].planName;
  }

  selectPlan(event) {
    this.subscriptionSelect = true;
    this.plan_detail_id = event.value;
    let planDetails = this.planSubArray.find(({ plan_detail_id }) => plan_detail_id === event.value);
    console.log('planDetails: ', planDetails);

    this.subName = planDetails.plan_detail_name;
    this.planPrice = planDetails.price;
    this.subCost = planDetails.price + (planDetails.price * 0.0236);

    this.plan = {
      provider_id: this.provider_id,
      plan_detail_id: event.value,
      price: this.subCost,
      number_of_day: planDetails.number_of_day
    };
    console.log('plan: ', this.plan);
  }

  verifyPartner() {
    // let query = `?mobile_number=${this.subscriptionForm.controls['discount'].value}`;
    let query = {
      mobile_number: this.subscriptionForm.controls['discount'].value
    };

    this.server.request('GET', '/patner/verifyPatner', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            // this.planPrice = this.planPrice - (this.planPrice * 10 / 100);
            // this.plan.price = this.planPrice + (this.planPrice * 2.36 / 100);
            // this.subCost = this.plan.price;
            this.patner_id = _response.body.patner_id;
            this.toaster.success('Subcsription code is applied successfully');
          }
        },
        (_error) => {
          // this.plan.price = this.planPrice + (this.planPrice * 2.36 / 100);
          // this.subCost = this.plan.price;
          this.toaster.error('Subcsription code does not exist');
        });

    // this.verifyPartnerService.VerifypartnerService(query)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         // this.planPrice = this.planPrice - (this.planPrice * 10 / 100);
    //         // this.plan.price = this.planPrice + (this.planPrice * 2.36 / 100);
    //         // this.subCost = this.plan.price;
    //         this.patner_id = _response.body.patner_id;
    //         this.toaster.success('Subcsription code is applied successfully');
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         // this.plan.price = this.planPrice + (this.planPrice * 2.36 / 100);
    //         // this.subCost = this.plan.price;
    //         this.toaster.error('Subcsription code does not exist');

    //       }
    //     });
  }

  pay() {
    this.server.request('POST', '/providers/ProviderSubscription', this.plan)
      .subscribe(
        (_response: any) => {
          this.paid_amount = _response.paid_amount;
          this.status = _response.status;
          this.razorPay();
        },
        (_error) => {

        });
    // this.getSubscriptionProvider.providerSubscription(this.plan)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.paid_amount = _response.body.paid_amount;
    //         this.status = _response.body.status;
    //         this.razorPay();
    //       }
    //     },
    //     (_error) => {

    //     });
  }

  razorPay() {
    // let queryParam = `?price=${this.paid_amount}`;
    // let queryParam = `?price=${this.subCost}`;
    // let queryParam = `?plan_detail_id=${this.plan_detail_id}`;

    let queryParam = {
      plan_detail_id: this.plan_detail_id
    };

    this.server.request('GET', '/razorpay_purchase/purchaseSubscription', queryParam)
      .subscribe((_response: any) => {
        if (_response.status === 200) {
          try {
            console.log('_response: ', _response.body);
            this.options.amount = _response.body.payload.order.amount;
            this.options.order_id = _response.body.payload.order.id;
            // console.log('options: ', this.options);
            this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
            this.rzp1.open();
            console.log('rzp: ', this.rzp1);
          } catch (err) {
            console.log('error: ', err)
          }
        }
      },
        (_error) => {
          console.log('pay error: ', _error);
        });

    // this.subscriptionRazorPay.purchaseSubscription(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       try {
    //         if (_response.status === 200) {
    //           console.log('_response: ', _response.body);
    //           this.options.amount = _response.body.payload.order.amount;
    //           this.options.order_id = _response.body.payload.order.id;
    //           // console.log('options: ', this.options);
    //           this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
    //           this.rzp1.open();
    //           console.log('rzp: ', this.rzp1);
    //         }
    //       } catch (err) {
    //         console.log('error: ', err);
    //       }
    //     },
    //     (_error) => {
    //       console.log('pay error: ', _error);
    //     });
  }

  verifySignature(response) {
    console.log('response', response);
    let signature = {
      provider_id: localStorage.getItem('provider_id'),
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
      plan_detail_id: this.plan_detail_id,
      status: this.status,
      patner_id: this.patner_id,
      refer_provider_id: this.refer_provider_id
    };

    this.server.request('POST', '/razorpay_purchase/signatureVerificationSubscription', signature)
      .subscribe(
        (_response: any) => {
          try {
            console.log('signature verified');
            this.toaster.success('Payment Successful');
            this.getProviderSubscription();
            this.planSelect = false;
            this.subscriptionSelect = false;
            // let status = {
            //   provider_id: this.provider_id,
            //   status: 1,
            //   patner_id: this.patner_id,
            //   refer_provider_id: this.refer_provider_id
            // };

            // this.getSubscriptionProvider.providerSubscription(status)
            //   .subscribe(
            //     (_response) => {
            //       console.log('signature verified');
            //       this.toaster.success('Payment Successful');
            //       this.getProviderSubscription();
            //       this.planSelect = false;
            //       this.subscriptionSelect = false;
            //     },
            //     (_error) => {
            //       this.toaster.error('Payment unsuccessful');
            //     });

          } catch (err) {
            console.log('error: ', err);
            this.toaster.error('Payment unsuccessful');
          }
        },
        (_error) => {
          this.toaster.error('Payment unsuccessful');
        });
    // this.subscriptionRazorPay.signatureVerificationSubscription(signature)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         try {
    //           let status = {
    //             provider_id: this.provider_id,
    //             status: 1,
    //             patner_id: this.patner_id,
    //             refer_provider_id: this.refer_provider_id
    //           };
    //           this.getSubscriptionProvider.providerSubscription(status)
    //             .subscribe(
    //               (_response) => {
    //                 console.log('signature verified');
    //                 this.toaster.success('Payment Successful');
    //                 this.getProviderSubscription();
    //                 this.planSelect = false;
    //                 this.subscriptionSelect = false;
    //               },
    //               (_error) => {
    //                 this.toaster.error('Payment unsuccessful');
    //               });
    //         } catch (err) {
    //           console.log('error: ', err);
    //           this.toaster.error('Payment unsuccessful');
    //         }
    //       }
    //     },
    //     (_error) => {
    //       this.toaster.error('Payment unsuccessful');
    //     });
  }

  changeButtonColor(index) {
    for (let i = 0; i < this.allSubscriptions.length; i++) {
      let button = document.getElementById('button-' + i);
      button.classList.remove('selected');
    }
    let element = document.getElementById('button-' + index);
    element.classList.toggle('selected');
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

}
