import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AdminCountService {

  constructor(private httpService: HttpClient) { }
  AdminCountService(data?) {
   return this.httpService.post<any>(environment.api.admincount, data, {'observe':'response'})
 }  
}