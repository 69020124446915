import { Component, OnInit, Inject } from '@angular/core';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { NoopScrollStrategy } from '@angular/cdk/overlay';


@Component({
  selector: 'app-partner-provider-refer-details',
  templateUrl: './partner-provider-refer-details.component.html',
  styleUrls: ['./partner-provider-refer-details.component.css']
})
export class PartnerProviderReferDetailsComponent implements OnInit {
  patner_id;
  totalPartnerAmount = '0';
  total_provider = '0';
  providerDetails = [];
  providerAvailable = true;
  displayedColumns: string[] = ['provider_name', 'total_amount', 'total_appointments', 'share'];
  active_flag;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private server: JwtTestService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.patner_id = localStorage.getItem('patner_id');

    let query = {
      patner_id: this.patner_id
    };

    this.server.request('GET', '/patner/getProvidersByPatnetId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            if (_response.body.length == 0) {
              this.providerAvailable = false;
            } else {
              this.providerAvailable = true;
              this.providerDetails = _response.body.results;
              this.total_provider = _response.body.total_provider ? _response.body.total_provider : '0';
              this.totalPartnerAmount = _response.body.totalPatnerAmount ? _response.body.totalPatnerAmount.toFixed(2) : '0';

              for (let i = 0; i < this.providerDetails.length; i++) {
                let appointment_query = {
                  provider_id: this.providerDetails[i].provider_id,
                  provider_created_date_time: this.providerDetails[i].provider_created_date_time
                };

                this.server.request('GET', '/patner/getAppointmentDetailForPatner', appointment_query)
                  .subscribe(
                    (_response: any) => {
                      if (_response.status === 200) {
                        this.providerDetails[i].appt_count = _response.body.total_appointment ? _response.body.total_appointment : '0';
                        this.providerDetails[i].total_amount = _response.body.only_gst ? _response.body.only_gst : '0';
                      }
                    },
                    (_error) => {

                    });
              }
            }
          }
        },
        (_error) => {
          this.providerAvailable = false;
        });

    this.server.request('GET', '/patner/getPatnerDetail', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.active_flag = _response.body.active_flag;
          }
        },
        (_error) => {

        });

    let appointment_query = {
      patner_id: this.patner_id,
      month: '',
      year: '',
    };

    this.server.request('GET', '/patner/getAppointmentDetailForPatnerbByPatnerId', appointment_query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.totalPartnerAmount = _response.body.only_gst;
          }
        },
        (_error) => {
        });
  }

  viewAppointmentDetails(provider_id, provider_created_date_time) {
    this.router.navigate(['/partner/partner-appointment-details']);
    localStorage.setItem('provider_id', provider_id);
    localStorage.setItem('provider_created_date_time', provider_created_date_time);
  }

  viewProfileDetails(provider_id, provider_created_date_time) {
    this.router.navigate(['/partner/partner-providerdetails/', provider_id]);
    localStorage.setItem('provider_id', provider_id);
    localStorage.setItem('provider_created_date_time', provider_created_date_time);
  }

  openDialog(unique_url_id): void {
    const dialogRef = this.dialog.open(PartnerDialog, {
      width: '250px',
      scrollStrategy: new NoopScrollStrategy(),
      data: { url: environment.shareBaseURL + 'customer-dashboard/profile-details/' + unique_url_id.replace(/ /g, "-"), name: 'animal' },
      panelClass: 'custom-dialog-box'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

@Component({
  selector: 'partner-dialog',
  templateUrl: 'partner-dialog.html'
})

export class PartnerDialog {
  shareURL = '';
  constructor(
    public dialogRef: MatDialogRef<PartnerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    try {
      this.shareURL = data.url
    } catch (err) {
      console.log(err);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}