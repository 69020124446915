import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class PartnersignupService {

  constructor(private httpService: HttpClient) { }
  PartnersignupService(data)
 {
    return this.httpService.post<any>(environment.api.signuppartnerAPI,data, { 'observe': 'response' })
}
}

