import { Component, OnInit } from '@angular/core';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-enquiry-details',
  templateUrl: './enquiry-details.component.html',
  styleUrls: ['./enquiry-details.component.css']
})
export class EnquiryDetailsComponent implements OnInit {
  enquiryDetails = [];
  enquiryavailable = false;
  displayedColumns: string[] = ['enquiry_id', 'partner_name', 'provider_name', 'category', 'address', 'pincode'];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private server: JwtTestService,
  ) { 
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.server.get('/patner/getPatnerEnquiryDetails')
    .subscribe(
      (_response: any) => {
        this.enquiryavailable = true;
        this.enquiryDetails = _response.body.results;
      },
      (_error) => {
        this.enquiryavailable = false;
      });
  }

}
