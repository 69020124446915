import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RescheduleappointmentService {

  constructor(private httpService: HttpClient) { }

  RescheduleappointmentService(data) {
    const href = environment.api.rescheduleAppointment;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
