import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { GetappointdetailsService } from '../../../services/getappointdetails/getappointdetails.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { ProfileServiceService } from 'src/app/services/getprofile/profile-service.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
  selector: 'app-appoint-success',
  templateUrl: './appoint-success.component.html',
  styleUrls: ['./appoint-success.component.css']
})
export class AppointSuccessComponent implements OnDestroy {
  queryParams = "";
  appoint_id = "";
  l_userid;
  unique_url_id = '';
  qualifications;
  category_name;
  experience;
  providerDetails: any;
  userid = '';
  pay_status = '';
  cost = '';
  users: "";
  Users = [
    {
      name: "",
      img: "https://favpng.com/png_view/logo-cartoon-girl-icon-woman-icon-education-icon-png/hL0UYHqc",
      date: "",
      time: "",
    }
  ];
  zoom: number;
  lat: number;
  long: number;
  provider_id;
  address_id;
  consultation_type;
  rzp1: any;
  options = {
    "key": "rzp_test_jc3xfnFgY5brGq",
    // "key": "rzp_live_RqunH7a3wo6Ajl",
    "amount": "",
    "name": "Apointt",
    "order_id": "",
    "currency": "INR",
    "handler": this.verifySignature.bind(this),
    "theme": {
      "color": "#93e0e3"
    }
  };
  redirect_url = '';

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private GetappointdetailsService: GetappointdetailsService,
    private providerProfileService: ProfileServiceService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private winRef: WindowRef,
    private router: Router,
    private location: LocationStrategy,
    private toaster: ToastrService,
    private server: JwtTestService,) {
    history.pushState(null, null, '/customer-dashboard');
    this.location.onPopState(() => {
      history.pushState(null, null, '/customer-dashboard');
    });

    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('address_id');
    localStorage.removeItem('consultation_type');
  }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');
    this.provider_id = sessionStorage.getItem('provider_id');
    this.address_id = localStorage.getItem('address_id');
    this.consultation_type = localStorage.getItem('consultation_type');
    this.getappointdetails(this.queryParams);
    this.getCurrentLocation();
    this.pay_status = localStorage.getItem('pay_status');

    if (this.pay_status == '1' || this.pay_status == '2') {
      this.toaster.success('Payment successful!');
    }

    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${this.userid}`;
    this.providerProfileService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          
          if (_response.status === 200) {
            try {
              this.providerDetails = _response.body[0];
              this.experience = this.providerDetails.experience;
              this.unique_url_id = this.providerDetails.unique_url_id;

              if (_response.body[0].qualification.includes("*")) {
                this.qualifications = _response.body[0].qualification.split('*');
              }
              else {
                this.qualifications = _response.body[0].qualification.split(' ');
              }
              this.qualifications.pop();

              let categories = _response.body[0].category_name;
              this.category_name = '';
              for (let i = 0; i < categories.length; i++) {
                this.category_name += categories[i] + ', ';
              }
              this.category_name = this.category_name.slice(0, -2);

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      )

  }

  getappointdetails(queryParams) {
    this.appoint_id = localStorage.getItem('appoint_id');
    this.pay_status = localStorage.getItem('pay_status');
    this.consultation_type = localStorage.getItem('consultation_type');
    this.address_id = localStorage.getItem('address_id');
    this.cost = localStorage.getItem('cost');

    queryParams = {
      appoint_id: this.appoint_id,
      pay_status: this.pay_status,
      total_cost: this.cost,
      address_id: this.address_id ? this.address_id : ``
    };

    this.server.request('GET', '/appointment/appointmentSuccess', queryParams)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.Users = _response.body;

            this.Users[0].date = moment(this.Users[0].date, 'MMMDD(ddd), YYYY').format('MMM D YYYY');
          }
        },
        (_error) => {
          
        });
  }

  getDirections(address) {
    address = address.replace(/ /g, '+').replace(/,/g, "%2C");
    window.open('https://www.google.com/maps/search/?api=1&query=' + address, "_blank");
  }

  verifySignature(response) {
    // console.log('response', response);
    let signature = {
      appoint_id: localStorage.getItem('appoint_id'),
      provider_id: sessionStorage.getItem('provider_id'),
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature
    };

    this.server.request('POST', '/razorpay_purchase/signatureVerification', signature)
      .subscribe((_response: any) => {
        // if (_response.status === 200) {
        try {
          console.log('signature verified');
          if (response.razorpay_payment_id) {
            this.redirect_url = '/customer-dashboard/appoint-success';
          } else {
            this.redirect_url = '/customer-dashboard/payment-method';
          }
          window.location.assign(this.redirect_url);
        } catch (err) {
          console.log('error: ', err);
        }

        // }
      },
        (_error) => {

        });
  }
  pay() {
    let query = {
      appoint_id: this.appoint_id,
      online_consultation_type: localStorage.getItem('consultation_type')
    }

    this.server.request('GET', '/razorpay_purchase/purchase', query)
      .subscribe((_response: any) => {
        if (_response.status === 200) {
          // console.log('_response: ', _response.body);
          this.options.amount = _response.body.payload.order.amount;
          this.options.order_id = _response.body.payload.order.id;
          // console.log('options: ', this.options);
          this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
          // console.log('rzp: ', this.rzp1);
        }
      },
        (_error) => {
          console.log('pay error: ', _error);
        });

    localStorage.setItem('pay_status', '1');
    localStorage.setItem('cost', this.cost);
  }

  private getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  placeMarker() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.long = position.coords.longitude;
    });

  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("container").style.maxWidth = "83.333333%";
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }
  onCLick() {

  }

}
