import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.css']
})
export class WebFooterComponent implements OnInit {
  playStoreLink = 'https://play.google.com/store/apps/details?id=org.nativescript.Blank';
  
  constructor() { }

  ngOnInit() {
  }

  shareAppLink(link: string) {
    let customer = document.getElementById('customer-btn') as HTMLElement;
    let business = document.getElementById('business-btn') as HTMLElement;

    if (link === 'customer') {
      this.playStoreLink = 'https://play.google.com/store/apps/details?id=org.nativescript.Blank';
      business.classList.remove('app-link-button-bg');
      customer.classList.add('app-link-button-bg');
    }
    else if (link === 'business') {
      this.playStoreLink = 'https://play.google.com/store/apps/details?id=com.blacktix.apointtBusiness';
      customer.classList.remove('app-link-button-bg');
      business.classList.add('app-link-button-bg');
    }
  }
}
