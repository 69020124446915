import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GetappointmentbyproviderService {

  constructor(private httpService: HttpClient) { }

  GetappointmentbyproviderService(query) {
    const href = environment.api.getappointmentbyprovider;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }
}
