import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

import { from } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AgmServiceService {

constructor(private httpService: HttpClient) { }

getAgmServiceService(query) {
     const href = environment.api.getlocation + `?city_name=${query.city_name}` ;
     return this.httpService.get<any>(href, { 'observe': 'response' })
  }
 
}
