import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-createnewevent',
  templateUrl: './createnewevent.component.html',
  styleUrls: ['./createnewevent.component.css']
})
export class CreateneweventComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
