import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dt-selection',
  templateUrl: './dt-selection.component.html',
  styleUrls: ['./dt-selection.component.css']
})
export class DtSelectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
    
  }
  onNext
}
