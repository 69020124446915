import { Component, OnInit } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(private metaTagService: Meta,) { }

  ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Apointt is an online apointment scheduling and managing application that offers users to grow business online, book appointments for online consultations at nearby provider instantly, accept online payments with easy settlement, notify user regarding pending, accepted and rejected appointmnets, video consultations & data security' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'features, Apointt features' }
    );
  }

}
