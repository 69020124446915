export const environment = {
  production: false,
  shareBaseURL: 'http://apointt.in/',
  baseUrl: 'http://www.google.com/maps/place',
  api: {
    like: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/like',
    getProvider: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getProvider',
    signupAPI: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/signUpApointt",
    loginAPI: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/logInApointt",
    loginadminAPI: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/logInAdmin",
    signupadminAPI: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/signUpApointt",
    getcategory: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/category/getCategory",
    getsubcategory: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/category/getChildCategory",
    getlocation: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/location/getLocation",
    getSearch: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/search/getSearch",
    getavailabletimeslot: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/getAppointmentByDate",
    setApointt: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/setApointt',
    getappointdetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/appointmentSuccess',
    getprofile: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getProviderProfile',
    getcompanyprofile: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/companyProfile',
    signupbusiness: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/signupProviderWithPromise',
    addcontactinfo: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/addContactInfo",
    getcontactinfo: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getCityCountry",
    sendOtp: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/confirmAccountSentOtp',
    verifyOtp: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/confirmAccountVerifyOtp',
    getappointmentbyprovider: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/getBookedAppointmentByProviderId',
    getappointmentbycustomer: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/getBookedAppointmentByUserId',
    moreinfo: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/providerMoreInfo',
    getuserprofile: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/getUserProfile',
    providercount: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/providerCount',
    changeappointstatus: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/changeApointStatus',
    changepassword: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/changePassword',
    myclients: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/myClients',
    scheduleTimings: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/scheduleTimings',
    getcanceledappointments: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/getCancelAppointmentByProviderId',
    notificationType: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/notification/notificationType',
    changeviewstatus: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/notification/changeViewStatus',
    cancelappointfornotification: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/notification/getCancelAppointmentForNotication',
    notificationcount: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/notification/notificationCount',
    getbookedappointfornotif: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/notification/getBookedAppointmentForNotication',
    getpendingappointfornotif: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/notification/getPendingAppointmentForNotication',
    addproviderimage: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/addProviderImages',
    uploadtocloud: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/providerImageUploadToCloud',
    getscheduletimings: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getScheduleTimings',
    getsettings: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getSettings',
    getproviderbypostcode: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getProviderByPostcode',
    razorpay: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/purchase',
    getapppointmentdetailsbyid: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/getAppointmentDetails',
    signatureverification: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/signatureVerification',
    forgotpasswordbymobile: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/forgotPasswordByMobile',
    verifyotpbyotp: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/verifyOtpByOtpValue',
    generatepasswordbymobile: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/newGenereatePasswordByMobile',
    // getpostcodebyarea: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getPostcodeByAreaName',
    deleteProviderPhoto: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/deleteProviderPhoto',
    getuserbypaystatus: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getUsersByPayStatus',
    getsubscription: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getsubscriptionOfProvider',
    getallsubscription: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getAllSubscription',
    providerSubscription: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/ProviderSubscription',
    purchasesubscription: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/purchaseSubscription',
    signatureverificationsubscription: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/signatureVerificationSubscription',
    providerBusinessGrowth: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/providerBusinessGrowth',
    rescheduleAppointment: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/rescheduleAppointment',
    addclient: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/addClient',
    getclientsbyproviderid: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/getAllClientsByProviderId',
    sendlinktoclient: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/sendProviderLinkToClients',
    admincount: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/count',
    CustomerDetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/getAllCustomerDetails',
    verifypartner: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/verifyPatner',
    signuppartnerAPI: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/signupPatner",
    loginpartnerAPI: "http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/logInPatner",
    getpartnerdetailbypatnerid: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/getPatnerDetailByPatnetId',
    getclientdetailbypartnerid: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/getClientDetailByPatnetId',
    getpartnerdetail: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/getPatnerDetail',
    partnerdetailupdate: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/patnerDetailUpdate',
    getproviderdetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/getAllProviderDetails',
    getpartnerdetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/getAllPatnerDetails',
    changeAdminStatus: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/changeAdminStatus',
    getmobilefromreferprovider: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getMobileNoFromReferProviderId',
    referbysmstoprovider: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/referBySmsToProvider',
    uploadPersonalIdentityDoc: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/uploadPersonalIdentityDocument',
    uploadCertificationDoc: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/uploadCertificationDocument',
    getpaymentidbyapointtid: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/getPaymentIdByApointtId',
    refund: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/refund',
    getproviderholidays: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getProviderHolidays',
    trackrefund: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/fetchRefundById',
    refundid: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/razorpay_purchase/refundId',
    bankdetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getBankDetails',
    addProviderBankDetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/addProviderBankDetails',
    getSearchProviderAndSubCategory: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/search/getSearchForProviderAndSubCategory',
    getSearchForCategory: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/search/getSearchForCategory',
    getAllLanguages: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getAllLanguage',
    getAllAddresses: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getAllAddress',
    addAppointType: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/addAppointType',
    updateUserProfile: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/users/updateUserProfile',
    addPartnerBankDetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/addPatnerBankDetails',
    addAddressPreferance: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/addAddressInfoPreferanceTwo',
    providerMonthAppointmentPercentage: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/providerMonthApointmentPercentage',
    addOnline:  'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/addOnline',
    getAppointmentByAddressId: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/appointment/getAppointmentsByAddressId',
    removeAddress: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/removeAddress',
    getarticle: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getHelpQuestionAnswers',
    isSettlementActive: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/isSettlementActive',
    addingSettlementIdForProvider: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/addingSettlementIdForProvider',
    getSettlementDetail: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/getSettlementDetail',
    getPaymentDetailBysettlementId: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/getPaymentDetailBysettlementId',
    changeSettlementAdminFlag: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/admin/changeSettlementAdminFlag',
    referPatnerBySmsToProvider: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/referPatnerBySmsToProvider',
    getProvidersByPatnetId: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/getProvidersByPatnetId',
    getAppointmentDetailForPatner: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/getAppointmentDetailForPatner',
    getAppointmentDetailForPatnerbByPatnerId: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/getAppointmentDetailForPatnerbByPatnerId',
    addingSettlementIdForPatner: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/addingSettlementIdForPatner',
    uploadAttachment: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/uploadattachment',
    getAttachment: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getAttachment',
    getProviderGalleryImages: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getProviderGalleryImageByProviderId',
    uploadProviderGalleryImages: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/uploadProviderGalleryImageByProviderId',
    addDocumentList: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/addDocumentList',
    changeDocumentStatus: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/changeDocumentStatus',
    getDocumentStatus: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/getDocumentStatus',
    providerGalleryImageUploadToCloud: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/providers/providerGalleryImageUploadToCloud',
    getPatnerEnquiryDetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/getPatnerEnquiryDetails',
    addPatnerEnquiryDetails: 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/patner/addPatnerEnquiryDetails',

    // like: 'http://localhost:8081/providers/like',
    // getProvider: 'http://localhost:8081/providers/getProvider',
    // signupAPI: "http://localhost:8081/users/signUpApointt",
    // loginAPI: "http://localhost:8081/users/logInApointt",
    // loginadminAPI: "http://localhost:8081/admin/logInAdmin",
    // signupadminAPI: "http://localhost:8081/users/signUpApointt",
    // getcategory: "http://localhost:8081/category/getCategory",
    // getsubcategory: "http://localhost:8081/category/getChildCategory",
    // getlocation: "http://localhost:8081/location/getLocation",
    // getSearch: "http://localhost:8081/search/getSearch",
    // getavailabletimeslot: "http://localhost:8081/appointment/getAppointmentByDate",
    // setApointt: 'http://localhost:8081/appointment/setApointt',
    // getappointdetails: 'http://localhost:8081/appointment/appointmentSuccess',
    // getprofile: 'http://localhost:8081/providers/getProviderProfile',
    // getcompanyprofile: 'http://localhost:8081/providers/companyProfile',
    // signupbusiness: 'http://localhost:8081/users/signupProviderWithPromise',
    // addcontactinfo: "http://localhost:8081/providers/addContactInfo",
    // getcontactinfo: "http://localhost:8081/providers/getCityCountry",
    // sendOtp: 'http://localhost:8081/providers/confirmAccountSentOtp',
    // verifyOtp: 'http://localhost:8081/providers/confirmAccountVerifyOtp',
    // getappointmentbyprovider: 'http://localhost:8081/appointment/getBookedAppointmentByProviderId',
    // getappointmentbycustomer: 'http://localhost:8081/appointment/getBookedAppointmentByUserId',
    // moreinfo: 'http://localhost:8081/providers/providerMoreInfo',
    // getuserprofile: 'http://localhost:8081/users/getUserProfile',
    // providercount: 'http://localhost:8081/providers/providerCount',
    // changeappointstatus: 'http://localhost:8081/appointment/changeApointStatus',
    // changepassword: 'http://localhost:8081/users/changePassword',
    // myclients: 'http://localhost:8081/providers/myClients',
    // scheduleTimings: 'http://localhost:8081/providers/scheduleTimings',
    // getcanceledappointments: 'http://localhost:8081/appointment/getCancelAppointmentByProviderId',
    // notificationType: 'http://localhost:8081/notification/notificationType',
    // changeviewstatus: 'http://localhost:8081/notification/changeViewStatus',
    // cancelappointfornotification: 'http://localhost:8081/notification/getCancelAppointmentForNotication',
    // notificationcount: 'http://localhost:8081/notification/notificationCount',
    // getbookedappointfornotif: 'http://localhost:8081/notification/getBookedAppointmentForNotication',
    // getpendingappointfornotif: 'http://localhost:8081/notification/getPendingAppointmentForNotication',
    // addproviderimage: 'http://localhost:8081/providers/addProviderImages',
    // uploadtocloud: 'http://localhost:8081/providers/providerImageUploadToCloud',
    // getscheduletimings: 'http://localhost:8081/providers/getScheduleTimings',
    // getsettings: 'http://localhost:8081/providers/getSettings',
    // getproviderbypostcode: 'http://localhost:8081/providers/getProviderByPostcode',
    // razorpay: 'http://localhost:8081/razorpay_purchase/purchase',
    // getapppointmentdetailsbyid: 'http://localhost:8081/appointment/getAppointmentDetails',
    // signatureverification: 'http://localhost:8081/razorpay_purchase/signatureVerification',
    // forgotpasswordbymobile: 'http://localhost:8081/users/forgotPasswordByMobile',
    // verifyotpbyotp: 'http://localhost:8081/users/verifyOtpByOtpValue',
    // generatepasswordbymobile: 'http://localhost:8081/users/newGenereatePasswordByMobile',
    // getpostcodebyarea: 'http://localhost:8081/providers/getPostcodeByAreaName',
    // deleteProviderPhoto: 'http://localhost:8081/providers/deleteProviderPhoto',
    // getuserbypaystatus: 'http://localhost:8081/providers/getUsersByPayStatus',
    // getsubscription: 'http://localhost:8081/providers/getsubscriptionOfProvider',
    // getallsubscription: 'http://localhost:8081/providers/getAllSubscription',
    // providerSubscription: 'http://localhost:8081/providers/ProviderSubscription',
    // purchasesubscription: 'http://localhost:8081/razorpay_purchase/purchaseSubscription',
    // signatureverificationsubscription: 'http://localhost:8081/razorpay_purchase/signatureVerificationSubscription',
    // providerBusinessGrowth: 'http://localhost:8081/providers/providerBusinessGrowth',
    // rescheduleAppointment: 'http://localhost:8081/appointment/rescheduleAppointment',
    // addclient: 'http://localhost:8081/users/addClient',
    // getclientsbyproviderid: 'http://localhost:8081/users/getAllClientsByProviderId',
    // sendlinktoclient: 'http://localhost:8081/users/sendProviderLinkToClients',
    // admincount: 'http://localhost:8081/admin/count',
    // CustomerDetails: 'http://localhost:8081/admin/getAllCustomerDetails',
    // verifypartner: 'http://localhost:8081/patner/verifyPatner',
    // signuppartnerAPI: "http://localhost:8081/users/signupPatner",
    // loginpartnerAPI: "http://localhost:8081/users/logInPatner",
    // getpartnerdetailbypatnerid: 'http://localhost:8081/patner/getPatnerDetailByPatnetId',
    // getclientdetailbypartnerid: 'http://localhost:8081/patner/getClientDetailByPatnetId',
    // getpartnerdetail: 'http://localhost:8081/patner/getPatnerDetail',
    // partnerdetailupdate: 'http://localhost:8081/patner/patnerDetailUpdate',
    // getproviderdetails: 'http://localhost:8081/admin/getAllProviderDetails',
    // getpartnerdetails: 'http://localhost:8081/admin/getAllPatnerDetails',
    // changeAdminStatus: 'http://localhost:8081/admin/changeAdminStatus',
    // getmobilefromreferprovider: 'http://localhost:8081/providers/getMobileNoFromReferProviderId',
    // referbysmstoprovider: 'http://localhost:8081/providers/referBySmsToProvider',
    // uploadPersonalIdentityDoc: 'http://localhost:8081/providers/uploadPersonalIdentityDocument',
    // uploadCertificationDoc: 'http://localhost:8081/providers/uploadCertificationDocument',
    // getpaymentidbyapointtid: 'http://localhost:8081/razorpay_purchase/getPaymentIdByApointtId',
    // refund: 'http://localhost:8081/razorpay_purchase/refund',
    // getproviderholidays: 'http://localhost:8081/providers/getProviderHolidays',
    // trackrefund: 'http://localhost:8081/razorpay_purchase/fetchRefundById',
    // refundid: 'http://localhost:8081/razorpay_purchase/refundId',
    // bankdetails: 'http://localhost:8081/providers/getBankDetails',
    // addProviderBankDetails: 'http://localhost:8081/providers/addProviderBankDetails',
    // getSearchProviderAndSubCategory: 'http://localhost:8081/search/getSearchForProviderAndSubCategory',
    // getSearchForCategory: 'http://localhost:8081/search/getSearchForCategory',
    // getAllLanguages: 'http://localhost:8081/providers/getAllLanguage',
    // getAllAddresses: 'http://localhost:8081/providers/getAllAddress',
    // addAppointType: 'http://localhost:8081/appointment/addAppointType',
    // updateUserProfile: 'http://localhost:8081/users/updateUserProfile',
    // addAddressPreferance: 'http://localhost:8081/providers/addAddressInfoPreferanceTwo',
    // addPartnerBankDetails: 'http://localhost:8081/patner/addPatnerBankDetails',
    // providerMonthAppointmentPercentage: 'http://localhost:8081/providers/providerMonthApointmentPercentage',
    // addOnline:  'http://localhost:8081/providers/addOnline',
    // getAppointmentByAddressId: 'http://localhost:8081/appointment/getAppointmentsByAddressId',
    // removeAddress: 'http://localhost:8081/providers/removeAddress',
    // getarticle: 'http://localhost:8081/providers/getHelpQuestionAnswers',
    // isSettlementActive: 'http://localhost:8081/providers/isSettlementActive',
    // addingSettlementIdForProvider: 'http://localhost:8081/providers/addingSettlementIdForProvider',
    // getSettlementDetail: 'http://localhost:8081/admin/getSettlementDetail',
    // getPaymentDetailBysettlementId: 'http://localhost:8081/admin/getPaymentDetailBysettlementId',
    // changeSettlementAdminFlag: 'http://localhost:8081/admin/changeSettlementAdminFlag',
    // referPatnerBySmsToProvider: 'http://localhost:8081/patner/referPatnerBySmsToProvider',
    // getProvidersByPatnetId: 'http://localhost:8081/patner/getProvidersByPatnetId',
    // getAppointmentDetailForPatner: 'http://localhost:8081/patner/getAppointmentDetailForPatner',
    // getAppointmentDetailForPatnerbByPatnerId: 'http://localhost:8081/patner/getAppointmentDetailForPatnerbByPatnerId',
    // addingSettlementIdForPatner: 'http://localhost:8081/patner/addingSettlementIdForPatner',
    // uploadAttachment: 'http://localhost:8081/providers/uploadattachment',
    // getAttachment: 'http://localhost:8081/providers/getAttachment',
    // getProviderGalleryImages: 'http://localhost:8081/providers/getProviderGalleryImageByProviderId',
    // uploadProviderGalleryImages: 'http://localhost:8081/providers/uploadProviderGalleryImageByProviderId',
    // addDocumentList: 'http://localhost:8081/providers/addDocumentList',
    // changeDocumentStatus: 'http://localhost:8081/providers/changeDocumentStatus',
    // getDocumentStatus: 'http://localhost:8081/providers/getDocumentStatus',
    // providerGalleryImageUploadToCloud: 'http://localhost:8081/providers/providerGalleryImageUploadToCloud',
    // getPatnerEnquiryDetails: 'http://localhost:8081/patner/getPatnerEnquiryDetails',
    // addPatnerEnquiryDetails: 'http://localhost:8081/patner/addPatnerEnquiryDetails',
  }
};

