import { Component, Inject, OnInit, ViewChild, APP_INITIALIZER, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { GetavailabletimeslotService } from '../../services/getavailabletimeslot/getavailabletimeslot.service';
import { SetapointtService } from '../../services/setapointt/setapointt.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { GlobalsService } from '../../services/globals/globals.service';
import { NotificationtypeService } from '../../services/notificationtype/notificationtype.service';
import { WindowRef } from '../../services/windowRef/window-ref.service';
import { RazorpayService } from '../../services/razorpay/razorpay.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RescheduleappointmentService } from '../../services/rescheduleappointment/rescheduleappointment.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import * as range from 'lodash.range';
import { GetAllAddressesService } from '../../services/getAllAddresses/get-all-addresses.service';
import { ProfileServiceService } from '../../services/getprofile/profile-service.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'ddd, DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

// export interface DialogData {
//   animal: string;
// }

@Component({
  selector: 'app-calender-scheduler',
  templateUrl: './calender-scheduler.component.html',
  styleUrls: ['./calender-scheduler.component.css'],
  providers: [ConfirmationService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class CalenderSchedulerComponent {
  cat_child_name: any;
  @Input('provider_id') provider_id: string;
  userid: any;
  providerUserId: any;
  appoint_id: any;
  sub: any;
  StartDate: any;
  EndDate: any;
  available_day = [];
  qualifications = [];
  category_name;
  category_id = [];
  // subCategoriesService = [];
  intro;
  l_userid;
  specialization;
  getavailabletimeslot = [
    // { StartTime: '', availableFlag: '', currentFlag: '' }
  ];
  morningArray = [
    // { StartTime: '', availableFlag: '', currentFlag: '' }
  ];
  afternoonArray = [
    // { StartTime: '', availableFlag: '', currentFlag: '' }
  ];
  eveningArray = [
    // { StartTime: '', availableFlag: '', currentFlag: '' }
  ];
  nightArray = [
    // { StartTime: '', availableFlag: '', currentFlag: '' }
  ];
  breakTimeArray = [];
  working_week_array = [];
  month_slot_array;
  date = '';
  currentdatetime = moment();
  format = 'h:mm A';
  current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
  morningBefore = moment('5:30 AM', this.format);
  morningAfter = moment('12:00 PM', this.format);
  afternoonBefore = moment('11:30 AM', this.format);
  afternoonAfter = moment('4:00 PM', this.format);
  eveningBefore = moment('3:30 PM', this.format);
  eveningAfter = moment('8:00 PM', this.format);
  nightBefore = moment('7:30 PM', this.format);
  nightAfter = moment('12:00 AM', this.format);
  today = moment();
  dateValid = false;
  msgs: Message[] = [];
  minDate: Date;
  maxDate: Date;
  show = true;
  // datePicker = new FormControl(moment());
  datePicker = moment();
  reschedule_flag;
  businessName;
  addressText;
  provider_name;
  working_hours;
  public appointmentForm: FormGroup;
  online_consultation_type;
  addresses = [];
  address_id;
  showOnline = true;
  online_address_id;
  defaultAddress = '';
  defaultSelected = true;
  catId;
  holiday_array;
  personal_leave_array;
  available_week_date = [];
  date1;
  date2;
  date3;
  date1Selected = true;
  date2Selected = false;
  date3Selected = false;
  @Input('online') online: boolean;

  rzp1: any;
  options = {
    "key": "rzp_test_TwFKC764AUDX57",
    "amount": "",
    "name": "Apointt",
    "order_id": "",
    "currency": "INR",
    "handler": function (response) {
      // console.log('response', response);
    },
    "theme": {
      "color": "#33aac3"
    }
  };

  showBreak = true;
  three_month_valid;

  // @Input('provider_id') provider_id: string;
  // @Input('providerUserId') providerUserId: any;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  public currentDate: moment.Moment;
  public namesOfDays = ['Sun', 'Mon', ' Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public weeks: Array<CalendarDate> = [];

  public selectedDate;

  constructor(private Router: Router,
    private getavailabletimeslotService: GetavailabletimeslotService,
    private location: Location,
    private route: ActivatedRoute,
    private setApointt: SetapointtService,
    private confirmationService: ConfirmationService,
    private global: GlobalsService,
    private notificationType: NotificationtypeService,
    private winRef: WindowRef,
    private razorPayService: RazorpayService,
    public dialog: MatDialog,
    private router: Router,
    private rescheduleAppointmentService: RescheduleappointmentService,
    private toaster: ToastrService,
    private ngxService: NgxUiLoaderService,
    private server: JwtTestService,
    private getAllAddressesService: GetAllAddressesService,
    private ProfileServiceService: ProfileServiceService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.sub = this.route
      .params
      .subscribe(params => {
        // console.log(params);
        // Defaults to 0 if no query param provided.
        // this.provider_id = params.provider_id || 1;
        this.StartDate = moment().subtract(1, 'days').format("YYYY-MM-DD") || 1;
        this.EndDate = moment().subtract(1, 'days').format("YYYY-MM-DD") || 1;
        // console.log('provider_id query param', this.provider_id);
        // localStorage.setItem('provider_id', this.provider_id);
        sessionStorage.setItem('provider_id', this.provider_id);
      });

    // this.providerUserId = localStorage.getItem('providerUserId');
    this.provider_id = sessionStorage.getItem('provider_id');
    this.providerUserId = sessionStorage.getItem('providerUserId');
    this.userid = localStorage.getItem('userid');
    // this.catId = localStorage.getItem('catChildId').charAt(0);
    this.catId = localStorage.getItem('catId');
    this.cat_child_name = localStorage.getItem('catChildName')

    this.appointmentForm = new FormGroup({
      appointmentType: new FormControl(''),
      addressOption: new FormControl('')
    });
    this.getProvider();

    this.minDate = this.today.toDate();
    this.maxDate = moment().add(90, 'day').toDate();
    this.StartDate = moment().format("YYYY-MM-DD") || 1;
    this.EndDate = moment().add(10, 'hours').format("YYYY-MM-DD") || 1;
    this.datePicker = sessionStorage.getItem('appointmentDate') ? moment(sessionStorage.getItem('appointmentDate')) : moment();
    this.current_date_time = this.datePicker.format('ddd, DD MMM');

    this.date1 = moment().format('ddd, D MMM');
    this.date2 = moment().add(1, 'day').format('ddd, D MMM');
    this.date3 = moment().add(2, 'day').format('ddd, D MMM');

    if (moment(this.date1, 'ddd, D MMM').isSame(moment(), 'day')) {
      this.date1 = 'Today';
    }
    if (moment(this.date2, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
      this.date2 = 'Tomorrow';
    }

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    // this.getTimeSlots(this.StartDate);
    this.getTimeSlots(this.datePicker.format('YYYY-MM-DD'));
    this.getMonthSlots(this.datePicker.format('YYYY-MM-DD'));
    this.reschedule_flag = localStorage.getItem('reschedule_flag');

    if (sessionStorage.getItem('appointmentDate')) {
      this.confirm1(this.datePicker, sessionStorage.getItem('appointmentStart'), sessionStorage.getItem('appointmentEnd'));
    }

    this.currentDate = moment();
    this.selectedDate = moment(this.currentDate).format('DD-MM-YYYY');
    this.generateCalendar();
  }

  getMonthSlots(startDate, address_id?) {
    let month = moment(startDate).format('MM');

    let queryParms = `?provider_id=${this.provider_id}&start_date=${startDate}&month=${month}`;
    address_id && address_id != '0' ? queryParms += `&address_id=${address_id}` : ``;

    this.getavailabletimeslotService.GetavailabletimeslotService(queryParms)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.month_slot_array = _response.body.data.month_slot_array ? _response.body.data.month_slot_array : [];
          }
        },
        (_error) => {

        });
  }

  getTimeSlots(startDate, address_id?) {
    this.morningArray = [];
    this.afternoonArray = [];
    this.eveningArray = [];
    this.nightArray = [];
    this.online_consultation_type = [];

    let queryParms = `?provider_id=${this.provider_id}&start_date=${startDate}&year_month=${moment(startDate).format('YYYYMM')}`;
    address_id && address_id != '0' ? queryParms += `&address_id=${address_id}` : ``;

    this.getavailabletimeslotService.GetavailabletimeslotService(queryParms)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          // console.log(_response);
          if (_response.status === 200) {
            try {
              if (_response.body) {
                this.available_day = _response.body.data.availableDay;
                this.breakTimeArray = _response.body.data.breakArray;
                this.businessName = _response.body.data.businessName;
                this.addressText = _response.body.data.addressText;
                this.provider_name = _response.body.data.name;
                this.working_hours = _response.body.data.workingTime;
                this.online_consultation_type = _response.body.data.online_consultation_type;
                // this.online_consultation_type.pop();
                // if (this.online_consultation_type.length == 1 && this.online_consultation_type[0] == '0') {
                //   this.showAddress(this.online_consultation_type[0]);
                // }
                this.working_week_array = _response.body.data.availableDate;
                this.holiday_array = _response.body.data.holiday_list ? _response.body.data.holiday_list.split(' ') : [];
                this.personal_leave_array = _response.body.data.personal_leaves ? _response.body.data.personal_leaves.split(' ') : [];
                this.available_week_date = _response.body.data.available_week_date ? _response.body.data.available_week_date : [];

                if (typeof (this.online_consultation_type) == 'number') {
                  if (this.online_consultation_type == 0) {
                    this.showAddress(this.online_consultation_type);
                  }
                } else if (typeof (this.online_consultation_type) == 'string') {
                  let online = this.online_consultation_type.split(' ');
                  online.pop();
                  if (online.length == 1 && online[0] == '0') {
                    this.showAddress(online[0]);
                  }
                }

                if (!_response.body.data.unionArray) {
                  this.show = false;
                  if (this.breakTimeArray.length > 0) {
                    if (this.breakTimeArray[0].StartTime == 'Invalid date' && this.breakTimeArray[0].EndTime == 'Invalid date') {
                      this.showBreak = false;
                    } else {
                      this.showBreak = true;
                    }
                  } else if (this.breakTimeArray.length == 0) {
                    this.showBreak = false;

                  }
                } else {
                  this.show = true;
                  this.getavailabletimeslot = _response.body.data.unionArray;
                  this.date = moment(startDate).format("YYYY-MM-DD");
                  // this.date = moment(this.currentdatetime).format("YYYY-MM-DD");
                  // console.log('getavailabletimeslot', this.getavailabletimeslot);

                  for (let i = this.getavailabletimeslot.length - 1; i >= 0; i--) {
                    let time = moment(this.getavailabletimeslot[i].StartTime, this.format);
                    if (this.breakTimeArray.length > 0) {
                      if (this.breakTimeArray[0].StartTime == 'Invalid date' && this.breakTimeArray[0].EndTime == 'Invalid date') {
                        this.showBreak = false;
                      }
                      // else if (time.isBetween(moment(this.breakTimeArray[0].StartTime, this.format), moment(this.breakTimeArray[0].EndTime, this.format), undefined, '[)')) {
                      //   this.showBreak = true;
                      //   this.getavailabletimeslot.splice(i, 1);
                      // }
                    } else if (this.breakTimeArray.length == 0) {
                      this.showBreak = false;
                    }
                  }

                  for (let i = 0; i < this.getavailabletimeslot.length; i++) {
                    let time = moment(this.getavailabletimeslot[i].StartTime, this.format);

                    if (time.isBetween(this.morningBefore, this.morningAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.morningArray.push(this.getavailabletimeslot[i]);

                    } else if (time.isBetween(this.afternoonBefore, this.afternoonAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.afternoonArray.push(this.getavailabletimeslot[i]);

                    } else if (time.isBetween(this.eveningBefore, this.eveningAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.eveningArray.push(this.getavailabletimeslot[i]);

                    } else {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(startDate, 'YYYY-MM-DD').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.nightArray.push(this.getavailabletimeslot[i]);
                    }
                  }
                }
              }
            } catch (err) {

            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_error.status === 409) {
            this.show = false;
          }
        }
      )
  }

  handleClick(selected, current_date_time) {
    // this.currentdatetime = moment(current_date_time).subtract(1, 'days') || moment();
    // console.log('selected', selected);
    // console.log('current_date_time', current_date_time);
    // console.log('StartDate', StartDate);
    // currentdatetime = moment();
    // current_date_time = moment(this.currentdatetime).format("DD-MM-YYYY");
    let startDate, endDate, min;
    // console.log('before change: ', moment(this.datePicker).format('DD-MM-YYYY'))
    if (selected == 'sub') {

      startDate = moment(current_date_time).subtract(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).subtract(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment();
      this.currentdatetime = moment(current_date_time).subtract(1, 'days') || moment();
      min = 30;
      this.datePicker = moment(current_date_time).subtract(1, 'days');
      this.selectedDate = moment(current_date_time).subtract(1, 'days').format('DD-MM-YYYY');

      this.date1 = moment(this.datePicker).format('ddd, D MMM');
      this.date2 = moment(this.datePicker).add(1, 'day').format('ddd, D MMM');
      this.date3 = moment(this.datePicker).add(2, 'day').format('ddd, D MMM');

      this.getTimeSlots(moment(this.date1, 'ddd, D MMM').format('YYYY-MM-DD'), this.showOnline ? this.address_id : this.online_address_id);
      // console.log('after sub: ', moment(this.datePicker).format('DD-MM-YYYY'));

      if (moment(this.date1, 'ddd, D MMM').isSame(moment(), 'day')) {
        this.date1 = 'Today';
      } else if (moment(this.date1, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
        this.date1 = 'Tomorrow';
      }
      if (moment(this.date2, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
        this.date2 = 'Tomorrow';
      }
    }
    if (selected == 'add') {
      startDate = moment(current_date_time).add(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).add(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment()
      this.currentdatetime = moment(current_date_time).add(1, 'days') || moment();
      min = 30;
      this.datePicker = moment(current_date_time).add(1, 'days');
      this.selectedDate = moment(current_date_time).add(1, 'days').format('DD-MM-YYYY');

      this.date1 = moment(this.datePicker).format('ddd, D MMM');
      this.date2 = moment(this.datePicker).add(1, 'day').format('ddd, D MMM');
      this.date3 = moment(this.datePicker).add(2, 'day').format('ddd, D MMM');

      this.getTimeSlots(moment(this.date1, 'ddd, D MMM').format('YYYY-MM-DD'), this.showOnline ? this.address_id : this.online_address_id);

      // console.log('after add: ', moment(this.datePicker).format('DD-MM-YYYY'));

      if (moment(this.date1, 'ddd, D MMM').isSame(moment().add(1, 'day'), 'day')) {
        this.date1 = 'Tomorrow';
      }
    }
    //Display Reasons
    this.current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrAfter(moment().add(90, 'day'), 'day')) {
      this.three_month_valid = false;
    } else {
      this.three_month_valid = true;
    }

    // this.ngxService.start();

    // this.getTimeSlots(startDate, this.showOnline ? this.address_id : this.online_address_id);
    // this.getMonthSlots(startDate, this.showOnline ? this.address_id : this.online_address_id);
    // this.generateCalendar();

    // console.log(' this.current_date_time ', this.current_date_time);

    // this.datePicker.setValue(this.current_date_time);
    // console.log('value: ', this.datePicker.value);

    // this.StartDate = moment().subtract(1, 'days').format("DD-MMM-YYYY,h:mm:ss A") || 1;
    // this.EndDate = moment().subtract(1, 'days').add(10, 'hours').format("DD-MMM-YYYY,h:mm:ss A") || 1;
  }

  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  private fillDates(currentMoment: moment.Moment) {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const lastOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'day');
    const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'day').add(7, 'day');
    const startCalendar = firstDayOfGrid.date();

    return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'day')).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        mDate: newDate,
      };
    });
  }

  private isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  private isSelected(date: moment.Moment): boolean {
    return this.selectedDate === moment(date).format('DD-MM-YYYY');
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'month');
    this.current_date_time = moment(this.currentDate).format('ddd, DD MMM');
    this.datePicker = moment(this.currentDate);
    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    this.generateCalendar();
    this.getTimeSlots(this.currentDate.format('YYYY-MM-DD'), this.showOnline ? this.address_id : this.online_address_id);
    this.getMonthSlots(this.currentDate.format('YYYY-MM-DD'), this.showOnline ? this.address_id : this.online_address_id);
  }

  public nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'month');
    this.current_date_time = moment(this.currentDate).format('ddd, DD MMM');
    this.datePicker = moment(this.currentDate);

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }
    this.generateCalendar();
    this.getTimeSlots(this.currentDate.format('YYYY-MM-DD'), this.showOnline ? this.address_id : this.online_address_id);
    this.getMonthSlots(this.currentDate.format('YYYY-MM-DD'), this.showOnline ? this.address_id : this.online_address_id);
  }

  public isDisabledMonth(currentDate): boolean {
    const today = moment();
    return moment(currentDate).isSame(today.add(90, 'days'), 'month');
  }

  public isDisabledPreviousMonth(currentDate): boolean {
    const today = moment();
    return moment(currentDate).isSameOrBefore(today, 'month');
  }

  public isDisabledDay(date: moment.Moment): boolean {
    // if (moment(date).isSameOrAfter(moment().add(90, 'day'), 'day')) {
    //   return true;
    // }
    // return false;

    if (this.available_day) {
      for (let i = 0; i < this.available_day.length; i++) {
        if (date.format('dddd') == this.available_day[i]) {
          return false;
        }
      }
    } else if (this.working_week_array) {
      for (let i = 0; i < this.working_week_array.length; i++) {
        if (date.date() == this.working_week_array[i]) {
          return false;
        }
      }
    } else if (this.available_week_date) {
      for (let i = 0; i < this.available_week_date.length; i++) {
        // this.available_week_date.forEach((v1, i) => {
        if (date.date() == this.available_week_date[i]) {
          return false;
        }
        // });
      }
    }

    return true;
  }

  public isSelectedMonth(date: moment.Moment): boolean {
    const today = moment();
    return moment(date).isSame(this.currentDate, 'month') && moment(date).isSameOrAfter(today, 'date');
  }

  public selectDate(date: CalendarDate) {
    let scheduler = document.getElementById('scheduler');
    let calendar = document.getElementById('calendar');
    let back_button = document.getElementById('back_button');

    scheduler.className = 'col-md-12 col-sm-12 col-lg-6 d-block';
    calendar.className = 'col-md-12 col-sm-12 col-lg-6 d-none d-md-block';
    back_button.className = 'd-block d-md-none';

    this.selectedDate = moment(date.mDate).format('DD-MM-YYYY');
    let startDate = moment(date.mDate).format('YYYY-MM-DD');
    this.current_date_time = moment(date.mDate).format("ddd, DD MMM");
    this.currentdatetime = moment(date.mDate);
    this.datePicker = moment(date.mDate)

    this.generateCalendar();
    // console.log('selected date: ', moment(date.mDate).format('DD-MM-YYYY'));

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    if (moment(this.current_date_time, 'ddd DD MMM').isSame(moment().add(90, 'day'), 'day')) {
      this.three_month_valid = false;
    } else {
      this.three_month_valid = true;
    }

    this.ngxService.start();
    this.getTimeSlots(startDate, this.showOnline ? this.address_id : this.online_address_id);
    this.getMonthSlots(startDate, this.showOnline ? this.address_id : this.online_address_id);
  }

  public isHoliday(date: moment.Moment): boolean {
    if (this.holiday_array) {
      for (let i = 0; i < this.holiday_array.length; i++) {
        if (moment(date).format('YYYY-MM-DD') == this.holiday_array[i]) {
          return true;
        }
      }
    }

    return false;
  }

  public isPersonalHoliday(date: moment.Moment): boolean {
    if (this.personal_leave_array) {
      for (let i = 0; i < this.personal_leave_array.length; i++) {
        if (moment(date).format('YYYY-MM-DD') == this.personal_leave_array[i]) {
          return true;
        }
      }
    }

    return false;
  }

  public checkBooked50(date: moment.Moment): boolean {
    if (this.month_slot_array) {
      for (let i = 0; i < this.month_slot_array.length; i++) {
        if (this.month_slot_array[i].Date == date.format('YYYY-MM-DD') && (this.month_slot_array[i].Count >= 50 && this.month_slot_array[i].Count < 80)) {
          return true;
        }
      }
    }
    return false;
  }

  public checkBooked80(date: moment.Moment): boolean {
    if (this.month_slot_array) {
      for (let i = 0; i < this.month_slot_array.length; i++) {
        if (this.month_slot_array[i].Date == date.format('YYYY-MM-DD') && this.month_slot_array[i].Count >= 80) {
          return true;
        }
      }
    }
    return false;
  }

  goBack() {
    let scheduler = document.getElementById('scheduler');
    let calendar = document.getElementById('calendar');
    let back_button = document.getElementById('back_button');

    scheduler.className = 'col-md-6 d-none d-md-block';
    calendar.className = 'col-md-6';
    back_button.className = 'd-none d-md-none';
  }

  showAddress(event) {
    localStorage.setItem('consultation_type', event);
    if (event == 1) {
      this.showOnline = false;
    } else {
      this.showOnline = true;
    }
    let query = `?provider_id=${this.provider_id}&online_consultation_flag=${event}`;
    this.getAllAddressesService.GetAllAddressesService(query)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.addresses = _response.body;

            for (let i = this.addresses.length - 1; i >= 0; i--) {
              if (event == 0 && this.addresses[i].address === null) {
                this.addresses.splice(i, 1);
              }
            }

            if (event == 1) {
              // this.online_address_id = this.addresses[1].address_id;
              this.online_address_id = this.addresses[0].address_id;
              this.getTimeSlots(this.currentdatetime.format('YYYY-MM-DD'), this.online_address_id);
              this.getMonthSlots(this.currentdatetime.format('YYYY-MM-DD'), this.online_address_id);
            }
            else {
              this.getTimeSlots(this.currentdatetime.format('YYYY-MM-DD'));
              this.getMonthSlots(this.currentdatetime.format('YYYY-MM-DD'));
              this.appointmentForm.controls['addressOption'].setValue('0');
            }
          }
        },
        (_error) => {

        });
  }

  selectAddress(event) {
    this.address_id = event.value;
    if (event.value == '0') {
      this.getTimeSlots(moment(this.currentdatetime).format('YYYY-MM-DD'));
      this.getMonthSlots(moment(this.currentdatetime).format('YYYY-MM-DD'));
      this.defaultSelected = true;
    } else {
      this.getTimeSlots(moment(this.currentdatetime).format('YYYY-MM-DD'), event.value);
      this.getMonthSlots(moment(this.currentdatetime).format('YYYY-MM-DD'), event.value);
      localStorage.setItem('address_id', this.address_id);
      this.defaultSelected = false;
    }
  }

  confirm1(date, startTime, endTime) {
    this.userid = localStorage.getItem('userid');
    if (this.userid && (this.userid != this.providerUserId)) {
      this.bookApointment(date, startTime, endTime);

      // this.confirmationService.confirm({
      //   message: "Are you sure that you want to proceed ? <br>Date: " + moment(date).format('ddd, DD MMM YYYY') + "<br>Start Time: " + startTime + "<br>End Time: " + endTime,
      //   header: 'Confirmation',
      //   // icon: 'pi pi-exclamation-triangle',
      //   accept: () => {
      //     this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      //     if (this.reschedule_flag) {
      //       this.rescheduleAppointment(date, startTime, endTime);
      //     } else {
      //       this.bookApointment(date, startTime, endTime);
      //     }

      //   },
      //   reject: () => {
      //     this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      //     sessionStorage.removeItem('appointmentDate');
      //     sessionStorage.removeItem('appointmentStart');
      //     sessionStorage.removeItem('appointmentEnd');
      //   }
      // });
    } else if (this.userid && (this.userid == this.providerUserId)) {
      this.confirmationService.confirm({
        message: "Please select another provider (you cannot take appointment from same provider account)",
        header: 'Alert',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'OK',
        rejectLabel: 'Cancel',
        accept: () => {
          this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        },
        reject: () => {
          this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected ' }];
          sessionStorage.removeItem('appointmentDate');
          sessionStorage.removeItem('appointmentStart');
          sessionStorage.removeItem('appointmentEnd');
        }
      });
    } else {
      this.Router.navigate(['quicksignin/', this.provider_id]);
      sessionStorage.setItem('appointmentDate', date);
      sessionStorage.setItem('appointmentStart', startTime);
      sessionStorage.setItem('appointmentEnd', endTime);

      // this.confirmationService.confirm({
      //   message: 'Please login to book appointment',
      //   header: 'Alert',
      //   icon: 'pi pi-exclamation-triangle',
      //   acceptLabel: 'OK',
      //   rejectLabel: 'Cancel',
      //   accept: () => {
      //     this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      //     this.Router.navigate(['quicksignin/', this.provider_id]);
      //     sessionStorage.setItem('appointmentDate', date);
      //     sessionStorage.setItem('appointmentStart', startTime);
      //     sessionStorage.setItem('appointmentEnd', endTime);
      //   },
      //   reject: () => {
      //     this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      //     sessionStorage.removeItem('appointmentDate');
      //     sessionStorage.removeItem('appointmentStart');
      //     sessionStorage.removeItem('appointmentEnd');
      //   }
      // });
    }

  }

  bookApointment(date, startTime, endTime) {
    this.userid = localStorage.getItem('userid');
    let apointment = {
      provider_id: this.provider_id,
      userid: this.userid,
      start_datetime: moment(date).format('YYYY-MM-DD') + " " + moment(startTime, 'h:mm A').format('hh:mm A'),
      end_datetime: moment(date).format('YYYY-MM-DD') + " " + endTime,
      // pay_status: this.animal
      consultation_type: this.online ? '1' : !this.online ? '0' : localStorage.getItem('consultation_type'),
      address_id: this.defaultSelected ? '' : localStorage.getItem('address_id'),
    }

    this.server.request('POST', '/appointment/setApointt', apointment)
      .subscribe(
        (_response: any) => {
          console.log("apointment booked");
          this.appoint_id = _response.appoint_id;
          localStorage.setItem('appoint_id', _response.appoint_id);
          localStorage.setItem('consultation_type', apointment.consultation_type);
          this.Router.navigate(['customer-dashboard/payment-method']);

          sessionStorage.removeItem('appointmentDate');
          sessionStorage.removeItem('appointmentStart');
          sessionStorage.removeItem('appointmentEnd');

          let notificationtype = {
            userid: this.userid,
            intended_userid: this.providerUserId,
            appoint_id: this.appoint_id,
            n_type: '0'
          };

          this.server.request('POST', '/notification/notificationType', notificationtype)
            .subscribe((_response) => {
              // console.log('notification type: ', notificationtype.n_type);
            },
              (_error) => {

              });
        },
        (_error) => {

        });
  }

  rescheduleAppointment(date, startTime, endTime) {
    let rescheduleArray = [];
    let appointment = {
      // provider_id: this.provider_id,
      // userid: this.userid,
      start_datetime: date + " " + startTime,
      end_datetime: date + " " + endTime,
      appoint_id: localStorage.getItem('appoint_id'),
      appoint_status: 0
    };
    rescheduleArray.push(appointment);

    let reschedule = {
      dt: JSON.stringify(rescheduleArray),
      provider_id: this.provider_id,
    };

    this.server.request('POST', '/appointment/rescheduleAppointment', reschedule)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Your appointment has been rescheduled');
          this.router.navigate(['/customer-dashboard/appoint-success']);

          sessionStorage.removeItem('appointmentDate');
        },
        (_error) => {
          this.toaster.error('Could not reschedule appointment');
        });
  }

  pay(pay_status) {
    if (pay_status == '1') {
      let query = `?appoint_id=${this.appoint_id}`

      this.razorPayService.RazorpayService(query)
        .subscribe(
          (_response) => {
            if (_response.status === 200) {
              // console.log('_response: ', _response.body);
              this.options.amount = _response.body.payload.order.amount;
              this.options.order_id = _response.body.payload.order.id;
              // this.Router.navigate(['customer-dashboard/appoint-success']);
            }
          },
          (_error) => {
            console.log('pay error: ', _error);
          }
        );
      this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
      this.rzp1.open();
      // console.log('rzp: ', this.rzp1);
      // this.Router.navigate(['customer-dashboard/appoint-success']);
    } else if (pay_status == '0') {
      this.Router.navigate(['customer-dashboard/appoint-success']);
    }
  }

  getProvider() {
    // this.provider_id = localStorage.getItem('provider_id');
    let queryParms = `?provider_id=${this.provider_id}`;
    // let queryParms = `?provider_name=${this.provider_name}`;
    // let queryParms = `?unique_url_id=${this.provider_name}`;
    let l_userid = `&l_userid=${this.userid}`
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          // console.log(_response);
          if (_response.status === 200) {
            try {

              if (_response.body[0].qualification.includes("*")) {
                this.qualifications = _response.body[0].qualification.split('*');
              }
              else {
                this.qualifications = _response.body[0].qualification.split(' ');
              }
              this.qualifications.pop();

              let categories = _response.body[0].category_name;
              this.category_name = '';
              for (let i = 0; i < categories.length; i++) {
                this.category_name += categories[i] + ', ';
              }
              this.category_name = this.category_name.slice(0, -2);

              this.defaultAddress = _response.body[0].address;

              this.appointmentForm.controls['appointmentType'].setValue('0');
              this.appointmentForm.controls['addressOption'].setValue('0');
              this.showAddress(0);

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      )
  }

  navigateBack() {
    if (this.cat_child_name) {
      this.router.navigate(['/customer-dashboard/provider-details-view', this.cat_child_name]);
    } else if (this.catId == '1') {
      this.router.navigate(['/customer-dashboard/child-category-view/Doctor'])
    } else if (this.catId == '2') {
      this.router.navigate(['/customer-dashboard/child-category-view/Lawyer']);
    } else if (this.catId == '3') {
      this.router.navigate(['/customer-dashboard/child-category-view/Beautician']);
    } else if (this.catId == '4') {
      this.router.navigate(['/customer-dashboard/child-category-view/Chartered%20Accountant']);
    }
  }

  dateSelected(date) {
    if (date == 'Today') {
      this.ngxService.start();
      this.getTimeSlots(moment().format('YYYY-MM-DD'));
    } else if (date == 'Tomorrow') {
      this.ngxService.start();
      this.getTimeSlots(moment().add(1, 'day').format('YYYY-MM-DD'));
    } else {
      this.ngxService.start();
      this.getTimeSlots(moment(date, 'ddd, D MMM').format('YYYY-MM-DD'));
    }
  }

  //   public initPay():void {
  //     this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
  //     this.rzp1.open();
  //  }
}
