import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ImageGalleryService {

  // SERVER_URL: string = "http://ec2-13-233-100-76.ap-south-1.compute.amazonaws.com:8081/providers/uploadProviderGalleryImageByProviderId";

  constructor(private http: HttpClient) { }

  //Get images by provider id
  getProviderImages(query){
    const href = environment.api.getProviderGalleryImages;
    return this.http.get<any>(href + query, {'observe': 'response'});
  }

  //Upload image
  // public upload(queryParms, formData) {

  //   return this.http.post<any>(this.SERVER_URL+ queryParms, formData, {
  //       reportProgress: true,
  //       observe: 'events'
  //     });
  // }

  //Upload image
  public upload(queryParms, formData) {
    const href = environment.api.uploadProviderGalleryImages;
    return this.http.post<any>(href + queryParms, formData, {
        reportProgress: true,
        observe: 'events'
      });
  }

  deleteGalleryImages(image_id){
    const href = environment.api.deleteProviderPhoto;
    return this.http.post<any>(href, image_id, {'observe': 'response'});
  }
  UploadImageGalleryToCloudService(data) {
    const href = environment.api.providerGalleryImageUploadToCloud;
    return this.http.post<any>(href, data, {'observe': 'response'});
  }
}
