import { Component, OnInit } from '@angular/core';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { ProfileServiceService } from '../../../services/getprofile/profile-service.service';
import * as moment from 'moment';
import { WindowRef } from '../../../services/windowRef/window-ref.service';

@Component({
  selector: 'app-choose-payment',
  templateUrl: './choose-payment.component.html',
  styleUrls: ['./choose-payment.component.css']
})
export class ChoosePaymentComponent implements OnInit {
  provider_id: any;
  userid: any;
  providerInfo = [];
  category_name;
  appointments = [];
  appoint_id: any;
  online_consultation_type;
  pay_methods;
  advances_payment_cost;
  cost;
  show_cod;
  show_pay_online;
  show_pay_advance;
  pay_today = false;
  showNote = false;
  userName = '';
  relation = '';
  redirect_url = '';
  rzp1: any;
  options = {
    "key": "rzp_test_jc3xfnFgY5brGq",
    // "key": "rzp_live_RqunH7a3wo6Ajl",
    "amount": "",
    "name": "Apointt",
    "order_id": "",
    "currency": "INR",
    "handler": this.verifySignature.bind(this),
    "theme": {
      "color": "#EB5757"
    }
  };

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private server: JwtTestService,
    private ProfileServiceService: ProfileServiceService,
    private winRef: WindowRef,

  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = sessionStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    this.appoint_id = localStorage.getItem('appoint_id');
    this.online_consultation_type = localStorage.getItem('consultation_type');
    this.userName = localStorage.getItem('name');
    this.relation = localStorage.getItem('relation');

    let queryParams = {
      appoint_id: this.appoint_id,
      online_consultation_type: this.online_consultation_type == 1 ? this.online_consultation_type : ``
    };

    this.server.request('GET', '/appointment/getAppointmentDetails', queryParams)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.appointments = _response.body;
            this.pay_methods = _response.body[0].pay_methods;
            this.advances_payment_cost = _response.body[0].advances_payment_cost;
            this.cost = _response.body[0].price;

            if (this.pay_methods.includes('0')) {
              this.show_cod = true;
            } else {
              this.show_cod = false;
            }

            if (this.pay_methods.includes('1')) {
              this.show_pay_online = true;
              this.pay_today = false;
            } else {
              this.pay_today = false;
              this.show_pay_online = false;
            }

            if (this.pay_methods.includes('2')) {
              this.pay_today = false;
              this.show_pay_advance = true;
            } else {
              this.pay_today = false;
              this.show_pay_advance = false;
            }

            this.appointments[0].date = moment(this.appointments[0].date, 'MMMDD,YYYY').format('MMM DD, YYYY');

            if (moment(this.appointments[0].date, 'MMM DD, YYYY').isSame(moment(), 'day')) {
              this.showNote = true;
            } else {
              this.showNote = false;
            }
          }
        },
        (_error) => {

        });

    this.getProvider();
  }

  getProvider() {
    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${this.userid}`
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          console.log("all details", _response.body[0]);
          if (_response.status === 200) {
            try {
              this.providerInfo = _response.body;
              let categories = _response.body[0].category_name;
              this.category_name = '';
              for (let i = 0; i < categories.length; i++) {
                this.category_name += categories[i] + ', ';
              }
              this.category_name = this.category_name.slice(0, -2);

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      )
  }

  getDirections(address) {
    address = address.replace(/ /g, '+').replace(/,/g, "%2C");
    window.open('https://www.google.com/maps/search/?api=1&query=' + address, "_blank");
  }

  payCod() {
    localStorage.setItem('pay_status', '0');
    localStorage.setItem('cost', this.cost);
  }

  pay() {
    let query = {
      appoint_id: this.appoint_id,
      online_consultation_type: localStorage.getItem('consultation_type')
    }

    this.server.request('GET', '/razorpay_purchase/purchase', query)
      .subscribe((_response: any) => {
        if (_response.status === 200) {
          console.log('_response: ', _response.body);
          this.options.amount = _response.body.payload.order.amount;
          this.options.order_id = _response.body.payload.order.id;
          console.log('options: ', this.options);
          this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
          console.log('rzp: ', this.rzp1);
        }
      },
        (_error) => {
          console.log('pay error: ', _error);
        });

    localStorage.setItem('pay_status', '1');
    localStorage.setItem('cost', this.cost);
  }

  payAdvance() {
    let query = {
      appoint_id: this.appoint_id,
      advances_payment_cost: this.advances_payment_cost,
      online_consultation_type: localStorage.getItem('consultation_type')
    };

    this.server.request('GET', '/razorpay_purchase/purchase', query)
      .subscribe((_response: any) => {
        if (_response.status === 200) {
          console.log('_response: ', _response.body);
          this.options.amount = _response.body.payload.order.amount;
          this.options.order_id = _response.body.payload.order.id;
          // console.log('options: ', this.options);
          this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
          console.log('rzp: ', this.rzp1);
          localStorage.setItem('cost', (parseFloat(_response.body.payload.order.amount) / 100).toString());
        }
      },
        (_error) => {
          console.log('pay error: ', _error);
        });

    localStorage.setItem('pay_status', '2');
  }

  verifySignature(response) {
    console.log('response', response);
    let signature = {
      appoint_id: localStorage.getItem('appoint_id'),
      provider_id: sessionStorage.getItem('provider_id'),
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature
    };

    this.server.request('POST', '/razorpay_purchase/signatureVerification', signature)
      .subscribe((_response: any) => {
        // if (_response.status === 200) {
        try {
          console.log('signature verified');
          if (response.razorpay_payment_id) {
            this.redirect_url = '/customer-dashboard/appoint-success';
          } else {
            this.redirect_url = '/customer-dashboard/payment-method';
          }
          window.location.assign(this.redirect_url);
        } catch (err) {
          console.log('error: ', err);
        }

        // }
      },
        (_error) => {

        });
  }
}
