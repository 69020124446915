import { Component, OnInit } from '@angular/core';
import { GetappointmentbyproviderService } from '../../../services/getappointmentbyprovider/getappointmentbyprovider.service';
import * as moment from 'moment';
import { ChangeAppointStatusService } from '../../../services/change-appoint-status/change-appoint-status.service';
import { NotificationtypeService } from '../../../services/notificationtype/notificationtype.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GetpaymentidbyapointtidService } from '../../../services/getpaymentidbyapointtid/getpaymentidbyapointtid.service';
import { RazorpayService } from '../../../services/razorpay/razorpay.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

export interface Appointment {
  name: string;
  date: string;
  amount: number;
}

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css'],
  providers: [ConfirmationService]
})
export class AppointmentsComponent implements OnInit {
  appointments = [
    {
      userid: '',
      appoint_id: '',
      start_datetime: '',
      end_datetime: '',
      name: '',
      time: ''
    }
  ];
  userid = '';
  provider_id = '';
  today = moment().format('YYYY-MM-DD');
  currentdatetime = moment();
  current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
  dateValid = false;
  appointmentavailable = true;
  displayedColumns: string[] = ['name', 'date', 'time', 'status'];
  payment_id;
  msgs: Message[] = [];
  filter;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getappointmentbyproviderService: GetappointmentbyproviderService,
    private changeApointStatus: ChangeAppointStatusService,
    private notificationTypeService: NotificationtypeService,
    private toaster: ToastrService,
    private ngxService: NgxUiLoaderService,
    private getPaymentIdByApointtId: GetpaymentidbyapointtidService,
    private razorPayService: RazorpayService,
    private confirmationService: ConfirmationService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.getAppointments(this.today);
    this.userid = localStorage.getItem('userid');
    this.provider_id = localStorage.getItem('provider_id');
  }

  handleClick(selected, current_date_time) {
    console.log('selected', selected);
    console.log('current_date_time', current_date_time);
    let startDate, endDate, min;
    if (selected == 'sub') {

      startDate = moment(current_date_time).subtract(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).subtract(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment();
      this.currentdatetime = moment(current_date_time).subtract(1, 'days') || moment();
      min = 30;

    }
    if (selected == 'add') {
      startDate = moment(current_date_time).add(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).add(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment()
      this.currentdatetime = moment(current_date_time).add(1, 'days') || moment();
      min = 30;
    }
    //Display Reasons
    this.current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }
    this.getAppointments(startDate);
    console.log(' this.current_date_time ', this.current_date_time);
  }

  getAppointments(startDate) {
    this.provider_id = localStorage.getItem('provider_id');
    let queryParam = `?provider_id=${this.provider_id}&date=${startDate}`;
    this.filter && this.filter < 4 ? queryParam += `&appoint_status=${this.filter}` : ``;
    this.ngxService.start();

    this.getappointmentbyproviderService.GetappointmentbyproviderService(queryParam)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_response.status === 200 && _response.body.data == 'Not Found') {
            this.appointmentavailable = false;
          }
          else if (_response.status === 200) {
            this.appointmentavailable = true;
            this.appointments = _response.body.data.results;
            console.log(this.appointments);

            for (let i = 0; i < this.appointments.length; i++) {
              this.appointments[i].time = moment(this.appointments[i].start_datetime).format('hh:mm A')
              this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_error.status === 409) {
            this.appointmentavailable = false;
          }
        }
      );
  }

  changeStatus(appointment_id, appoint_status, userid) {
    console.log('appointment_id ', appointment_id);
    console.log('status ', appoint_status);
    let status = {
      appoint_id: appointment_id,
      appoint_status: appoint_status
    };

    this.changeApointStatus.ChangeAppointStatusService(status)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            if (appoint_status == '1') {
              // alert('Appointment accepted');
              this.toaster.success('Appointment accepted');
            } else if (appoint_status == '2') {
              // alert('Appointment rejected');
              this.toaster.error('Appointment rejected');
            } else if (appoint_status == '3') {
              this.toaster.error('Appointment rejected');
              this.getPaymentId(appointment_id);
            }

            this.getAppointments(this.today);
          }
        },
        (_error) => {

        }
      );

    let notificationtype = {
      userid: this.userid,
      intended_userid: userid,
      appoint_id: appointment_id,
      n_type: appoint_status
    };

    this.server.request('POST', '/notification/notificationType', notificationtype)
      .subscribe((_response) => {
        console.log('notification type: ', notificationtype.n_type);
      },
        (_error) => {

        });

    // this.notificationTypeService.NotificationtypeService(notificationtype)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         console.log('notification type: ', notificationtype.n_type);
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
  }

  onChange(event, startDate) {
    startDate = moment(startDate).format('YYYY-MM-DD');
    let query = `?provider_id=${this.provider_id}&date=${startDate}`;
    this.filter = event.value;
    if (event.value == 4) {
      query = `?provider_id=${this.provider_id}&date=${startDate}`;
    } else {
      query += `&appoint_status=${event.value}`
    }
    this.getappointmentbyproviderService.GetappointmentbyproviderService(query)
      .subscribe(
        (_response) => {
          if (_response.status === 200 && _response.body.data == 'Not Found') {
            this.appointmentavailable = false;
          }
          else if (_response.status === 200) {
            this.appointmentavailable = true;
            this.appointments = _response.body.data.results;
            console.log(this.appointments);

            for (let i = 0; i < this.appointments.length; i++) {
              this.appointments[i].time = moment(this.appointments[i].start_datetime).format('hh:mm A')
              this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            this.appointmentavailable = false;
          }
        }
      );
  }

  confirm1(appointment_id, appoint_status, userid) {
    this.confirmationService.confirm({
      message: 'Amount will be automatically refunded to customer',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        this.changeStatus(appointment_id, appoint_status, userid);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      },
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
    });
  }

  getPaymentId(appoint_id) {
    let query = `?appoint_id=${appoint_id}`;

    this.getPaymentIdByApointtId.GetpaymentidbyapointtidService(query)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.payment_id = _response.body.razorpay_payment_id;
            this.refund();
          }
        },
        (_error) => {

        });
  }

  refund() {
    let payment = {
      razorpay_payment_id: this.payment_id
    };

    this.razorPayService.refundPayment(payment)
      .subscribe(
        (__response) => {
          if (__response.status === 200) {
            this.toaster.success('Payment refunded successfully');

            let refund = {
              razorpay_payment_id: this.payment_id,
              refund_id: __response.body.id
            };
            this.razorPayService.refundId(refund)
              .subscribe(
                (_response) => {
                  if (_response.status === 200) {
                    console.log('refund id success');
                  }
                },
                (_error) => {

                });
          }
        },
        (_error) => {
          this.toaster.error('Unable to refund payment');
        });
  }

}
