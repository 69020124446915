import { Component, OnInit, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-child-category',
  templateUrl: './child-category.component.html',
  styleUrls: ['./child-category.component.css']
})
export class ChildCategoryComponent {
    catId = null;
    catName = '';
  @Input('cartObj') cartObj: any
  constructor(private router: Router) { }

  ngOnInit() {
    if (this.cartObj)
      this.catId = this.cartObj.catChildId;
  }
  viewProduct() {
    this.router.navigate(['./customer-dashboard/provider-details-view/' + this.cartObj.catChildName]);
    localStorage.setItem('catChildId', this.cartObj.catId);
    localStorage.setItem('catChildName', this.cartObj.catChildName);
  }
  // handleClick() {
  //   this.router.navigate(['/customer-dashboard/provider-details-view/', this.catId])
  //   console.log('Click!');
  // }
}
