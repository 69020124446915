import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-partner-refferal',
  templateUrl: './partner-refferal.component.html',
  styleUrls: ['./partner-refferal.component.css']
})
export class PartnerRefferalComponent implements OnInit {
  ownerForm: FormGroup;
  patner_id;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private toaster: ToastrService,
    private router: Router,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
   }

  ngOnInit() {
    this.patner_id= localStorage.getItem('patner_id');

    this.ownerForm = new FormGroup({
      mobile_number: new FormControl('', [Validators.pattern(/^\d{10}$/), Validators.maxLength(10)])
    });
  }

  public createOwner = (ownerFormValue, formDirective: FormGroupDirective) => {
    if (this.ownerForm.valid) {
      this.sendReferLink(ownerFormValue);
      formDirective.resetForm();
      this.ownerForm.reset();
    }
  }

  sendReferLink(ownerFormValue) {
    let link = {
      mobile_number: ownerFormValue.mobile_number,
      patner_id: this.patner_id
    };

    this.server.request('POST', '/patner/referPatnerBySmsToProvider', link)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Link shared successfully');
        },
        (_error) => {

        });

  }

}
