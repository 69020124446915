import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MoreinfoService {

  constructor(private httpService: HttpClient) { }

  MoreinfoService(data) {
    const href = environment.api.moreinfo;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
