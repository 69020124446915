import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GetappointmentbyproviderService } from '../../../services/getappointmentbyprovider/getappointmentbyprovider.service';
import * as moment from 'moment';
import { ProvidercountService } from '../../../services/providercount/providercount.service';
import { Router } from '@angular/router';
import { ProfileServiceService } from '../../../services/getprofile/profile-service.service';
import { ChangeAppointStatusService } from '../../../services/change-appoint-status/change-appoint-status.service';
import { NotificationtypeService } from '../../../services/notificationtype/notificationtype.service';
import { ToastrService } from 'ngx-toastr';
import { GetpaymentidbyapointtidService } from '../../../services/getpaymentidbyapointtid/getpaymentidbyapointtid.service';
import { RazorpayService } from '../../../services/razorpay/razorpay.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { LocationStrategy } from '@angular/common';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject, Subject } from 'rxjs'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  // encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class DashboardComponent implements OnInit, OnDestroy {
  appointments = [
    // {
    //   userid: '',
    //   appoint_id: '',
    //   start_datetime: '',
    //   end_datetime: '',
    //   name: '',
    //   cost: '',
    //   date: ''
    // }
  ];
  userid = '';
  provider_id = '';
  today = moment().format('YYYY-MM-DD');
  currentdatetime = moment();
  current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
  dateValid = false;
  appointmentavailable = true;
  providerCount = [];
  subCategoriesService = [];
  intervalId;
  clickIntervalId;
  payment_id;
  msgs: Message[] = [];
  refundAppts = [];
  video_url = '';

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getappointmentbyproviderService: GetappointmentbyproviderService,
    private providerCountService: ProvidercountService,
    private router: Router,
    private providerProfile: ProfileServiceService,
    private changeApointStatus: ChangeAppointStatusService,
    private notificationTypeService: NotificationtypeService,
    private toaster: ToastrService,
    private getPaymentIdByApointtId: GetpaymentidbyapointtidService,
    private razorPayService: RazorpayService,
    private confirmationService: ConfirmationService,
    private location: LocationStrategy,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }

    history.pushState(null, null, '/provider-dashboard');
    this.location.onPopState(() => {
      history.pushState(null, null, '/provider-dashboard');
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.clickIntervalId);
  }

  ngOnInit() {
    this.getAppointments(this.today);

    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    this.video_url = "https://ec2-3-20-232-225.us-east-2.compute.amazonaws.com:6070/" + this.provider_id

    let queryParam = `?provider_id=${this.provider_id}`
    this.providerCountService.ProvidercountService(queryParam)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.providerCount = _response.body;
          }
        },
        (_error) => {

        }
      );

    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${this.userid}`;
    this.providerProfile.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            try {
              this.subCategoriesService = _response.body;
            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      );

    this.intervalId = setInterval(() => {
      this.getAppointments(this.today);
    }, 5000);
  }

  confirm1(appointment_id, appoint_status, userid) {
    this.confirmationService.confirm({
      message: 'Amount will be automatically refunded to customer',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        this.changeStatus(appointment_id, appoint_status, userid);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      },
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
    });
  }

  handleClick(selected, current_date_time) {
    console.log('selected', selected);
    console.log('current_date_time', current_date_time);
    let startDate, endDate, min;
    if (selected == 'sub') {

      startDate = moment(current_date_time).subtract(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).subtract(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment();
      this.currentdatetime = moment(current_date_time).subtract(1, 'days') || moment();
      min = 30;

    }
    if (selected == 'add') {
      startDate = moment(current_date_time).add(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).add(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment()
      this.currentdatetime = moment(current_date_time).add(1, 'days') || moment();
      min = 30;
    }
    //Display Reasons
    this.current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    clearInterval(this.intervalId);
    this.getAppointments(startDate);
    clearInterval(this.clickIntervalId);
    this.clickIntervalId = setInterval(() => {
      this.getAppointments(startDate);
      console.log('startDate: ', startDate);
    }, 5000);

    console.log(' this.current_date_time ', this.current_date_time);
  }

  getAppointments(startDate) {
    this.provider_id = localStorage.getItem('provider_id');
    let queryParam = `?provider_id=${this.provider_id}&date=${startDate}`;
    this.getappointmentbyproviderService.GetappointmentbyproviderService(queryParam)
      .subscribe(
        (_response) => {
          if (_response.status === 200 && _response.body.data == 'Not Found') {
            this.appointmentavailable = false;
          }
          else if (_response.status === 200) {
            this.appointmentavailable = true;
            this.appointments = _response.body.data.results;
            // console.log(this.appointments);

            // let time = moment();

            if (this.appointments) {
              for (let i = 0; i < this.appointments.length; i++) {
                this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
                this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
                this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');

                // let difference = moment(this.appointments[i].start_datetime, 'hh:mm A').diff(time, 'minutes');
                // if (difference == 60 && this.appointments[i].apoint_status == 0) {
                //   // console.log('difference -1', i , ': ', difference);
                //   this.changeStatus(this.appointments[i].appoint_id, 2, this.userid);
                // } else {
                //   // console.log('difference', i , ': ', difference);
                // }

                if (this.appointments[i].apoint_status == 3) {
                  this.refundAppts.push(this.appointments[i]);
                }
              }
              // this.getRefundId();
            } else {
              return;
            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            this.appointmentavailable = false;
          }
        }
      );
  }

  changeStatus(appointment_id, appoint_status, userid) {
    // console.log('appointment_id ', appointment_id);
    // console.log('status ', appoint_status);
    let status = {
      appoint_id: appointment_id,
      appoint_status: appoint_status
    };

    this.changeApointStatus.ChangeAppointStatusService(status)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            if (appoint_status == '1') {
              // alert('Appointment accepted');
              this.toaster.success('Appointment accepted');
            } else if (appoint_status == '2') {
              // alert('Appointment rejected');
              this.toaster.error('Appointment rejected');
              // this.getPaymentId(appointment_id);
            } else if (appoint_status == '3') {
              this.toaster.error('Appointment rejected');
              this.getPaymentId(appointment_id);
            }
          }
        },
        (_error) => {

        }
      );
    // this.provider_id = localStorage.getItem('provider_id');
    let notificationtype = {
      userid: this.userid,
      intended_userid: userid,
      appoint_id: appointment_id,
      n_type: appoint_status
    };

    this.server.request('POST', '/notification/notificationType', notificationtype)
      .subscribe(
        (_response) => {
          // console.log('notification type: ', notificationtype.n_type);
        },
        (_error) => {

        }
      );

    // this.notificationTypeService.NotificationtypeService(notificationtype)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         // console.log('notification type: ', notificationtype.n_type);
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
  }

  getPaymentId(appoint_id) {
    let query = `?appoint_id=${appoint_id}`;

    this.getPaymentIdByApointtId.GetpaymentidbyapointtidService(query)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.payment_id = _response.body.razorpay_payment_id;
            this.refund();
          }
        },
        (_error) => {

        });
  }

  getRefundId() {
    for (let i = 0; i < this.refundAppts.length; i++) {
      let query = `?appoint_id=${this.refundAppts[i].appoint_id}`
      this.getPaymentIdByApointtId.GetpaymentidbyapointtidService(query)
        .subscribe(
          (_response) => {
            if (_response.status === 200) {
              this.payment_id = _response.body.razorpay_payment_id;

              let found = this.appointments.find(appointment => appointment.appoint_id === this.refundAppts[i].appoint_id);
              let index = this.appointments.indexOf(found);
              this.appointments[index].refund_id = _response.body.refund_id;
            }
          },
          (_error) => {

          });
    }
  }

  refund() {
    let payment = {
      razorpay_payment_id: this.payment_id
    };

    this.razorPayService.refundPayment(payment)
      .subscribe(
        (__response) => {
          if (__response.status === 200) {
            this.toaster.success('Payment refunded successfully');

            let refund = {
              razorpay_payment_id: this.payment_id,
              refund_id: __response.body.id
            };
            this.razorPayService.refundId(refund)
              .subscribe(
                (_response) => {
                  if (_response.status === 200) {
                    console.log('refund id success');
                  }
                },
                (_error) => {

                });
          }
        },
        (_error) => {
          this.toaster.error('Unable to refund payment');
        });
  }

}
