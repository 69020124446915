import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetavailabletimeslotService {
  
  constructor(private httpService: HttpClient) { }
  GetavailabletimeslotService(queryParms) {
   const href = environment.api.getavailabletimeslot +queryParms;
   
  return this.httpService.get<any>(href, { 'observe': 'response' })
}


}
