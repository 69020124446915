import { Component, OnInit } from '@angular/core';
// import {GetproviderdetailspartnerService} from '../../../services/getproviderdetailspartner/getproviderdetailspartner.service'
import { ProfileServiceService } from '../../../services/getprofile/profile-service.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-partner-provider-profile-details',
  templateUrl: './partner-provider-profile-details.component.html',
  styleUrls: ['./partner-provider-profile-details.component.css']
})
export class PartnerProviderProfileDetailsComponent implements OnInit {
  subCategoriesService = [];
  provider_id = "";
  userid;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    // private GetproviderdetailspartnerService: GetproviderdetailspartnerService,
    private ProfileServiceService: ProfileServiceService,
    private http: HttpClient,
    private httpClient: HttpClient,
    private Router: Router,
    private server: JwtTestService,) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }

  }

  ngOnInit() {
    // this.GetproviderdetailspartnerService.getProviderDetailsPartner()
    //   .subscribe(
    //     (_response) => {
    //       this.providerProfile = _response;
    //     },
    //     (_error) => {

    //     });
    this.provider_id = localStorage.getItem('provider_id');

    this.userid = localStorage.getItem('userid');
    this.getProvider();
  }
  getProvider() {
    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = ``
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.subCategoriesService = _response.body;

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      )
  }

}
