import { Component, Inject, OnInit, EventEmitter, NgZone } from '@angular/core';
import { ProfileServiceService } from '../../services/getprofile/profile-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FavLikeService } from '../../services/like/fav-like.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { GetcategoryServiceService } from '../../services/getcategory/getcategory-service.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-provider-profile',
  templateUrl: './provider-profile.component.html',
  styleUrls: ['./provider-profile.component.css']
})
export class ProviderProfileComponent implements OnInit {
  boolean = {
    false: "2",
    true: "1"

  };
  selected: boolean;
  baseUrl = environment.baseUrl;
  subCategoriesService = [];
  provider_id = "";
  like_status = 1
  category = [];
  serviceCards = [];
  fbIcon = faFacebookSquare;
  l_userid;
  sub: any;
  // selected: boolean;
  // lat: number =18.50989;
  // long:number= 73.807182;
  zoom: number;
  lat: number;
  long: number;
  selectedChange = new EventEmitter<boolean>();

  constructor(private ProfileServiceService: ProfileServiceService,
    public dialog: MatDialog,
    private FavLikeService: FavLikeService,
    private route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private httpClient: HttpClient,
    private getcategoryServiceService: GetcategoryServiceService,
    private Router: Router, ) {
    this.selected = false;
  }

  ngOnInit() {
    this.getCurrentLocation();
    this.getCatergory();
    this.sub = this.route
      .params
      .subscribe(params => {
        console.log(params);
        // Defaults to 0 if no query param provided.
        this.provider_id = params.provider_id || 1;
        console.log('provider_id query param', this.provider_id);
        // console.log('model  query param', this.model);
      });
    this.l_userid = localStorage.getItem('userid');
    this.getProvider();

    console.log('data', 'response');
  }

  getProvider() {
    // this.provider_id = localStorage.getItem('provider_id');
    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${this.l_userid}`
    // this.ProfileServiceService.ProfileServiceService(queryParms,l_userid)
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.subCategoriesService = _response.body;

              if (_response.body[0].fav_status == '1') {
                this.selected = true;
              } else {
                this.selected = false;
              }

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      )
  }

  getCatergory() {
    // this.urlQueryParam = `?category_id=${this.category_id}`;
    this.getcategoryServiceService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.category = _response.body;
            } catch (err) {
            }
          }
        },
        (_error) => {

        }

      )
  }

  private getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }
  placeMarker() {
    navigator.geolocation.getCurrentPosition((position) => {
      //this.lat=($event.coords.latitude);
      //this.long=($event.coords.longitude);
      this.lat = position.coords.latitude;
      this.long = position.coords.longitude;
      //this.Router[("http://maps.google.com/?lat,long")];
      // return this.http.get("http://maps.google.com/?lat,long");
      // this.Router.navigate(['http://maps.google.com/?q=<lat>,<long>']);
      let url = this.baseUrl + + '/lat/long';
      return this.httpClient.get<JSON>(url);
    });

  }

  public toggleSelected() {
    this.selected = !this.selected
    if (this.selected) {
      this.like_status = 1
    } else {
      this.like_status = 2
    }
    let queryparams = {
      userid: localStorage.getItem('userid'),
      provider_id: localStorage.getItem('provider_id'),
      provider_like_status: this.like_status
    }
    this.FavLikeService.FavLikeService(queryparams)
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.serviceCards = _response.body;
              // shop_id => this.shop_id = shop_id
            } catch (err) {

            }
          }
        },
        (_error) => {

        }
      )
    // this.selected = !this.selected;
    // this.selectedChange.emit(this.selected);
  }

}


