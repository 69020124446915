import { Component, OnInit } from '@angular/core';
import { BankDetailsService } from '../../../services/bank-details/bank-details.service';
import { FormControl, FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { query } from '@angular/animations';
import { debounceTime, tap, switchMap, finalize, flatMap } from 'rxjs/operators';
import { AddproviderbankdetailsService } from '../../../services/addproviderbankdetails/addproviderbankdetails.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-settlement-case',
  templateUrl: './settlement-case.component.html',
  styleUrls: ['./settlement-case.component.css']
})
export class SettlementCaseComponent implements OnInit {
  bank_ifsc: string;
  bankdetail: FormGroup
  bankInfo = [];
  isLoading;
  settlement_array = [];
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private BankDetailsService: BankDetailsService,
    private toaster: ToastrService,
    private addProviderBankDetails: AddproviderbankdetailsService,
    private server: JwtTestService,
    private router: Router,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.bankdetail = new FormGroup({
      Full_name: new FormControl('', [Validators.required]),
      Account_number: new FormControl('', [Validators.required]),
      bank_ifsc: new FormControl('', [Validators.required]),
    });

    this.settlement_array = JSON.parse(localStorage.getItem('settlement_array'));

    // this.bankdetail.controls['bank_ifsc']
    //   .valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => this.isLoading = true),
    //     switchMap(value => this.BankDetailsService.BankDetailsService({ bank_ifsc: value })
    //       .pipe(
    //         finalize(() => this.isLoading = false),
    //       ))
    //   )
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.bankInfo = _response.body;
    //       } else {
    //         this.bankInfo = [];
    //       }
    //     },
    //     (_error) => {
    //       this.bankInfo = [];
    //     });
  }
  getbankdetail() {
    // let countQuery = `?bank_ifsc=${bankdetailvalue.bank_ifsc}`;
    let countQuery = {
      bank_ifsc: this.bankdetail.controls['bank_ifsc'].value
    };
    this.server.request('GET', '/providers/getBankDetails', countQuery)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.bankInfo = _response.body;
          }
        },
        (_error) => {

        });
    // this.BankDetailsService.BankDetailsService({ bank_ifsc: this.bankdetail.controls['bank_ifsc'].value })
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         // this.toaster.success('success');
    //         this.bankInfo = _response.body;
    //       }
    //     }, (_error) => {

    //     }
    //   );
  }

  addBankDetails() {
    console.log('setttt: ', this.settlement_array);

    let bank = {
      provider_id: localStorage.getItem('provider_id'),
      userid: localStorage.getItem('userid'),
      bank_ifsc: this.bankdetail.controls['bank_ifsc'].value,
      account_number: this.bankdetail.controls['Account_number'].value,
      account_name: this.bankdetail.controls['Full_name'].value,
      bank_branch: this.bankInfo[0].bank_branch,
      bank_address: this.bankInfo[0].bank_address,
      bank_name: this.bankInfo[0].bank_name,
      bank_city: this.bankInfo[0].bank_city,
      bank_district: this.bankInfo[0].bank_district,
      bank_state: this.bankInfo[0].bank_state,
      cost: localStorage.getItem('month_gst_total_online_amount'),
      settlement_start_date_time: this.settlement_array[0].start_datetime,
      settlement_end_date_time: this.settlement_array[this.settlement_array.length - 1].end_datetime,
    };

    this.server.request('POST', '/providers/addProviderBankDetails', bank)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Bank details added successfully');
          let settlement_id = _response.settlement_id;

          let settlement = {
            settlement_array: this.settlement_array,
            settlement_id: settlement_id
          };

          this.server.request('POST', '/providers/addingSettlementIdForProvider', settlement)
          .subscribe(
            (_response: any) => {
                console.log('settlement id added');
            },
            (_error) => {
              console.log('error: ', _error);
            });

          this.router.navigate(['/provider-dashboard/payment-details']);
        },
        (_error) => {
          this.toaster.error('Could not add bank details');
        });

    // this.addProviderBankDetails.AddproviderbankdetailsService(bank)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.toaster.success('Bank details added successfully');
    //       }
    //     },
    //     (_error) => {
    //       this.toaster.error('Could not add bank details');
    //     });
  }

  displaySearch(bank: any) {
    if (bank) { return bank.bank_name; }
  }

}
