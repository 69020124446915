import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetappointmentbyproviderService } from '../../../services/getappointmentbyprovider/getappointmentbyprovider.service';
import * as moment from 'moment';
import { GetavailabletimeslotService } from '../../../services/getavailabletimeslot/getavailabletimeslot.service';
import { BehaviorSubject } from 'rxjs';
import { RescheduleappointmentService } from '../../../services/rescheduleappointment/rescheduleappointment.service';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import * as range from 'lodash.range';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

@Component({
  selector: 'app-rescheduler',
  templateUrl: './rescheduler.component.html',
  styleUrls: ['./rescheduler.component.css']
})

export class ReschedulerComponent implements OnInit {
  provider_id = '';
  currentdatetime = moment();
  current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
  today = moment();
  dateValid = false;
  datePicker = moment();
  public currentDate: moment.Moment;
  public selectedDate;
  public weeks: Array<CalendarDate> = [];
  morningArray = [];
  afternoonArray = [];
  eveningArray = [];
  nightArray = [];
  online_consultation_type;
  available_day = [];
  breakTimeArray = [];
  businessName;
  addressText;
  provider_name;
  working_hours;
  working_week_array = [];
  holiday_array;
  personal_leave_array;
  available_week_date = [];
  show = true;
  showBreak = true;
  getavailabletimeslot = [];
  date = '';
  format = 'h:mm A';
  morningBefore = moment('5:30 AM', this.format);
  morningAfter = moment('12:00 PM', this.format);
  afternoonBefore = moment('11:30 AM', this.format);
  afternoonAfter = moment('4:00 PM', this.format);
  eveningBefore = moment('3:30 PM', this.format);
  eveningAfter = moment('8:00 PM', this.format);
  nightBefore = moment('7:30 PM', this.format);
  nightAfter = moment('12:00 AM', this.format);
  month_slot_array;
  three_month_valid;
  public namesOfDays = ['Sun', 'Mon', ' Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getappointmentbyproviderService: GetappointmentbyproviderService,
    private getavailabletimeslotService: GetavailabletimeslotService,
    private rescheduleAppointmentService: RescheduleappointmentService,
    private toaster: ToastrService,
    private server: JwtTestService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');

    this.datePicker = sessionStorage.getItem('appointmentDate') ? moment(sessionStorage.getItem('appointmentDate')) : moment();
    this.current_date_time = this.datePicker.format('ddd, DD MMM');

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    this.getTimeSlots(this.datePicker.format('YYYY-MM-DD'));
    this.getMonthSlots(this.datePicker.format('YYYY-MM-DD'));

    this.currentDate = moment();
    this.selectedDate = moment(this.currentDate).format('DD-MM-YYYY');
    this.generateCalendar();

  }

  getMonthSlots(startDate, address_id?) {
    let month = moment(startDate).format('MM');

    let queryParms = `?provider_id=${this.provider_id}&start_date=${startDate}&month=${month}`;
    address_id && address_id != '0' ? queryParms += `&address_id=${address_id}` : ``;

    this.getavailabletimeslotService.GetavailabletimeslotService(queryParms)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.month_slot_array = _response.body.data.month_slot_array ? _response.body.data.month_slot_array : [];
          }
        },
        (_error) => {

        });
  }

  getTimeSlots(startDate, address_id?) {
    this.morningArray = [];
    this.afternoonArray = [];
    this.eveningArray = [];
    this.nightArray = [];
    this.online_consultation_type = [];

    let queryParms = `?provider_id=${this.provider_id}&start_date=${startDate}&year_month=${moment(startDate).format('YYYYMM')}`;
    address_id && address_id != '0' ? queryParms += `&address_id=${address_id}` : ``;

    this.getavailabletimeslotService.GetavailabletimeslotService(queryParms)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_response.status === 200) {
            try {
              if (_response.body) {
                this.available_day = _response.body.data.availableDay;
                this.breakTimeArray = _response.body.data.breakArray;
                this.businessName = _response.body.data.businessName;
                this.addressText = _response.body.data.addressText;
                this.provider_name = _response.body.data.name;
                this.working_hours = _response.body.data.workingTime;
                this.online_consultation_type = _response.body.data.online_consultation_type;
                this.working_week_array = _response.body.data.availableDate;
                this.holiday_array = _response.body.data.holiday_list ? _response.body.data.holiday_list.split(' ') : [];
                this.personal_leave_array = _response.body.data.personal_leaves ? _response.body.data.personal_leaves.split(' ') : [];
                this.available_week_date = _response.body.data.available_week_date ? _response.body.data.available_week_date : [];

                // if (typeof (this.online_consultation_type) == 'number') {
                //   if (this.online_consultation_type == 0) {
                //     this.showAddress(this.online_consultation_type);
                //   }
                // } else if (typeof (this.online_consultation_type) == 'string') {
                //   let online = this.online_consultation_type.split(' ');
                //   online.pop();
                //   if (online.length == 1 && online[0] == '0') {
                //     this.showAddress(online[0]);
                //   }
                // }

                if (!_response.body.data.unionArray) {
                  this.show = false;
                  if (this.breakTimeArray.length > 0) {
                    if (this.breakTimeArray[0].StartTime == 'Invalid date' && this.breakTimeArray[0].EndTime == 'Invalid date') {
                      this.showBreak = false;
                    } else {
                      this.showBreak = true;
                    }
                  } else if (this.breakTimeArray.length == 0) {
                    this.showBreak = false;

                  }
                } else {
                  this.show = true;
                  this.getavailabletimeslot = _response.body.data.unionArray;
                  this.date = moment(startDate).format("YYYY-MM-DD");

                  for (let i = this.getavailabletimeslot.length - 1; i >= 0; i--) {
                    let time = moment(this.getavailabletimeslot[i].StartTime, this.format);
                    if (this.breakTimeArray.length > 0) {
                      if (this.breakTimeArray[0].StartTime == 'Invalid date' && this.breakTimeArray[0].EndTime == 'Invalid date') {
                        this.showBreak = false;
                      }
                    } else if (this.breakTimeArray.length == 0) {
                      this.showBreak = false;
                    }
                  }

                  for (let i = 0; i < this.getavailabletimeslot.length; i++) {
                    let time = moment(this.getavailabletimeslot[i].StartTime, this.format);

                    if (time.isBetween(this.morningBefore, this.morningAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.morningArray.push(this.getavailabletimeslot[i]);

                    } else if (time.isBetween(this.afternoonBefore, this.afternoonAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.afternoonArray.push(this.getavailabletimeslot[i]);

                    } else if (time.isBetween(this.eveningBefore, this.eveningAfter)) {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.eveningArray.push(this.getavailabletimeslot[i]);

                    } else {
                      if (time.isSameOrBefore(moment(this.today, this.format)) && (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today))) {
                        this.getavailabletimeslot[i].currentFlag = 'true';
                      } else {
                        this.getavailabletimeslot[i].currentFlag = 'false';
                      }
                      this.nightArray.push(this.getavailabletimeslot[i]);
                    }
                  }
                }
              }
            } catch (err) {

            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_error.status === 409) {
            this.show = false;
          }
        }
      )
  }

  handleClick(selected, current_date_time) {
    let startDate, endDate, min;
    if (selected == 'sub') {

      startDate = moment(current_date_time).subtract(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).subtract(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment();
      this.currentdatetime = moment(current_date_time).subtract(1, 'days') || moment();
      min = 30;
      this.datePicker = moment(current_date_time).subtract(1, 'days');
      this.selectedDate = moment(current_date_time).subtract(1, 'days').format('DD-MM-YYYY');
    }
    if (selected == 'add') {
      startDate = moment(current_date_time).add(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time).add(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment()
      this.currentdatetime = moment(current_date_time).add(1, 'days') || moment();
      min = 30;
      this.datePicker = moment(current_date_time).add(1, 'days');
      this.selectedDate = moment(current_date_time).add(1, 'days').format('DD-MM-YYYY');
    }
    //Display Reasons
    this.current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrAfter(moment().add(90, 'day'), 'day')) {
      this.three_month_valid = false;
    } else {
      this.three_month_valid = true;
    }

    this.ngxService.start();

    this.getTimeSlots(startDate);
    this.getMonthSlots(startDate);
    this.generateCalendar();
  }

  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  private fillDates(currentMoment: moment.Moment) {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const lastOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'day');
    const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'day').add(7, 'day');
    const startCalendar = firstDayOfGrid.date();

    return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'day')).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        mDate: newDate,
      };
    });
  }

  private isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  private isSelected(date: moment.Moment): boolean {
    return this.selectedDate === moment(date).format('DD-MM-YYYY');
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'month');
    this.current_date_time = moment(this.currentDate).format('ddd, DD MMM');
    this.datePicker = moment(this.currentDate);
    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    this.generateCalendar();
    this.getTimeSlots(this.currentDate.format('YYYY-MM-DD'));
    this.getMonthSlots(this.currentDate.format('YYYY-MM-DD'));
  }

  public nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'month');
    this.current_date_time = moment(this.currentDate).format('ddd, DD MMM');
    this.datePicker = moment(this.currentDate);

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }
    this.generateCalendar();
    this.getTimeSlots(this.currentDate.format('YYYY-MM-DD'));
    this.getMonthSlots(this.currentDate.format('YYYY-MM-DD'));
  }

  public isDisabledMonth(currentDate): boolean {
    const today = moment();
    return moment(currentDate).isSame(today.add(90, 'days'), 'month');
  }

  public isDisabledPreviousMonth(currentDate): boolean {
    const today = moment();
    return moment(currentDate).isSameOrBefore(today, 'month');
  }

  public isDisabledDay(date: moment.Moment): boolean {
    if (this.available_day) {
      for (let i = 0; i < this.available_day.length; i++) {
        if (date.format('dddd') == this.available_day[i]) {
          return false;
        }
      }
    } else if (this.working_week_array) {
      for (let i = 0; i < this.working_week_array.length; i++) {
        if (date.date() == this.working_week_array[i]) {
          return false;
        }
      }
    } else if (this.available_week_date) {
      for (let i = 0; i < this.available_week_date.length; i++) {
        if (date.date() == this.available_week_date[i]) {
          return false;
        }
      }
    }

    return true;
  }

  public isSelectedMonth(date: moment.Moment): boolean {
    const today = moment();
    return moment(date).isSame(this.currentDate, 'month') && moment(date).isSameOrAfter(today, 'date');
  }

  public selectDate(date: CalendarDate) {
    let scheduler = document.getElementById('scheduler');
    let calendar = document.getElementById('calendar');
    let back_button = document.getElementById('back_button');

    scheduler.className = 'col-md-12 col-sm-12 col-lg-6 d-block';
    calendar.className = 'col-md-12 col-sm-12 col-lg-6 d-none d-md-block';
    back_button.className = 'd-block d-md-none';

    this.selectedDate = moment(date.mDate).format('DD-MM-YYYY');
    let startDate = moment(date.mDate).format('YYYY-MM-DD');
    this.current_date_time = moment(date.mDate).format("ddd, DD MMM");
    this.currentdatetime = moment(date.mDate);
    this.datePicker = moment(date.mDate)

    this.generateCalendar();

    if (moment(this.current_date_time, 'ddd DD MMM').isSameOrBefore(this.today)) {
      this.dateValid = false;
    } else {
      this.dateValid = true;
    }

    if (moment(this.current_date_time, 'ddd DD MMM').isSame(moment().add(90, 'day'), 'day')) {
      this.three_month_valid = false;
    } else {
      this.three_month_valid = true;
    }

    this.ngxService.start();
    this.getTimeSlots(startDate);
    this.getMonthSlots(startDate);
  }

  public isHoliday(date: moment.Moment): boolean {
    if (this.holiday_array) {
      for (let i = 0; i < this.holiday_array.length; i++) {
        if (moment(date).format('YYYY-MM-DD') == this.holiday_array[i]) {
          return true;
        }
      }
    }

    return false;
  }

  public isPersonalHoliday(date: moment.Moment): boolean {
    if (this.personal_leave_array) {
      for (let i = 0; i < this.personal_leave_array.length; i++) {
        if (moment(date).format('YYYY-MM-DD') == this.personal_leave_array[i]) {
          return true;
        }
      }
    }

    return false;
  }

  public checkBooked50(date: moment.Moment): boolean {
    if (this.month_slot_array) {
      for (let i = 0; i < this.month_slot_array.length; i++) {
        if (this.month_slot_array[i].Date == date.format('YYYY-MM-DD') && (this.month_slot_array[i].Count >= 50 && this.month_slot_array[i].Count < 80)) {
          return true;
        }
      }
    }
    return false;
  }

  public checkBooked80(date: moment.Moment): boolean {
    if (this.month_slot_array) {
      for (let i = 0; i < this.month_slot_array.length; i++) {
        if (this.month_slot_array[i].Date == date.format('YYYY-MM-DD') && this.month_slot_array[i].Count >= 80) {
          return true;
        }
      }
    }
    return false;
  }

  goBack() {
    let scheduler = document.getElementById('scheduler');
    let calendar = document.getElementById('calendar');
    let back_button = document.getElementById('back_button');

    scheduler.className = 'col-md-6 d-none d-md-block';
    calendar.className = 'col-md-6';
    back_button.className = 'd-none d-md-none';
  }

  rescheduleAppointment(date, startTime, endTime) {
    let rescheduleArray = [];
    let appt = {
      appoint_id: localStorage.getItem('appoint_id'),
      start_datetime: date + ' ' + startTime,
      end_datetime: date + ' ' + endTime
      // start_datetime: moment(this.events[i].start).format('YYYY-MM-DD h:mm A'),
      // end_datetime: moment(this.events[i].end).format('YYYY-MM-DD h:mm A'),
    };

    rescheduleArray.push(appt);
    let reschedule = {
      dt: JSON.stringify(rescheduleArray),
      provider_id: this.provider_id,
    };

    console.log('reschedule: ', reschedule);

    this.server.request('POST', '/appointment/rescheduleAppointment', reschedule)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Your appointment has been rescheduled');
          this.router.navigate(['/provider-dashboard']);

          this.getTimeSlots(date);
        },
        (_error) => {
          this.toaster.error('Could not reschedule appointment');
        });
  }
}

/* OLD VERSION */
/* import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GetappointmentbyproviderService } from '../../../services/getappointmentbyprovider/getappointmentbyprovider.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { GetavailabletimeslotService } from '../../../services/getavailabletimeslot/getavailabletimeslot.service';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView, CalendarWeekViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent } from 'angular-calendar';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RescheduleappointmentService } from '../../../services/rescheduleappointment/rescheduleappointment.service';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'

export const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  green: {
    primary: '#0cc73e',
    secondary: '#bafdcc',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  grey: {
    primary: '#96999e',
    secondary: '#ced1d6'
  }
};

export interface Appointment {
  userid: string;
  appoint_id: string;
  start_datetime: string;
  end_datetime: string;
  name: string;
  cost: string;
  date: string;
  apoint_status: number;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'ddd, DD MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-rescheduler',
  templateUrl: './rescheduler.component.html',
  styleUrls: ['./rescheduler.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ReschedulerComponent implements OnInit, OnDestroy {
  appointments: Appointment[] = [];
  rescheduledAppointments: Appointment[] = [];
  provider_id = '';
  today = moment().format('YYYY-MM-DD');
  appointmentavailable = true;
  datePicker = new FormControl(moment());
  datePicker2 = new FormControl(moment());
  minDate: Date;
  timeSlots = [];

  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Day;
  viewDate: Date = new Date();
  events$: Observable<CalendarEvent<{ appointment: Appointment }>[]>;
  events: CalendarEvent[] = [];
  externalEvents: CalendarEvent[] = [];
  refresh = new Subject<void>();
  difference;
  dayStartHour;
  dayEndHour;
  dayStartMinute;
  dayEndMinute;
  intervalId;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getappointmentbyproviderService: GetappointmentbyproviderService,
    private getavailabletimeslotService: GetavailabletimeslotService,
    private http: HttpClient,
    private rescheduleAppointmentService: RescheduleappointmentService,
    private toaster: ToastrService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.minDate = moment(this.today).toDate();
    this.getAppointments();
    this.getTimeSlots(this.today);

    this.intervalId = setInterval(() => {
      this.getAppointments();
      this.getTimeSlots(this.today);
    }, 5000);

    // let queryParam = `?provider_id=${this.provider_id}&date=${this.today}`;
    // this.getappointmentbyproviderService.GetappointmentbyproviderService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.appointments = _response.body.data.results;
    //         this.difference = moment(this.appointments[0].end_datetime).diff(moment(this.appointments[0].start_datetime), 'minutes');

    //         this.externalEvents = [];
    //         for (let i = 0; i < this.appointments.length; i++) {
    //           let event = {
    //             title: this.appointments[i].name,
    //             start: moment(this.appointments[i].start_datetime).toDate(),
    //             end: moment(this.appointments[i].end_datetime).toDate(),
    //             color: (this.appointments[i].apoint_status == 0 ? colors.yellow
    //               : this.appointments[i].apoint_status == 1 ? colors.green
    //                 : colors.red),
    //             draggable: true,
    //             allDay: false,
    //             meta: this.appointments[i].appoint_id,
    //           }

    //           this.externalEvents.push(event);
    //         }
    //         this.refresh.next();
    //       }
    //     },
    //     (_error) => {
    //     }
    //   );
  }

  getTimeSlots(startDate) {
    this.timeSlots = [];
    this.events = [];
    startDate = moment(startDate).format('YYYY-MM-DD');
    let queryParms = `?provider_id=${this.provider_id}&start_date=${startDate}`;
    this.getavailabletimeslotService.GetavailabletimeslotService(queryParms)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            try {
              if (_response.body) {
                this.dayStartHour = moment(_response.body.data.workingTime[0].StartTime, 'h:mm A').toDate().getHours();
                this.dayEndHour = moment(_response.body.data.workingTime[0].EndTime, 'h:mm A').toDate().getHours();
                this.dayStartMinute = moment(_response.body.data.workingTime[0].StartTime, 'h:mm A').toDate().getMinutes();
                this.dayEndMinute = moment(_response.body.data.workingTime[0].EndTime, 'h:mm A').toDate().getMinutes();

                if (_response.body.data.unionArray) {
                  this.difference = moment(_response.body.data.unionArray[0].EndTime, 'h:mm A').diff(moment(_response.body.data.unionArray[0].StartTime, 'h:mm A'), 'minutes');
                  let arrLen = _response.body.data.unionArray.length;

                  for (let i = 0; i < _response.body.data.unionArray.length; i++) {
                    if (_response.body.data.unionArray[i].availableFlag == false) {
                      this.timeSlots.push(_response.body.data.unionArray[i]);
                    }
                  }
                  console.log('difference: ', this.difference);

                  for (let i = 0; i < this.timeSlots.length; i++) {
                    let time = {
                      title: 'Booked',
                      start: moment(startDate + ' ' + this.timeSlots[i].StartTime, 'YYYY-MM-DD h:mm A').toDate(),
                      end: moment(startDate + ' ' + this.timeSlots[i].StartTime, 'YYYY-MM-DD h:mm A').add(this.difference, 'minutes').toDate(),
                      draggable: false,
                      color: colors.grey,
                    };

                    this.events.push(time);
                  }
                  console.log('timeSlots: ', this.events);
                  this.refresh.next();
                }
                this.refresh.next();
              }
            } catch (err) {
              console.log('error: ', err);
            }
          }
        },
        (_error) => {

        }
      );
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   console.log('event: ', event);
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex);
  //   }
  // }

  change(event) {
    let startDate = moment(event.value).format('YYYY-MM-DD');

    this.getAppointments(startDate);
  }

  // change2(event) {
  //   let startDate = moment(event.value).format('YYYY-MM-DD');

  //   this.getTimeSlots(startDate);
  // }

  getAppointments(startDate?): void {
    startDate ? startDate = moment(startDate).format('YYYY-MM-DD') : '';
    // let queryParam = `?provider_id=${this.provider_id}`;
    // queryParam += startDate ? `&date=${startDate}` : `&date=${this.today}`;

    let queryParam = {
      provider_id: this.provider_id,
      date: startDate ? startDate : this.today
    };

    this.server.request('GET', '/appointment/getBookedAppointmentByProviderId', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            if (_response.body.data == 'Not Found') {
              this.externalEvents = [];
              this.refresh.next();

            } else {
              this.appointments = _response.body.data.results;
              this.difference = moment(this.appointments[0].end_datetime).diff(moment(this.appointments[0].start_datetime), 'minutes');

              this.externalEvents = [];
              for (let i = 0; i < this.appointments.length; i++) {
                if (this.appointments[i].apoint_status == 0 && moment(this.appointments[i].start_datetime).isAfter(moment(), 'minute')) {
                  let event = {
                    title: this.appointments[i].name,
                    start: moment(this.appointments[i].start_datetime).toDate(),
                    end: moment(this.appointments[i].end_datetime).toDate(),
                    color: (this.appointments[i].apoint_status == 0 ? colors.yellow
                      : this.appointments[i].apoint_status == 1 ? colors.green
                        : colors.red),
                    draggable: true,
                    allDay: false,
                    meta: {
                      appoint_id: this.appointments[i].appoint_id,
                      appoint_status: this.appointments[i].apoint_status,
                      start: moment(this.appointments[i].start_datetime).format('h:mm A')
                    },
                  }

                  this.externalEvents.push(event);
                }

              }
              this.refresh.next();
            }
          }
        },
        (_error) => {

        });

    // this.getappointmentbyproviderService.GetappointmentbyproviderService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200 && _response.body.data == 'Not Found') {
    //         this.externalEvents = [];
    //         this.refresh.next();

    //       } else if (_response.status === 200) {
    //         this.appointments = _response.body.data.results;
    //         this.difference = moment(this.appointments[0].end_datetime).diff(moment(this.appointments[0].start_datetime), 'minutes');

    //         this.externalEvents = [];
    //         for (let i = 0; i < this.appointments.length; i++) {
    //           if (this.appointments[i].apoint_status != 2) {
    //             let event = {
    //               title: this.appointments[i].name,
    //               start: moment(this.appointments[i].start_datetime).toDate(),
    //               end: moment(this.appointments[i].end_datetime).toDate(),
    //               color: (this.appointments[i].apoint_status == 0 ? colors.yellow
    //                 : this.appointments[i].apoint_status == 1 ? colors.green
    //                   : colors.red),
    //               draggable: true,
    //               allDay: false,
    //               meta: {
    //                 appoint_id: this.appointments[i].appoint_id,
    //                 appoint_status: this.appointments[i].apoint_status,
    //                 start: moment(this.appointments[i].start_datetime).format('h:mm A')
    //               },
    //             }

    //             this.externalEvents.push(event);
    //           }

    //         }
    //         this.refresh.next();
    //       }
    //     },
    //     (_error) => {
    //     }
    //   );

    // startDate ? startDate = moment(startDate).format('YYYY-MM-DD') : '';
    // const params = new HttpParams()
    //   .set('provider_id', this.provider_id)
    //   .set('date', startDate ? startDate : this.today);

    // this.events$ = this.http
    //   .get(environment.api.getappointmentbyprovider, { params })
    //   .pipe(
    //     map(({ data }: { data }) => {
    //       if (data == 'Not Found') {
    //         return;
    //       } else {
    //         return data.results.map((appointment: Appointment) => {
    //           this.difference = moment(appointment.end_datetime).diff(moment(appointment.start_datetime), 'minutes');
    //           return {
    //             title: appointment.name,
    //             start: moment(appointment.start_datetime).toDate(),
    //             end: moment(appointment.end_datetime).toDate(),
    //             color: (appointment.apoint_status == 0 ? colors.yellow
    //               : appointment.apoint_status == 1 ? colors.green
    //                 : colors.red),
    //             draggable: false,
    //             allDay: false,
    //             meta: appointment.appoint_id,
    //           };
    //         });
    //       }
    //     })
    //   );

    // this.events$.subscribe((_error: any) => {
    //   if (_error.status === 409) {
    //     this.events = []
    //   }
    // });

    // this.events$.forEach((appointment) => {
    //   this.externalEvents = [];
    //   if (appointment) {
    //     for (let i = 0; i < appointment.length; i++) {
    //       this.externalEvents.push(appointment[i]);
    //     }
    //     this.refresh.next();
    //   }
    // });
  }

  eventDropped({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    console.log('event dropped: ', event, ' newStart: ', newStart, ' newEnd: ', newEnd);
    const externalIndex = this.externalEvents.indexOf(event);

    if (externalIndex > -1) {
      this.externalEvents.splice(externalIndex, 1);
      this.events.push(event);
    } 

    if (moment(newStart).isAfter(moment())) {
      event.start = newStart;
      event.end = moment(event.start).add(this.difference, 'minutes').toDate();
  
      if (this.view === 'month') {
        this.viewDate = newStart;
      }
      this.events = [...this.events];
      this.refresh.next();
      console.log('event drop: ', this.events);
      this.rescheduleAppointment();
    } else {
      this.toaster.error('Cannot reschedule appointment before current time');
    }
    
  }

  externalDrop(event: CalendarEvent) {
    console.log('externalDrop: ', event);
    if (this.externalEvents.indexOf(event) === -1) {
      this.events = this.events.filter((iEvent) => iEvent !== event);
      this.externalEvents.push(event);
    }
  }

  rescheduleAppointment() {
    let rescheduleArray = [];
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].title !== 'Booked') {
        let appt = {
          appoint_id: this.events[i].meta.appoint_id,
          start_datetime: moment(this.events[i].start).format('YYYY-MM-DD h:mm A'),

          end_datetime: moment(this.events[i].end).format('YYYY-MM-DD h:mm A'),
          // appoint_status: 1
        };

        rescheduleArray.push(appt);
      }
    }
    let reschedule = {
      dt: JSON.stringify(rescheduleArray),
      provider_id: this.provider_id,
    };

    console.log('reschedule: ', reschedule);

    this.server.request('POST', '/appointment/rescheduleAppointment', reschedule)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Your appointment has been rescheduled');
          this.getAppointments(this.today);

          this.events = [];
          this.getTimeSlots(this.today);
        },
        (_error) => {
          this.toaster.error('Could not reschedule appointment');
        });

    // this.rescheduleAppointmentService.RescheduleappointmentService(reschedule)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.toaster.success('Your appointment has been rescheduled');
    //         this.getAppointments(this.today);

    //         this.events = [];
    //         this.getTimeSlots(this.today);
    //       }
    //       // else {
    //       //   this.toaster.error('Could not reschedule appointment');
    //       // }
    //     },
    //     (_error) => {
    //       this.toaster.error('Could not reschedule appointment');
    //     });
  }

} */
