import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {

  constructor(private httpService: HttpClient) { }

  ChangepasswordService(data) {
    const href = environment.api.changepassword;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
