import { Component, OnInit,Input } from '@angular/core';
import {GetProvideServiceService} from '../../../services/getProvider/get-provide-service.service'

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  
  @Input('provider_name') provider_name:string ;
  @Input('service') service:any;
  @Input('serviceName') serviceName:string;
  // @Input('service.serviceName')serviceName:any;
  
  constructor( private getProvideServiceService:GetProvideServiceService) { }

  ngOnInit() {
    let QueryParams = '';
    // let L_userid='';
    this.getProvideServiceService.GetProvideServiceService(QueryParams)
    .subscribe(
     (_response) => {
       console.log(_response);
       if (_response.status === 200) {
         try {
           this.getProvideServiceService = _response.body;
           // shop_id => this.shop_id = shop_id
         } catch (err) {
 
         }
       }
     },
     (_error) => {
 
     }
   )
      console.log('data', 'response');
    }
    
  }


