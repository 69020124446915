import { Component, OnInit } from '@angular/core';
import { GetallsubscriptionService } from '../../../services/getallsubscription/getallsubscription.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  allSubscriptions = [
    { planId: '', planName: '', planSubArray: [] }
  ];

  constructor(
    private getAllSubcription: GetallsubscriptionService,
  ) { }

  ngOnInit() {
    this.getAllSubscriptions();
  }

  getAllSubscriptions() {
    this.getAllSubcription.GetallsubscriptionService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.allSubscriptions = _response.body;
          }
        },
        (_error) => {

        });
  }

}
