import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetproviderdetailsService {
  private loggedIn = false;
  private token: string;

  constructor(private httpService: HttpClient) { }

  GetproviderdetailsService(data?) {
    const href = environment.api.getproviderdetails;
    return this.httpService.post<any>(href, data, { 'observe': 'response' });
  }

  setLoggedIn(loggedIn: boolean, token?: string) {
    this.loggedIn = loggedIn;
    this.token = token;
  }

  GetsearchfilterService(query) {
    const href = environment.api.getproviderdetails;
    const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    return this.httpService.request('POST', href, {
      body: { provider_user_id: query.provider_user_id },
      responseType: 'json',
      observe: 'response',
      headers: header
    });
    //  return this.httpService.post<any>(href, {provider_user_id:query.provider_user_id},  { 'observe': 'response' })
  }
}