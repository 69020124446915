import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

  constructor(private httpService: HttpClient) { }

  forgotPasswordByMobile(query) {
    const href = environment.api.forgotpasswordbymobile;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }

  verifyOtpByOtp(query) {
    const href = environment.api.verifyotpbyotp;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }

  generateNewPassword(data) {
    const href = environment.api.generatepasswordbymobile;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
