import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { ElementRef, VERSION, ViewChild } from '@angular/core';
import { style } from '@angular/animations';

@Component({
  selector: 'app-ds-navbar',
  templateUrl: './ds-navbar.component.html',
  styleUrls: ['./ds-navbar.component.css']
})
export class DsNavbarComponent  {
  navbarOpen = false;
   toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
   }
  constructor() { }

  ngOnInit() {

  }

}
