import { Component, OnInit, NgZone, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormControlDirective, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { COMMA, ENTER, SPACE, DASH } from '@angular/cdk/keycodes';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import * as moment from 'moment';
import { GetContactInfoService } from '../../../services/contact-info/get-contact-info.service';
import { ContactInfoService } from '../../../services/contact-info/contact-info.service';
import { AddproviderimagesService } from '../../../services/addproviderimages/addproviderimages.service';
import { GetsettingsService } from '../../../services/getsettings/getsettings.service';
import { DeleteproviderphotoService } from '../../../services/deleteproviderphoto/deleteproviderphoto.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompanyProfileService } from '../../../services/company-profile.service';
import { GetscheduletimingsService } from '../../../services/getscheduletimings/getscheduletimings.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GetAllLanguagesService } from '../../../services/getAllLanguages/get-all-languages.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';

interface salutation {
  value: string,
  viewValue: string
}

export interface Language {
  name: String
}

export interface Degree {
  name: String
}

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css'],
  providers: [ConfirmationService,],
  // encapsulation: ViewEncapsulation.None,
})
export class AccountSettingsComponent implements OnInit {
  provider_id = '';
  userid = '';
  ownerForm: FormGroup;
  salutations: string[] = ['Mr', 'Mrs', 'Adv', 'Dr', 'Miss'];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  languages = [];
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  citycountry = [];
  filesToUpload: Array<File> = [];
  urls: any[] = [];
  // settings = [];
  first_name;
  middle_name;
  last_name;
  title;
  provider_experience;
  address_text;
  mobile_number;
  gender;
  salutationValue;
  postcode;
  specific_address;
  provider_url;
  photo_id;
  msgs: Message[] = [];
  business_name;
  cost;
  degrees = [];
  qualification_id;
  qualification_name;
  address_id;
  online_consultation_type;
  break_start_time;
  break_end_time;
  languageCtrl = new FormControl();
  getLanguages = [];
  filteredLanguages: Observable<string[]>;
  markerMoved = false;
  google_address = '';
  showOnlineNote = false;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  @ViewChild('languageInput', { static: true }) languageInput: ElementRef<HTMLInputElement>;
  @ViewChild('languageAuto', { static: true }) languageAuto: MatAutocomplete;

  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('searchLocation', { static: true })
  public searchElementRef: ElementRef;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private getContactInfoService: GetContactInfoService,
    private contactInfoService: ContactInfoService,
    private addProviderImagesService: AddproviderimagesService,
    private getSettingsService: GetsettingsService,
    private deleteProviderPhoto: DeleteproviderphotoService,
    private toaster: ToastrService,
    private confirmationService: ConfirmationService,
    private ngxService: NgxUiLoaderService,
    private companyProfileService: CompanyProfileService,
    private getScheduleTimingsService: GetscheduletimingsService,
    private getAllLanguagesService: GetAllLanguagesService,
    private server: JwtTestService,
  ) {
    this.filteredLanguages = this.languageCtrl.valueChanges.pipe(
      startWith(null),
      map((language: string | null) => language ? this._filter(language) : this.getLanguages.slice()));

    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    this.business_name = localStorage.getItem('business_name');

    this.ownerForm = new FormGroup({
      salutation: new FormControl({ value: '', disabled: true }),
      first_name: new FormControl(''),
      middle_name: new FormControl(''),
      last_name: new FormControl(''),
      gender: new FormControl(''),
      // title: new FormControl(''),
      // languages: new FormControl(''),
      experience: new FormControl('', [Validators.min(1)]),
      address_text: new FormControl(''),
      postcode: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      mobile_no: new FormControl({ value: '', disabled: true }),
      image: new FormControl(''),
      business_name: new FormControl(''),
      cost: new FormControl('', [Validators.required, Validators.min(10), Validators.pattern("^[0-9]*$")]),
      education_degree: new FormControl(''),
      online_consultation_type: new FormControl(''),
      current_address: new FormControl({ value: '', disabled: true }),
    });

    this.getAllLanguagesService.GetAllLanguagesService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getLanguages = _response.body;
          } else {
            this.getLanguages = [];
          }
        },
        (_error) => {
          this.getLanguages = [];
        }
      );

    this.filteredLanguages = this.languageCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.setCurrentLocation();

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    this.getSettings(this.provider_id);
    this.getTimings(this.provider_id);
  }

  getSettings(provider_id) {
    let queryParam = {
      provider_id: provider_id
    }
    this.ngxService.start();

    this.server.request('GET', '/providers/getSettings', queryParam)
      .subscribe(
        (_response: any) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_response.status === 200) {
            // this.settings = _response.body;
            this.first_name = _response.body.first_name;
            this.middle_name = _response.body.middle_name;
            this.last_name = _response.body.last_name;
            this.title = _response.body.title;
            this.provider_experience = _response.body.Provider_experience;
            this.address_text = _response.body.address_text;
            this.mobile_number = _response.body.mobile_number;
            this.gender = _response.body.gender;
            this.salutationValue = _response.body.salutation;
            this.postcode = _response.body.postcode;
            this.provider_url = _response.body.provider_url;
            this.photo_id = _response.body.provider_photo_id;
            this.cost = _response.body.cost;
            this.qualification_id = _response.body.qualification_id;
            this.address_id = _response.body.address_id;
            this.online_consultation_type = _response.body.online_consultation_type;

            if (this.online_consultation_type == '1') {
              this.showOnlineNote = true;
            } else {
              this.showOnlineNote = false;
            }

            let provider_languages = _response.body.provider_languages.split(' ');
            provider_languages.pop();
            console.log('provider_languages: ', provider_languages);

            for (let i = 0; i < provider_languages.length; i++) {
              this.languages.push(provider_languages[i]);
            }
            console.log('languages: ', this.languages);

            let provider_degrees = [];
            if (_response.body.qualification_name.includes("*")) {
              provider_degrees = _response.body.qualification_name.split('*');
            }
            else {
              provider_degrees = _response.body.qualification_name.split(' ');
            }
            provider_degrees.pop();

            for (let i = 0; i < provider_degrees.length; i++) {
              this.degrees.push(provider_degrees[i]);
            }

            this.specific_address = this.address_text.slice(0, this.address_text.indexOf(','));
            this.google_address = this.address_text.slice(this.address_text.indexOf(',') + 1).trim();

            this.ownerForm.controls['postcode'].setValue(this.postcode);
            this.ownerForm.controls['address_text'].setValue(this.specific_address);
            this.ownerForm.controls['first_name'].setValue(this.first_name);
            this.ownerForm.controls['middle_name'].setValue(this.middle_name);
            this.ownerForm.controls['last_name'].setValue(this.last_name);
            this.ownerForm.controls['salutation'].setValue(this.salutationValue);
            this.ownerForm.controls['mobile_no'].setValue(this.mobile_number);
            this.ownerForm.controls['gender'].setValue(this.gender);
            this.ownerForm.controls['experience'].setValue(this.provider_experience);
            this.ownerForm.controls['business_name'].setValue(this.business_name);
            this.ownerForm.controls['cost'].setValue(this.cost);
            this.ownerForm.controls['current_address'].setValue(this.address_text);
            this.getCityCountry();
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);

        });
  }

  getTimings(provider_id) {
    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getScheduleTimings', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.break_start_time = moment(_response.body.break_start_time, 'hh:mm A').format('HH:mm:ss');
            this.break_end_time = moment(_response.body.break_end_time, 'hh:mm A').format('HH:mm:ss');

            if (this.break_start_time == '00:00:00' && this.break_end_time == '00:00:00') {
              this.break_start_time = '';
              this.break_end_time = '';
            }
          }
        },
        (_error) => {

        });
  }

  confirm1(photo_id) {
    this.confirmationService.confirm({
      message: "Are you sure that you delete this image? ",
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        this.deletePhoto(photo_id);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  checkOnline() {
    if (this.ownerForm.controls['online_consultation_type'].value == '1') {
      this.showOnlineNote = true;
    } else if (this.ownerForm.controls['online_consultation_type'].value != '1') {
      this.showOnlineNote = false;
    }
  }

  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid && this.degrees.length > 0) {
      this.changeSettings(ownerFormValue);
    } else {
      this.toaster.error('Please fill in all details');
    }
  }

  changeSettings(ownerFormValue) {
    let language_str = '';
    let address_str = '';

    for (var i = 0; i < this.languages.length; i++) {
      language_str += this.languages[i] + ' ';
    }

    if (this.markerMoved) {
      address_str = ownerFormValue.address_text + ', ' + this.address;
    } else {
      address_str = ownerFormValue.address_text + ', ' + this.google_address;
    }

    let user: any = {
      provider_id: this.provider_id,
      userid: this.userid,
      gender: ownerFormValue.gender,
      first_name: ownerFormValue.first_name,
      middle_name: ownerFormValue.middle_name,
      last_name: ownerFormValue.last_name,
      // title: ownerFormValue.title,
      address_text: address_str,
      postcode: ownerFormValue.postcode,
      salutation: ownerFormValue.salutation,
      address_lng: this.markerMoved ? this.longitude : '',
      address_lat: this.markerMoved ? this.latitude : '',
      provider_languages: language_str,
      city_name: ownerFormValue.city,
      Provider_experience: ownerFormValue.experience,
      mobile_number: ownerFormValue.mobile_no,
      business_name: ownerFormValue.business_name,
      default_cost: ownerFormValue.cost,
      address_id: this.address_id
    };

    this.contactInfoService.ContactInfoService(user)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            try {
              if (this.filesToUpload.length > 0) {
                const formData: any = new FormData();
                const files: Array<File> = this.filesToUpload;

                for (let i = 0; i < files.length; i++) {
                  formData.append("avatar", files[i], files[i]['name']);
                }

                let queryParam = `?provider_id=${this.provider_id}`;
                this.photo_id ? queryParam += `&provider_photo_id=${this.photo_id}` : '';

                this.addProviderImagesService.AddproviderimagesService(formData, queryParam)
                  .subscribe(
                    (_response) => {
                      this.addProviderImagesService.UploadImageToCloudService({ dt: JSON.stringify(_response.body.details) })
                        .subscribe(
                          (_response) => {
                            if (_response.status === 200) {
                              console.log('image added');
                              this.getSettings(this.provider_id);
                              this.urls = [];
                              this.ownerForm.controls['image'].setValue('');
                            }
                          },
                          (_error) => {

                          }
                        );
                    },
                    (_error) => {

                    }
                  );
              }
              // alert('Settings changed');
              this.toaster.success('Settings changed');
              this.languages = [];
              // this.degrees = [];
              if (this.filesToUpload.length == 0) {
                this.getSettings(this.provider_id);
              }
            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      );

    let degrees_str = '';
    let online_type = '';

    for (var i = 0; i < this.degrees.length; i++) {
      // degrees_str += this.degrees[i] + ' ';
      degrees_str += this.degrees[i] + '*';
    }

    if (this.ownerForm.controls['online_consultation_type'].dirty) {
      if (ownerFormValue.online_consultation_type) {
        online_type = '1 ';
      } else {
        online_type = '0 ';
      }
    } else {
      online_type = this.online_consultation_type;
    }

    let business = {
      business_name: ownerFormValue.business_name,
      default_cost: ownerFormValue.cost,
      provider_id: this.provider_id,
      userid: this.userid,
      qualification_name: degrees_str,
      qualification_id: this.qualification_id,
      online_consultation_type: online_type,
      break_start_time: this.break_start_time == 'Invalid date' || this.break_start_time == '' || this.break_start_time == this.break_end_time ? '' : moment(this.break_start_time, 'hh:mm').format('HH:mm:SS'),
      break_end_time: this.break_end_time == 'Invalid date' || this.break_end_time == '' || this.break_start_time == this.break_end_time ? '' : moment(this.break_end_time, 'hh:mm').format('HH:mm:SS'),
    };
    this.companyProfileService.CompanyProfileService(business)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.degrees = [];
            // this.getSettings(this.provider_id);
          }
        },
        (_error) => {

        });
  }

  onFileChange(event) {
    this.filesToUpload = <Array<File>>event.target.files;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls[0] = event.target.result;
        }

        reader.readAsDataURL(event.target.files[i]);
      }

    }
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.ownerForm.get('image').setValue(event.target.files);
    }
  }

  deletePhoto(photo_id) {
    let photo = {
      provider_photo_id: photo_id
    };

    this.server.request('POST', '/providers/deleteProviderPhoto', photo)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Photo has been deleted');
        },
        (_error) => {

        });
  }

  removeImage() {
    this.fileInput.nativeElement.value = '';
    this.urls = [];
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    //Add language
    if ((value || '').trim()) {
      if (!this.languages.includes(value.trim())) {
        for (let i = 0; i < this.getLanguages.length; i++) {
          if (this.getLanguages[i].language_name == value) {
            this.languages.push(value.trim());
            break;
          } else {
            this.toaster.error('Please select a different language');
          }
        }
      }
    }

    console.log('languages: ', this.languages);

    //Reset input value
    if (input) {
      input.value = '';
    }
  }

  remove(language: Language): void {
    const index = this.languages.indexOf(language);

    if (index >= 0) {
      this.languages.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.languages.includes(event.option.viewValue.trim())) {
      this.languages.push(event.option.viewValue);
    }
    this.languageInput.nativeElement.value = '';
    // this.languageCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.getLanguages.filter(language => language.language_name.toLowerCase().indexOf(filterValue) === 0);
  }

  addDegree(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    //Add language
    if ((value || '').trim()) {
      this.degrees.push(value.trim());
    }

    //Reset input value
    if (input) {
      input.value = '';
    }
  }

  removeDegree(degree: Degree): void {
    const index = this.degrees.indexOf(degree);

    if (index >= 0) {
      this.degrees.splice(index, 1);
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.markerMoved = true;
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getCityCountry() {
    let postcode = this.ownerForm.controls['postcode'].value;
    let queryParam = `?postcode=${postcode}`;
    this.getContactInfoService.GetContactInfoService(queryParam)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.citycountry = _response.body;
            this.ownerForm.controls['city'].setValue(this.citycountry[0].city);
            this.ownerForm.controls['country'].setValue(this.citycountry[0].country);
          }
        },
        (_error) => {

        }
      );
  }

}
