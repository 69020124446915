import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';
import { SignupBusinessService } from '../../services/signup-business/signup-business.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CryptoServicesService } from '../../services/crypto-services/crypto-services.service';
import { GetcategoryServiceService } from '../../services/getcategory/getcategory-service.service';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
//     const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

//     return (invalidCtrl || invalidParent);
//   }
// }

@Component({
  selector: 'app-signup-business',
  templateUrl: './signup-business.component.html',
  styleUrls: ['./signup-business.component.css']
})
export class SignupBusinessComponent implements OnInit {
  public ownerForm: FormGroup;
  //name = new FormControl('', [Validators.required, Validators.name]);
  passwordHide = true;
  confirmPasswordHide = true;
  errorMatcher = new CrossFieldErrorMatcher();
  formGroup: FormGroup;
  isActive;
  refer_provider_id;
  type;
  sub: any
  public termsForm: FormGroup;
  categories;
  images = [
    { name: 'https://ik.imagekit.io/zi19k9ks6j/hands-unrecognizable-female-doctor-writing-form-typing-laptop-keyboard_PrtHDD17V.jpg' },
    { name: 'https://ik.imagekit.io/zi19k9ks6j/judge-holding-pen-checking-document-wooden-desk_WUTZtcGOP.jpg' },
    { name: 'https://ik.imagekit.io/zi19k9ks6j/brunette-girl-getting-her-hair-done_SaEQbxFtO.jpg' },
    { name: 'https://ik.imagekit.io/zi19k9ks6j/business-people-discussing-results-successful-teamwork_-8iSBHk76.jpg' }
  ];

  constructor(
    private Router: Router,
    private formBuilder: FormBuilder,
    private signupBusiness: SignupBusinessService,
    private location: Location,
    private toastr: ToastrService,
    private server: JwtTestService,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private cryptoService: CryptoServicesService,
    private getCategoryService: GetcategoryServiceService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit() {

    this.sub = this.route
      .params
      .subscribe(params => {
        console.log(params);
        // Defaults to 0 if no query param provided.
        this.refer_provider_id = params.provider_id || '';
        this.type = params.type || '';
        console.log('refer_provider_id', this.refer_provider_id);
        console.log('type: ', this.type);
      });

    this.ownerForm = new FormGroup({
      business_name: new FormControl('', [Validators.required, Validators.pattern(/[A-Za-z]/)]),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/), Validators.maxLength(10)]),
      //email_address : new FormControl('', [Validators.required, Validators.email]), 
      // password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]),
      confirmpassword: new FormControl(''),
    },
      // passwordMatchValidator
      {
        validators: this.passwordValidator
      }
    );

    this.termsForm = new FormGroup({
      terms: new FormControl({ value: '', disabled: true }, Validators.required)
    });

    this.ownerForm.valueChanges.subscribe(() => {
      if (this.ownerForm.valid) {
        this.termsForm.controls['terms'].enable();
      } else {
        this.termsForm.controls['terms'].disable();
      }
    });

    this.getCategoryService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.categories = _response.body;
          }
        },
        (_error) => {

        });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.location.back();
  }
  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid && this.termsForm.valid) {
      this.signupadmin(ownerFormValue);
    }

  }

  signupadmin(ownerFormValue) {
    let user: any = {
      business_name: ownerFormValue.business_name,
      mobile_number: ownerFormValue.mobile_number,
      user_type: "2",
      // password: ownerFormValue.password,
      password: this.cryptoService.set('secret key 123', ownerFormValue.password),
      refer_provider_id: this.refer_provider_id && this.type == '11897' ? this.refer_provider_id : '',
      refer_patner_id: this.refer_provider_id && this.type == '12897' ? this.refer_provider_id : ''
      // confirmpassword: ownerFormValue.password,
    };

    this.ngxService.start();

    // const request = this.server.request('POST', '/users/signupProviderWithPromise', {
    //   business_name: ownerFormValue.business_name,
    //   mobile_number: ownerFormValue.mobile_number,
    //   user_type: "2",
    //   password: ownerFormValue.password,
    // });

    // request.subscribe((response: any) => {
    //   console.log('response: ', response);
    //   if (response.msg == 'mobile alreday exist') {
    //     this.toastr.warning('Mobile is already registered');
    //   } else {
    //     this.toastr.success('Registration successful');
    //     this.Router.navigate(['./business-wizard']);
    //     localStorage.setItem('business_name', user.business_name);
    //     localStorage.setItem('mobile_number', user.mobile_number);
    //     localStorage.setItem('userid', response.userid);
    //     localStorage.setItem('provider_id', response.provider_id);
    //   }
    // });

    this.signupBusiness.SignupBusinessService(user)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if ((_response.status === 200) && (_response.body.msg == "mobile alreday exist")) {
            // alert('Mobile is already registered');
            this.toastr.warning('', 'Mobile is already registered');
          } else if (_response.status === 200) {
            // alert("Successful")
            this.toastr.success('Registered Successfully');
            this.Router.navigate(['./business-wizard']);
            localStorage.setItem('business_name', user.business_name);
            localStorage.setItem('mobile_number', user.mobile_number);
            localStorage.setItem('userid', _response.body.userid);
            localStorage.setItem('provider_id', _response.body.provider_id);
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.toastr.error(' invalid username or password');
          // alert('invalid username or password');
        }
      );
  }

  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('password').value !== ownerForm.get('confirmpassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }

  // public scrollToSignUp(elementId: string): void {
  //   this.viewportScroller.scrollToAnchor(elementId);
  // }
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   let element = document.querySelector('.navbar');
  //   if (window.pageYOffset > element.clientHeight) {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top navbar-scrolled';
  //   } else {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top';
  //   }

  // }

}