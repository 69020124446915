import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CalendarEvent, CalendarView, CalendarMonthViewDay, CalendarEventTitleFormatter, CalendarEventAction, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { GetappointmentbyproviderService } from '../../../services/getappointmentbyprovider/getappointmentbyprovider.service';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
import { ChangeAppointStatusService } from '../../../services/change-appoint-status/change-appoint-status.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationtypeService } from '../../../services/notificationtype/notificationtype.service';
import { GetpaymentidbyapointtidService } from '../../../services/getpaymentidbyapointtid/getpaymentidbyapointtid.service';
import { RazorpayService } from '../../../services/razorpay/razorpay.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { GetscheduletimingsService } from '../../../services/getscheduletimings/getscheduletimings.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject, Subject } from 'rxjs';
import { GetproviderholidaysService } from '../../../services/getproviderholidays/getproviderholidays.service';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as range from 'lodash.range';
import { Router } from '@angular/router';
import { ProviderMonthAppointmentPercentageService } from '../../../services/providerMonthAppointmentPercentage/provider-month-appointment-percentage.service';
import { GetsettingsService } from '../../../services/getsettings/getsettings.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  green: {
    primary: '#0cc73e',
    secondary: '#bafdcc',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
};

interface Appointment {
  appoint_id: number;
  name: string;
  start_datetime: string;
  end_datetime: string;
  apoint_status: number;
  userid: number;
  pay_status: number;
}

interface Holiday {
  holiday_id: number;
  holiday_name: string;
  holiday_date: string;
};

interface EventGroupMeta {
  type: string;
}

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

@Component({
  selector: 'app-calender-view',
  templateUrl: './calender-view.component.html',
  styleUrls: ['./calender-view.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
    ConfirmationService
  ],
})
export class CalenderViewComponent implements OnInit, OnDestroy {
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events$: Observable<CalendarEvent[]>;
  holidays$: Observable<CalendarEvent[]>;
  allEvents$;
  provider_id;
  userid;
  payment_id;
  today = moment().format('YYYY-MM-DD');
  events = [];
  activeDayIsOpen: boolean = false;
  groupedSimilarEvents: CalendarEvent[] = [];
  acceptAction: CalendarEventAction[] = [
    {
      label: ' Accept ',
      a11yLabel: 'Accept',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.changeStatus(event.meta.appoint_id, 1, event.meta.userid);
      },
      cssClass: 'accept-action'
    },
    {
      label: ' Reject ',
      a11yLabel: 'Reject',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        if (event.meta.pay_status == 1 || event.meta.pay_status == 2) {
          this.confirm1(event.meta.appoint_id, 3, event.meta.userid);
        } else if (event.meta.pay_status == 0) {
          this.changeStatus(event.meta.appoint_id, 2, event.meta.userid);
        }
      },
      cssClass: 'reject-action'
    }
  ];

  rejectAction: CalendarEventAction[] = [
    {
      label: ' Reject ',
      a11yLabel: 'Reject',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        if (event.meta.pay_status == 1 || event.meta.pay_status == 2) {
          this.confirm1(event.meta.appoint_id, 3, event.meta.userid);
        } else if (event.meta.pay_status == 0) {
          this.changeStatus(event.meta.appoint_id, 2, event.meta.userid);
        }
      },
      cssClass: 'reject-action'
    },
  ];

  eventsArray: CalendarEvent[] = [];
  msgs: Message[] = [];
  intervalId;
  dayStartHour;
  dayEndHour;
  dayStartMinute;
  dayEndMinute;
  excludeDays: number[] = [];
  working_days = [];
  refresh: Subject<any> = new Subject();
  monthEvents: CalendarEvent[] = [];

  invalidDates: Array<Date> = [];
  // appointments = [];
  appointments = new MatTableDataSource<any>([]);
  holidays = [];
  pendingCount = 0;
  acceptCount = 0;
  rejectCount = 0;
  appointmentsAvaliable = false;
  remaining_day;
  category_id;
  document_list = [];
  document_status = '';
  displayedColumns: string[] = ['time', 'name', 'mobile_number', 'action'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };
  public pieChartLabels: Label[] = ['Pending', 'Accepted', 'Rejected'];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  pieChartPlugins;
  // public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(96, 155, 255, 1)', 'rgba(55, 131, 59, 1)', 'rgba(200, 55, 45, 1)'],
    },
  ];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  public currentDate: moment.Moment;
  public namesOfDays = ['Sun', 'Mon', ' Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public weeks: Array<CalendarDate> = [];
  public selectedDate;
  current_date;

  booked_month_slot_array = [];
  pending_month_slot_array = [];
  currentdatetime = moment();
  current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");
  clickIntervalId;
  available_online;
  videoIntervalId;

  constructor(
    private http: HttpClient,
    private changeApointStatusService: ChangeAppointStatusService,
    private toaster: ToastrService,
    private notificationTypeService: NotificationtypeService,
    private getPaymentByApointIdService: GetpaymentidbyapointtidService,
    private razorPayService: RazorpayService,
    private getAppointmentbyProviderService: GetappointmentbyproviderService,
    private confirmationService: ConfirmationService,
    private getScheduleTimingsService: GetscheduletimingsService,
    private server: JwtTestService,
    private getProviderHolidaysService: GetproviderholidaysService,
    private router: Router,
    private providerMonthAppointmentPercentage: ProviderMonthAppointmentPercentageService,
    private getSettingsService: GetsettingsService,
    public dialog: MatDialog,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.clickIntervalId);
    clearInterval(this.videoIntervalId);
  }

  ngAfterViewInit() {
    this.appointments.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    let query = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getSettings', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.available_online = _response.body.online_consultation_type;
          }
        },
        (_error) => {
        });

    this.server.request('GET', '/providers/getScheduleTimings', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.dayStartHour = moment(_response.body.start_time, 'h:mm A').toDate().getHours();
            this.dayStartMinute = moment(_response.body.start_time, 'h:mm A').toDate().getMinutes();
            this.dayEndHour = moment(_response.body.end_time, 'h:mm A').toDate().getHours();
            this.dayEndMinute = moment(_response.body.end_time, 'h:mm A').toDate().getMinutes();

            if (_response.body.working_days) {
              this.working_days = _response.body.working_days.split(' ');
              this.working_days.pop();

              for (let i = 0; i < this.working_days.length; i++) {
                this.working_days[i] = moment(this.working_days[i], 'dddd').day();
              }

              for (let i = 0; i < 7; i++) {
                if (!this.working_days.includes(i)) {
                  this.excludeDays.push(i);
                }
              }
            }
          }
        },
        (_error) => {

        });

    this.server.request('GET', '/providers/getProviderProfile', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.category_id = _response.body[0].category_id;
          }
        },
        (_error) => {
        });

    this.currentDate = moment();
    this.current_date = moment(this.currentDate).format('ddd, DD MMM');
    this.selectedDate = moment(this.currentDate).format('DD-MM-YYYY');
    this.generateCalendar();

    this.getHolidays();
    this.getAppointments(moment().format('YYYY-MM-DD'));
    this.getMonthAppointmentPercentage(moment().format('MM'));
    // this.getProviderSubscription();

    this.intervalId = setInterval(() => {
      this.getMonthAppointmentPercentage(moment(this.selectedDate, 'DD-MM-YYYY').format('MM'));
      this.getAppointments(moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    }, 5000);

    // this.allEvents$ = forkJoin(this.events$, this.holidays$)
    //   .pipe(
    //     map(([events, holidays]) => events.concat(holidays))
    //   );

    // this.intervalId = setInterval(() => {
    // this.getAppointments(moment().format('YYYY-MM-DD'));
    // // this.getAppointments();

    //   // this.allEvents$ = forkJoin(this.events$, this.holidays$)
    //   //   .pipe(
    //   //     map(([events, holidays]) => events.concat(holidays))
    //   //   );

    //   // this.refresh.next();
    // }, 5000);
  }

  handleClick(selected, current_date_time) {
    let startDate, endDate, min;
    if (selected == 'sub') {

      startDate = moment(current_date_time, 'ddd, DD MMM').subtract(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time, 'ddd, DD MMM').subtract(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment();
      this.currentdatetime = moment(current_date_time, 'ddd, DD MMM').subtract(1, 'days') || moment();
      min = 30;
      this.selectedDate = moment(current_date_time, 'ddd, DD MMM').subtract(1, 'days').format('DD-MM-YYYY');
    }
    if (selected == 'add') {
      startDate = moment(current_date_time, 'ddd, DD MMM').add(1, 'days').format("YYYY-MM-DD") || moment();
      endDate = moment(current_date_time, 'ddd, DD MMM').add(1, 'days').add(12, 'hours').format("YYYY-MM-DD") || moment()
      this.currentdatetime = moment(current_date_time, 'ddd, DD MMM').add(1, 'days') || moment();
      min = 30;
      this.selectedDate = moment(current_date_time, 'ddd, DD MMM').add(1, 'days').format('DD-MM-YYYY');
    }
    //Display Reasons
    this.current_date_time = moment(this.currentdatetime).format("ddd, DD MMM");

    clearInterval(this.intervalId);
    this.getAppointments(startDate);
    this.getMonthAppointmentPercentage(moment(startDate).format('MM'));

    clearInterval(this.clickIntervalId);
    this.clickIntervalId = setInterval(() => {
      this.getAppointments(moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
      this.getMonthAppointmentPercentage(moment(this.selectedDate, 'DD-MM-YYYY').format('MM'));
      console.log('startDate: ', startDate);
    }, 5000);
  }

  getHolidays(): void {
    this.getProviderHolidaysService.GetproviderholidaysService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.holidays = _response.body;

            for (let i = 0; i < this.holidays.length; i++) {
              let date = moment(this.holidays[i].holiday_date).toDate();
              this.invalidDates.push(date);
            }
          }
        },
        (_error) => {
          this.holidays = [];
        });

    // this.holidays$ = this.http
    //   .get(environment.api.getproviderholidays)
    //   .pipe(
    //     map((holidays: Holiday[]) => {
    //       return holidays.map((holiday: Holiday) => {
    //         return {
    //           title: holiday.holiday_name,
    //           start: moment(holiday.holiday_date).toDate(),
    //           end: moment(holiday.holiday_date).toDate(),
    //           allDay: true,
    //           colors: colors.blue,
    //           meta: {
    //             type: 'Holiday'
    //           }
    //         };
    //       });
    //     })
    //   );

    // this.holidays$.subscribe((_error: any) => {
    //   if (_error.status === 409) {
    //   }
    // });
  }

  getAppointments(date?): void {
    this.selectedDate = moment(date).format('DD-MM-YYYY');
    this.current_date = moment(this.selectedDate, 'DD-MM-YYYY').format('ddd, DD MMM');
    this.generateCalendar();

    date ? date = moment(date).format('YYYY-MM-DD') : date = moment().format('YYYY-MM-DD');

    let query = {
      provider_id: this.provider_id,
      date: date ? date : ``
    };

    this.server.request('GET', '/appointment/getBookedAppointmentByProviderId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            if (_response.body.data == 'Not Found') {
              this.appointmentsAvaliable = false;

              this.pendingCount = 0;
              this.acceptCount = 0;
              this.rejectCount = 0;
              this.pieChartData = [];
              this.appointments.data = [];

            } else {
              this.appointmentsAvaliable = true;

              this.pendingCount = 0;
              this.acceptCount = 0;
              this.rejectCount = 0;
              this.pieChartData = [];

              this.appointments.data = _response.body.data.results;
              // let time = moment();

              for (let i = 0; i < this.appointments.data.length; i++) {
                this.appointments.data[i].date_time = moment(this.appointments.data[i].start_datetime);
                this.appointments.data[i].datte = moment(this.appointments.data[i].start_datetime).format('DD MMM YYYY');
                this.appointments.data[i].start_datetime = moment(this.appointments.data[i].start_datetime).format('h:mm A');
                this.appointments.data[i].end_datetime = moment(this.appointments.data[i].end_datetime).format('h:mm A');

                if (this.appointments.data[i].document_status == 0) {
                  this.appointments.data[i].document_status = 'Not required';
                } else if (this.appointments.data[i].document_status == 1) {
                  this.appointments.data[i].document_status = 'Requested to customer';
                } else if (this.appointments.data[i].document_status == 2) {
                  this.appointments.data[i].document_status = 'Need to review';
                } else if (this.appointments.data[i].document_status == 3) {
                  this.appointments.data[i].document_status = 'Approved';
                }

                // let difference = moment(this.appointments[i].start_datetime, 'hh:mm A').diff(time, 'minutes');
                // if (difference == 60 && this.appointments[i].apoint_status == 0 && this.appointments[i].pay_status == '0') {
                //   this.changeStatus(this.appointments[i].appoint_id, 2, this.userid);
                // } else if (difference == 60 && this.appointments[i].apoint_status == 0 && (this.appointments[i].pay_status == '1' || this.appointments[i].pay_status == '2')) {
                //   this.changeStatus(this.appointments[i].appoint_id, 3, this.userid);
                // }

                // let query = {
                //   appoint_id: this.appointments[i].appoint_id
                // };
                // this.server.request('GET', '/providers/getDocumentStatus', query)
                //   .subscribe(
                //     (_response: any) => {
                //       if (_response.status == 200) {
                //         if (_response.body[0].document_status == 0) {
                //           this.document_status = 'Not required';
                //         } else if (_response.body[0].document_status == 1) {
                //           this.document_status = 'Requested from customer';
                //         } else if (_response.body[0].document_status == 2) {
                //           this.document_status = 'Need to review';
                //         } else if (_response.body[0].document_status == 3) {
                //           this.document_status = 'Approved';
                //         }
                //         // this.appointments[i].document_status = _response.body[0].document_status;
                //       }
                //     },
                //     (_error) => {
                //       console.log('error: ', _error);
                //     });

                if (this.appointments.data[i].pay_status == '0') {
                  this.appointments.data[i].pay_status = 'COV';
                } else if (this.appointments.data[i].pay_status == '1') {
                  this.appointments.data[i].pay_status = 'Online Payment';
                } else if (this.appointments.data[i].pay_status == '2') {
                  this.appointments.data[i].pay_status = 'Advance Payment';
                }

                if (this.appointments.data[i].apoint_status == 0) {
                  this.pendingCount++;
                } else if (this.appointments.data[i].apoint_status == 1) {
                  this.acceptCount++;
                } else if (this.appointments.data[i].apoint_status == 2 || this.appointments.data[i].apoint_status == 3) {
                  this.rejectCount++;
                }
              }
              this.pieChartData.push(this.pendingCount);
              this.pieChartData.push(this.acceptCount);
              this.pieChartData.push(this.rejectCount);
            }
          }
        },
        (_error) => {
          this.appointments.data= [];
          this.pendingCount = 0;
          this.acceptCount = 0;
          this.rejectCount = 0;
          this.appointmentsAvaliable = false;
          this.pieChartData = [];
        });

    // this.getAppointmentbyProviderService.GetappointmentbyproviderService(query)
    //   .subscribe(
    //     (_response) => {
    //       this.refresh.next();
    //       if (_response.status === 200) {
    //         if (_response.body.data == 'Not Found') {
    //           this.appointmentsAvaliable = false;

    //           this.pendingCount = 0;
    //           this.acceptCount = 0;
    //           this.rejectCount = 0;
    //           this.pieChartData = [];
    //           this.appointments = [];

    //         }
    //         else {
    //           this.appointmentsAvaliable = true;

    //           this.pendingCount = 0;
    //           this.acceptCount = 0;
    //           this.rejectCount = 0;
    //           this.pieChartData = [];

    //           this.appointments = _response.body.data.results;

    //           for (let i = 0; i < this.appointments.length; i++) {
    //             this.appointments[i].datte = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
    //             this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('h:mm A');
    //             this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('h:mm A');

    //             if (this.appointments[i].pay_status == '0') {
    //               this.appointments[i].pay_status = 'COV';
    //             } else if (this.appointments[i].pay_status == '1') {
    //               this.appointments[i].pay_status = 'Online Payment';
    //             } else if (this.appointments[i].pay_status == '2') {
    //               this.appointments[i].pay_status = 'Advance Payment';
    //             }

    //             if (this.appointments[i].apoint_status == 0) {
    //               this.pendingCount++;
    //             } else if (this.appointments[i].apoint_status == 1) {
    //               this.acceptCount++;
    //             } else if (this.appointments[i].apoint_status == 2 || this.appointments[i].apoint_status == 3) {
    //               this.rejectCount++;
    //             }
    //           }
    //           this.pieChartData.push(this.pendingCount);
    //           this.pieChartData.push(this.acceptCount);
    //           this.pieChartData.push(this.rejectCount);
    //         }
    //       }
    //     },
    //     (_error) => {
    //       this.appointments = [];
    //       this.pendingCount = 0;
    //       this.acceptCount = 0;
    //       this.rejectCount = 0;
    //       this.appointmentsAvaliable = false;
    //       this.pieChartData = [];
    //     });

    // this.groupedSimilarEvents = [];

    // const params = new HttpParams()
    //   .set('provider_id', this.provider_id)
    // // .set('date', this.today);

    // this.events$ = this.http
    //   .get(environment.api.getappointmentbyprovider, { params })
    //   .pipe(
    //     map(({ data }: { data }) => {
    //       if (data.results) {
    //         return data.results.map((appointment: Appointment) => {
    //           // if (appointment.apoint_status == 0) {
    //           //   let diff = moment(appointment.start_datetime).diff(moment(), 'hour');
    //           //   if (diff <= 19 && (appointment.pay_status == 1 || appointment.pay_status == 2)) {
    //           //     this.changeStatus(appointment.appoint_id, 3, this.userid);
    //           //   } else if (diff <= 19 && appointment.pay_status == 0) {
    //           //     this.changeStatus(appointment.appoint_id, 2, this.userid);
    //           //   }
    //           // }
    //           let appt = {
    //             title: appointment.name,
    //             start: moment(appointment.start_datetime).toDate(),
    //             end: moment(appointment.end_datetime).toDate(),
    //             color: (appointment.apoint_status == 0 ? colors.yellow
    //               : appointment.apoint_status == 1 ? colors.green
    //                 : colors.red),
    //             meta: {
    //               type: appointment.apoint_status == 2 || appointment.apoint_status == 3 ? 2 : appointment.apoint_status,
    //               appoint_id: appointment.appoint_id,
    //               userid: appointment.userid,
    //               pay_status: appointment.pay_status
    //             },
    //             actions: (appointment.apoint_status == 0 ? this.acceptAction
    //               : appointment.apoint_status == 1 ? this.rejectAction
    //                 : []),
    //           };
    //           this.eventsArray.push(appt);
    //           return {
    //             title: appointment.name,
    //             start: moment(appointment.start_datetime).toDate(),
    //             end: moment(appointment.end_datetime).toDate(),
    //             color: (appointment.apoint_status == 0 ? colors.yellow
    //               : appointment.apoint_status == 1 ? colors.green
    //                 : colors.red),
    //             meta: {
    //               type: appointment.apoint_status == 2 || appointment.apoint_status == 3 ? 2 : appointment.apoint_status,
    //               appoint_id: appointment.appoint_id,
    //               userid: appointment.userid,
    //               pay_status: appointment.pay_status
    //             },
    //             actions: (appointment.apoint_status == 0 ? this.acceptAction
    //               : appointment.apoint_status == 1 ? this.rejectAction
    //                 : []),
    //           };
    //         });
    //       }
    //     })
    //   );

    // this.events$.subscribe((_error: any) => {
    //   if (_error.status === 409) {
    //     this.events = []
    //     this.eventsArray = [];
    //   }
    // });

    // // group any events together that have the same type and dates
    // // use for when you have a lot of events on the week or day view at the same time
    // const processedEvents = new Set();
    // this.eventsArray.forEach((event) => {
    //   if (processedEvents.has(event)) {
    //     return;
    //   }
    //   const similarEvents = this.eventsArray.filter((otherEvent) => {
    //     return (
    //       otherEvent !== event &&
    //       !processedEvents.has(otherEvent) &&
    //       moment(otherEvent.start).isSame(moment(event.start), 'minute') &&
    //       (moment(otherEvent.end).isSame(moment(event.end), 'minute') ||
    //         (!otherEvent.end && !event.end)) &&
    //       otherEvent.color.primary === event.color.primary &&
    //       otherEvent.color.secondary === event.color.secondary
    //     );
    //   });
    //   processedEvents.add(event);
    //   similarEvents.forEach((otherEvent) => {
    //     processedEvents.add(otherEvent);
    //   });
    //   if (similarEvents.length > 0) {
    //     this.groupedSimilarEvents.push({
    //       title: `${similarEvents.length + 1} events`,
    //       color: event.color,
    //       start: event.start,
    //       end: event.end,
    //       meta: {
    //         groupedEvents: [event, ...similarEvents],
    //       },
    //     });
    //   } else {
    //     this.groupedSimilarEvents.push(event);
    //   }
    // });
  }

  // dayClicked({
  //   date,
  //   events,
  // }: {
  //   date: Date;
  //   events: CalendarEvent<{ appointment: Appointment }>[];
  // }): void {
  //   let month = document.getElementById('month-view');
  //   let month_open = document.getElementById('month-open');
  //   if (moment(date).isSame(moment(this.viewDate), 'month')) {
  //     if (
  //       (moment(this.viewDate).isSame(moment(date), 'day') && this.activeDayIsOpen === true) ||
  //       events.length === 0
  //     ) {
  //       this.activeDayIsOpen = false;
  //       month.className = 'col-12';
  //       month_open.className = 'cal-month-view';
  //     } else {
  //       this.activeDayIsOpen = true;
  //       this.viewDate = date;
  //       this.monthEvents = events;
  //       month.className = 'col-12 col-md-8';
  //       month_open.className = 'cal-month-view col-12 col-md-4';
  //     }
  //   }
  // }

  // eventClicked({ event }: { event: CalendarEvent }): void {
  //   console.log('Event clicked', event);
  // }

  // beforeMonthViewRender({
  //   body,
  // }: {
  //   body: CalendarMonthViewDay<EventGroupMeta>[];
  // }): void {
  //   // month view has a different UX from the week and day view so we only really need to group by the type
  //   body.forEach((cell) => {
  //     const groups = {};
  //     cell.events.forEach((event: CalendarEvent<EventGroupMeta>) => {
  //       if (event.meta.type !== 'Holiday') {
  //         groups[event.meta.type] = groups[event.meta.type] || [];
  //         groups[event.meta.type].push(event);
  //       }

  //     });
  //     cell['eventGroups'] = Object.entries(groups);
  //   });
  // }

  // holidayColor(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
  //   renderEvent.body.forEach((day) => {
  //     day.events.forEach((event) => {
  //       if (event.meta.type == 'Holiday') {
  //         day.cssClass = 'holiday-bg';
  //       }
  //     });
  //   });
  // }

  getMonthAppointmentPercentage(month) {
    let query = {
      provider_id: this.provider_id,
      month: month
    };

    this.server.request('GET', '/providers/providerMonthApointmentPercentage', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.booked_month_slot_array = _response.body.booked_month_slot_array;
            this.pending_month_slot_array = _response.body.pending_month_slot_array;
          }
        },
        (_error) => {
          this.booked_month_slot_array = [];
          this.pending_month_slot_array = [];
        });
  }

  confirm1(appointment_id, appoint_status, userid) {
    this.confirmationService.confirm({
      message: 'Amount will be automatically refunded to customer',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        this.changeStatus(appointment_id, appoint_status, userid);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      },
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
    });
  }

  changeStatus(appointment_id, appoint_status, userid) {
    let status = {
      appoint_id: appointment_id,
      appoint_status: appoint_status,
      appoint_status_reason: appoint_status == 2 || appoint_status == 3 ? 1 : ''
    };

    this.server.request('POST', '/appointment/changeApointStatus', status)
      .subscribe(
        (_response) => {
          if (appoint_status == '1') {
            this.toaster.success('Appointment accepted');
          } else if (appoint_status == '2') {
            this.toaster.error('Appointment rejected');
            // this.getPaymentId(appointment_id);
          } else if (appoint_status == '3') {
            this.toaster.error('Appointment rejected');
            this.getPaymentId(appointment_id);
          }
          let date = moment(this.selectedDate, 'DD-MM-YYYY');
          this.getAppointments(moment(date));
          this.getMonthAppointmentPercentage(moment(date).format('MM'));
        },
        (_error) => {

        });

    let notificationtype = {
      userid: this.userid,
      intended_userid: userid,
      appoint_id: appointment_id,
      n_type: appoint_status
    };

    this.server.request('POST', '/notification/notificationType', notificationtype)
      .subscribe((_response) => {
        // console.log('notification type: ', notificationtype.n_type);
      },
        (_error) => {

        });
  }

  getPaymentId(appoint_id) {
    let query = {
      appoint_id: appoint_id
    };

    this.server.request('GET', '/razorpay_purchase/getPaymentIdByApointtId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.payment_id = _response.body.razorpay_payment_id;
            this.refund();
          }
        },
        (_error) => {

        });
  }

  refund() {
    let payment = {
      razorpay_payment_id: this.payment_id
    };

    this.server.request('POST', '/razorpay_purchase/refund', payment)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Payment refunded successfully');

          let refund = {
            razorpay_payment_id: this.payment_id,
            refund_id: _response.id
          };

          this.server.request('POST', '/razorpay_purchase/refundId', refund)
            .subscribe(
              (_response: any) => {
                console.log('refund id success');
              },
              (_error) => {

              });

        },
        (_error) => {

        });
  }

  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  private fillDates(currentMoment: moment.Moment) {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const lastOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'day');
    const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'day').add(7, 'day');
    const startCalendar = firstDayOfGrid.date();

    return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'day')).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        mDate: newDate,
      };
    });
  }

  private isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  private isSelected(date: moment.Moment): boolean {
    return this.selectedDate === moment(date).format('DD-MM-YYYY');
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'month');
    this.generateCalendar();
    this.getMonthAppointmentPercentage(moment(this.currentDate).format("MM"));
  }

  public nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'month');
    this.generateCalendar();
    this.getMonthAppointmentPercentage(moment(this.currentDate).format("MM"));
  }

  public isDisabledMonth(currentDate): boolean {
    const today = moment();
    return moment(currentDate).isSame(today.add(90, 'days'), 'month');
  }

  public isDisabledDay(date: moment.Moment): boolean {
    return false;
  }

  public isSelectedMonth(date: moment.Moment): boolean {
    const today = moment();
    return moment(date).isSame(this.currentDate, 'month');
    // && moment(date).isSameOrAfter(today, 'date') -- to disable previous dates
  }

  public selectDate(date: CalendarDate) {
    this.selectedDate = moment(date.mDate).format('DD-MM-YYYY');
    this.current_date = moment(this.selectedDate).format('ddd, DD MMM');
    clearInterval(this.intervalId);
    clearInterval(this.clickIntervalId);

    this.generateCalendar();
    this.getAppointments(moment(date.mDate).format('YYYY-MM-DD'));

    this.intervalId = setInterval(() => {
      this.getAppointments(moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    }, 5000);
  }

  public isHoliday(date: moment.Moment): boolean {
    return false;
  }

  public checkBooked50(date: moment.Moment): boolean {
    if (this.booked_month_slot_array) {
      for (let i = 0; i < this.booked_month_slot_array.length; i++) {
        if (this.booked_month_slot_array[i].Date == date.format('YYYY-MM-DD') && (this.booked_month_slot_array[i].Count >= 50 && this.booked_month_slot_array[i].Count < 80)) {
          return true;
        }
      }
    }
    return false;
  }

  public checkBooked80(date: moment.Moment): boolean {
    if (this.booked_month_slot_array) {
      for (let i = 0; i < this.booked_month_slot_array.length; i++) {
        if (this.booked_month_slot_array[i].Date == date.format('YYYY-MM-DD') && this.booked_month_slot_array[i].Count >= 80) {
          return true;
        }
      }
    }
    return false;
  }

  public checkPending(date: moment.Moment): boolean {
    if (this.pending_month_slot_array) {
      for (let i = 0; i < this.pending_month_slot_array.length; i++) {
        if (this.pending_month_slot_array[i].Date == date.format('YYYY-MM-DD')) {
          return true;
        }
      }
    }
    return false;
  }

  checkVideoCallTime(appointment) {
    let now = moment();
    if (now.isBetween(moment(appointment.start_datetime, 'h:mm A').subtract(1, 'minute'), moment(appointment.end_datetime, 'h:mm A'))) {
      return true;
    }
    return false;
  }

  videoCall(appoint_id, end_datetime) {
    localStorage.setItem('appoint_id', appoint_id);
    // this.router.navigate(['/provider-dashboard/video-call']);
    let myWindow = window.open('https://video.apointt.com:8443/' + appoint_id);

    let eventTime = moment(end_datetime, 'h:mm A').unix();
    let currentTime = moment().unix();
    let diffTime = eventTime - currentTime;
    let duration = moment(end_datetime, 'h:mm A').diff(moment(), 'second');
    let interval = 1000;

    if (diffTime > 0) {
      this.videoIntervalId = setInterval(() => {
        if (duration <= 0) {
          clearInterval(this.videoIntervalId);
          myWindow.close();
        } else {
          duration = moment(end_datetime, 'h:mm A').diff(moment(), 'second');
        }
      }, interval);
    }
  }

  checkAppointmentDate(date_time) {
    let today = moment();
    if (moment(date_time).isSameOrAfter(today)) {
      return true;
    }
    return false;
  }

  // getProviderSubscription() {
  //   let queryParam = {
  //     provider_id: this.provider_id
  //   };

  //   this.server.request('GET', '/providers/getsubscriptionOfProvider', queryParam)
  //     .subscribe(
  //       (_response: any) => {
  //         if (_response.status === 200) {
  //           this.remaining_day = _response.body.remaining_day;
  //         }
  //       },
  //       (_error) => {

  //       });
  // }

  // checkSubscription() {
  //   if (this.remaining_day <= 10 && this.remaining_day > 0) {
  //     return true;
  //   }
  //   return false;
  // }

  // checkSubscriptionExpired() {
  //   if (this.remaining_day <= 0) {
  //     return true;
  //   }
  //   return false;
  // }

  rescheduleAppointment(appoint_id) {
    localStorage.setItem('appoint_id', appoint_id);
    this.router.navigate(['/provider-dashboard/rescheduler']);
  }

  onNavigate(appoint_id) {
    localStorage.setItem('appoint_id', appoint_id);
    this.router.navigate(['/provider-dashboard/view-document']);
  }

  requestDoc(appoint_id) {
    let query = {
      category_id: this.category_id
    };

    this.server.request('GET', '/providers/getProviderDocumentListByCategoryID', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.document_list = _response.body[0].document_description.split(', ');
            this.openDialog(appoint_id);
          }
        },
        (_error) => {

        });
  }

  openDialog(appoint_id): void {
    const dialogRef = this.dialog.open(DocumentListDialog, {
      width: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: { docs: this.document_list, selected: [] }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('result: ', result);
      let doc_str = '';

      if (result && result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          doc_str += result[i] + ', ';
        }

        let attachment = {
          provider_id: parseInt(this.provider_id),
          userid: parseInt(localStorage.getItem('userid')),
          appoint_id: appoint_id,
          // attachment_type: 2,
          document_list: doc_str,
          attachment_id: ''
        };

        this.server.request('POST', '/providers/addDocumentList', attachment)
          .subscribe(
            (_response: any) => {
              this.toaster.success('Request sent');

              let document_status = {
                appoint_id: appoint_id,
                document_status: 1
              };

              this.server.request('POST', '/providers/changeDocumentStatus', document_status)
                .subscribe(
                  (_response: any) => {
                    console.log('document status changed');
                  },
                  (_error) => {
                    console.log("error: ", _error);
                  });
            },
            (_error) => {
              console.log('error: ', _error);
            });
      } else if (result && result.length == 0) {
        this.toaster.error('Please select a document');
      }
    });

  }

  applyFilter(filterValue: string) {
    this.appointments.filter = filterValue.trim().toLowerCase();
  }
}
@Component({
  selector: 'document-list-dialog',
  templateUrl: 'document-list-dialog.html'
})

export class DocumentListDialog {
  constructor(
    public dialogRef: MatDialogRef<DocumentListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // try {
    //   this.shareURL = data.url
    // } catch (err) {
    //   console.log(err);
    // }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
