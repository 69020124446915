import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/jwt-test/auth.service';
import { BrowserModule, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent {
  public ownerForm: FormGroup;
  hide = true;
  user_type = { provider: '2', customer: '1' };
  isActive;
  showError = false;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;

  /*  email_address = new FormControl('', [Validators.required, Validators.email]);
   getErrorMessage() {
     return this.email_address.hasError('required') ? 'You must enter a value' :
         this.email_address.hasError('email') ? 'Not a valid email' :
             '';
   }*/
  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private location: Location,
    private Router: Router,
    //  private toastr:ToastrModule,
    private toastr: ToastrService,
    private metaTagService: Meta,
    private authService: AuthService) { }

  ngOnInit() {
    this.ownerForm = new FormGroup({
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/), Validators.maxLength(10)]),
      password: new FormControl('', [Validators.required]),
      user_type: new FormControl(''),
    });

    localStorage.clear();
    sessionStorage.clear();

    this.metaTagService.updateTag(
      { name: 'description', content: 'Want to book an Online Appointment on your convinient day and time for expert advice and online consultation at nearby Hospital, Clinic, Salon, CA, Lawyer? Login Now!' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'Login, apointt Login, Login for online booking, Book appointment online ' }
    );

  }
  get userType() {
    return this.ownerForm.get('user_type');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.location.back();
  }

  async createOwner(ownerFormValue) {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.ownerForm.valid) {
      try {
        await this.authService.login(this.ownerForm.value);
        localStorage.setItem('mobile_number', ownerFormValue.mobile_number);
      } catch (err) {
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

  // public createOwner = (ownerFormValue) => {
  //   if (this.ownerForm.valid) {
  //     this.login(ownerFormValue);
  //   } else {
  //     this.showError = true;
  //   }

  // }

  login(ownerFormValue) {
    let user: any = {
      // email_address: ownerFormValue.email_address,
      mobile_number: ownerFormValue.mobile_number,
      // user_type: ownerFormValue.user_type,
      password: ownerFormValue.password,
    };

    this.loginService.LoginService(user)
      .subscribe(
        (_response) => {

          if (_response.status === 200) {
            { positionClass: 'toast-custom' };
            //this.toastr.success('Login successfully' );
            //alert('Sucessful');
            // this.Router.navigate(['/categories-view']);
            if (_response.body.user_type == 2) {
              this.Router.navigate(['/provider-dashboard']);
            } else if (_response.body.user_type == 1) {
              this.Router.navigate(['/customer-dashboard/categories-view']);
            }
            // localStorage.setItem('mobile_number', user.mobile_number);
            localStorage.setItem('userid', _response.body.userid);
            localStorage.setItem('provider_id', _response.body.provider_id);
            localStorage.setItem('mobile_number', ownerFormValue.mobile_number);
          }
        },
        (_error) => {
          //  alert(' Please check username or password');
          // alert('invalid username or password');
          this.toastr.error('Please check username or password')

        }
      );
    // console.log('data', user);
  }

  goToUrl(): void {
    window.location.assign('https://blacktix.com');
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   let element = document.querySelector('.navbar');
  //   if (window.pageYOffset > element.clientHeight) {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top navbar-scrolled';
  //   } else {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top';
  //   }

  // }


}
