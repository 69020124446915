import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SignupService } from '../../services/signup/signup.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { from } from 'rxjs';
import { WizardComponent } from 'src/app/ui-components/initial-wizard/wizard/wizard.component';
import { NGB_DATEPICKER_18N_FACTORY } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CryptoServicesService } from '../../services/crypto-services/crypto-services.service';
import { BrowserModule, Meta } from '@angular/platform-browser';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
//     const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

//     return (invalidCtrl || invalidParent);
//   }
// }


@Component({
  selector: 'app-signup-admin',
  templateUrl: './signup-admin.component.html',
  styleUrls: ['./signup-admin.component.css']
})
export class SignupAdminComponent {

  public ownerForm: FormGroup;
  //name = new FormControl('', [Validators.required, Validators.name]);
  //matcher = new MyErrorStateMatcher();
  passwordHide = true;
  confirmPasswordHide = true;
  formGroup: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  isActive;
  public termsForm: FormGroup;

  constructor(
    private Router: Router,
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private location: Location,
    private toastr: ToastrService,
    private server: JwtTestService,
    private ngxService: NgxUiLoaderService,
    private metaTagService: Meta,
    private cryptoService: CryptoServicesService
  ) { }

  ngOnInit() {
    this.ownerForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern(/[A-Za-z]/)]),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/), Validators.maxLength(10)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]),
      confirmpassword: new FormControl(''),
    },
      {
        validators: this.passwordValidator
      }
    );

    this.termsForm = new FormGroup({
      terms: new FormControl({ value: '', disabled: true }, Validators.required)
    });

    this.ownerForm.valueChanges.subscribe(() => {
      if (this.ownerForm.valid) {
        this.termsForm.controls['terms'].enable();
      } else {
        this.termsForm.controls['terms'].disable();
      }
    });

    this.metaTagService.updateTag(
      { name: 'description', content: 'Are you looking for nearby best Doctors, Lawyers, CA, Beauticians & other professionals? For Online consultation Signup now!' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'signup, apointt signup, apointt register, signup for online booking, ' }
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }

  public onCancel = () => {
    this.location.back();
  }

  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid && this.termsForm.valid) {
      this.signupadmin(ownerFormValue);
    }
  }

  signupadmin(ownerFormValue) {
    let user: any = {
      name: ownerFormValue.name,
      user_type: "1",
      mobile_number: ownerFormValue.mobile_number,
      // password: ownerFormValue.password,
      password: this.cryptoService.set('secret key 123', ownerFormValue.password),
      // confirmpassword: ownerFormValue.password,
    };

    this.ngxService.start();

    this.signupService.SignupService(user)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if ((_response.status === 200) && (_response.body.msg == 'Mobile already exists')) {
            this.toastr.warning('', 'Mobile number is already registered');
          } else if (_response.status === 200) {
            // alert("Successful")
            this.toastr.success('Registration successful');
            this.Router.navigate(['./home-page']);
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.toastr.error('Please check mobile number or password');
        }
      );
  }

  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('password').value !== ownerForm.get('confirmpassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   let element = document.querySelector('.navbar');
  //   if (window.pageYOffset > element.clientHeight) {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top navbar-scrolled';
  //   } else {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top';
  //   }

  // }
}
