import { Component, OnInit } from '@angular/core';
import { PartnersignupService } from '../../services/partnersignup/partnersignup.service';
import { from } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CryptoServicesService } from '../../services/crypto-services/crypto-services.service';
import { BrowserModule, Meta } from '@angular/platform-browser';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}
interface category_id {
  value: string,
  viewValue: string
}
@Component({
  selector: 'app-signup-partner',
  templateUrl: './signup-partner.component.html',
  styleUrls: ['./signup-partner.component.css']
})
export class SignupPartnerComponent implements OnInit {
  categories: category_id[] = [
    { value: '1', viewValue: 'Doctor' },
    { value: '2', viewValue: 'Lawyer' },
    { value: '3', viewValue: 'Beautician' },
    { value: '4', viewValue: 'CA' }
  ];
  // category_id: string[] = ['Doctor', 'Beautician', 'Lawyer', 'CA'];
  public ownerForm: FormGroup;
  address_text;
  //name = new FormControl('', [Validators.required, Validators.name]);
  passwordHide = true;
  confirmPasswordHide = true;
  errorMatcher = new CrossFieldErrorMatcher();
  formGroup: FormGroup;
  isActive;
  termsForm: FormGroup;

  constructor(
    private Router: Router,
    private formBuilder: FormBuilder,
    private Partnersignup: PartnersignupService,
    private location: Location,
    private toastr: ToastrService,
    private server: JwtTestService,
    private ngxService: NgxUiLoaderService,
    private metaTagService: Meta,
    private cryptoService: CryptoServicesService
  ) { }

  ngOnInit() {

    this.ownerForm = new FormGroup({
      full_name: new FormControl(''),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
      email_address: new FormControl('', [Validators.email]),
      address_text: new FormControl('', []),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/), Validators.pattern(/[A-Za-z]/)]),
      confirmpassword: new FormControl(''),
      postcode: new FormControl('', []),
      category_id: new FormControl('', []),

    },
      // passwordMatchValidator 
      {
        validators: this.passwordValidator
      })

    this.termsForm = new FormGroup({
      terms: new FormControl({ value: '', disabled: true }, Validators.required)
    });

    this.ownerForm.valueChanges.subscribe(() => {
      if (this.ownerForm.valid) {
        this.termsForm.controls['terms'].enable();
      } else {
        this.termsForm.controls['terms'].disable();
      }
    });

    this.metaTagService.updateTag(
      { name: 'description', content: 'Sign up now & earn easily from first day!' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'partner signup, apointt partner signup, apointt partner register, partner signup for online booking,' }
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }

  public onCancel = () => {
    this.location.back();
  }
  
  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid) {
      this.partnersignup(ownerFormValue);
    }
  }

  partnersignup(ownerFormValue) {
    let user: any = {
      full_name: ownerFormValue.full_name,
      mobile_number: ownerFormValue.mobile_number,
      // email_address:ownerFormValue.email_address,
      // password: ownerFormValue.password,
      password: this.cryptoService.set('secret key 123', ownerFormValue.password),
      user_type: 3
      // confirmpassword: ownerFormValue.password,
      // postcode: ownerFormValue.postcode,
      // address_text: ownerFormValue.address_text,
      // category_id: ownerFormValue.category_id,
    };

    this.ngxService.start();

    this.Partnersignup.PartnersignupService(user)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if ((_response.status === 200) && (_response.body.msg == 'mobile alreday exist')) {
            this.toastr.warning('', 'Mobile number is already registered');
            // this.ownerFormValue.controls['postcode'].setValue(_response.body.postcode);
          } else if (_response.status === 200) {
            // alert("Successful")
            this.toastr.success('Registration successful');
            this.Router.navigate(['/partner-login']);
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.toastr.error(' invalid username or password');
          // alert('invalid username or password');
        }
      );
  }

  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('password').value !== ownerForm.get('confirmpassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }
}
