import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CanonicalService } from './services/canonical/canonical.service';
import {DeeplinkService} from './services/deeplink/deeplink.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'scheduler';
  //lat = 18.557961;
  //lng = 73.7830858;
  constructor(
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    private deeplinkService  : DeeplinkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.canonicalService.setCanonicalURL();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(()=> {
      var rt = this.getChild(this.activatedRoute)
      console.log(rt);
      console.log(this.activatedRoute);

      rt.data.subscribe(data => {
        console.log(data);

        if (data.description) {
          this.metaTagService.updateTag({ name: 'description', content: data.description })
        } else {
          this.metaTagService.removeTag("name='description'")
        }

        if (data.robots) {
          this.metaTagService.updateTag({ name: 'robots', content: data.robots })
        } else {
          this.metaTagService.updateTag({ name: 'robots', content: "follow,index" })
        }

        if (data.ogDescription) {
          this.metaTagService.updateTag({ property: 'og:description', content: data.ogDescription })
        } else {
          this.metaTagService.removeTag("property='og:description'")
        }

        if (data.keywords) {
          this.metaTagService.updateTag({ property: 'keywords', content: data.ogDescription })
        } else {
          this.metaTagService.removeTag("property='keywords'")
        }
      })
    })

    

    // this.metaTagService.addTags([
    //   { name: 'description', content: 'Apointt is used to Search Doctors, Lawyers, CA, CS,Beauticians and other professionals nearby to your location, to book appointment from schedule and Visit'},
    //   { name: 'keywords', content: 'beautician, ca dmv appointment, online doctor appointment scheduling, how to schedule doctor appointment, virtual doctor appointment, eye doctor appointment near me,' },
    //   { name: 'robots', content: 'index, follow' },
    //   { name: 'author', content: 'Blacktix Technology LLP' },
    //   { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    //   { name: 'date', content: '2020-07-31', scheme: 'YYYY-MM-DD' },
    //   { charset: 'UTF-8' }
    // ]);

    // this.deeplinkService.deeplink();
  }

  handleDetail(params){
    this.deeplinkService.deeplink();
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      console.log("if condition");
      return this.getChild(activatedRoute.firstChild);
    } else {
      console.log("Else condition");
      return activatedRoute;
      
    }
  }
  
}
