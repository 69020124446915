import { Component, OnInit } from '@angular/core';
import {GetProvideServiceService} from '../../../services/getProvider/get-provide-service.service';
import{Router}from '@angular/router';
import{ActivatedRoute}from '@angular/router';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent {
  model: any ={};
  subCategoriesService =[]
  sub:any;
  urlQueryParam ='';
  L_userid='';
  provider_id ='';

  constructor( private route:ActivatedRoute,
  private getProvideServiceService:GetProvideServiceService) { }

  ngOnInit() {

    this.sub = this.route
    .params
  .subscribe(params => {
    console.log(params);
    // Defaults to 0 if no query param provided.
    this.provider_id = params.provider_id || 1;
    console.log('provider_id query param', this.provider_id);
    // console.log('model  query param', this.model);
  });
    let queryParms = `?provider_id=${this.provider_id}?L_userid=${this.L_userid}`
    // let L_userid=`?L_userid=${this.L_userid}`
    this.getProvideServiceService.GetProvideServiceService(queryParms)
    .subscribe(
     (_response) => {
       console.log(_response);
       if (_response.status === 200) {
         try {
           this.subCategoriesService = _response.body;
           console.log('subCategoriesService',this.subCategoriesService)
           // shop_id => this.shop_id = shop_id
         } catch (err) {
 
         }
       }
     },
     (_error) => {
 
     }
   )
      console.log('data', 'response'); 
  }

}
