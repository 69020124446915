import { Injectable } from '@angular/core';
import{environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'
import { from } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GetProvideServiceService {
  constructor(private httpService: HttpClient) { }
  GetProvideServiceService(QueryParams) {
     const href = environment.api.getProvider +QueryParams;
     ;
    return this.httpService.get<any>(href, { 'observe': 'response' })
  }

  getProviderByPostcode(query) {
    const href = environment.api.getproviderbypostcode;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }
  
}
