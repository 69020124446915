import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GetappointdetailsService } from '../../services/getappointdetails/getappointdetails.service';
import * as moment from 'moment';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-video-call-test',
  templateUrl: './video-call-test.component.html',
  styleUrls: ['./video-call-test.component.css']
})
export class VideoCallTestComponent implements OnInit, OnDestroy {
  isButtonDisabled = false;
  appoint_id;
  url = 'https://video.apointt.com:8443/';
  urlSafe: SafeResourceUrl;
  appointmentDetails = [];
  intervalId;
  // buttonintervalId;
  showVideo = true;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private sanitizer: DomSanitizer,
    private getAppointmentDetailsService: GetappointdetailsService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    // clearInterval(this.buttonintervalId);
  }

  ngOnInit() {
    this.appoint_id = localStorage.getItem('appoint_id');

    // let query = `?appoint_id=${this.appoint_id}`;
    let query = {
      appoint_id: this.appoint_id
    };

    this.server.request('GET', '/appointment/getAppointmentDetails', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.appointmentDetails = _response.body;
            this.checkVideoCallTime();
          }
        },
        (_error) => {

        });

    // this.getAppointmentDetailsService.getAppointmentDetailsById(query)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.appointmentDetails = _response.body;
    //         this.checkVideoCallTime();
    //       }
    //     },
    //     (_error) => {

    //     });
    this.url = this.url + localStorage.getItem('appoint_id');

    // this.checkVideoCallTime();
    this.intervalId = setInterval(() => {
      this.checkVideoCallTime();
    }, 1000);
    // this.buttonintervalId = setInterval(() => {
    //   this.disablebutton();
    // }, 1000);
  }

  videoCall() {
    // this.url = this.url + localStorage.getItem('appoint_id');
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.isButtonDisabled = !this.isButtonDisabled;
  }

  checkVideoCallTime() {
    let end_time = moment(this.appointmentDetails[0].endTime, 'hh:mm A');
    // let end_time = moment('03:03 PM', 'hh:mm A');

    // this.intervalId = setInterval(() => {
    let current_time = moment();
    let diff = end_time.diff(current_time, 'seconds');
    if (diff <= 0) {
      clearInterval(this.intervalId);
      this.showVideo = false;
      // console.log(moment(current_time).format('hh:mm A'), ' ', moment(end_time).format('hh:mm A'), ' diff: ', diff, ' close video');
    } else {
      this.showVideo = true;
      // console.log(moment(current_time).format('hh:mm A'), ' ', moment(end_time).format('hh:mm A'), ' diff: ', diff, ' video');
    }
    // }, 1000);
  }
  // disablebutton() {
  //   let start_time = moment(this.appointmentDetails[0].startTime, 'hh:mm A');
  //   let current_time = moment();
  //   let diff = start_time.diff(current_time, 'seconds');
  //   if (diff <= 0) {
  //     clearInterval(this.buttonintervalId);
  //     this.isButtonDisabled = true;
  //   } else {
  //     this.isButtonDisabled = false;
  //   }
  // }
}