import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { JwtTestService } from 'src/app/services/jwt-test/jwt-test.service';
import { GetcategoryServiceService } from '../../services/getcategory/getcategory-service.service';

@Component({
  selector: 'app-login-navbar',
  templateUrl: './login-navbar.component.html',
  styleUrls: ['./login-navbar.component.css']
})
export class LoginNavbarComponent implements OnInit {
  category: [];
  showName = false;
  showCustomerName = false;
  customerName = '';
  user_type;
  provider_id;
  provider_url;
  business_name;
  @Input('active') active: string;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private GetcategoryServiceService: GetcategoryServiceService,
    private router: Router,
    private server: JwtTestService,
  ) { 
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.GetcategoryServiceService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.category = _response.body;
          }
        },
        (_error) => {

        }
      );

    this.user_type = localStorage.getItem('user_type');
    if (this.user_type == 1) {
      this.showCustomerName = true;
      this.showName = false;
      this.customerName = localStorage.getItem('first_name');
    } else if (this.user_type == 2) {
      this.showCustomerName = false;
      this.showName = true;

      this.business_name = localStorage.getItem('business_name');
      this.provider_id = localStorage.getItem('provider_id');

      let queryParam = {
        provider_id: this.provider_id
      };

      this.server.request('GET', '/providers/getSettings', queryParam)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              this.provider_url = _response.body.provider_url;
            }
          },
          (_error) => {

          });
    } else {
      this.showName = false;
      this.showCustomerName = false;
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
    this.showName = false;
    this.showCustomerName = false;
  }

  setCategory(catName, catId) {
    localStorage.removeItem('catChildId');
    localStorage.removeItem('catChildName');
    localStorage.setItem('catName', catName);
    localStorage.setItem('catId', catId);
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   let element = document.querySelector('.navbar');
  //   if (window.pageYOffset > element.clientHeight) {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top navbar-scrolled';
  //   } else {
  //     element.className = 'navbar navbar-expand-md navbar-light fixed-top';
  //   }

  // }

}
