import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { GetpartnerdetailbypartneridService } from '../../../services/getpartnerdetailbypartnerid/getpartnerdetailbypartnerid.service';
import { UpdatepartnerdetailService } from '../../../services/updatepartnerdetail/updatepartnerdetail.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

interface category_id {
  value: string,
  viewValue: string
}

@Component({
  selector: 'app-partner-settings',
  templateUrl: './partner-settings.component.html',
  styleUrls: ['./partner-settings.component.css']
})
export class PartnerSettingsComponent implements OnInit {
  categories: category_id[] = [
    { value: '1', viewValue: 'Doctor' },
    { value: '2', viewValue: 'Lawyer' },
    { value: '3', viewValue: 'Beautician' },
    { value: '4', viewValue: 'CA' }
  ];
  public ownerForm: FormGroup;
  passwordHide = true;
  confirmPasswordHide = true;
  errorMatcher = new CrossFieldErrorMatcher();
  formGroup: FormGroup;
  isActive;
  patner_id = '';
  full_name;
  email_address;
  mobile_number;
  address_text;
  postcode;
  category_id;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private location: Location,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private getPartnerDetailService: GetpartnerdetailbypartneridService,
    private updatePartnerDetailService: UpdatepartnerdetailService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.ownerForm = new FormGroup({
      full_name: new FormControl(''),
      mobile_number: new FormControl({ value: '', disabled: true }),
      email_address: new FormControl('', [Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      address_text: new FormControl('', [Validators.required]),
      password: new FormControl('',),
      confirmpassword: new FormControl(''),
      postcode: new FormControl('', [Validators.required]),
      category_id: new FormControl(''),
    },
      {
        validators: this.passwordValidator
      });

    this.patner_id = localStorage.getItem('patner_id');

    let query = {
      patner_id: this.patner_id
    };

    this.server.request('GET', '/patner/getPatnerDetail', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.full_name = _response.body.full_name;
            this.email_address = _response.body.email_address;
            this.mobile_number = _response.body.mobile_number;
            this.address_text = _response.body.address_text;
            this.postcode = _response.body.postcode;
            this.category_id = _response.body.category_id;

            this.ownerForm.controls['full_name'].setValue(this.full_name);
            this.ownerForm.controls['email_address'].setValue(this.email_address);
            this.ownerForm.controls['mobile_number'].setValue(this.mobile_number);
            this.ownerForm.controls['address_text'].setValue(this.address_text);
            this.ownerForm.controls['postcode'].setValue(this.postcode);
            this.ownerForm.controls['category_id'].setValue(this.category_id);
          }
        },
        (_error) => {

        });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.location.back();
  }
  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid) {
      this.partnersignup(ownerFormValue);
    } else {
      this.toastr.error('Please fill all the details');
    }
  }

  partnersignup(ownerFormValue) {
    let user: any = {
      full_name: ownerFormValue.full_name,
      mobile_number: ownerFormValue.mobile_number,
      email_address: ownerFormValue.email_address,
      // password: ownerFormValue.password,
      // confirmpassword: ownerFormValue.password,
      postcode: ownerFormValue.postcode,
      address_text: ownerFormValue.address_text,
      category_id: ownerFormValue.category_id,
      patner_id: this.patner_id,
      userid: localStorage.getItem('userid')
    };

    this.ngxService.start();

    this.server.request('POST', '/patner/patnerDetailUpdate', user)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.toastr.success('Settings changed');
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.toastr.error('Unable to change settings');
        });
  }

  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('password').value !== ownerForm.get('confirmpassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }

}
