import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { GetuserbypaystatusService } from '../../../services/getuserbypaystatus/getuserbypaystatus.service';
import { ProviderbusinessgrowthService } from '../../../services/providerbusinessgrowth/providerbusinessgrowth.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-business-analytics',
  templateUrl: './business-analytics.component.html',
  styleUrls: ['./business-analytics.component.css']
})
export class BusinessAnalyticsComponent implements OnInit {
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = ['COV', 'Pay Online (full)', 'Pay Advance'];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  pieChartPlugins;
  // public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(235, 87, 87, 1)', 'rgba(87, 235, 235, 1)', 'rgba(235, 220, 87, 1)'],
    },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 300
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  barChartPlugins
  // public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Appointments (per month)' },
  ];
  public barChartColors = [
    {
      backgroundColor: 'rgba(87, 235, 235, 1)',
    },
  ];

  provider_id = '';
  total_pay_amount;
  cov_amount;
  full_pay_amount;
  advanced_pay_amount;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getUserByPayStatus: GetuserbypaystatusService,
    private providerBusinessGrowth: ProviderbusinessgrowthService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');

    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.total_pay_amount = _response.body.total_pay_amount;
            this.cov_amount = _response.body.cov_amount;
            this.full_pay_amount = _response.body.full_pay_amount;
            this.advanced_pay_amount = _response.body.advanced_pay_amount;

            this.pieChartData.push(this.cov_amount);
            this.pieChartData.push(this.full_pay_amount);
            this.pieChartData.push(this.advanced_pay_amount);
          }
        },
        (_error) => {

        });

    let businessQuery = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/providerBusinessGrowth', businessQuery)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.barChartData[0].data.push(_response.body.jan_month_appointment);
            this.barChartData[0].data.push(_response.body.feb_month_appointment);
            this.barChartData[0].data.push(_response.body.mar_month_appointment);
            this.barChartData[0].data.push(_response.body.april_month_appointment);
            this.barChartData[0].data.push(_response.body.may_month_appointment);
            this.barChartData[0].data.push(_response.body.june_month_appointment);
            this.barChartData[0].data.push(_response.body.july_month_appointment);
            this.barChartData[0].data.push(_response.body.aug_month_appointment);
            this.barChartData[0].data.push(_response.body.sep_month_appointment);
            this.barChartData[0].data.push(_response.body.oct_month_appointment);
            this.barChartData[0].data.push(_response.body.nov_month_appointment);
            this.barChartData[0].data.push(_response.body.dec_month_appointment);
          }
        },
        (_error) => {

        });
  }

}
