import { Component, OnInit, ViewChild } from '@angular/core';
import { GetuserbypaystatusService } from '../../../services/getuserbypaystatus/getuserbypaystatus.service';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface details {
  userid: string;
  date: string;
  total_cost: string;
  pay_status: string;
  name: string;
  mobile_number: string;
}

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {
  provider_id = '';
  details = new MatTableDataSource<any>([]);
  total_pay_amount;
  gst_total_pay_amount;
  gst_amt;
  cov_amount = 0;
  advanced_pay_amount;
  gst_total_online_amount;
  gst_total_advance_amount;
  gst_total_full_amount;
  detailsAvailable;
  settlement;
  appoint_status;
  month_gst_total_online_amount;
  displayedColumns: string[] = ['name', 'mobile_number', 'date', 'total_cost', 'pay_status', 'appoint_status'];
  pay_methods = [{ id: 0, method: 'COV' }, { id: 1, method: 'Full Payment' }, { id: 2, method: 'Advance Payment' }, { id: 3, method: 'Refunded' }, { id: 5, method: 'Settlement' }, { id: 4, method: 'View All' }];
  show_cov = false;
  show_pay_online = false;
  show_pay_advance = false;
  show_refund = false;
  show_settlement = false;
  total_online_amount;
  settlement_array = [];
  month;
  month_name;
  months = [
    { value: '01', month: 'January' },
    { value: '02', month: 'February' },
    { value: '03', month: 'March' },
    { value: '04', month: 'April' },
    { value: '05', month: 'May' },
    { value: '06', month: 'June' },
    { value: '07', month: 'July' },
    { value: '08', month: 'August' },
    { value: '09', month: 'September' },
    { value: '10', month: 'October' },
    { value: '11', month: 'November' },
    { value: '12', month: 'December' }];
  years = [];
  year;
  appointment_status = [{ id: 0, status: 'Pending' }, { id: 1, status: 'Accepted' }, { id: 2, status: 'Rejected' }, { id: 3, status: 'Refunded' }];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getUserByPayStatus: GetuserbypaystatusService,
    private ngxService: NgxUiLoaderService,
    private server: JwtTestService,
    private toaster: ToastrService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngAfterViewInit() {
    this.details.paginator = this.paginator;
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');

    this.years.push(moment().subtract(1, 'year').format('YYYY'));
    this.years.push(moment().format('YYYY'));

    let queryParam = {
      provider_id: this.provider_id
    };

    this.ngxService.start();

    this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
      .subscribe(
        (_response: any) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);

          if (_response.status === 200) {
            this.detailsAvailable = true;
            this.details.data = _response.body.results;
            this.total_pay_amount = _response.body.total_pay_amount;
            this.gst_total_pay_amount = _response.body.gst_total_pay_amount;
            this.gst_amt = _response.body.gst_amt;
            this.cov_amount = _response.body.cov_amount;
            this.advanced_pay_amount = _response.body.advanced_pay_amount;
            this.gst_total_online_amount = _response.body.gst_total_online_amount;
            this.month_gst_total_online_amount = _response.body.month_gst_total_online_amount;
            this.gst_total_advance_amount = _response.body.gst_total_advance_amount.toFixed(2);
            this.gst_total_full_amount = _response.body.gst_total_full_amount;
            localStorage.setItem('month_gst_total_online_amount', this.month_gst_total_online_amount);
            this.total_online_amount = _response.body.total_online_amount;

            for (var i = 0; i < this.details.data.length; i++) {
              this.details.data[i].date = moment(this.details.data[i].start_datetime).format('DD MMM YYYY');

              if (this.details.data[i].pay_status == '0') {
                this.details.data[i].pay_status = 'COV';
              }
              if (this.details.data[i].pay_status == '1') {
                this.details.data[i].pay_status = 'Full Payment';
              }
              if (this.details.data[i].pay_status == '2') {
                this.details.data[i].pay_status = 'Advance Payment';
              }

              if (this.details.data[i].appoint_status == '3') {
                this.details.data[i].appoint_status = 'Refunded';
              }
              if (this.details.data[i].appoint_status == '2') {
                this.details.data[i].appoint_status = 'Rejected';
              }
              if (this.details.data[i].appoint_status == '1') {
                this.details.data[i].appoint_status = 'Accepted';
              }
              if (this.details.data[i].appoint_status == '0') {
                this.details.data[i].appoint_status = 'Pending';
              }
            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.detailsAvailable = false;
        });

    this.details.filterPredicate = (data: details, filter: string) => {
      // return data.name == filter;
      return data.name.toLowerCase().includes(filter.toLowerCase());
    };
  }

  monthFilter(event) {
    this.month = event.value;
    this.month_name = (event.value - 1).toString().length == 1 ? '0'.concat((event.value - 1).toString()) : (event.value - 1);
    console.log('month_name: ', this.month_name)
    this.onChange('5');

    if (moment(this.month, 'MM').isSame(moment(), 'month')) {
      this.toaster.error('Settlement for current month is not possible');
    }
  }

  yearFilter(event) {
    this.year = event.value;
    this.onChange('5');
  }

  onChange(event) {
    if (event) {
      let queryParam = {
        provider_id: this.provider_id,
        appoint_status: event.id == 3 ? event.id : event.id == 0 || event.id == 1 || event.id == 2 ? 1 : ``,
        pay_status: event.id == 0 || event.id == 1 || event.id == 2 ? event.id : ``,
        settlement: '',
        month_name: '',
        month: '',
        year: ''
      };
      if (this.displayedColumns.indexOf('settlement_status') > 1) {
        this.displayedColumns.splice(this.displayedColumns.indexOf('settlement_status'), 1);
      }
      if (event.id == 4) {
        queryParam = {
          provider_id: this.provider_id,
          appoint_status: '',
          pay_status: '',
          settlement: '',
          month_name: '',
          month: '',
          year: ''
        };
        if (this.displayedColumns.indexOf('settlement_status') > 1) {
          this.displayedColumns.splice(this.displayedColumns.indexOf('settlement_status'), 1);
        }
      }
      else if (event.id == 5) {
        queryParam = {
          provider_id: this.provider_id,
          appoint_status: '',
          pay_status: '',
          settlement: 'abc',
          month_name: this.month ? moment(this.month, 'MM').subtract(1, 'month').format('MM') : moment(this.details.data[0].start_datetime, 'YYYY-MM-DD HH:mm:ss').subtract(1, 'month').format('MM'), // 00 = jan
          month: this.month ? moment(this.month, 'MM').format('MM') : moment(this.details.data[0].start_datetime, 'YYYY-MM-DD HH:mm:ss').format('MM'), //01 = jan
          year: this.year ? this.year : moment(this.details.data[0].start_datetime, 'YYYY')
        };
        this.displayedColumns.push('settlement_status');
        this.show_settlement = true;
      }

      this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              this.detailsAvailable = true;
              this.details.data = _response.body.results;
              this.settlement_array = _response.body.results;
              this.total_pay_amount = _response.body.total_pay_amount;
              this.month_gst_total_online_amount = _response.body.month_gst_total_online_amount;
              this.cov_amount = 0;
              localStorage.setItem('month_gst_total_online_amount', this.month_gst_total_online_amount);

              if (event.id == 0) {
                this.show_cov = true;
                this.show_pay_online = false;
                this.show_pay_advance = false;
                this.show_refund = false;
                this.show_settlement = false;
                this.settlement = false;

              } else if (event.id == 1) {
                this.show_pay_online = true;
                this.show_pay_advance = false;
                this.show_cov = false;
                this.show_refund = false;
                this.show_settlement = false;
                this.settlement = false;

              } else if (event.id == 2) {
                this.show_pay_advance = true;
                this.show_pay_online = false;
                this.show_cov = false;
                this.show_refund = false;
                this.show_settlement = false;
                this.settlement = false;

              } else if (event.id == 3) {
                this.show_refund = true;
                this.show_pay_online = false;
                this.show_cov = false;
                this.show_pay_advance = false;
                this.show_settlement = false;
                this.settlement = false;

              } else if (event.id == 5) {
                this.show_settlement = true;
                this.show_pay_online = false;
                this.show_cov = false;
                this.show_pay_advance = false;
                this.show_refund = false;

              } else if (event.id == 4) {
                this.show_settlement = false;
                this.show_pay_online = false;
                this.show_cov = false;
                this.show_pay_advance = false;
                this.show_refund = false;
                this.settlement = false;
              }

              for (var i = 0; i < this.details.data.length; i++) {
                this.cov_amount += parseInt(this.details.data[i].total_cost);
                this.details.data[i].date = moment(this.details.data[i].start_datetime).format('DD MMM YYYY');

                if (this.details.data[i].pay_status == '0') {
                  this.details.data[i].pay_status = 'COV';
                }
                if (this.details.data[i].pay_status == '1') {
                  this.details.data[i].pay_status = 'Full Payment';
                }
                if (this.details.data[i].pay_status == '2') {
                  this.details.data[i].pay_status = 'Advance Payment';
                }

                if (this.details.data[i].appoint_status == '3') {
                  this.details.data[i].appoint_status = 'Refunded';
                }
                if (this.details.data[i].appoint_status == '2') {
                  this.details.data[i].appoint_status = 'Rejected';
                }
                if (this.details.data[i].appoint_status == '1') {
                  this.details.data[i].appoint_status = 'Accepted';
                }
                if (this.details.data[i].appoint_status == '0') {
                  this.details.data[i].appoint_status = 'Pending';
                }
              }

              if (event.id == 5) {
                let settlement_query = {
                  related_id: this.provider_id,
                  // settlement_array: JSON.stringify(this.settlement_array)
                  start_datetime: this.settlement_array[0].start_datetime,
                };
                localStorage.setItem('settlement_array', JSON.stringify(this.settlement_array));

                console.log('settlement: ', settlement_query);

                this.server.request('GET', '/providers/isSettlementActive', settlement_query)
                  .subscribe(
                    (_response: any) => {
                      if (_response.status === 200) {
                        this.settlement = true;
                      }
                    },
                    (_error) => {
                      this.settlement = false;
                    });
              }

            }
          },
          (_error) => {
            this.detailsAvailable = false;
          });
    }
  }

  onAppointmentFilterChange(event) {
    if (event) {
      let queryParam = {
        provider_id: this.provider_id,
        appoint_status: event.id,
        pay_status: ``,
        settlement: '',
        month_name: '',
        month: '',
        year: ''
      };

      this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              this.detailsAvailable = true;
              this.details.data = _response.body.results;
              this.total_pay_amount = _response.body.total_pay_amount;

              for (var i = 0; i < this.details.data.length; i++) {
                this.details.data[i].date = moment(this.details.data[i].start_datetime).format('DD MMM YYYY');

                if (this.details.data[i].pay_status == '0') {
                  this.details.data[i].pay_status = 'COV';
                }
                if (this.details.data[i].pay_status == '1') {
                  this.details.data[i].pay_status = 'Pay Online (full)';
                }
                if (this.details.data[i].pay_status == '2') {
                  this.details.data[i].pay_status = 'Pay Advance';
                }

                if (this.details.data[i].appoint_status == '3') {
                  this.details.data[i].appoint_status = 'Refunded';
                }
                if (this.details.data[i].appoint_status == '2') {
                  this.details.data[i].appoint_status = 'Rejected';
                }
                if (this.details.data[i].appoint_status == '1') {
                  this.details.data[i].appoint_status = 'Accepted';
                }
                if (this.details.data[i].appoint_status == '0') {
                  this.details.data[i].appoint_status = 'Pending';
                }
              }
            }
          },
          (_error) => {
            if (_error.status === 404) {
              this.detailsAvailable = false;
            }
          });
    }
  }

  onClear(event) {
    let queryParam = {
      provider_id: this.provider_id
    };
    this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.detailsAvailable = true;
            this.details.data = _response.body.results;
            this.total_pay_amount = _response.body.total_pay_amount;

            for (var i = 0; i < this.details.data.length; i++) {
              this.details.data[i].date = moment(this.details.data[i].start_datetime).format('DD MMM YYYY');

              if (this.details.data[i].pay_status == '0') {
                this.details.data[i].pay_status = 'COV';
              }
              if (this.details.data[i].pay_status == '1') {
                this.details.data[i].pay_status = 'Pay Online (full)';
              }
              if (this.details.data[i].pay_status == '2') {
                this.details.data[i].pay_status = 'Pay Advance';
              }

              if (this.details.data[i].appoint_status == '3') {
                this.details.data[i].appoint_status = 'Refunded';
              }
              if (this.details.data[i].appoint_status == '2') {
                this.details.data[i].appoint_status = 'Rejected';
              }
              if (this.details.data[i].appoint_status == '1') {
                this.details.data[i].appoint_status = 'Accepted';
              }
              if (this.details.data[i].appoint_status == '0') {
                this.details.data[i].appoint_status = 'Pending';
              }
            }
          }
        },
        (_error) => {
          if (_error.status === 404) {
            this.detailsAvailable = false;
          }
        });
  }

  applyFilter(filterValue: string) {
    this.details.filter = filterValue;
  }

  applyDateFilter(dateInput) {
    console.log('date', dateInput);
    let date = moment(dateInput).format('YYYY-MM-DD');

    let queryParam = {
      provider_id: this.provider_id,
      appoint_status: ``,
      pay_status: ``,
      settlement: '',
      month_name: '',
      month: '',
      year: '',
      date: dateInput ? date : ``
    };

    this.server.request('GET', '/providers/getUsersByPayStatus', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.detailsAvailable = true;
            this.details.data = _response.body.results;
            this.total_pay_amount = _response.body.total_pay_amount;

            for (var i = 0; i < this.details.data.length; i++) {
              this.details.data[i].date = moment(this.details.data[i].start_datetime).format('DD MMM YYYY');

              if (this.details.data[i].pay_status == '0') {
                this.details.data[i].pay_status = 'COV';
              }
              if (this.details.data[i].pay_status == '1') {
                this.details.data[i].pay_status = 'Pay Online (full)';
              }
              if (this.details.data[i].pay_status == '2') {
                this.details.data[i].pay_status = 'Pay Advance';
              }

              if (this.details.data[i].appoint_status == '3') {
                this.details.data[i].appoint_status = 'Refunded';
              }
              if (this.details.data[i].appoint_status == '2') {
                this.details.data[i].appoint_status = 'Rejected';
              }
              if (this.details.data[i].appoint_status == '1') {
                this.details.data[i].appoint_status = 'Accepted';
              }
              if (this.details.data[i].appoint_status == '0') {
                this.details.data[i].appoint_status = 'Pending';
              }
            }
          }
        },
        (_error) => {
          if (_error.status === 404) {
            this.detailsAvailable = false;
          }
        });
  }

}