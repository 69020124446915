import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class SignupBusinessService {

  constructor(private httpService: HttpClient) { }

  SignupBusinessService(data) {
    return this.httpService.post<any>(environment.api.signupbusiness, data, {'observe': 'response'});
  }
}
