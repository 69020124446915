import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavbarComponent } from './ui-components/navbar/navbar.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { HomePageComponent } from './modules/home-page/home-page.component';
// import { DtSchedulerComponent } from './ui-components/initial-wizard/dt-scheduler/dt-scheduler.component';
// import { WizardComponent } from './ui-components/initial-wizard/wizard/wizard.component';
// import { CategoryServiceComponent } from './ui-components/category-service/category-service.component';
// import { FooterComponent } from './ui-components/footer/footer.component';
import { DsNavbarComponent } from './ui-components/navbar/ds-navbar/ds-navbar.component';
// import { DtSelectionComponent } from './ui-components/dt-selection/dt-selection.component';
// import { AppointTableComponent } from './ui-components/appoint-table/appoint-table.component';
import { from } from 'rxjs';
// import { AgmComponentComponent } from '../app/ui-components/agm-component/agm-component.component';
// import { CreateneweventComponent } from './ui-components/createnewevent/createnewevent.component';
// import { OneOnOneComponent } from './ui-components/one-on-one/one-on-one.component';
import { SignupAdminComponent } from './modules/signup-admin/signup-admin.component';
// import { AccSettingsComponent } from './modules/home-page/acc-settings/acc-settings.component';
import { CalenderSchedulerComponent } from './ui-components/calender-scheduler/calender-scheduler.component';
import { CategoriesViewComponent } from './ui-components/customer-dashboard/categories-view/categories-view.component';
import { ChildCategoryViewComponent } from './ui-components/customer-dashboard/child-category-view/child-category-view.component';
import { ProviderDetailsViewComponent } from './ui-components/customer-dashboard/provider-details-view/provider-details-view.component';
// import { ServiceDetailsComponent } from './ui-components/customer-dashboard/service-details/service-details.component';
import { CategoryComponent } from './ui-components/card-services/category/category.component';
import { ChildCategoryComponent } from './ui-components/card-services/child-category/child-category.component';
import { ProviderDetailsComponent } from './ui-components/card-services/provider-details/provider-details.component';
// import { DetailsComponent } from './ui-components/card-services/details/details.component';
// import { CalendarSchedulerComponent } from './ui-components/card-services/calendar-scheduler/calendar-scheduler.component';
import { AppointSuccessComponent } from './ui-components/customer-dashboard/appoint-success/appoint-success.component';
//import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { GetLocationComponent } from './ui-components/get-location/get-location.component';
// import{CompanyProfileComponent} from './business-wizard/company-profile/company-profile.component';
//import{ProfileDetailsViewComponent} from './ui-components/customer-dashboard/profile-details-view/profile-details-view.component';
import { ProfileDetailsComponent } from './ui-components/customer-dashboard/profile-details/profile-details.component';
import { BusinessWizardComponent } from './ui-components/business-wizard/business-wizard.component';
import { SignupBusinessComponent } from './modules/signup-business/signup-business.component';
// import { ContactInfoComponent } from './ui-components/business-wizard/contact-info/contact-info.component';
import { CustomerDashboardComponent } from './ui-components/customer-dashboard/customer-dashboard.component';
import { ShareDialogComponent } from './ui-components/card-services/share-dialog/share-dialog.component';
import { QuicksigninComponent } from './modules/quicklogin/quicksignin/quicksignin.component';
import { QuicksignupComponent } from './modules/quicklogin/quicksignup/quicksignup.component';
import { ProviderDashboardComponent } from './ui-components/provider-dashboard/provider-dashboard.component';
import { BookedAppointDetailsComponent } from './ui-components/booked-appoint-details/booked-appoint-details.component';
import { ProviderProfileComponent } from './ui-components/provider-profile/provider-profile.component';
import { CustomerProfileComponent } from './ui-components/customer-dashboard/customer-profile/customer-profile.component';
import { AppointmentsComponent } from './ui-components/provider-dashboard/appointments/appointments.component';
import { DashboardComponent } from './ui-components/provider-dashboard/dashboard/dashboard.component';
import { ChangepasswordComponent } from './ui-components/provider-dashboard/changepassword/changepassword.component';
import { ChangepasswordCustomerComponent } from './ui-components/customer-dashboard/changepassword-customer/changepassword-customer.component';
import { MyclientsComponent } from './ui-components/provider-dashboard/myclients/myclients.component';
import { SchedulerComponent } from './ui-components/provider-dashboard/scheduler/scheduler.component';
import { AccountSettingsComponent } from './ui-components/provider-dashboard/account-settings/account-settings.component';
import { PaymentMethodComponent } from './ui-components/calender-scheduler/payment-method/payment-method.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { IntroductionPageComponent } from './ui-components/introduction-page/introduction-page/introduction-page.component';
import { CancellationRefundComponent } from './ui-components/introduction-page/cancellation-refund/cancellation-refund.component';
import { PrivacyStatementComponent } from './ui-components/introduction-page/privacy-statement/privacy-statement.component';
import { TermsOfServiceComponent } from './ui-components/introduction-page/terms-of-service/terms-of-service.component';
// import { HelpPageComponent } from './ui-components/introduction-page/help-page/help-page.component';
import { FeedbackFormComponent } from './ui-components/feedback-form/feedback-form.component';
import { HeaderNavbarComponent } from './ui-components/header-navbar/header-navbar.component';
import { WebFooterComponent } from './ui-components/web-footer/web-footer.component';
import { PageFooterComponent } from './ui-components/page-footer/page-footer.component';
import { CalenderViewComponent } from './ui-components/provider-dashboard/calender-view/calender-view.component';
import { PaymentDetailsComponent } from './ui-components/provider-dashboard/payment-details/payment-details.component';
import { BusinessAnalyticsComponent } from './ui-components/provider-dashboard/business-analytics/business-analytics.component';
import { MyGalleryComponent } from './ui-components/provider-dashboard/my-gallery/my-gallery.component';
import { SubscriptionComponent } from './ui-components/introduction-page/subscription/subscription.component';
import { SubscriptionsComponent } from './ui-components/provider-dashboard/subscriptions/subscriptions.component';
import { ReschedulerComponent } from './ui-components/provider-dashboard/rescheduler/rescheduler.component';
import { AddclientsComponent } from './ui-components/provider-dashboard/addclients/addclients.component';
import { HelpComponent } from './ui-components/introduction-page/help/help.component';
import { AdminComponent } from './ui-components/admin/admin.component';
import { AdminLoginComponent } from './modules/admin-login/admin-login.component';
import { CustomerDetailComponent } from './ui-components/admin/customer-detail/customer-detail.component';
import { AdminDashboardComponent } from './ui-components/admin/admin-dashboard/admin-dashboard.component';
import { QrDownloadComponent } from './ui-components/provider-dashboard/qr-download/qr-download.component';
import { OverlayTourComponent } from './ui-components/overlay-tour/overlay-tour.component';
import { SignupPartnerComponent } from './modules/signup-partner/signup-partner.component';
import { PartnerLoginComponent } from './modules/partner-login/partner-login.component';
import { PartnerDashboardComponent } from './ui-components/partner/partner-dashboard/partner-dashboard.component';
import { PartnerComponent } from './ui-components/partner/partner.component';
import { PartnerProviderDetailsComponent } from './ui-components/partner/partner-provider-details/partner-provider-details.component';
import { PartnerSettingsComponent } from './ui-components/partner/partner-settings/partner-settings.component';
import { ReferralComponent } from './ui-components/provider-dashboard/referral/referral.component';
import { ProviderDetailComponent } from './ui-components/admin/provider-detail/provider-detail.component';
import { PartnerDetailComponent } from './ui-components/admin/partner-detail/partner-detail.component';
import { SettlementCaseComponent } from './ui-components/provider-dashboard/settlement-case/settlement-case.component';
import { MonthlyInvoiceComponent } from './ui-components/provider-dashboard/monthly-invoice/monthly-invoice.component';
import { PartnerSettlementComponent } from './ui-components/partner/partner-settlement/partner-settlement.component';
import { VideoCallTestComponent } from './ui-components/video-call-test/video-call-test.component';
import { CalendarDemoComponent } from './ui-components/calendar-demo/calendar-demo.component';
import { ScheduleManagementComponent } from './ui-components/provider-dashboard/schedule-management/schedule-management.component';
import { ProviderVideoCallComponent } from './ui-components/provider-dashboard/provider-video-call/provider-video-call.component';
import { MobileFooterComponent } from './ui-components/mobile-footer/mobile-footer.component';
import { BusinessSignupComponent } from './ui-components/business-signup/business-signup.component';
import { FeaturesComponent } from './ui-components/introduction-page/features/features.component';
import { SettlementDetailComponent } from './ui-components/admin/settlement-detail/settlement-detail.component';
import { SettlementDetailByIdComponent } from './ui-components/admin/settlement-detail-by-id/settlement-detail-by-id.component';
import { PartnerReportComponent } from './ui-components/partner/partner-report/partner-report/partner-report.component';
import { PartnerRefferalComponent } from './ui-components/partner/partner-refferal/partner-refferal.component';
import { PartnerProviderProfileDetailsComponent } from './ui-components/partner/partner-provider-profile-details/partner-provider-profile-details.component';
import { PartnerHomeComponent } from './ui-components/partner/partner-home/partner-home.component';
import { PartnerProviderReferDetailsComponent } from './ui-components/partner/partner-provider-refer-details/partner-provider-refer-details.component';
import { PartnerProviderAppointmentDetailsComponent } from './ui-components/partner/partner-provider-appointment-details/partner-provider-appointment-details.component';
import { PartnerAllAppointmentDetailsComponent } from './ui-components/partner/partner-all-appointment-details/partner-all-appointment-details.component';
import { ViewDocumentComponent } from './ui-components/provider-dashboard/view-document/view-document.component';
import { EnquiryFormComponent } from './ui-components/enquiry-form/enquiry-form.component';
import { EnquiryDetailsComponent } from './ui-components/admin/enquiry-details/enquiry-details.component';
import { AboutUsComponent } from './ui-components/introduction-page/about-us/about-us.component';
import { ContactUsComponent } from './ui-components/introduction-page/contact-us/contact-us.component';
import { ChoosePaymentComponent } from './ui-components/calender-scheduler/choose-payment/choose-payment.component';

const routes: Routes = [
  { path: 'features', component: FeaturesComponent },
  { path: 'business-sign', component: BusinessSignupComponent,
    data: {
      description: "Grow business by managing client appointments online, organised payment structure & monthly growth reports & analytics insights. Sign up Now!",
      robots: 'index, follow',
      keywords: 'business signup, apointt business signup, apointt business register, grow business,'
    } 
  },
  { path: 'mobile-footer', component: MobileFooterComponent },
  {
    path: 'admin', component: AdminComponent,
    children: [
      { path: '', component: AdminDashboardComponent },
      { path: 'admin-dashboard', component: AdminDashboardComponent },
      { path: 'customer-detail', component: CustomerDetailComponent },
      { path: 'provider-details', component: ProviderDetailComponent },
      { path: 'partner-details', component: PartnerDetailComponent },
      { path: 'settlements', component: SettlementDetailComponent },
      { path: 'settlement-details', component: SettlementDetailByIdComponent },
      { path: 'enquiry-details', component: EnquiryDetailsComponent }
    ]
  },
  {
    path: 'partner', component: PartnerComponent,
    children: [
      { path: '', component: PartnerProviderReferDetailsComponent },
      { path: 'partner-dashboard', component: PartnerDashboardComponent },
      { path: 'provider-details', component: PartnerProviderDetailsComponent },
      { path: 'partner-settings', component: PartnerSettingsComponent },
      { path: 'partner-settlement', component: PartnerSettlementComponent },
      { path: 'partner-report', component: PartnerReportComponent },
      { path: 'partner-referral', component: PartnerRefferalComponent },
      { path: 'partner-providerdetails/:provider_id', component: PartnerProviderProfileDetailsComponent },
      { path: 'partner-referral-details', component: PartnerProviderReferDetailsComponent },
      { path: 'partner-appointment-details', component: PartnerProviderAppointmentDetailsComponent },
      { path: 'all-appointments', component: PartnerAllAppointmentDetailsComponent },
    ]
  },
  { path: 'partner-login', component: PartnerLoginComponent,
  data: {
    description: "Login now & earn easily from first day!",
    robots: 'index, follow',
    keywords: 'partner login, apointt partner login, apointt register partner, Partner login for easy income,'
  } },
  { path: 'partner-signup', component: SignupPartnerComponent,
  data: {
    description: "Sign up now & earn easily from first day!",
    robots: 'index, follow',
    keywords: 'partner signup, apointt partner signup, apointt partner register, partner signup for online booking,'
  }   },
  { path: 'partner-home', component: PartnerHomeComponent,
    data: {
      description: "Want to grow by easy earning with zero investment and efforts from the first day? Partner with us!",
      robots: 'index, follow',
      keywords: 'partner easy income, no investment, zero effort, earn easily,'
    }  
  },
  { path: 'admin-login', component: AdminLoginComponent },
  { path: 'overlay', component: OverlayTourComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'help', component: HelpComponent },
  // {path: 'help-page', component: HelpPageComponent},
  { path: 'intro-page', component: IntroductionPageComponent },
  { path: 'header-navbar', component: HeaderNavbarComponent },
  { path: 'web-footer', component: WebFooterComponent },
  { path: 'page-footer', component: PageFooterComponent },
  { path: 'cancellation', component: CancellationRefundComponent },
  { path: 'privacy-statement', component: PrivacyStatementComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: '', component: IntroductionPageComponent },
  {
    path: 'navbar', component: NavbarComponent,
    children: [{ path: 'ds-navbar', component: DsNavbarComponent },
    ]
  },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'quicksignin/:provider_id', component: QuicksigninComponent },
  { path: 'quicksignup', component: QuicksignupComponent },
  // { path: 'contact-info', component: ContactInfoComponent },
  { path: 'home-page', component: HomePageComponent,
    data: {
      description: "Want to book an Online Appointment on your convinient day and time for expert advice and online consultation at nearby Hospital, Clinic, Salon, CA, Lawyer? Login Now!",
      robots: 'index, follow',
      ogDescription: 'Want to book an Online Appointment on your convinient day and time for expert advice and online consultation at nearby Hospital, Clinic, Salon, CA, Lawyer? Login Now!',
      keywords: 'Login, apointt Login, Login for online booking, Book appointment online',
  } 
 },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  // { path: 'appoint-table', component: AppointTableComponent },
  // {path:'company-profile',component:CompanyProfileComponent},
  // { path: '', component: AppointTableComponent },
  // { path: 'wizard', component: WizardComponent },
  // { path: 'agm-component', component: AgmComponentComponent },
  // { path: 'footer', component: FooterComponent },
  // { path: 'category-service', component: CategoryServiceComponent },
  { path: 'ds-navbar', component: DsNavbarComponent },
  // { path: 'createnewevent', component: CreateneweventComponent },
  // { path: 'one-on-one', component: OneOnOneComponent },
  // { path: 'dt-selection', component: DtSelectionComponent },
  // { path: 'dt-scheduler', component: DtSchedulerComponent },
  { path: 'signup-admin', component: SignupAdminComponent,
    data: {
      description: "Are you looking for nearby best Doctors, Lawyers, CA, Beauticians & other professionals? For Online consultation Signup now! ",
      robots: 'index, follow',
      ogDescription: 'Are you looking for nearby best Doctors, Lawyers, CA, Beauticians & other professionals? For Online consultation Signup now!',
      keywords: 'signup, apointt signup, apointt register, signup for online booking,'
    }  },
  // { path: 'acc-settings', component: AccSettingsComponent },
  // { path: 'appoint-table', component: AppointTableComponent },
  { path: 'categories-view', component: CategoriesViewComponent },
  { path: 'child-category-view/:catId', component: ChildCategoryViewComponent },
  { path: 'provider-details-view/:catId', component: ProviderDetailsViewComponent },
  // { path: 'service-details/:provider_id', component: ServiceDetailsComponent },
  // { path: 'details', component: DetailsComponent },
  // { path: 'calendar-scheduler', component: CalendarSchedulerComponent },
  { path: 'get-location', component: GetLocationComponent },
  //{path:'profile-details-view',component:ProfileDetailsViewComponent},
  { path: 'business-wizard', component: BusinessWizardComponent },
  { path: 'sign-up-business', component: SignupBusinessComponent },
  { path: 'sign-up-business/:type/:provider_id', component: SignupBusinessComponent },
  { path: 'eniquiry-form', component: EnquiryFormComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },

  //{path:'profile-details-view',component:ProfileDetailsViewComponent},
  { path: 'share-dialog', component: ShareDialogComponent },
  {
    path: 'provider-dashboard', component: ProviderDashboardComponent,
    children: [
      { path: '', component: CalenderViewComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'appointments', component: AppointmentsComponent },
      { path: 'change-password', component: ChangepasswordComponent },
      { path: 'my-clients', component: MyclientsComponent },
      { path: 'scheduler', component: SchedulerComponent },
      { path: 'account-settings', component: AccountSettingsComponent },
      { path: 'calendar-view', component: CalenderViewComponent },
      { path: 'payment-details', component: PaymentDetailsComponent },
      { path: 'business-analytics', component: BusinessAnalyticsComponent },
      { path: 'my-gallery', component: MyGalleryComponent},
      { path: 'subscriptions', component: SubscriptionsComponent },
      { path: 'rescheduler', component: ReschedulerComponent },
      { path: 'add-clients', component: AddclientsComponent },
      { path: 'qr-download', component: QrDownloadComponent },
      { path: 'referral', component: ReferralComponent },
      { path: 'settlement-case', component: SettlementCaseComponent },
      { path: 'monthly-invoice', component: MonthlyInvoiceComponent },
      { path: 'schedule-management', component: ScheduleManagementComponent },
      { path: 'video-call', component: ProviderVideoCallComponent },
      { path: 'view-document', component: ViewDocumentComponent },
    ]
  },
  { path: 'provider-profile/:provider_id', component: ProviderProfileComponent },
  {
    path: 'customer-dashboard', component: CustomerDashboardComponent, data: { breadcrumb: 'Home' },
    children: [
      { path: "", component: CategoriesViewComponent },
      { path: 'categories-view', component: CategoriesViewComponent },
      { path: 'profile-details/:provider_name', component: ProfileDetailsComponent, data: { breadcrumb: '' } },
      { path: 'child-category-view/:catName', component: ChildCategoryViewComponent, 
        data: { breadcrumb: ''} },
      { path: 'provider-details-view', component: ProviderDetailsViewComponent },
      { path: 'provider-details-view/:catName', component: ProviderDetailsViewComponent, data: { breadcrumb: '' } },
      { path: 'calender-scheduler/:provider_id', component: CalenderSchedulerComponent, data: { breadcrumb: 'Book Appointment' } },
      { path: 'appoint-success', component: AppointSuccessComponent, data: { breadcrumb: 'Appointment Success' } },
      { path: 'customer-profile/:userid', component: CustomerProfileComponent, data: { breadcrumb: 'Profile' } },
      { path: 'change-password', component: ChangepasswordCustomerComponent, data: { breadcrumb: 'Change Password' } },
      { path: 'payment-method', component: PaymentMethodComponent, data: { breadcrumb: 'Choose Payment Method' } },
      { path: 'booked-appointments', component: BookedAppointDetailsComponent, data: { breadcrumb: 'Booked Appointments' } },
      { path: 'video-call', component: VideoCallTestComponent, data: { breadcrumb: 'Video Call' } },
      { path: 'choose-payment-method', component: ChoosePaymentComponent },
    ]
  },

  { path: 'calendar-demo', component: CalendarDemoComponent },
  // {
  //   path: 'customer-dashboard', component: CustomerDashboardComponent,
  //   data: {
  //     title: 'Home',
  //     breadcrumb: [
  //       { label: 'Home', url: '' }
  //     ]
  //   },
  //   children: [
  //     {
  //       path: '', component: CategoriesViewComponent,
  //       // data: {
  //       //   title: 'Home',
  //       //   breadcrumb: [
  //       //     { label: 'Home', url: '' }
  //       //   ]
  //       // },
  //     },
  //     {
  //       path: 'categories-view', component: CategoriesViewComponent,
  //       // data: {
  //       //   title: 'Home',
  //       //   breadcrumb: [
  //       //     { label: 'Home', url: '' }
  //       //   ]
  //       // },
  //     },
  //     {
  //       path: 'child-category-view/:catName', component: ChildCategoryViewComponent,
  //       data: {
  //         title: 'Child Category',
  //         breadcrumb: [
  //           { label: 'Home', url: '/customer-dashboard' },
  //           { label: 'Child Category {{catName}}', url: '' }
  //         ]
  //       }
  //     }
  //   ]
  // },
  { path: 'feedback-form', component: FeedbackFormComponent }

]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
