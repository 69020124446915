import { Component, OnInit } from '@angular/core';
import { CompanyProfileService } from '../../services/company-profile.service';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { CategoryLevelService } from '../../services/categoryByLevel/category-level.service';
import { debounceTime, switchMap, tap, finalize } from 'rxjs/operators';
@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {
  public ownerForm: FormGroup;
  companyprofile = [];
  user: string;
  category = [];
  isLoading = false;

  constructor(private companyprofileService: CompanyProfileService,
    private categoryLevelService: CategoryLevelService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.ownerForm = this.formBuilder.group({
      type: [''],
    });

    // this.ownerForm
    // .get('type')
    // .valueChanges
    // .pipe(
    //   debounceTime(300),
    //   tap(() => this.isLoading = true),
    //   switchMap(value => this.categoryLevelService.CategoryLevelService({catName: value})
    //   .pipe(
    //     finalize(() => this.isLoading = false),
    //   ))
    // )
    // .subscribe(
    //   (_response => {
    //     if (_response.status === 200) {
    //       this.category = _response.body;
    //       console.log(this.category);
    //     } else {
    //       this.category = [];
    //     }
    //   }),
    //   (_error => {
    //     this.category = [];
    //   })
    // );
    
    

  //   this.categoryLevelService.CategoryLevelService()
  //   .subscribe(
  //     (_response) => {
  //       console.log(_response);
  //       if (_response.status === 200) {
  //         try {
  //           this.category = _response.body;
  
  //         } catch (err) {
  
  //         }
  //       }
  //     },
  //     (_error) => {
  
  //     }
  //   )
  // console.log('data', 'response');



    this.ownerForm = new FormGroup({
      provider_id: new FormControl(''),
      userid: new FormControl(''),
      type: new FormControl(''),
      year_established: new FormControl(''),
      legal_number: new FormControl(''),
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }

  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid) {
      this.companyProfile(ownerFormValue);
    }


  }

  companyProfile(ownerFormValue) {
    let user: any = {
      provider_id: ownerFormValue.provider_id,

      userid: ownerFormValue.userid,
      type: ownerFormValue.type,
      year_established: ownerFormValue.year_established,
      legal_number: ownerFormValue.legal_number

    };
    this.companyprofileService.CompanyProfileService(user)
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.companyprofile = _response.body;

            } catch (err) {

            }
          }
        },
        (_error) => {

        }
      )
    console.log('data', 'response');
  }
}


