import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartnerenquirydetailsService {

  constructor(private httpService: HttpClient) { }

  getPartnerEnquiryDetails(query) {
    const href = environment.api.getPatnerEnquiryDetails;
    return this.httpService.get<any>(href + query, { 'observe': 'response' });
  }

  addPartnerEnquiryDetails(user) {
    return this.httpService.post<any>(environment.api.addPatnerEnquiryDetails, user, { 'observe': 'response' });

  }
}
