import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReferbysmstoproviderService } from '../../../services/referbysmstoprovider/referbysmstoprovider.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {
  ownerForm: FormGroup;
  provider_id;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private toaster: ToastrService,
    private router: Router,
    private referBySmsToProviderService: ReferbysmstoproviderService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');

    this.ownerForm = new FormGroup({
      // email: new FormControl('', [Validators.email]),
      mobile_number: new FormControl('', [Validators.pattern(/^\d{10}$/)])
    });
  }

  public createOwner = (ownerFormValue, formDirective: FormGroupDirective) => {
    if (this.ownerForm.valid) {
      this.sendReferLink(ownerFormValue);
      formDirective.resetForm();
      this.ownerForm.reset();
    }
  }

  sendReferLink(ownerFormValue) {
    let link = {
      mobile_number: ownerFormValue.mobile_number,
      provider_id: this.provider_id
    };

    this.server.request('POST', '/providers/referBySmsToProvider', link)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Link shared successfully');
        },
        (_error) => {

        });
  }

}
