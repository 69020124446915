import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, Renderer } from '@angular/core';
import { OverlayService } from '../../services/overlay/overlay.service';
import { ProvidercountService } from '../../services/providercount/providercount.service';
import { Router } from '@angular/router';
import { ProfileServiceService } from '../../services/getprofile/profile-service.service';
import { GetbookedappointfornotifService } from '../../services/getbookedappointfornotif/getbookedappointfornotif.service';
import { GetpendingappointfornotifService } from '../../services/getpendinappointfornotif/getpendingappointfornotif.service';
import { CancelapointfornotificationService } from '../../services/cancelapointfornotification/cancelapointfornotification.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ChangeviewstatusService } from '../../services/changeviewstatus/changeviewstatus.service';
import { NotificationcountService } from '../../services/notificationcount/notificationcount.service';
import { BehaviorSubject } from 'rxjs';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service';
import { GetsubscriptionproviderService } from '../../services/getsubscriptionprovider/getsubscriptionprovider.service';
import { GetsettingsService } from '../../services/getsettings/getsettings.service';
import { GetcategoryServiceService } from '../../services/getcategory/getcategory-service.service';

@Component({
  selector: 'app-provider-dashboard',
  templateUrl: './provider-dashboard.component.html',
  styleUrls: ['./provider-dashboard.component.css']
})
export class ProviderDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  isActive1 = true;
  isActive2 = false;
  appointmentavailable = true;
  cancelappointment = true;
  userid = '';
  provider_id = '';
  providerCount: any = [
    { total_client: '', today_appointment: '', total_appointment: '' }
  ];
  subCategoriesService = [];
  intervalId;
  loginError = false;
  popup = false;
  provider_url;
  provider_name;
  qualification;
  appointments = [];
  pendingAppointments = [];
  canceledAppointments = [];
  today = moment().format('YYYY-MM-DD');
  notif_count = 0;
  prev_notif_count = 0;
  cancel_count = 0;
  prev_cancel_count;
  pending_count = 0;
  prev_pending_count = 0;
  showNotif = false;
  viewText = 'View All';
  expand = false;
  active;
  navbarOpen = false;
  remaining_day;
  category: [];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private providerCountService: ProvidercountService,
    private router: Router,
    private providerProfile: ProfileServiceService,
    private getBookedAppointForNotif: GetbookedappointfornotifService,
    private getPendingAppointForNotif: GetpendingappointfornotifService,
    private cancelApointForNotificationService: CancelapointfornotificationService,
    private toaster: ToastrService,
    private changeViewStatusService: ChangeviewstatusService,
    private notificationCountService: NotificationcountService,
    private service: OverlayService,
    private server: JwtTestService,
    private getSubscriptionProvider: GetsubscriptionproviderService,
    private getSettingsService: GetsettingsService,
    private el: ElementRef,
    private renderer: Renderer,
    private GetcategoryServiceService: GetcategoryServiceService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngAfterViewInit() {
    this.service.showOverlay(1);
  }

  restartOnboarding() {
    this.service.showOverlay(1);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    this.active = localStorage.getItem('active');

    this.GetcategoryServiceService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.category = _response.body;
          }
        },
        (_error) => {

        });

    if (this.active == 0 || this.active === 'null') {
      this.loginError = true;
      // this.openNav();
    } else if (this.active == 1) {
      this.loginError = false;
    }

    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/providerCount', queryParam)
      .subscribe(
        (_response: any) => {
          this.providerCount = _response;
        },
        (_error) => {

        });

    this.server.request('GET', '/providers/getSettings', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.provider_url = _response.body.provider_url;
            this.provider_name = localStorage.getItem('business_name');
          }
        },
        (_error) => {

        });

    let countQuery = {
      userid: this.userid
    };

    this.server.request('GET', '/notification/notificationCount', countQuery)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.notif_count = _response.body.notification_count ? _response.body.notification_count : 0;
          }
        },
        (_error) => {

        }
      );

    this.getAppointments(this.today);
    this.getCancelledAppointments(this.today);
    this.getProviderSubscription();

    this.intervalId = setInterval(() => {
      let queryParam = {
        provider_id: this.provider_id
      };

      // this.server.request('GET', '/providers/providerCount', queryParam)
      //   .subscribe(
      //     (_response: any) => {
      //       this.providerCount = _response
      //     },
      //     (_error) => {

      //     });

      let cancelAppointmentqueryParam = {
        intended_userid: this.userid,
        date: this.today
      };

      this.server.request('GET', '/notification/getCancelAppointmentForNotication', cancelAppointmentqueryParam)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              try {
                this.cancelappointment = true;
                this.canceledAppointments = _response.body.data.results;
                this.prev_cancel_count = this.cancel_count;
                this.cancel_count = _response.body.data.total ? _response.body.data.total : 0;
                // console.log('prev: ', this.prev_cancel_count, ' cancel: ', this.cancel_count);
                if (this.cancel_count > this.prev_cancel_count) {
                  this.toaster.error('Appointment has been cancelled');
                }
                // console.log(this.canceledAppointments);

                if (this.canceledAppointments) {
                  for (let i = 0; i < this.canceledAppointments.length; i++) {
                    this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
                    this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
                    this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
                  }
                } else {
                  return;
                }
              } catch (err) {
                console.log('Error: ', err);
              }
            }
          },
          (_error) => {
            if (_error.status === 409) {
              this.cancelappointment = false;
            }
          }
        );

      // this.getAppointments(this.today);
      let pendingAppointmentqueryParam = {
        intended_userid: this.userid,
        date: this.today
      };
      this.server.request('GET', '/notification/getPendingAppointmentForNotication', pendingAppointmentqueryParam)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              try {
                this.pendingAppointments = _response.body.data.results;
                this.prev_pending_count = this.pending_count;
                this.pending_count = _response.body.data.total ? _response.body.data.total : 0;
                // console.log('prev pending: ', this.prev_pending_count, ' pending: ', this.pending_count);
                if (this.pending_count > this.prev_pending_count) {
                  this.toaster.success('New appointment arrived');
                }

                if (this.pendingAppointments) {
                  for (let i = 0; i < this.pendingAppointments.length; i++) {
                    this.pendingAppointments[i].date = moment(this.pendingAppointments[i].start_datetime).format('DD MMM YYYY');
                    this.pendingAppointments[i].start_datetime = moment(this.pendingAppointments[i].start_datetime).format('hh:mm A');
                    this.pendingAppointments[i].end_datetime = moment(this.pendingAppointments[i].end_datetime).format('hh:mm A');
                  }
                } else {
                  return;
                }
              } catch (err) {
                console.log('Error: ', err);
              }
            }
          }, (_error) => {

          }
        );

      this.server.request('GET', '/notification/notificationCount', countQuery)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              this.prev_notif_count = this.notif_count ? this.notif_count : 0;
              this.notif_count = _response.body.notification_count ? _response.body.notification_count : 0;
              // console.log('prev-book: ', this.prev_notif_count, ' book: ', this.notif_count);
              // if (this.notif_count > this.prev_notif_count) {
              //   this.toaster.success('New appointment arrived');
              // }
            }
          },
          (_error) => {

          }
        );
    }, 5000);
  }

  getAppointments(startDate) {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    let queryParam = {
      intended_userid: this.userid,
      date: startDate
    };

    this.server.request('GET', '/notification/getBookedAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.appointments = _response.body.data.results;
              // console.log('booked appointments: ', this.appointments);

              if (this.appointments) {
                for (let i = 0; i < this.appointments.length; i++) {
                  this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
                  this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
                  this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            // console.log('booked appointments: ', this.appointments);
          }
        });

    this.server.request('GET', '/notification/getPendingAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.pendingAppointments = _response.body.data.results;
              this.pending_count = _response.body.data.total ? _response.body.data.total : 0;
              // console.log(this.pendingAppointments);

              if (this.pendingAppointments) {
                for (let i = 0; i < this.pendingAppointments.length; i++) {
                  this.pendingAppointments[i].date = moment(this.pendingAppointments[i].start_datetime).format('DD MMM YYYY');
                  this.pendingAppointments[i].start_datetime = moment(this.pendingAppointments[i].start_datetime).format('hh:mm A');
                  this.pendingAppointments[i].end_datetime = moment(this.pendingAppointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        }, (_error) => {

        }
      );
  }

  getCancelledAppointments(startDate) {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    // let queryParam = `?intended_userid=${this.userid}&date=${startDate}`;
    let queryParam = {
      intended_userid: this.userid,
      date: startDate
    };

    this.server.request('GET', '/notification/getCancelAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.cancelappointment = true;
              this.canceledAppointments = _response.body.data.results;
              this.cancel_count = _response.body.data.total ? _response.body.data.total : 0;
              // console.log(this.canceledAppointments);

              if (this.canceledAppointments) {
                for (let i = 0; i < this.canceledAppointments.length; i++) {
                  this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
                  this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
                  this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            this.cancelappointment = false;
          }
        }
      );

  }

  notificationCount(appoint_id, id, n_type) {
    let appointment = {
      appoint_id: appoint_id,
      is_viewed: '1',
      n_type: n_type
    };

    this.server.request('POST', '/notification/changeViewStatus', appointment)
      .subscribe(
        (_response) => {
          // alert('notification read');
          let elem: HTMLElement = document.getElementById(id);
          elem.setAttribute("style", "background-color: white; display: block;");
          this.router.navigate(['/provider-dashboard']);
        },
        (_error) => {

        }
      );
  }

  getProviderSubscription() {
    // let queryParam = `?provider_id=${this.provider_id}`;
    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getsubscriptionOfProvider', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.remaining_day = _response.body.remaining_day;
          }
        },
        (_error) => {

        });
  }

  checkSubscription() {
    if (this.remaining_day <= 10 && this.remaining_day > 0) {
      return true;
    }
    return false;
  }

  checkSubscriptionExpired() {
    if (this.remaining_day <= 0) {
      return true;
    }
    return false;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  completeProfile() {
    this.router.navigate(['/business-wizard']);
  }

  // openNav() {
  //   document.getElementById("overlay").style.display = "block";
  // }

  expandMenu() {
    document.getElementById("main-menu").style.width = "210px";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("container").style.maxWidth = "83.333333%";
    this.navbarOpen = true;
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
    this.navbarOpen = false;
  }

  ToggleNavBar() {
    let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  }

  goToLink() {
    window.open('/customer-dashboard/categories-view', "_blank");
  }

  toggleNotification(state: boolean) {
    this.showNotif = state;
    if (state == false) {
      this.viewText = 'View All';
      this.expand = false;
    }
  }

  viewAll() {
    var viewMore = document.getElementsByClassName('content');
    let notifElem: HTMLElement = document.getElementById('notification');
    for (let i = 0; i < viewMore.length; i++) {
      let elem: HTMLElement = document.getElementById(viewMore[i].id);
      if (elem.style.display === 'block') {
        elem.style.display = 'none';
        notifElem.style.height = 'auto';
        notifElem.style.overflowY = 'hidden';
        this.viewText = 'View All';
        this.expand = false;
      } else {
        elem.style.display = 'block';
        notifElem.style.height = '300px';
        notifElem.style.overflowY = 'scroll';
        this.viewText = 'View Less';
        this.expand = true;
      }
    }
  }
  onMenuClick() {
    //this.el.nativeElement.querySelector('.navbar-ex1-collapse')  get the DOM
    //this.renderer.setElementClass('DOM-Element', 'css-class-you-want-to-add', false) if 3rd value is true
    //it will add the css class. 'in' class is responsible for showing the menu.
    this.renderer.setElementClass(this.el.nativeElement.querySelector('.navbar-ex1-collapse'), 'in', false);
  }
}

