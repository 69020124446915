import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetallsubscriptionService {

  constructor(private httpService: HttpClient) { }

  GetallsubscriptionService() {
    const href = environment.api.getallsubscription;
    return this.httpService.get<any>(href, {'observe': 'response'});
  }
}
