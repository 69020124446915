import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, AbstractControl, ValidatorFn } from '@angular/forms';
import { AddAddressPreferanceService } from '../../../services/addAddressPreferance/add-address-preferance.service';
import * as moment from 'moment';
import { GetsettingsService } from '../../../services/getsettings/getsettings.service';
import { GetAllAddressesService } from '../../../services/getAllAddresses/get-all-addresses.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { GetContactInfoService } from '../../../services/contact-info/get-contact-info.service';
import { ToastrService } from 'ngx-toastr';
import { GetAppointmentByAddressIdService } from '../../../services/getAppointmentByAddressId/get-appointment-by-address-id.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { ChangeAppointStatusService } from '../../../services/change-appoint-status/change-appoint-status.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

function durationValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value !== null && (control.value % 5 !== 0)) {
    return { 'durationNotValid': true }
  }
  return null;
}

@Component({
  selector: 'app-schedule-management',
  templateUrl: './schedule-management.component.html',
  styleUrls: ['./schedule-management.component.css'],
  providers: [ConfirmationService]
})
export class ScheduleManagementComponent implements OnInit {
  scheduleForm: FormGroup;
  timeForm: FormGroup;
  provider_id;
  userid;
  online_consultation_type;
  addresses = [];
  errorMatcher = new CrossFieldErrorMatcher();
  errorMatcher2 = new CrossFieldErrorMatcher();
  errorMatcher3 = new CrossFieldErrorMatcher();

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  markerChange = false;
  postcode;
  city;
  citycountry;
  @ViewChild('searchLocation', { static: true })
  public searchElementRef: ElementRef;

  week1Selected = false;
  week2Selected = false;
  week3Selected = false;
  week4Selected = false;
  week5Selected = false;

  monSelected = false;
  tueSelected = false;
  wedSelected = false;
  thuSelected = false;
  friSelected = false;
  satSelected = false;
  sunSelected = false;

  week1Days = '';
  week2Days = '';
  week3Days = '';
  week4Days = '';
  week5Days = '';
  weeks = [];
  monthDays = [];

  weekly = true;
  monthly = false;
  walk_in = true;
  selected_address;
  selected_address_id;
  addressConfirm = false;
  appointments = [];
  msgs: Message[] = [];
  payment_id;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private addAddressPreferanceService: AddAddressPreferanceService,
    private getSettingsService: GetsettingsService,
    private getAllAddressesService: GetAllAddressesService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private getContactInfoService: GetContactInfoService,
    private toaster: ToastrService,
    private getAppointmentByAddressId: GetAppointmentByAddressIdService,
    private confirmationService: ConfirmationService,
    private changeApointStatusService: ChangeAppointStatusService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    this.scheduleForm = new FormGroup({
      appointment_type: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      new_address: new FormControl(''),
      recurrence_type: new FormControl('', [Validators.required]),
      criteria: new FormControl(''),
      duration: new FormControl('', [Validators.required, Validators.min(5), durationValidator]),
      cost: new FormControl('', [Validators.required, Validators.min(10), Validators.pattern("^[0-9]*$")]),
    });

    this.timeForm = new FormGroup({
      start_time: new FormControl('', [Validators.required]),
      end_time: new FormControl('', [Validators.required]),
      break_start_time: new FormControl(''),
      break_end_time: new FormControl('')
    },
      { validators: [this.breakTimeValidator, this.timeValidator] });

    // let queryParam = `?provider_id=${this.provider_id}`;
    let queryParam = {
      provider_id: this.provider_id
    };

    this.server.request('GET', '/providers/getSettings', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.online_consultation_type = _response.body.online_consultation_type;
          }
        },
        (_error) => {

        });

    // this.getSettingsService.GetsettingsService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.online_consultation_type = _response.body.online_consultation_type;
    //       }
    //     },
    //     (_error) => {

    //     });

    this.setCurrentLocation();

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.markerChange = true;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.postcode = results[0].address_components.pop();
          this.postcode = this.postcode.long_name;
          this.address = results[0].formatted_address;
          if (this.markerChange) {
            // this.scheduleForm.controls['new_address'].setValue(this.address);
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getCityCountry() {
    let queryParam = `?postcode=${this.postcode}`;
    this.getContactInfoService.GetContactInfoService(queryParam)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.citycountry = _response.body;
            this.city = this.citycountry[0].city;
          }
        }
      )
  }

  selectAppointmentType(event) {
    if (event == '0') {
      this.walk_in = true;
    } else if (event == '1') {
      this.walk_in = false;
    } else if (event == '0 1') {
      this.walk_in = true;
    }

    let query = `?provider_id=${this.provider_id}&online_consultation_flag=${event}`;
    this.getAllAddressesService.GetAllAddressesService(query)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.addresses = _response.body;

            for (let i = this.addresses.length - 1; i >= 0; i--) {
              if (event == '0' && this.addresses[i].address === null) {
                this.addresses.splice(i, 1);
              }
            }
          }
        },
        (_error) => {

        });
  }

  selectAddress(event) {
    this.addressConfirm = true;
    this.selected_address = this.addresses.find(address => address.address_id == event).address;
    this.selected_address_id = event;

    // let query = `?address_id=${event}`;
    let query = {
      address_id: event
    };

    this.server.request('GET', '/appointment/getAppointmentsByAddressId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200 && _response.body.results) {
            this.appointments = _response.body.results;

            for (let i = this.appointments.length - 1; i >= 0; i--) {
              if (this.appointments[i].apoint_status == 2 || this.appointments[i].apoint_status == 3) {
                this.appointments.splice(i, 1);
              }
            }

            for (let i = 0; i < this.appointments.length; i++) {
              this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
              this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('h:mm A');
              this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('h:mm A');

              if (this.appointments[i].pay_status == '0') {
                this.appointments[i].pay_status = 'COV';
              } else if (this.appointments[i].pay_status == '1') {
                this.appointments[i].pay_status = 'Online Payment';
              } else if (this.appointments[i].pay_status == '2') {
                this.appointments[i].pay_status = 'Advance Payment';
              }
            }
          } else if (_response.status === 200 && _response.body.length == 0) {
            this.appointments = [];
          }
        },
        (_error) => {

        });
    // this.getAppointmentByAddressId.GetAppointmentByAddressIdService(query)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200 && _response.body.results) {
    //         this.appointments = _response.body.results;

    //         for (let i = this.appointments.length - 1; i >= 0; i--) {
    //           if (this.appointments[i].apoint_status == 2 || this.appointments[i].apoint_status == 3) {
    //             this.appointments.splice(i, 1);
    //           }
    //         }

    //         for (let i = 0; i < this.appointments.length; i++) {
    //           this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
    //           this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('h:mm A');
    //           this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('h:mm A');

    //           if (this.appointments[i].pay_status == '0') {
    //             this.appointments[i].pay_status = 'COV';
    //           } else if (this.appointments[i].pay_status == '1') {
    //             this.appointments[i].pay_status = 'Online Payment';
    //           } else if (this.appointments[i].pay_status == '2') {
    //             this.appointments[i].pay_status = 'Advance Payment';
    //           }
    //         }
    //       } else if (_response.status === 200 && _response.body.length == 0) {
    //         this.appointments = [];
    //       }
    //     }, (_error) => {

    //     });
  }

  selectRecurrence(event) {
    if (event == 'weekly') {
      this.weekly = true;
      this.monthly = false;
    } else if (event == 'monthly') {
      this.weekly = false;
      this.monthly = true;
    }
  }

  confirm1(appointment_id, appoint_status, userid) {
    this.confirmationService.confirm({
      message: 'Amount will be automatically refunded to customer',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        this.changeStatus(appointment_id, appoint_status, userid);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      },
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
    });
  }

  changeStatus(appointment_id, appoint_status, userid) {
    // console.log('appointment_id ', appointment_id);
    // console.log('status ', appoint_status);
    let status = {
      appoint_id: appointment_id,
      appoint_status: appoint_status,
      appoint_status_reason: appoint_status == 2 || appoint_status == 3 ? 1 : ''
    };

    this.server.request('POST', '/appointment/changeApointStatus', status)
      .subscribe(
        (_response) => {
          if (appoint_status == '1') {
            this.toaster.success('Appointment accepted');
          } else if (appoint_status == '2') {
            this.toaster.error('Appointment rejected');
          } else if (appoint_status == '3') {
            this.toaster.error('Appointment rejected');
            this.getPaymentId(appointment_id);
          }
        },
        (_error) => {

        });

    // this.changeApointStatusService.ChangeAppointStatusService(status)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         if (appoint_status == '1') {
    //           this.toaster.success('Appointment accepted');
    //         } else if (appoint_status == '2') {
    //           this.toaster.error('Appointment rejected');
    //         } else if (appoint_status == '3') {
    //           this.toaster.error('Appointment rejected');
    //           this.getPaymentId(appointment_id);
    //         }
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
    // this.provider_id = localStorage.getItem('provider_id');
    let notificationtype = {
      userid: this.userid,
      intended_userid: userid,
      appoint_id: appointment_id,
      n_type: appoint_status
    };

    this.server.request('POST', '/notification/notificationType', notificationtype)
      .subscribe((_response) => {
        // console.log('notification type: ', notificationtype.n_type);
      },
        (_error) => {

        });

    // this.notificationTypeService.NotificationtypeService(notificationtype)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         // console.log('notification type: ', notificationtype.n_type);
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
  }

  getPaymentId(appoint_id) {
    // let query = `?appoint_id=${appoint_id}`;
    let query = {
      appoint_id: appoint_id
    };

    this.server.request('GET', '/razorpay_purchase/getPaymentIdByApointtId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.payment_id = _response.body.razorpay_payment_id;
            this.refund();
          }
        },
        (_error) => {

        });

    // this.getPaymentByApointIdService.GetpaymentidbyapointtidService(query)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.payment_id = _response.body.razorpay_payment_id;
    //         this.refund();
    //       }
    //     },
    //     (_error) => {

    //     });
  }

  refund() {
    let payment = {
      razorpay_payment_id: this.payment_id
    };

    this.server.request('POST', '/razorpay_purchase/refund', payment)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Payment refunded successfully');

          let refund = {
            razorpay_payment_id: this.payment_id,
            refund_id: _response.id
          };

          this.server.request('POST', '/razorpay_purchase/refundId', refund)
            .subscribe(
              (_response: any) => {
                console.log('refund id success');
              },
              (_error) => {

              });

        },
        (_error) => {

        });

    // this.razorPayService.refundPayment(payment)
    //   .subscribe(
    //     (__response) => {
    //       if (__response.status === 200) {
    //         this.toaster.success('Payment refunded successfully');

    //         let refund = {
    //           razorpay_payment_id: this.payment_id,
    //           refund_id: __response.body.id
    //         };
    //         this.razorPayService.refundId(refund)
    //           .subscribe(
    //             (_response) => {
    //               if (_response.status === 200) {
    //                 console.log('refund id success');
    //               }
    //             },
    //             (_error) => {

    //             });
    //       }
    //     },
    //     (_error) => {
    //       this.toaster.error('Unable to refund payment');
    //     });
  }

  deleteAddress(address_id) {
    let address = {
      address_id: address_id
    };

    this.server.request('POST', '/providers/removeAddress', address)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Address has been deleted');
          this.addressConfirm = false;
          this.selectAppointmentType('0');
        },
        (_error) => {
          this.toaster.error('Could not delete address');
        });

    // this.getAppointmentByAddressId.removeAddress(address)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.toaster.success('Address has been deleted');
    //         this.addressConfirm = false;
    //         this.selectAppointmentType('0');
    //       }
    //     },
    //     (_error) => {
    //       this.toaster.error('Could not delete address');
    //     });

    console.log('deleted Address : ', address_id);
  }

  selectWeek(week) {
    if (week == 1) {
      this.week1Selected = !this.week1Selected;
    } else if (week == 2) {
      this.week2Selected = !this.week2Selected;
    } else if (week == 3) {
      this.week3Selected = !this.week3Selected;
    } else if (week == 4) {
      this.week4Selected = !this.week4Selected;
    } else if (week == 5) {
      this.week5Selected = !this.week5Selected;
    }
  }

  selectDay(day) {
    if (day == 1) {
      this.monSelected = !this.monSelected;
    } else if (day == 2) {
      this.tueSelected = !this.tueSelected;
    } else if (day == 3) {
      this.wedSelected = !this.wedSelected;
    } else if (day == 4) {
      this.thuSelected = !this.thuSelected;
    } else if (day == 5) {
      this.friSelected = !this.friSelected;
    } else if (day == 6) {
      this.satSelected = !this.satSelected;
    } else if (day == 0) {
      this.sunSelected = !this.sunSelected;
    }

    // console.log('week: ', moment().format('YYYY-MM-DD') , moment().week() - moment().startOf('month').week());
  }

  selectMonthDays(day) {
    let date = document.getElementById(day);
    if (date.className.includes('date-selected')) {
      date.className = 'date';
    } else {
      date.className = 'date-selected';
    }

    if (date.className.includes('date-selected')) {
      this.monthDays.push(day);
    } else if (date.className == 'date') {
      this.monthDays.splice(this.monthDays.indexOf(day), 1);
    }
  }

  checkDetails(scheduleFormValue) {
    if (this.scheduleForm.valid && this.timeForm.valid) {
      this.submitDetails(scheduleFormValue);
    } else {
      this.toaster.error('Please fill in all the details');
    }
  }

  submitDetails(scheduleFormValue) {
    let selected_week_days = '';
    let selected_month_days = '';
    let addressPreferance = {};
    this.week1Days = '';
    this.week2Days = '';
    this.week3Days = '';
    this.week4Days = '';
    this.week5Days = '';

    if (this.week1Selected) {
      if (this.sunSelected) {
        this.week1Days += 0 + ' ';
      }
      if (this.monSelected) {
        this.week1Days += 1 + ' ';
      }
      if (this.tueSelected) {
        this.week1Days += 2 + ' ';
      }
      if (this.wedSelected) {
        this.week1Days += 3 + ' ';
      }
      if (this.thuSelected) {
        this.week1Days += 4 + ' ';
      }
      if (this.friSelected) {
        this.week1Days += 5 + ' ';
      }
      if (this.satSelected) {
        this.week1Days += 6 + ' ';
      }
    }
    if (this.week2Selected) {
      if (this.sunSelected) {
        this.week1Days += 7 + ' ';
      }
      if (this.monSelected) {
        this.week1Days += 8 + ' ';
      }
      if (this.tueSelected) {
        this.week1Days += 9 + ' ';
      }
      if (this.wedSelected) {
        this.week1Days += 10 + ' ';
      }
      if (this.thuSelected) {
        this.week1Days += 11 + ' ';
      }
      if (this.friSelected) {
        this.week1Days += 12 + ' ';
      }
      if (this.satSelected) {
        this.week1Days += 13 + ' ';
      }
    }
    if (this.week3Selected) {
      if (this.sunSelected) {
        this.week1Days += 14 + ' ';
      }
      if (this.monSelected) {
        this.week1Days += 15 + ' ';
      }
      if (this.tueSelected) {
        this.week1Days += 16 + ' ';
      }
      if (this.wedSelected) {
        this.week1Days += 17 + ' ';
      }
      if (this.thuSelected) {
        this.week1Days += 18 + ' ';
      }
      if (this.friSelected) {
        this.week1Days += 19 + ' ';
      }
      if (this.satSelected) {
        this.week1Days += 20 + ' ';
      }
    }
    if (this.week4Selected) {
      if (this.sunSelected) {
        this.week1Days += 21 + ' ';
      }
      if (this.monSelected) {
        this.week1Days += 22 + ' ';
      }
      if (this.tueSelected) {
        this.week1Days += 23 + ' ';
      }
      if (this.wedSelected) {
        this.week1Days += 24 + ' ';
      }
      if (this.thuSelected) {
        this.week1Days += 25 + ' ';
      }
      if (this.friSelected) {
        this.week1Days += 26 + ' ';
      }
      if (this.satSelected) {
        this.week1Days += 27 + ' ';
      }
    }
    if (this.week5Selected) {
      if (this.sunSelected) {
        this.week1Days += 28 + ' ';
      }
      if (this.monSelected) {
        this.week1Days += 29 + ' ';
      }
      if (this.tueSelected) {
        this.week1Days += 30 + ' ';
      }
      if (this.wedSelected) {
        this.week1Days += 31 + ' ';
      }
      if (this.thuSelected) {
        this.week1Days += 32 + ' ';
      }
      if (this.friSelected) {
        this.week1Days += 33 + ' ';
      }
      if (this.satSelected) {
        this.week1Days += 34 + ' ';
      }
    }

    selected_week_days = this.week1Days + this.week2Days + this.week3Days + this.week4Days + this.week5Days;
    selected_month_days = this.monthDays.toString().replace(/,/g, ' ') + ' ';

    // console.log('weeks_days: ', selected_week_days);

    if (scheduleFormValue.appointment_type == '0') {
      addressPreferance = {
        provider_id: this.provider_id,
        userid: this.userid,
        default_cost: scheduleFormValue.cost,
        default_time_duration: scheduleFormValue.duration,
        working_start_time: moment(this.timeForm.controls['start_time'].value, 'hh:mm').format('HH:mm:SS'),
        working_end_time: moment(this.timeForm.controls['end_time'].value, 'hh:mm').format('HH:mm:SS'),
        break_start_time: this.timeForm.controls['break_start_time'].value == 'Invalid date' || this.timeForm.controls['break_start_time'].value == '' ? '' : moment(this.timeForm.controls['break_start_time'].value, 'hh:mm').format('HH:mm:SS'),
        break_end_time: this.timeForm.controls['break_end_time'].value == 'Invalid date' || this.timeForm.controls['break_end_time'].value == '' ? '' : moment(this.timeForm.controls['break_end_time'].value, 'hh:mm').format('HH:mm:SS'),
        working_week_days: scheduleFormValue.recurrence_type == 'weekly' ? selected_week_days : '',
        working_month_days: scheduleFormValue.recurrence_type == 'monthly' ? selected_month_days : '',
        postcode: scheduleFormValue.new_address.length > 0 ? this.postcode : '',
        city_name: scheduleFormValue.new_address.length > 0 ? this.city : '',
        address_id: scheduleFormValue.new_address.length == 0 ? scheduleFormValue.address : '',
        address_text: scheduleFormValue.new_address.length > 0 ? scheduleFormValue.new_address + ', ' + this.address : '',
        address_lat: scheduleFormValue.new_address.length > 0 ? this.latitude : '',
        address_lng: scheduleFormValue.new_address.length > 0 ? this.longitude : '',
        online_consultation_flag: '0 ',
        working_days: ''
      };

      this.server.request('POST', '/providers/addAddressInfoPreferanceTwo', addressPreferance)
        .subscribe(
          (_response: any) => {
            this.toaster.success('Address information changed successfully');
            this.week1Days = '';
            this.week2Days = '';
            this.week3Days = '';
            this.week4Days = '';
            this.week5Days = '';

            this.selectAppointmentType('0');
          },
          (_error) => {
            this.toaster.error('Could not change address information');
          });

      // this.addAddressPreferanceService.AddAddressPreferanceService(addressPreferance)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         // console.log('preferance added');
      //         this.toaster.success('Address information changed successfully');
      //         this.week1Days = '';
      //         this.week2Days = '';
      //         this.week3Days = '';
      //         this.week4Days = '';
      //         this.week5Days = '';
      //       }
      //     },
      //     (_error) => {
      //       this.toaster.error('Could not change address information');
      //     });

    } else if (scheduleFormValue.appointment_type == '1') {
      addressPreferance = {
        provider_id: this.provider_id,
        userid: this.userid,
        default_cost: scheduleFormValue.cost,
        default_time_duration: scheduleFormValue.duration,
        working_start_time: moment(this.timeForm.controls['start_time'].value, 'hh:mm').format('HH:mm:SS'),
        working_end_time: moment(this.timeForm.controls['end_time'].value, 'hh:mm').format('HH:mm:SS'),
        break_start_time: this.timeForm.controls['break_start_time'].value == 'Invalid date' || this.timeForm.controls['break_start_time'].value == '' ? '' : moment(this.timeForm.controls['break_start_time'].value, 'hh:mm').format('HH:mm:SS'),
        break_end_time: this.timeForm.controls['break_end_time'].value == 'Invalid date' || this.timeForm.controls['break_end_time'].value == '' ? '' : moment(this.timeForm.controls['break_end_time'].value, 'hh:mm').format('HH:mm:SS'),
        working_week_days: scheduleFormValue.recurrence_type == 'weekly' ? selected_week_days : '',
        working_month_days: scheduleFormValue.recurrence_type == 'monthly' ? selected_month_days : '',
        online_consultation_flag: '1 ',
        working_days: '',
        address_id: this.addresses.length > 0 ? this.addresses[0].address_id : '',
      };

      this.server.request('POST', '/providers/addOnline', addressPreferance)
        .subscribe(
          (_response: any) => {
            this.toaster.success('Address information changed successfully');
            this.week1Days = '';
            this.week2Days = '';
            this.week3Days = '';
            this.week4Days = '';
            this.week5Days = '';

            this.selectAppointmentType('1');
          },
          (_error) => {
            this.toaster.error('Could not change address information');
          });

      // this.addAddressPreferanceService.AddOnline(addressPreferance)
      //   .subscribe((_response) => {
      //     if (_response.status === 200) {
      //       // console.log('preferance added');
      //       this.toaster.success('Address information changed successfully');
      //       this.week1Days = '';
      //       this.week2Days = '';
      //       this.week3Days = '';
      //       this.week4Days = '';
      //       this.week5Days = '';
      //     }
      //   },
      //     (_error) => {
      //       this.toaster.error('Could not change address information');
      //     });
    }
    // else if (scheduleFormValue.appointment_type == '0 1') {
    //   addressPreferance = {
    //     provider_id: this.provider_id,
    //     userid: this.userid,
    //     default_cost: scheduleFormValue.cost,
    //     default_time_duration: scheduleFormValue.duration,
    //     working_start_time: moment(this.timeForm.controls['start_time'].value, 'hh:mm').format('HH:mm:SS'),
    //     working_end_time: moment(this.timeForm.controls['end_time'].value, 'hh:mm').format('HH:mm:SS'),
    //     break_start_time: this.timeForm.controls['break_start_time'].value == 'Invalid date' || this.timeForm.controls['break_start_time'].value == '' ? '' : moment(this.timeForm.controls['break_start_time'].value, 'hh:mm').format('HH:mm:SS'),
    //     break_end_time: this.timeForm.controls['break_end_time'].value == 'Invalid date' || this.timeForm.controls['break_end_time'].value == '' ? '' : moment(this.timeForm.controls['break_end_time'].value, 'hh:mm').format('HH:mm:SS'),
    //     working_week_days: scheduleFormValue.recurrence_type == 'weekly' ? selected_week_days : selected_month_days,
    //     working_month_days: scheduleFormValue.recurrence_type == 'monthly' ? selected_month_days : '',
    //     postcode: scheduleFormValue.new_address.length > 0 ? this.postcode : '',
    //     city_name: scheduleFormValue.new_address.length > 0 ? this.city : '',
    //     address_id: scheduleFormValue.new_address.length == 0 ? scheduleFormValue.address : '',
    //     address_text: scheduleFormValue.new_address.length > 0 ? scheduleFormValue.new_address + ', ' + this.address : '',
    //     address_lat: scheduleFormValue.new_address.length > 0 ? this.latitude : '',
    //     address_lng: scheduleFormValue.new_address.length > 0 ? this.longitude : '',
    //     online_consultation_flag: '0 1 ',
    //     working_days: ''
    //   };

    //   console.log('addressPreferance: ', addressPreferance);

    // this.server.request('POST', '/providers/addAddressInfoPreferanceTwo', addressPreferance)
    //   .subscribe(
    //     (_response: any) => {
    //       this.toaster.success('Address information changed successfully');
    //       this.week1Days = '';
    //       this.week2Days = '';
    //       this.week3Days = '';
    //       this.week4Days = '';
    //       this.week5Days = '';
    //     },
    //     (_error) => {
    //       this.toaster.error('Could not change address information');
    //     });
    //   this.addAddressPreferanceService.AddAddressPreferanceService(addressPreferance)
    //     .subscribe(
    //       (_response) => {
    //         if (_response.status === 200) {
    //           // console.log('preferance added');
    //           this.toaster.success('Address information changed successfully');
    //           this.week1Days = '';
    //           this.week2Days = '';
    //           this.week3Days = '';
    //           this.week4Days = '';
    //           this.week5Days = '';
    //         }
    //       },
    //       (_error) => {
    //         this.toaster.error('Could not change address information');
    //       });

    // }
  }

  breakTimeValidator: ValidatorFn = (timeForm: FormGroup) => {
    const start = timeForm.get('start_time').value;
    const end = timeForm.get('end_time').value;
    const break_start = timeForm.get('break_start_time').value;
    const break_end = timeForm.get('break_end_time').value;

    console.log('diff: ', moment(break_end, 'hh:mm').diff(moment(break_start, 'hh:mm'), 'minute'));
    if (timeForm.controls['break_start_time'].value !== '' && timeForm.controls['break_end_time'].value !== '') {
      let startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
      let endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));
      let timeCondition = moment(break_end, 'hh:mm').diff(moment(break_start, 'hh:mm'), 'minute') <= 240 && moment(break_end, 'hh:mm').diff(moment(break_start, 'hh:mm'), 'minute') > 0;
      if (!startCondition || !endCondition || !timeCondition) {
        return { 'breakNotValid': true };
      }
    }

    // let startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
    // let endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));

    // if (!startCondition || !endCondition) {
    //   return { 'breakNotValid': true };
    // }

    return null;
  }

  timeValidator: ValidatorFn = (timeForm: FormGroup) => {
    const start = timeForm.get('start_time').value;
    const end = timeForm.get('end_time').value;
    const break_start = timeForm.get('break_start_time').value;
    const break_end = timeForm.get('break_end_time').value;
    let startCondition, endCondition;

    const duration = this.scheduleForm.controls['duration'].value;

    let condition = start !== null && end !== null && (moment(end, 'hh:mm').diff(moment(start, 'hh:mm'), 'minute') >= duration);

    console.log("condition:", condition);
    if (timeForm.controls['break_start_time'].value !== '' && timeForm.controls['break_end_time'].value !== '' && timeForm.controls['break_start_time'].value !== timeForm.controls['break_end_time'].value) {
      startCondition = start !== null && break_start !== null && moment(start, 'hh:mm').isBefore(moment(break_start, 'hh:mm')) && moment(break_start, 'hh:mm').isBefore(moment(end, 'hh:mm'));
      endCondition = end !== null && break_end !== null && moment(end, 'hh:mm').isAfter(moment(break_end, 'hh:mm')) && moment(break_end, 'hh:mm').isAfter(moment(start, 'hh:mm'));
    }

    this.breakTimeValidator;
    if (!condition) {
      return { 'timeNotValid': true };
    }
    // if (!startCondition || !endCondition) {
    //   return { 'breakNotValid': true };
    // }
    return null;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.timeForm.controls[controlName].hasError(errorName);
  }

}
