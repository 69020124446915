import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  constructor() { }
  deeplink() {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    if (isAndroid== true) {
      let app = {
        launchApp: function() {
          window.location.replace("bundlename://linkname"); //which page to open(now from mobile, check its authorization)
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function() {
          window.location.href =  "https://play.google.com/store/apps/details?id=org.nativescript.Blank";
        }
    };
    app.launchApp();
   } else{
   //navigate to website url
  }
 }
}
