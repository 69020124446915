import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { GetpartnerdetailService } from '../../../services/getpartnerdetail/getpartnerdetail.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { GetpartnerdetailbypartneridService } from '../../../services/getpartnerdetailbypartnerid/getpartnerdetailbypartnerid.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partner-dashboard',
  templateUrl: './partner-dashboard.component.html',
  styleUrls: ['./partner-dashboard.component.css']
})
export class PartnerDashboardComponent implements OnInit {
  subCategoriesService: []
  patner_id;
  totalPartnerAmount = '';
  total_provider = '';
  active_flag;
  partnerDetails = [];
  totalPatnerAmount;
  gst_totalPatnerAmount;

  partnerAvailable = true;
  displayedColumns: string[] = ['provider_name', 'price', 'patner_amount'];
  filters = [
    { id: '1', name: 'Jan', group: 'Month' },
    { id: '1', name: 'Feb', group: 'Month' },
    { id: '1', name: 'Mar', group: 'Month' },
    { id: '1', name: 'Apr', group: 'Month' },
    { id: '1', name: 'May', group: 'Month' },
    { id: '1', name: 'June', group: 'Month' },
    { id: '1', name: 'July', group: 'Month' },
    { id: '1', name: 'Aug', group: 'Month' },
    { id: '1', name: 'Sept', group: 'Month' },
    { id: '1', name: 'Oct', group: 'Month' },
    { id: '1', name: 'Nov', group: 'Month' },
    { id: '1', name: 'Dec', group: 'Month' },

  ];
  @Input() selected: boolean;
  @Output() selectedChange = new EventEmitter<boolean>();
  @Input('unique_url_id') unique_url_id: string;
  @Input('provider_id') provider_id: string;
  @Input('providerUserId') providerUserId: string;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getPartnerDetailService: GetpartnerdetailService,
    private server: JwtTestService,
    private getPartnerDetailByPartnerId: GetpartnerdetailbypartneridService,
    private http: HttpClient,
    private httpClient: HttpClient,
    private router: Router,

  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.patner_id = localStorage.getItem('patner_id');
    this.unique_url_id = localStorage.getItem('unique_url_id');

    // let query = `?patner_id=${this.patner_id}`;
    let query = {
      patner_id: this.patner_id
    };

    this.server.request('GET', '/patner/getPatnerDetailByPatnetId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            if (_response.body.length == 0) {
              this.partnerAvailable = false;
            } else {
              this.partnerAvailable = true;
              this.partnerDetails = _response.body.results;
              // this.totalPatnerAmount = _response.totalPatnerAmount;
              this.gst_totalPatnerAmount = _response.body.gst_totalPatnerAmount;
              localStorage.setItem('gst_totalPatnerAmount', this.gst_totalPatnerAmount);
            }
          }
        },
        (_error) => {
          this.partnerAvailable = false;
        });

        this.server.request('GET', '/patner/getPatnerDetailByPatnetId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.totalPartnerAmount = _response.body.totalPatnerAmount ? _response.body.totalPatnerAmount : 0;
            this.total_provider = _response.body.total_provider ? _response.body.total_provider : 0;
          }
        },
        (_error) => {

        });

    this.server.request('GET', '/patner/getPatnerDetail', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.active_flag = _response.body.active_flag;
          }
        },
        (_error) => {

        });
    // this.getPartnerDetailService.GetpartnerdetailService(query)
    // .subscribe(
    //   (_response) => {
    //     if (_response.status === 200 && _response.body.length == 0) {
    //       this.partnerAvailable = false;
    //     }
    //     else if (_response.status === 200) {
    //       this.partnerAvailable = true;
    //       this.partnerDetails = _response.body.results;
    //       this.totalPatnerAmount = _response.body.totalPatnerAmount;
    //     }
    //   },
    //   (_error) => {
    //     if (_error.status === 409) {
    //       this.partnerAvailable = false;
    //     }
    //   });
  }
  onClearFilter(event) {
    // this.filterQuery = ``;
  }
  viewProfileDetails(provider_id) {
    this.router.navigate(['/partner/partner-providerdetails/', provider_id]);
    localStorage.setItem('provider_id', provider_id);
    // localStorage.setItem('providerUserId', this.providerUserId);
    // sessionStorage.setItem('provider_id', this.provider_id);
    // sessionStorage.setItem('providerUserId', this.providerUserId);
  }


}
