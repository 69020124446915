import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acc-settings',
  templateUrl: './acc-settings.component.html',
  styleUrls: ['./acc-settings.component.css']
})
export class AccSettingsComponent implements OnInit {
  

  constructor() { }

  ngOnInit() {
  }

}
