import { Component, OnInit, NgZone, ViewChild, ElementRef, } from '@angular/core';
import { GetProvideServiceService } from '../../../services/getProvider/get-provide-service.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FavLikeService } from '../../../services/like/fav-like.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { GetContactInfoService } from '../../../services/contact-info/get-contact-info.service';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { GetsubcategoryserviceService } from 'src/app/services/getsubcategory/getsubcategoryservice.service';

@Component({
  selector: 'app-provider-details-view',
  templateUrl: './provider-details-view.component.html',
  styleUrls: ['./provider-details-view.component.css']
})

export class ProviderDetailsViewComponent {
  // model: any = {};
  item;
  subCategoriesService = [];
  totalRecords: string;
  page: Number = 1
  category_id;
  sub_category_id;
  category_name = '';
  // num: number;
  sub: any;
  selected: boolean;
  l_userid;
  userid;
  // days: number;
  // getLocation: "";
  // getsearch: ""
  // Category = ['Doctor', 'Lawyer', 'Beautician'];
  // available = ['Monday', 'Tuesday', 'Wednesday', 'Friday', 'Saturday', 'Sunday'];
  sorting = [{ id: 'DESC', name: 'Fees: High to Low' }, { id: 'ASC', name: 'Fees: Low to High' }];
  // Filter = [{ id: '1', gender: 'Male' }, { id: '2', gender: 'Female' }];
  // selectedCity = '';
  // fav_status = 1;
  show = true;
  filterShow = true;
  // areaArray = [];
  filters = [
    { id: '1', name: 'Male', group: 'Gender' },
    { id: '2', name: 'Female', group: 'Gender' },
    // { id: '1,2', name: 'Both', group: 'Gender'},
    { id: '0,1,2,3,4,5', name: '0-5 years', group: 'Experience' },
    { id: '6,7,8,9,10', name: '5-10 years', group: 'Experience' },
    { id: '11,12,13,14,15,16,17,18,19,20,21,22,23,23,24,25', name: '10+ years', group: 'Experience' },
    // { id: 'Monday', name: 'Monday', group: 'Available' },
    // { id: 'Tuesday', name: 'Tuesday', group: 'Available' },
    // { id: 'Wednesday', name: 'Wednesday', group: 'Available' },
    // { id: 'Thursday', name: 'Thursday', group: 'Available' },
    // { id: 'Friday', name: 'Friday', group: 'Available' },
    // { id: 'Saturday', name: 'Saturday', group: 'Available' },
    // { id: 'Sunday', name: 'Sunday', group: 'Available' },
    // { id: 'English', name: 'English', group: 'Languages' },
    // { id: 'Hindi', name: 'Hindi', group: 'Languages' },
    // { id: 'Marathi', name: 'Marathi', group: 'Languages' },
    { id: '1', name: 'Online Consultation', group: 'Online Consultation' }
    // { id: 'Punjabi', name: 'Punjabi', group: 'Languages' },
    // { id: 'MBBS', name: 'MBBS', group: 'Degree' },
    // { id: 'MD', name: 'MD', group: 'Degree' },
    // { id: 'LLB', name: 'LLB', group: 'Degree' },
    // { id: 'LLP', name: 'LLP', group: 'Degree' },
  ];
  specialtyFilter = [];
  availabilityFilter = [
    { id: 'Monday', name: 'Monday', group: 'Available' },
    { id: 'Tuesday', name: 'Tuesday', group: 'Available' },
    { id: 'Wednesday', name: 'Wednesday', group: 'Available' },
    { id: 'Thursday', name: 'Thursday', group: 'Available' },
    { id: 'Friday', name: 'Friday', group: 'Available' },
    { id: 'Saturday', name: 'Saturday', group: 'Available' },
    { id: 'Sunday', name: 'Sunday', group: 'Available' },
  ];
  private geoCoder;
  latitude: number;
  longitude: number;
  postcode;
  address: string;
  cityName;
  filterQuery = ``;
  sortQuery = ``;
  categories = [];

  constructor(
    private getProvideServiceService: GetProvideServiceService,
    private FavLikeService: FavLikeService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private metaTagService: Meta,
    private getContactInfoService: GetContactInfoService,
    private getsubcategoryserviceService: GetsubcategoryserviceService,
  ) { }

  ngOnInit() {
    this.sub = this.route
      .params
      .subscribe(params => {
        // console.log(params);
        // Defaults to 0 if no query param provided.
        // this.category_id = params.catId || 1;
        this.category_name = params.catName || 1;
      });

    this.metaTags();

    this.l_userid = localStorage.getItem('userid');
    this.userid = localStorage.getItem('userid');
    this.cityName = localStorage.getItem('cityName');
    this.category_id = localStorage.getItem('catId');
    this.sub_category_id = localStorage.getItem('catChildId');
    let queryParms = `?category_id=${this.category_id}&l_userid=${this.l_userid}`;
    this.sub_category_id ? queryParms += `&sub_category_id=${localStorage.getItem('catChildId')}` : ``;
    this.cityName ? queryParms += `&city_name=${this.cityName}` : ``;

    this.ngxService.start();

    this.getCategories();

    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;
    // });

    this.getProvideServiceService.GetProvideServiceService(queryParms)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          // console.log(_response);
          if (_response.status === 200 && _response.body.length == 0) {
            this.show = false;
          } else if (_response.status === 200) {
            try {
              this.show = true;
              this.subCategoriesService = _response.body;

              for (let i = 0; i < this.subCategoriesService.length; i++) {
                if (this.subCategoriesService[i].qualification.includes('*')) {
                  this.subCategoriesService[i].qualification = this.subCategoriesService[i].qualification.replace(/\*/g, ' ')
                }
                this.subCategoriesService[i].show = false;
              }
            } catch (err) {
              console.log(err);
            }
          }

        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if (_error.status === 404) {
            this.show = false;
          }
        });
  }

  metaTags() {
    if (this.category_name == 'Orthopaedic') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Orthopaedic near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'orthopedic near me , best orthopedic near me , orthopedic near me specialist, childrens orthopedic near me, ' }
      );
    } else if (this.category_name == 'Gynecologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Gynecologist near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'gynecologists near me, list of gynecologists near me, best gynecologists near me , gynecologists & obstetricians ' }
      );
    } else if (this.category_name == 'Orthodontics ') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Orthodontics near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'orthodontics near me, orthodontics specialist near me, dentist near me, nearby dentist , online dentist consultation , dentist near me open right now , online dentist ' }
      );
    } else if (this.category_name == 'Neurologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Neurologists near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'neurologist near me , best neurologist near me, neurologist online consultation, pediatric neurologist online consultation ' }
      );
    } else if (this.category_name == 'Psychologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Psychologist near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'psychologist online consultation, child psychologist online consultation, psychologist near me, counseling psychologist, online psychologist, counselling psychologist near me, ' }
      );
    } else if (this.category_name == 'Dermatologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Dermatologist near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'dermatologists near me, top dermatologists , best dermatologists for acne, dermatologists specializing in hair loss, best dermatologists, dermatologists nearby' }
      );
    } else if (this.category_name == 'Dentist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Dentists near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'dentist near me, nearby dentist , online dentist consultation , dentist near me open right now , online dentist ' }
      );
    } else if (this.category_name == 'Ophthalmologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Ophthalmologists near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'ophthalmologist near me , best ophthalmologist near me, pediatric ophthalmologist near me, ophthalmologist clinic, best ophthalmologist, ophthalmologist doctor, neuro ophthalmologist near me, ophthalmologist near me open now, good ophthalmologist near me, ophthalmologist online consultation, ' }
      );
    } else if (this.category_name == 'Anesthesiologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Anesthesiologist near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'anesthesiologist colleges near me, best anesthesiologist, ' }
      );
    } else if (this.category_name == 'Gastroenterologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Gastroenterologist near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'gastroenterologist near me, gastroenterologist, best gastroenterologist near me , gastroenterologist doctors near me, best gastroenterologist, gastroenterologist specialist, gastroenterologist doctor near me, gastroenterologist hospital near me, gastroenterologist clinic near me' }
      );
    } else if (this.category_name == 'Cardiologist') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best male/female Cardiologist near me and book appointments online instantly for expert advice through video call with the insights of their Experience, Location, Fees, Address and Phone number. Get right medical assistance anytime and anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'cardiologist near me, best cardiologist near me, cardiologist doctor, cardiologist specialist, best cardiologist, doctor cardiologist, cardiologist clinic near me' }
      );
    } else if (this.category_name == 'Trial') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best lawyers for Trial near me - For legal help Consult & Hire best advocates instantly by booking appointment online. Get their Experience, Achievements, Address and Contact details insights. Get right Legal Advice anytime & anywhere only on Apointt.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'lawyer trial consultation, trial lawyers near me' }
      );
    } else if (this.category_name == 'Immigration') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best lawyers for Immigration near me - For legal help Consult & Hire best advocates instantly by booking appointment online. Get their Experience, Achievements, Address and Contact details insights. Get right Legal Advice anytime & anywhere only on Apointt.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'lawyer immigration consultation, lawyer immigration consultation near me' }
      );
    } else if (this.category_name == 'Personal Injury') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best lawyers for Personal Injury near me - For legal help Consult & Hire best advocates instantly by booking appointment online. Get their Experience, Achievements, Address and Contact details insights. Get right Legal Advice anytime & anywhere only on Apointt.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'lawyer personal injury consultation, personal injury lawyer' }
      );
    } else if (this.category_name == 'Criminal Law') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best lawyers for Criminal Law near me - For legal help Consult & Hire best advocates instantly by booking appointment online. Get their Experience, Achievements, Address and Contact details insights. Get right Legal Advice anytime & anywhere only on Apointt.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'criminal lawyer, best criminal lawyer in india, online lawyer consultation' }
      );
    } else if (this.category_name == 'Real Estate') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best lawyers for Real Estate near me - For legal help Consult & Hire best advocates instantly by booking appointment online. Get their Experience, Achievements, Address and Contact details insights. Get right Legal Advice anytime & anywhere only on Apointt.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'real estate lawyer , real estate lawyer fees, real estate lawyer near me, real estate lawyer consultation fee' }
      );
    } else if (this.category_name == 'Entertainment Lawyer') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best lawyers for Trial near me - For legal help Consult & Hire best advocates instantly by booking appointment online. Get their Experience, Achievements, Address and Contact details insights. Get right Legal Advice anytime & anywhere only on Apointt.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'entertainment lawyer, entertainment lawyer india, entertainment lawyer near me' }
      );
    } else if (this.category_name == 'Internet Lawyers') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find best lawyers for Trial near me - For legal help Consult & Hire best advocates instantly by booking appointment online. Get their Experience, Achievements, Address and Contact details insights. Get right Legal Advice anytime & anywhere only on Apointt.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'internet lawyer, internet lawyer near me' }
      );
    } else if (this.category_name == 'Makeup ') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find hygienic & Best Makeup Salons near me. Dont wait in salon queue just Book appointment for Online Consultation instantly with insights of their Experience, Address, Fees & Contact details. Get Best skin care clinics near by antime anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'makeup salon near me, makeup salon, bridal makeup salon, bridal makeup salon near me, beauty makeup salon, hair and makeup salon, eye makeup salon, best makeup salon, ' }
      );
    } else if (this.category_name == 'Hair designer') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find hygienic & Best Hair Designer salons near me. Dont wait in salon queue just Book appointment for Online Consultation instantly with insights of their Experience, Address, Fees & Contact details. Get Best skin care clinics near by antime anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'hair designer near me, hair designer salon, wedding hair designer, best hair designer near me, top hair designer, best hair designer in india, hair designer salon near me' }
      );
    } else if (this.category_name == 'Barbering') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find hygienic & Best Barber salons near me. Dont wait in salon queue just Book appointment for Online Consultation instantly with insights of their Experience, Address, Fees & Contact details. Get Best skin care clinics near by antime anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'barber salon, barber salon near me, good barber salon near me' }
      );
    } else if (this.category_name == 'Nail technicians') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find hygienic & Best Nail technicians near me. Dont wait in salon queue just Book appointment for Online Consultation instantly with insights of their Experience, Address, Fees & Contact details. Get Best skin care clinics near by antime anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'nail salon near me, nail salon near me prices, fancy nail salon, nail salon prices list, tip and toe nail salon prices, nail salon near me online booking' }
      );
    } else if (this.category_name == 'Cosmetology') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find hygienic & Best Cosmetologists near me. Dont wait in salon queue just Book appointment for Online Consultation instantly with insights of their Experience, Address, Fees & Contact details. Get Best skin care clinics near by antime anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'cosmetician near me, cosmetician online booking' }
      );
    } else if (this.category_name == 'Chartered Accountant Audit') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Best Chartered Accountant Firms and Services near me - Choose best CA firms providing Audit & Advisory with verified details of their Experience, Fees, Address & Contact number. Book an appointment for Online Consultation instantly. Get Best CA services near by anytime & anywhere only on APOINTT.' },

      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'ca audit firms near me, ca audit fees, ca audit firms, ca audit services, ca audit services near me, ca audit charges, ' }
      );
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log('address: ', results);
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          this.postcode = results[0].address_components.pop();
          // console.log('postcode: ', this.postcode.long_name);

          this.getCity(this.postcode.long_name.slice(0, 3));

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });

  }

  getCity(postcode) {
    this.postcode = postcode.long_name.substr(0, 3);
    let cityQuery = `?postcode=${postcode.long_name.substr(0, 3)}`;

    this.getContactInfoService.GetContactInfoService(cityQuery)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.cityName = _response.body[0].city;
            // this.getProvidersByCityName(this.cityName);
          }
        },
        (_error) => {

        });
  }

  // getProvidersByCityName(city) {
  //   this.l_userid = localStorage.getItem('userid');
  //   let queryParms = `?category_id=${localStorage.getItem('catChildId')}&l_userid=${this.l_userid}&city_name=${city}`;

  //   this.getProvideServiceService.GetProvideServiceService(queryParms)
  //     .subscribe(
  //       (_response) => {
  //         setTimeout(() => {
  //           this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
  //         }, 0);
  //         if (_response.status === 200 && _response.body.length == 0) {
  //           this.show = false;
  //         } else if (_response.status === 200) {
  //           try {
  //             this.show = true;
  //             this.subCategoriesService = _response.body;

  //             // if (_response.body[0].fav_status == '1') {
  //             //   this.selected = true;
  //             // } else {
  //             //   this.selected = false;
  //             // }
  //           } catch (err) {
  //             console.log(err);
  //           }
  //         }
  //       },
  //       (_error) => {
  //         setTimeout(() => {
  //           this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
  //         }, 0);
  //         if (_error.status === 404) {
  //           this.show = false;
  //         }
  //       });

  // }

  // getProvider(event) {
  //   console.log(event);
  //   let queryParms = `?city_name=${event}&l_userid=${this.l_userid}`;
  //   this.getProvideServiceService.GetProvideServiceService(queryParms)
  //     .subscribe(
  //       (_response) => {
  //         if (_response.status === 200) {
  //           this.show = true;
  //           this.subCategoriesService = _response.body;
  //           if (_response.body[0].fav_status == '1') {
  //             this.selected = true;
  //           } else {
  //             this.selected = false;
  //           }
  //         }
  //       },
  //       (_error) => {
  //         if (_error.status === 404) {
  //           this.show = false;
  //         }
  //       }
  //     );
  // }

  // getProviderPostcode(event) {
  //   console.log('event: ', event);
  //   let postcode = '';
  //   for (var i = 0; i < event.length; i++) {
  //     postcode += event[i].postcode + ' ';
  //   }

  //   let queryParms = `?category_id=${localStorage.getItem('catChildId')}&l_userid=${this.l_userid}&postcode=${postcode}`;
  //   this.getProvideServiceService.GetProvideServiceService(queryParms)
  //     .subscribe(
  //       (_response) => {
  //         if (_response.status === 200 && _response.body.length == 0) {
  //           this.filterShow = false;
  //         } else if (_response.status === 200) {
  //           // this.show = true;
  //           this.filterShow = true;
  //           this.subCategoriesService = _response.body;
  //           if (_response.body[0].fav_status == '1') {
  //             this.selected = true;
  //           } else {
  //             this.selected = false;
  //           }
  //         }
  //       },
  //       (_error) => {
  //         if (_error.status === 404) {
  //           // this.show = false;
  //           this.filterShow = false;
  //           this.subCategoriesService = [];
  //         }
  //       });
  // }

  onChange(event) {
    let queryParms = ``;
    for (let i = 0; i < event.length; i++) {
      if (event[i].group === 'Gender') {
        queryParms += `&gender=${event[i].id}`;
      }
      if (event[i].group === 'Experience') {
        queryParms += `&Provider_experience=${event[i].id}`;
      }
      // if (event[i].group === 'Available') {
      //   queryParms += `&working_days=${event[i].id}`;
      // }
      // if (event[i].group === 'Languages') {
      //   queryParms += `&provider_languages=${event[i].id}`;
      // }
      // if (event[i].group === 'Degree') {
      //   queryParms += `&qualification_name=${event[i].id}`;
      // }
      if (event[i].group === 'Online Consultation') {
        queryParms += `&online_consultation_type=${event[i].id}`;
      }
    }
    this.filterQuery = queryParms;
    this.filterAndSort();
  }

  onAvailabilityChange(event) {
    let queryParms = `&working_days=${event.id}`;
    this.filterQuery = queryParms;
    this.filterAndSort();
  }

  onSpecialityChange(event) {
    this.router.navigate(['/customer-dashboard/provider-details-view/', event.name]);
    localStorage.setItem('catChildId', event.id);
    localStorage.setItem('catChildName', event.name);
  }

  onChangeSort(event) {
    if (event) {
      this.sortQuery = `&price=${event.id}`;
    }
    this.filterAndSort();
  }

  onClearFilter(event) {
    this.filterQuery = ``;
    this.filterAndSort();
  }

  onClearSort(event) {
    this.sortQuery = ``;
    this.filterAndSort();
  }

  filterAndSort() {
    let queryParms = `?category_id=${localStorage.getItem('catId')}&sub_category_id=${localStorage.getItem('catChildId')}&l_userid=${localStorage.getItem('userid')}`;
    this.postcode ? queryParms += `&postcode=${this.postcode}` :
      this.cityName ? queryParms += `&city_name=${this.cityName}` : ``;
    queryParms += this.filterQuery;
    queryParms += this.sortQuery;
    console.log('filterandsort: ', queryParms);

    this.getProvideServiceService.GetProvideServiceService(queryParms)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            if (_response.body.length == 0) {
              // this.show = false;
              this.filterShow = false;
              this.subCategoriesService = [];
            } else {
              // this.show = true;
              this.filterShow = true;
              this.subCategoriesService = _response.body;
              if (_response.body[0].fav_status == '1') {
                this.selected = true;
              } else {
                this.selected = false;
              }

              for (let i = 0; i < this.subCategoriesService.length; i++) {
                if (this.subCategoriesService[i].qualification.includes('*')) {
                  this.subCategoriesService[i].qualification = this.subCategoriesService[i].qualification.replace(/\*/g, ' ')
                }
              }
            }
          }
          this.totalRecords = _response.body.length;
        },
        (_error) => {
          if (_error.status === 404) {
            // this.show = false;
            this.filterShow = false;
            this.subCategoriesService = [];
          }
        }
      );

  }

  getCategories() {
    let query = `?category_name=${localStorage.getItem('catName')}`;

    this.getsubcategoryserviceService.GetsubcategoryserviceService(query)
    .subscribe(
      (_response) => {
        if (_response.status === 200) {
          this.categories = _response.body;
          for (let i = 0; i < _response.body.length; i++) {
            let cat = {
              id: _response.body[i].catId,
              name: _response.body[i].catChildName,
              group: 'Speciality'
            };
            this.specialtyFilter.push(cat);
          }
        }
      },
      (_error) => {

      });
  }

  goToCategory(cat) {
    this.router.navigate(['./customer-dashboard/provider-details-view/' + cat.catChildName]);
    localStorage.setItem('catChildId', cat.catId);
    localStorage.setItem('catChildName', cat.catChildName);
  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("container").style.maxWidth = "83.333333%";
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }
}


