import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddclientService {

  constructor(private httpService: HttpClient) { }

  AddclientService(data) {
    const href = environment.api.addclient;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }

  getAllClientsByProviderIdService(query) {
    const href = environment.api.getclientsbyproviderid;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }

  shareProviderLinkService(data) {
    const href = environment.api.sendlinktoclient;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
