import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CancelapointfornotificationService {

  constructor(private httpService: HttpClient) { }

  CancelapointfornotificationService(query) {
    const href = environment.api.cancelappointfornotification;
    return this.httpService.get<any>(href + query, {'observe': 'response'});
  }
}
