import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AgmServiceService } from 'src/app/services/agm/agm-service.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { debounceTime, tap, switchMap, finalize, flatMap } from 'rxjs/operators';
import { GetsearchService } from '../../services/getserachservice/getsearch.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { GetProvideServiceService } from '../../services/getProvider/get-provide-service.service';
import { GetContactInfoService } from '../../services/contact-info/get-contact-info.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface marker {
  name: string;
  latitude: number;
  longitude: number;
  // label?: string;
  //  draggable: boolean;
  //  content?:string;
  isShown: boolean;
  infoLat: number;
  //  icon:string;
}

@Component({
  selector: 'app-get-location',
  templateUrl: './get-location.component.html',
  styleUrls: ['./get-location.component.css']
})
export class GetLocationComponent implements OnInit {
  catId = '';
  catChildId = '';
  // show: boolean = false; 
  // getLocation: [];
  // keyword = 'locationName';
  getSearchArray: [];
  isLoading = false;
  searchForm: FormGroup;
  // city = new FormControl('');
  latitude: number;
  longitude: number;
  zoom: number = 12;
  address: string;
  private geoCoder;
  previous;
  postcode;
  cityArea = [];
  cityName = '';
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  areaControl = new FormControl('');
  filteredAreas: Observable<string[]>;
  areas: string[] = [];
  url = '';
  showMap;
  hide;
  getSearchCategories = [];
  getSearchSubCat = [];
  searchCatId;
  // areaArray = [];

  radius = 5000;
  radiusLat = 0;
  radiusLong = 0;
  formatted_address = '';

  markers: marker[] = []

  @ViewChild('searchLocation', { static: false })
  public searchElementRef: ElementRef;

  @ViewChild('searchLocation2', { static: false })
  public searchElementRef2: ElementRef;

  @ViewChild('areaInput', { static: false }) areaInput: ElementRef<HTMLInputElement>;
  @ViewChild('city', { static: true }) matAutocomplete: MatAutocomplete;

  // @Input('selectedCity') selectedCity: '';
  // @Output() selectEvent = new EventEmitter();

  @Input('areaArray') areaArray: any = [];
  @Output() areaSelectEvent = new EventEmitter();

  @Output() citySelectEvent = new EventEmitter();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private agmServiceService: AgmServiceService,
    private formBuilder: FormBuilder,
    private getSearch: GetsearchService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private getProviderService: GetProvideServiceService,
    private getContactInfoService: GetContactInfoService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    // this.filteredAreas = this.areaControl.valueChanges.pipe(
    //   startWith(null),
    //   map((area: string | null) => area ? this._filter(area) : this.cityArea.slice()));
  }

  ngOnInit() {
    // this.getSearchDetails();
    this.catId = localStorage.getItem('catId');
    this.catChildId = localStorage.getItem('catChildId');
    this.cityName = localStorage.getItem('cityName');
    this.formatted_address = localStorage.getItem('formatted_address');

    this.latitude = Number(localStorage.getItem('latitude'));
    this.longitude = Number(localStorage.getItem('longitude'));
    this.radiusLat = Number(localStorage.getItem('radiusLat'));
    this.radiusLong = Number(localStorage.getItem('radiusLong'));

    this.url = this.router.url;
    // if (this.url.includes('/customer-dashboard/provider-details-view/')) {
    //   this.showMap = true;
    // } else {
    //   this.showMap = false;
    // }

    this.getSearch.getSearchForCategory()
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getSearchCategories = _response.body;
            if (this.catId && this.url != '/customer-dashboard') {
              let category = this.getSearchCategories.find(cat => cat.id == this.catId);
              this.searchForm.controls['search_category'].setValue(category.id);
            }
          }
        },
        (_error) => {
          this.getSearchCategories = [];
        });

    this.getSearch.getSearchProviderAndSubCategory(this.catId ? this.catId : '')
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getSearchSubCat = _response.body;
            if (this.catChildId && (!this.url.includes('/customer-dashboard/child-category-view') && this.url != '/customer-dashboard')) {
              let subcat = this.getSearchSubCat.find(sub => sub.id == this.catChildId);
              this.searchForm.controls['search_provider'].setValue(subcat);
            }
          }
        },
        (_error) => {
          this.getSearchSubCat = [];
        });

    this.searchForm = this.formBuilder.group({
      // city: [''],
      search: [''],
      search_category: [''],
      search_provider: [''],
    });

    // this.searchForm
    //   .get('city')
    //   .valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => this.isLoading = true),
    //     switchMap(value => this.agmServiceService.getAgmServiceService({ city_name: value })
    //       .pipe(
    //         finalize(() => this.isLoading = false),
    //       ))
    //   )
    //   .subscribe(
    //     (_response => {
    //       if (_response.status === 200) {
    //         this.getLocation = _response.body;
    //       } else {
    //         this.getLocation = [];
    //       }
    //     }),
    //     (_error => {
    //       this.getLocation = [];
    //     })
    //   );

    // this.searchForm
    //   .get('search')
    //   .valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => this.isLoading = true),
    //     switchMap(value => this.getSearch.GetsearchService({ sterm: value })
    //       .pipe(
    //         finalize(() => this.isLoading = false),
    //       ))
    //   )
    //   .subscribe(
    //     (_response => {
    //       if (_response.status === 200) {
    //         this.getSearchArray = _response.body;
    //       } else {
    //         this.getSearchArray = [];

    //       }
    //     }),
    //     (_error => {
    //       this.getSearchArray = [];

    //     })
    //   );

    // this.searchForm
    //   .get('search_category')
    //   .valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => this.isLoading = true),
    //     switchMap(value => this.getSearch.getSearchForCategory({ sterm: value })
    //       .pipe(
    //         finalize(() => this.isLoading = false),
    //       ))
    //   )
    //   .subscribe(
    //     (_response => {
    //       if (_response.status === 200) {
    //         this.getSearchCategories = _response.body;
    //       } else {
    //         this.getSearchCategories = [];
    //       }
    //     }),
    //     (_error => {
    //       this.getSearchCategories = [];
    //     })
    //   );

    this.searchForm
      .get('search_provider')
      .valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => this.isLoading = true),
        switchMap(value => this.getSearch.getSearchProviderAndSubCategory(this.searchCatId ? this.searchCatId : this.catId, this.postcode ? this.postcode.long_name.substr(0, 3) : '', value)
          .pipe(
            finalize(() => this.isLoading = false),
          ))
      )
      .subscribe(
        (_response => {
          if (_response.status === 200) {
            this.getSearchSubCat = _response.body;
          } else {
            this.getSearchSubCat = [];
          }
        }),
        (_error => {
          this.getSearchSubCat = [];
        })
      );

    // this.areaControl
    //   .valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => this.isLoading = true),
    //     switchMap(value => this.getContactInfoService.getPostcodeByArea({ area_name: value, postcode: this.postcode.long_name.slice(0, 3) })
    //       .pipe(
    //         finalize(() => this.isLoading = false),
    //       ))
    //   )
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.cityArea = _response.body;
    //       } else {
    //         this.cityArea = [];
    //       }
    //     },
    //     (_error) => {
    //       this.cityArea = [];
    //     });

    if (this.cityName === null) {
      this.setCurrentLocation();
    }

    this.mapsAPILoader.load().then(() => {
      if (this.cityName === null) {
        this.setCurrentLocation();
      }
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });

      let autocomplete2 = new google.maps.places.Autocomplete(this.searchElementRef2.nativeElement, {
        types: ["address"]
      });

      if (autocomplete) {
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            localStorage.setItem('formatted_address', place.formatted_address);

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.radiusLat = place.geometry.location.lat();
            this.radiusLong = place.geometry.location.lng();
            this.zoom = 12;

            localStorage.setItem('latitude', this.latitude.toString());
            localStorage.setItem('longitude', this.longitude.toString());
            localStorage.setItem('radiusLat', this.radiusLat.toString());
            localStorage.setItem('radiusLong', this.radiusLong.toString());

            this.getAddress(this.latitude, this.longitude);
          });
        });
      } else if (autocomplete2) {
        autocomplete2.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            localStorage.setItem('formatted_address', place.formatted_address);

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.radiusLat = place.geometry.location.lat();
            this.radiusLong = place.geometry.location.lng();
            this.zoom = 12;

            localStorage.setItem('latitude', this.latitude.toString());
            localStorage.setItem('longitude', this.longitude.toString());
            localStorage.setItem('radiusLat', this.radiusLat.toString());
            localStorage.setItem('radiusLong', this.radiusLong.toString());

            this.getAddress(this.latitude, this.longitude);
          });
        });
      }

    });
  }

  addressChange(event) {
    // this.areas = [];
    if (this.searchElementRef.nativeElement.value == '' || this.searchElementRef2.nativeElement.value == '') {
      localStorage.removeItem('formatted_address');
      localStorage.removeItem('cityName');
    }
  }

  // clickedMarker(infowindow) {
  //   // if (this.previous) {
  //   //   this.previous.close();
  //   // }
  //   this.previous = infowindow;
  //   infowindow.open();
  // }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 10;
        this.radiusLat = this.latitude;
        this.radiusLong = this.longitude;

        localStorage.setItem('latitude', this.latitude.toString());
        localStorage.setItem('longitude', this.longitude.toString());
        localStorage.setItem('radiusLat', this.radiusLat.toString());
        localStorage.setItem('radiusLong', this.radiusLong.toString());

        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  // radiusDragEnd($event: any) {
  //   console.log($event);
  //   this.radiusLat = $event.coords.lat;
  //   this.radiusLong = $event.coords.lng;
  //   this.showHideMarkers();
  // }

  // event(type, $event) {
  //   console.log(type, $event);
  //   this.radius = $event;
  //   this.showHideMarkers();
  // }

  // showHideMarkers() {
  //   Object.values(this.markers).forEach(value => {
  //     value.isShown = this.getDistanceBetween(value.latitude, value.longitude, this.radiusLat, this.radiusLong);
  //   });
  // }

  // getDistanceBetween(lat1, long1, lat2, long2) {
  //   var from = new google.maps.LatLng(lat1, long1);
  //   var to = new google.maps.LatLng(lat2, long2);

  //   if (google.maps.geometry.spherical.computeDistanceBetween(from, to) <= this.radius) {
  //     console.log('Radius', this.radius);
  //     console.log('Distance Between', google.maps.geometry.spherical.computeDistanceBetween(
  //       from, to
  //     ));
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log('address: ', results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.postcode = results[0].address_components.pop();
          this.citySelectEvent.emit(this.postcode);
          this.searchElementRef.nativeElement.value = this.address;
          this.searchElementRef2.nativeElement.value = this.address;
          localStorage.setItem('formatted_address', this.address);

          // let url = this.router.url;
          // let queryParam = `?postcode=${this.postcode.long_name}`;

          // if (this.catId && url.includes('/customer-dashboard/child-category-view/')) {
          //   queryParam += `&category_id=${this.catId}`;
          // }

          // if (this.catChildId && url.includes('/customer-dashboard/provider-details-view/')) {
          //   queryParam += `&category_id=${this.catChildId}`;
          // }
          // this.getProviderService.getProviderByPostcode(queryParam)
          // this.getProviderService.GetProvideServiceService(queryParam)
          //   .subscribe(
          //     (_response) => {
          //       if (_response.status === 200) {
          //         this.markers = _response.body;

          //         for (let i = 0; i < this.markers.length; i++) {
          //           // this.markers[i].isShown = this.getDistanceBetween(this.markers[i].lat, this.markers[i].long, this.radiusLat, this.radiusLong);
          //           this.markers[i].isShown = true;
          //           this.markers[i].infoLat = this.markers[i].latitude + 0.001;
          //         }
          //       }
          //     },
          //     (_error) => {

          //     }
          //   );

          if (this.cityName !== '') {
            let cityQuery = `?postcode=${this.postcode.long_name.slice(0, 3)}`;
            this.getContactInfoService.GetContactInfoService(cityQuery)
              .subscribe(
                (_response) => {
                  if (_response.status === 200) {
                    this.cityArea = _response.body;
                    this.cityName = _response.body[0].city;
                    localStorage.setItem('cityName', this.cityName);
                  }
                },
                (_error) => {

                });
          }


        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  clearLocation() {
    this.searchElementRef.nativeElement.value = '';
    this.searchElementRef2.nativeElement.value = '';
    localStorage.removeItem('formatted_address');
    localStorage.removeItem('cityName');
  }

  // displayFn(city: any) {
  //   if (city) { return city.locationName; }
  // }

  displaySearch(search: any) {
    if (search && search.type !== 'Provider') { return search.name; }
    else { return search.name; }
  }

  // displayArea(search: any) {
  //   if (search) { return search.area; }
  // }

  jumpToPage(search) {
    console.log('search: ', search);
    if (search.type === 'Category') {
      this.router.navigated = false;
      this.router.navigate(['/customer-dashboard/child-category-view/', search.name]);
      localStorage.setItem('catId', search.id);
    } else if (search.type === 'Sub Category') {
      this.router.navigated = false;
      this.router.navigate(['/customer-dashboard/provider-details-view/', search.name]);
      localStorage.setItem('catChildId', search.id);
      localStorage.setItem('catId', search.parent_id);
    } else if (search.type === 'Provider') {
      this.router.navigated = false;
      this.router.navigate(['/customer-dashboard/profile-details', search.unique_url_id]);
      localStorage.setItem('catChildId', search.parent_id);
      let id = localStorage.getItem('catChildId');
      localStorage.setItem('catId', id.charAt(0));
      sessionStorage.setItem('provider_id', search.id);
    }

  }
  // selectedOption(event) {
  //   this.selectedCity = event.option.value;
  //   // console.log(this.selectedCity);
  //   this.selectEvent.emit(this.selectedCity);
  // }

  // remove(area: string): void {
  //   const index = this.areas.indexOf(area);

  //   if (index >= 0) {
  //     this.areas.splice(index, 1);
  //   }
  // }

  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;

  //   if ((value || '').trim()) {
  //     this.areas.push(value.trim());
  //   }

  //   if (input) {
  //     input.value = '';
  //   }

  //   this.areaControl.setValue(null);
  // }

  // selected(event: MatAutocompleteSelectedEvent): void {
  //   this.areas.push(event.option.viewValue);
  //   this.areaArray.push(event.option.value);
  //   this.areaSelectEvent.emit(this.areaArray);
  //   this.areaInput.nativeElement.value = '';
  //   this.areaControl.setValue(null);
  // }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.cityArea.filter(area => area.toLowerCase().indexOf(filterValue) === 0);
  // }

  // selectEvent(item: any) {
  //   // this.selectedCity = item.locationName;
  //   this.show = false;
  // }

  // getSearchDetails() {
  //   this.agmServiceService.getAgmServiceService()
  //     .subscribe(
  //       (_response) => {
  //         console.log(_response);
  //         if (_response.status === 200) {
  //           try {
  //             this.getLocation = _response.body;
  //             // this.locationStoredData = _response.body;
  //             // this.getLocation = _response.body;

  //           } catch (err) {

  //           }
  //         } else {
  //           this.getLocation = [];
  //         }
  //       },
  //       (_error) => {
  //         this.getLocation = [];
  //       }
  //     )
  // }

  selectCategory(event) {
    console.log('select: ', this.searchForm.controls['search_category'].value);
    this.searchCatId = this.searchForm.controls['search_category'].value;
    // let query = `?category_id=${event.id}`;
    // this.postcode ? query += `&postcode=${this.postcode.long_name}` : ``;

    this.getSearch.getSearchProviderAndSubCategory(this.searchCatId, this.postcode ? this.postcode.long_name : '')
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            this.getSearchSubCat = _response.body;
            this.searchForm.controls['search_provider'].setValue('');
          }
        },
        (_error) => {
          this.getSearchSubCat = [];
        });
  }
}
