import { Component, OnInit, Input, Inject, } from '@angular/core';
import { style } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetcategoryServiceService } from 'src/app/services/getcategory/getcategory-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GetsearchService } from 'src/app/services/getserachservice/getsearch.service';
import { AgmServiceService } from 'src/app/services/agm/agm-service.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { ItemTemplateDirective } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';

import { filter } from 'rxjs/operators';
import { GetcountService } from 'src/app/services/getcount/getcount.service';
@Component({
  selector: 'app-categories-view',
  templateUrl: './categories-view.component.html',

  styleUrls: ['./categories-view.component.css']
})
export class CategoriesViewComponent {
  userid = '';
  //  items: string[] = [{ name: "archie" }, { name: "jake" }, { name: "richard" }];
  keyword = 'locationName';
  category_id = '';
  category_name = '';
  category = [];
  getLocation: [];
  urlQueryParam = '';
  sub: any
  id: ''
  catChildId: '';
  state: []
  searchStoredData = [];
  catId: '';
  catName: '';
  selected: '';
  cartObj: any;
  show: boolean = false;
  categoryCount = [];

  constructor(private router: Router,
    private getcategoryServiceService: GetcategoryServiceService,
    private route: ActivatedRoute,
    private getsearchService: GetsearchService,
    private config: NgSelectConfig,
    private agmServiceService: AgmServiceService,
    private GetcountService: GetcountService,
    private location: LocationStrategy,

  ) {
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';

    history.pushState(null, null, '/customer-dashboard');
    this.location.onPopState(() => {
      history.pushState(null, null, '/customer-dashboard');
    });
  }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');
    if (this.category_name)
      this.catName == this.category_name

    this.sub = this.route
      .params
      .subscribe(params => {
        console.log(params);
        // Defaults to 0 if no query param provided.
        this.category_name = params.catName || 1;
        console.log('category_id query param', this.category_name);
        // console.log('model  query param', this.model);
      });
    // this.getSearchDetails();
    this.getCatergory();
    console.log('data', '_response.body');
  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("container").style.maxWidth = "83.333333%";
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  getCatergory() {
    // this.urlQueryParam = `?category_id=${this.category_id}`;
    this.getcategoryServiceService.GetcategoryServiceService()
      .subscribe(
        (_response) => {
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.category = _response.body;
              for (let i = 0; i < this.category.length; i++) {
                this.category[i].show = false;
              }
            } catch (err) {
            }
          }
        },
        (_error) => {

        });
  }

  // getSearchDetails() {
  //   this.getsearchService.GetsearchService()
  //     .subscribe(
  //       (_response) => {
  //         console.log(_response);
  //         if (_response.status === 200) {
  //           try {
  //             this.getsearch = _response.body;
  //             this.searchStoredData = _response.body;;

  //           } catch (err) {

  //           }
  //         }
  //       },
  //       (_error) => {

  //       }

  //     )




  //   this.agmServiceService.getAgmServiceService()
  //     .subscribe(
  //       (_response) => {
  //         console.log(_response);
  //         if (_response.status === 200) {
  //           try {
  //             this.getLocation = _response.body;
  //             // this.locationStoredData = _response.body;
  //             this.getLocation = _response.body;

  //           } catch (err) {

  //           }
  //         }
  //       },
  //       (_error) => {

  //       }

  //     )

  // }


}