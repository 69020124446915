import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CompanyProfileService {

  constructor(private httpService: HttpClient) { }
  CompanyProfileService(data) {
    return this.httpService.post<any>(environment.api.getcompanyprofile,data, { 'observe': 'response' })
 }
}
