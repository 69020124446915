import { Component, OnInit } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.css']
})
export class TermsOfServiceComponent implements OnInit {

  constructor(private metaTagService: Meta,) { }

  ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Apointt is a product of Blacktix Technology LLP allowing users to enroll their business and manage customers online as well as offline bookings, payments, prescription documents & online consultations anytime. kindly go through terms of services regarding prices, payments, information accuracy, general conditions, prohibited uses & warranty before using the services. ' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'terms of service, Apointt terms of service, terms of service agreement, terms of condition' }
    );
  }

}
