import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class GetcountService {

  constructor(private httpService: HttpClient) { }
  GetcountService(data) {
  //   const href = environment.api.getCount + data;
  //  return this.httpService.get<any>(href, { 'observe': 'response' })
 }
}
