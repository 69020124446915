import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/jwt-test/auth.service';
import { ProfileServiceService } from 'src/app/services/getprofile/profile-service.service';
// ------------------------------------------------
import * as moment from 'moment';

import { SignupService } from '../../../services/signup/signup.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { BehaviorSubject, from } from 'rxjs';
import { WizardComponent } from 'src/app/ui-components/initial-wizard/wizard/wizard.component';
import { NGB_DATEPICKER_18N_FACTORY } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CryptoServicesService } from '../../../services/crypto-services/crypto-services.service';
import { BrowserModule, Meta } from '@angular/platform-browser';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}


@Component({
  selector: 'app-quicksignin',
  templateUrl: './quicksignin.component.html',
  styleUrls: ['./quicksignin.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class QuicksigninComponent implements OnInit {

  public ownerForm: FormGroup;
  // hide = true;
  sub: any;
  provider_id = '';
  providerUserId: any;
  providerInfo = [];
  qualifications;
  category_name;

  online_consultation_type;
  appoint_id;
  appointments = [];
  cost;
  showNote = false;

  passwordHide = true;
  confirmPasswordHide = true;
  formGroup: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  isActive;
  public termsForm: FormGroup;

  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private ProfileServiceService: ProfileServiceService,
    private location: Location,
    private Router: Router,
    private route: ActivatedRoute,
    //  private toastr:ToastrModule,
    private toastr: ToastrService,
    private authService: AuthService,

    private signupService: SignupService,
    private server: JwtTestService,
    private ngxService: NgxUiLoaderService,
    private metaTagService: Meta,
    private cryptoService: CryptoServicesService
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.providerUserId = sessionStorage.getItem('providerUserId');

    this.sub = this.route
      .params
      .subscribe(params => {
        console.log(params);
        // Defaults to 0 if no query param provided.
        this.provider_id = params.provider_id || 1;
        console.log('provider_id query param', this.provider_id);
      });

    this.online_consultation_type = localStorage.getItem('consultation_type');
    this.appoint_id = localStorage.getItem('appoint_id');

    // this.ownerForm = new FormGroup({
    //   mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
    //   password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    // });

    // let queryParams = {
    //   appoint_id: this.appoint_id,
    //   online_consultation_type: this.online_consultation_type == 1 ? this.online_consultation_type : ``
    // };

    // this.server.request('GET', '/appointment/getAppointmentDetails', queryParams)
    //   .subscribe(
    //     (_response: any) => {
    //       if (_response.status === 200) {
    //         this.appointments = _response.body;
    //         console.log("apoint-", this.appointments);
    //         this.cost = _response.body[0].price;

    //         this.appointments[0].date = moment(this.appointments[0].date, 'MMMDD,YYYY').format('D MMM YYYY, ddd');

    //         if (moment(this.appointments[0].date, 'D MMM YYYY, ddd').isSame(moment(), 'day')) {
    //           this.showNote = true;
    //         } else {
    //           this.showNote = false;
    //         }
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );

    let userid = localStorage.getItem('userid');
    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${userid}`;
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid).subscribe(
      (_response) => {
        console.log("all details", _response.body[0]);
        if (_response.status === 200) {
          try {
            this.providerInfo = _response.body;
            // this.subCategoriesService = _response.body;

            // this.intro = this.subCategoriesService[0].introduction.slice(this.subCategoriesService[0].introduction.indexOf('intro: '), this.subCategoriesService[0].introduction.indexOf('specialization: '));
            // this.intro = this.intro.slice(6);

            // this.specialization = this.subCategoriesService[0].introduction.slice(this.subCategoriesService[0].introduction.indexOf('specialization: '), this.subCategoriesService[0].introduction.indexOf('workplace: '));
            // this.specialization = this.specialization.slice(15);

            if (_response.body[0].qualification.includes("*")) {
              this.qualifications = _response.body[0].qualification.split('*');
            }
            else {
              this.qualifications = _response.body[0].qualification.split(' ');
            }
            this.qualifications.pop();

            // this.category_name = _response.body[0].category_name;
            let categories = _response.body[0].category_name;
            this.category_name = '';
            for (let i = 0; i < categories.length; i++) {
              this.category_name += categories[i] + ', ';
            }
            this.category_name = this.category_name.slice(0, -2);

          } catch (err) {
            console.log(err);
          }
        }
      },
      (_error) => {

      }
    );

    this.ownerForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern(/[A-Za-z]/)]),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/), Validators.maxLength(10)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]),
      confirmpassword: new FormControl(''),
    },
      {
        validators: this.passwordValidator
      }
    );

    this.termsForm = new FormGroup({
      terms: new FormControl({ value: '', disabled: true }, Validators.required)
    });

    this.ownerForm.valueChanges.subscribe(() => {
      if (this.ownerForm.valid) {
        this.termsForm.controls['terms'].enable();
      } else {
        this.termsForm.controls['terms'].disable();
      }
    });

    this.metaTagService.updateTag(
      { name: 'description', content: 'Are you looking for nearby best Doctors, Lawyers, CA, Beauticians & other professionals? For Online consultation Signup now!' },

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'signup, apointt signup, apointt register, signup for online booking, ' }
    );
  }

  //   public hasError = (controlName: string, errorName: string) => {
  //     return this.ownerForm.controls[controlName].hasError(errorName);
  //   }
  //   public onCancel = () => {
  //     this.location.back();
  //   }

  //   async createOwner(ownerFormValue) {
  //     this.loginInvalid = false;
  //     this.formSubmitAttempt = false;
  //     if (this.ownerForm.valid) {
  //       try {
  //         await this.authService.login(this.ownerForm.value);
  //         localStorage.setItem('mobile_number', ownerFormValue.mobile_number);
  //       } catch(err) {
  //         this.loginInvalid = true;
  //       }
  //     } else {
  //       this.formSubmitAttempt = true;
  //     }
  //   }
  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }

  public onCancel = () => {
    this.location.back();
  }

  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid && this.termsForm.valid) {
      this.signupadmin(ownerFormValue);
    }
  }

  signupadmin(ownerFormValue) {
    let user: any = {
      name: ownerFormValue.name,
      user_type: "1",
      mobile_number: ownerFormValue.mobile_number,
      password: this.cryptoService.set('secret key 123', ownerFormValue.password),
    };

    this.ngxService.start();

    this.signupService.SignupService(user)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          if ((_response.status === 200) && (_response.body.msg == 'Mobile already exists')) {
            this.toastr.warning('', 'Mobile number is already registered');
          } else if (_response.status === 200) {
            // alert("Successful")
            this.toastr.success('Registration successful');
            this.authService.login(this.ownerForm.value);
            localStorage.setItem('mobile_number', ownerFormValue.mobile_number);
            // this.Router.navigate(['/customer-dashboard/payment-method']);
            this.bookApointment();
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          this.toastr.error('Please check mobile number or password');
        }
      );
  }

  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('password').value !== ownerForm.get('confirmpassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }

  bookApointment() {
    let date = sessionStorage.getItem('appointmentDate');
    let startTime = sessionStorage.getItem('appointmentStart');
    let endTime = sessionStorage.getItem('appointmentEnd');

    let userid = localStorage.getItem('userid');
    let apointment = {
      provider_id: this.provider_id,
      userid: userid,
      start_datetime: moment(date).format('YYYY-MM-DD') + " " + moment(startTime, 'h:mm A').format('hh:mm A'),
      end_datetime: moment(date).format('YYYY-MM-DD') + " " + endTime,
      consultation_type: localStorage.getItem('consultation_type'),
      address_id: localStorage.getItem('address_id'),
    }

    this.server.request('POST', '/appointment/setApointt', apointment)
      .subscribe(
        (_response: any) => {
          console.log("apointment booked");
          this.appoint_id = _response.appoint_id;
          localStorage.setItem('appoint_id', this.appoint_id);
          localStorage.setItem('consultation_type', apointment.consultation_type);
          this.Router.navigate(['customer-dashboard/payment-method']);

          sessionStorage.removeItem('appointmentDate');
          sessionStorage.removeItem('appointmentStart');
          sessionStorage.removeItem('appointmentEnd');

          let notificationtype = {
            userid: userid,
            intended_userid: this.providerUserId,
            appoint_id: this.appoint_id,
            n_type: '0'
          };

          this.server.request('POST', '/notification/notificationType', notificationtype)
            .subscribe((_response) => {
              // console.log('notification type: ', notificationtype.n_type);
            },
              (_error) => {

              });
        },
        (_error) => {

        });
  }
}
