import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConfirmAccountService {

  constructor(private httpService: HttpClient) { }

  sendOtp(data) {
    const href = environment.api.sendOtp;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }

  verifyOtp(queryParams) {
    const href = environment.api.verifyOtp + queryParams;
    return this.httpService.get<any>(href, {'observe': 'response'});
  }

  // confirmAccount(data) {
  //   const href = environment.api.confirmAccount;
  //   return this.httpService.post<any>(href, data, {'observe': 'response'});
  // }
}
