import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CategoryLevelService {

  constructor(private httpService: HttpClient) { }
  CategoryLevelService (query){
    // const href = environment.api.categoryLevel + `?sterm=${query.sterm}`;
    //  return this.httpService.get<any>(href, { 'observe': 'response' })

}
}