import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { map, mergeMap } from 'rxjs/internal/operators';
import { GetsearchService } from 'src/app/services/getserachservice/getsearch.service';
import { GetsubcategoryserviceService } from 'src/app/services/getsubcategory/getsubcategoryservice.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-child-category-view',
  templateUrl: './child-category-view.component.html',
  styleUrls: ['./child-category-view.component.css']
})
export class ChildCategoryViewComponent {
  breadcrumbs;
  model: any = {};
  category_id = '1';
  category_name = '';
  serviceCards = [];
  category: [];
  urlQueryParam = '';
  sub: any
  searchText;
  queryParams;
  userid;

  constructor(private getsubcategoryserviceService: GetsubcategoryserviceService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private metaTagService: Meta,
  ) { }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');

    this.sub = this.route
      .params
      .subscribe(params => {
        console.log(params);
        // Defaults to 0 if no query param provided.
        this.category_id = params.catId || 1;
        this.category_name = params.catName || 1;
        console.log('category_id query param', this.category_name);
        // console.log('model  query param', this.model);
      });

    if (this.category_name == 'Doctor'){
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find specialists and super-specialists near me- Choose best doctors nearby with Fees, Experience, Address details & Book online appointment now! ' },
  
      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'schedule doctor appointment, doctor appointment or doctors appointment, schedule doctor appointment online, online doctor appointment, schedule an appointment, schedule appointment, appointment scheduler free, doctor online consultation, psychiatrist online consultation, dentist online consultation, orthopedic online consultation, gynaecologist online consultation, pediatrician online consultation, neurologist online consultation, skin specialist online consultation, gastroenterologist online consultation, cardiologist online consultation, online appointment booking software, doctor online appointment booking, doctors near me, gastroenterologist doctors near me, pediatrician doctors near me, orthopaedic doctors near me, good ent doctors near me, ob gyn doctors near me, cosmetic doctors near me, hair loss doctors near me ' }
      );
    } else if(this.category_name == 'Lawyer') {
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find Judicial advisors & law service consultants near me. Book appointment for online consultation anytime & anywhere on APOINTT' },
  
      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'divorce lawyers near me, property lawyers near me, lawyers near me, civil lawyers near me, criminal lawyers near me, notary lawyers near me, best lawyers near me, real estate lawyers near me, immigration lawyers near me, family court lawyers near me, good lawyers near me , business lawyers near me , property document verification lawyers near me, lawyers near me for wills , good divorce lawyers near me , top lawyers near me ,' }
      );
    } else if(this.category_name == 'Beautician'){
      this.metaTagService.updateTag(
        { name: 'description', content: 'Best beauty Salons near me - Tired of waiting in salon queue? Find certified beautician nearby & book appointment for online consultation now on APOINTT.' },
  
      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'hair salon online appointment booking, hair care doctors near me, bridal beautician near me, hair and makeup salon near me, party makeup salon near me, makeup salon near me, hair salon near me for ladies, hair salon near me, open hair salon near me, unisex hair salon near me, barbers near me, gents barbers, kids barbers, top barbers near me, nail technicians near me, nail salon near me, nail salon prices near me, cosmetologist near me, best salon near me' }
      );
    } else if(this.category_name == 'Chartered Accountant'){
      this.metaTagService.updateTag(
        { name: 'description', content: 'Find certified and well known chartered accountants/CA firms and services near me & Book appointment for Online consultation on APOINTT' },
  
      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'ca near me, best ca near me, ca near me for itr filing, ca near me for company registration, ca near me for gst , tax filing ca near me' }
      );
    }
    
    if (this.category_name == 'CA-CS') {
      this.queryParams = `?category_name=CA/CS`;
    } else {
      this.queryParams = `?category_name=${this.category_name}`;
    }
    this.ngxService.start();

    this.getsubcategoryserviceService.GetsubcategoryserviceService(this.queryParams)
      .subscribe(
        (_response) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
          console.log(_response);
          if (_response.status === 200) {
            try {
              this.serviceCards = _response.body;
              for (let i = 0; i < this.serviceCards.length; i++) {
                this.serviceCards[i].show = false;
              }
              // shop_id => this.shop_id = shop_id
              
            } catch (err) {

            }
          }
        },
        (_error) => {
          setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
          }, 0);
        }
      )

  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("container").style.maxWidth = "83.333333%";
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  selectType(selectedTab) {
    console.log('selectType click', selectedTab);

  }
}