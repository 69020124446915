import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadpersonalidentitydocService {

  constructor(private httpService: HttpClient) { }

  UploadpersonalidentitydocService(data, query) {
    const href = environment.api.uploadPersonalIdentityDoc;
    return this.httpService.post<any>(href + query, data, {'observe': 'response'});
  }
}
