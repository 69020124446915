import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { GetproviderdetailsService } from '../../../services/getproviderdetails/getproviderdetails.service';
import { ChangeAdminStatusService } from '../../../services/change-admin-status/change-admin-status.service';
// import { NotificationtypeService } from '../../../services/notificationtype/notificationtype.service';
import { from } from 'rxjs';
import { AgmServiceService } from 'src/app/services/agm/agm-service.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { debounceTime, tap, switchMap, finalize, flatMap } from 'rxjs/operators';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { query } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.css']
})
export class ProviderDetailComponent implements OnInit {
  ProviderDetails: any = [{
    name: '', provider_name: '', provider_mobile_no: '', user_id: '', certificationImageUrl: '', personalIdentityImageUrl: '', provider_id: ''
  }];
  provider_id = '';
  provideravailable = true;
  image1 = 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/';
  // image1 = 'https://api.apointt.com:8082/';
  image2 = 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/';
  // image2 = 'https://api.apointt.com:8082/';
  getSearchArray: [];
  admin_status = '';
  searchForm: FormGroup;
  isLoading = false;
  name: string;
  intervalId;
  clickIntervalId;
  displayedColumns: string[] = ['name', 'user_id', 'certificationImageUrl', 'personalIdentityImageUrl', 'admin_status'];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  @ViewChild('searchName', { static: false })
  public searchElementRef: ElementRef;

  constructor(
    private router: Router,
    private agmServiceService: AgmServiceService,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private GetproviderdetailsService: GetproviderdetailsService,
    private ChangeAdminStatusService: ChangeAdminStatusService,
    // private NotificationtypeService: NotificationtypeService,
    private toaster: ToastrService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.GetproviderdetailsService.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.clickIntervalId);
  }


  ngOnInit() {
    // this.GetproviderdetailsService.GetproviderdetailsService()
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.provideravailable = true;
    //         this.ProviderDetails = _response.body;
    //         for (let index = 0; index < this.ProviderDetails.length; index++) {
    //           this.ProviderDetails[index].certificationImageUrl = this.image1 + this.ProviderDetails[index].certificationImageUrl

    //         }
    //         for (let index = 0; index < this.ProviderDetails.length; index++) {
    //           this.ProviderDetails[index].personalIdentityImageUrl = this.image2 + this.ProviderDetails[index].personalIdentityImageUrl

    //         }
    //         console.log(this.ProviderDetails)
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         this.provideravailable = false;
    //       }
    //     }
    //   );

    this.server.request('POST', '/admin/getAllProviderDetails')
      .subscribe(
        (_response) => {
          this.provideravailable = true;
          this.ProviderDetails = _response;
          for (let index = 0; index < this.ProviderDetails.length; index++) {
            this.ProviderDetails[index].certificationImageUrl = this.image1 + this.ProviderDetails[index].certificationImageUrl
            this.ProviderDetails[index].personalIdentityImageUrl = this.image2 + this.ProviderDetails[index].personalIdentityImageUrl
          }
          console.log(this.ProviderDetails)
        },
        (_error) => {
          this.provideravailable = false;
        });

    this.searchForm = this.formBuilder.group({
      search: [''],
    });

    this.searchForm
      .get('search')
      .valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => this.isLoading = true),
        switchMap(value => this.GetproviderdetailsService.GetsearchfilterService({ provider_user_id: value })
          .pipe(
            finalize(() => this.isLoading = false),
          ))
      )
      .subscribe(
        (_response => {
          if (_response.status === 200) {
            this.ProviderDetails = _response.body;
          } else {
            this.ProviderDetails = [];

          }
        }),
        (_error => {
          this.getSearchArray = [];

        })
      );

  }
  displaySearch(search: any) {
    if (search) { return search.provider_user_id; }
  }

  changeStatus(admin_status, user_id) {
    console.log('user_id ', user_id);
    console.log('status ', admin_status);

    let status = {
      user_id: user_id,
      admin_status: admin_status
    };

    this.server.request('POST', '/admin/changeAdminStatus', status)
      .subscribe(
        (_response) => {
          if (admin_status == '1') {
            // alert('Account activate');
            this.toaster.success('Account activate');
          } else if (admin_status == '2') {
            // alert('Account deactivate');
            this.toaster.error('Account deactivate');
          }
        },
        (_error) => {

        });

    // this.ChangeAdminStatusService.ChangeAdminStatusService(status)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         if (admin_status == '1') {
    //           // alert('Account activate');
    //           this.toaster.success('Account activate');
    //         } else if (admin_status == '2') {
    //           // alert('Account deactivate');
    //           this.toaster.error('Account deactivate');
    //         }
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
    // this.provider_id = localStorage.getItem('provider_id');
    // let notificationtype = {
    //   provider_id: this.provider_id,
    //   // intended_userid: userid,
    //   // appoint_id: appointment_id,
    //   n_type: admin_status
    // };

    // this.NotificationtypeService.NotificationtypeService(notificationtype)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         console.log('notification type: ', notificationtype.n_type);
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
  }
  onNavigate(certificationImageUrl) {
    // let link = `http://ec2-3-16-91-238.us-east-2.compute.amazonaws.com:8081/common/2020/8/10/21/a3b209db5d97/4fac1c10-db1f-11ea-bedc-a3b209db5d97.jpg`;
    window.open(certificationImageUrl, "_blank");
  }
  onNavigate1(personalIdentityImageUrl) {
    // let link = `http://ec2-3-16-91-238.us-east-2.compute.amazonaws.com:8081/common/2020/8/10/21/a3b209db5d97/4fac1c10-db1f-11ea-bedc-a3b209db5d97.jpg`;
    window.open(personalIdentityImageUrl, "_blank");
  }

  viewSettlements() {
    this.router.navigate(['/admin/settlements']);
    localStorage.setItem('related_type', '1');
  }

}
