import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { formatDate } from '@angular/common';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  // you can override any of the methods defined in the parent class

  month(event: CalendarEvent): string {
    let time = event.meta.type !== 'Holiday' ? formatDate(event.start, 'h:mm a', this.locale) + ' to ' + formatDate(event.end, 'h:mm a', this.locale) + ':' : ''
    let pay_status = '';
    if (event.meta.type !== 'Holiday') {
      if (event.meta.pay_status == 0) pay_status = '(COV)';
      else if (event.meta.pay_status == 1) pay_status = '(Online Payment)';
      else if (event.meta.pay_status == 2) pay_status = '(Advance Payment)';
      if (event.meta.type == 3) pay_status = '(Refunded)';
    }
    return `<b>${time}</b> ${pay_status} ${event.title}`;
  }

  week(event: CalendarEvent): string {
    let time = event.meta.type !== 'Holiday' ? formatDate(event.start, 'h:mm a', this.locale) : ''
    return `<b>${time}</b> ${event.title}`;
  }

  day(event: CalendarEvent): string {
    let time = event.meta.type !== 'Holiday' ? formatDate(event.start, 'h:mm a', this.locale) : ''
    return `<b>${time}</b> ${event.title}`;
  }
}
