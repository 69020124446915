import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from '../../../services/windowRef/window-ref.service';
import { RazorpayService } from '../../../services/razorpay/razorpay.service';
import { GetappointdetailsService } from '../../../services/getappointdetails/getappointdetails.service';
import * as moment from 'moment';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AddAppointTypeService } from '../../../services/addAppointType/add-appoint-type.service';
import { GetuserprofileService } from '../../../services/getuserprofile/getuserprofile.service';
import { ProfileServiceService } from '../../../services/getprofile/profile-service.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  patientOption = 1;
  userName: string;
  userPhone: any;
  userEmail: any;
  appoint_id: any;
  provider_id: any;
  userid: any;
  providerInfo = [];
  appointments = [];
  qualifications = [];
  category_name;
  redirect_url = '';
  rzp1: any;
  options = {
    "key": "rzp_test_jc3xfnFgY5brGq",
    // "key": "rzp_live_RqunH7a3wo6Ajl",
    "amount": "",
    "name": "Apointt",
    "order_id": "",
    "currency": "INR",
    "handler": this.verifySignature.bind(this),
    "theme": {
      "color": "#93e0e3"
    }
  };

  pay_methods;
  advances_payment_cost;
  cost;
  show_cod;
  show_pay_online;
  show_pay_advance;
  today = moment();
  pay_today = false;
  firstFormGroup: FormGroup;
  self_toggle = true;
  userDetails = [];
  other_relation = false;
  online_consultation_type;
  isLinear = true;
  catId;
  showNote = false;
  // full_name

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router,
    private winRef: WindowRef,
    private razorPayService: RazorpayService,
    private appointmentDetailsById: GetappointdetailsService,
    private server: JwtTestService,
    private addAppointTypeService: AddAppointTypeService,
    private ProfileServiceService: ProfileServiceService,
    private getUserProfileService: GetuserprofileService,
    private toastr: ToastrService,

  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnDestroy() {
    // localStorage.removeItem('consultation_type');
  }

  ngOnInit() {
    this.appoint_id = localStorage.getItem('appoint_id');
    this.provider_id = sessionStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    this.online_consultation_type = localStorage.getItem('consultation_type');
    this.catId = localStorage.getItem('catId');

    this.firstFormGroup = new FormGroup({
      relation: new FormControl(''),
      full_name: new FormControl('', [Validators.pattern('^[a-zA-Z \-\']+')]),
      email: new FormControl('', [Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      contact: new FormControl('', [Validators.pattern(/^\d{10}$/)]),
      other_relation: new FormControl(''),
    });

    let queryParams = {
      appoint_id: this.appoint_id,
      online_consultation_type: this.online_consultation_type == 1 ? this.online_consultation_type : ``
    };

    this.server.request('GET', '/appointment/getAppointmentDetails', queryParams)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.appointments = _response.body;
            this.pay_methods = _response.body[0].pay_methods;
            this.advances_payment_cost = _response.body[0].advances_payment_cost;
            this.cost = _response.body[0].price;
            
            if (this.pay_methods.includes('0')) {
              this.show_cod = true;
            } else {
              this.show_cod = false;
            }

            if (this.pay_methods.includes('1')) {
              this.show_pay_online = true;
              this.pay_today = false;
            } else {
              this.pay_today = false;
              this.show_pay_online = false;
            }

            if (this.pay_methods.includes('2')) {
              this.pay_today = false;
              this.show_pay_advance = true;
            } else {
              this.pay_today = false;
              this.show_pay_advance = false;
            }

            this.appointments[0].date = moment(this.appointments[0].date, 'MMMDD,YYYY').format('D MMM YYYY, ddd');
            
            if (moment(this.appointments[0].date, 'D MMM YYYY, ddd').isSame(moment(), 'day')) {
              this.showNote = true;
            } else {
              this.showNote = false;
            }
          }
        },
        (_error) => {

        });
    let userQuery = {
      userid: this.userid
    };

    this.server.request('GET', '/users/getUserProfile', userQuery)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.userDetails = _response.body;
            this.userName = this.userDetails[0].user_name;
            this.userPhone = this.userDetails[0].user_mobile_no;
            this.userEmail = this.userDetails[0].user_email_address;
          }
        },
        (_error) => {

        });

    this.getProvider();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.firstFormGroup.controls[controlName].hasError(errorName);
  }

  changeToggle() {
    // this.self_toggle = !this.self_toggle;

    if (this.patientOption == 1) {
      this.firstFormGroup.controls['full_name'].clearValidators();
      this.firstFormGroup.controls['email'].clearValidators();
      this.firstFormGroup.controls['contact'].clearValidators();
      this.firstFormGroup.controls['relation'].clearValidators();

      this.firstFormGroup.controls['full_name'].updateValueAndValidity();
      this.firstFormGroup.controls['email'].updateValueAndValidity();
      this.firstFormGroup.controls['contact'].updateValueAndValidity();
      this.firstFormGroup.controls['relation'].updateValueAndValidity();

    } else if (this.patientOption == 2) {
      this.firstFormGroup.controls['full_name'].setValidators([Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]);
      // this.firstFormGroup.controls['email'].setValidators([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]);
      this.firstFormGroup.controls['contact'].setValidators([Validators.required, Validators.pattern(/^\d{10}$/)])
      this.firstFormGroup.controls['relation'].setValidators([Validators.required]);

      this.firstFormGroup.controls['full_name'].updateValueAndValidity();
      this.firstFormGroup.controls['email'].updateValueAndValidity();
      this.firstFormGroup.controls['contact'].updateValueAndValidity();
      this.firstFormGroup.controls['relation'].updateValueAndValidity();
    }
  }

  selectRelation(event) {
    if (event == 'Other') {
      this.other_relation = true;
      this.firstFormGroup.controls['other_relation'].setValidators([Validators.required]);
      this.firstFormGroup.controls['other_relation'].updateValueAndValidity();
    } else {
      this.other_relation = false;
      this.firstFormGroup.controls['other_relation'].clearValidators();
      this.firstFormGroup.controls['other_relation'].updateValueAndValidity();
    }
  }
  public createOwner = (firstFormGroupValue) => {
    if (this.firstFormGroup.valid) {
      this.addApointType(firstFormGroupValue);
    } else {
      this.toastr.error('Please fill form details');
    }
  }

  addApointType(firstFormGroupValue?) {
    let apointType = {
      appoint_id: localStorage.getItem('appoint_id'),
      appoint_type: this.patientOption == 1 ? '0' :  '1',
      client_name: this.patientOption == 2 ? firstFormGroupValue.full_name : '',
      client_mobile_number: this.patientOption == 2 ? firstFormGroupValue.contact : '',
      client_email_address: this.patientOption == 2 ? firstFormGroupValue.email : '',
      client_relation: this.patientOption == 2 ? firstFormGroupValue.relation : '',
    };

    this.server.request('POST', '/appointment/addAppointType', apointType)
      .subscribe(
        (_response: any) => {
          console.log('client added');
          if (this.patientOption == 1) {
            localStorage.setItem('name', this.userName);
            localStorage.setItem('relation', 'Self');
          } else if (this.patientOption == 2) {
            localStorage.setItem('name', apointType.client_name);
            localStorage.setItem('relation', apointType.client_relation);
          }
          this.router.navigate(['/customer-dashboard/choose-payment-method']);
        },
        (_error) => {

        });
  }

  payCod() {
    localStorage.setItem('pay_status', '0');
    localStorage.setItem('cost', this.cost);
  }

  pay() {
    let query = {
      appoint_id: this.appoint_id,
      online_consultation_type: localStorage.getItem('consultation_type')
    }

    this.server.request('GET', '/razorpay_purchase/purchase', query)
      .subscribe((_response: any) => {
        if (_response.status === 200) {
          console.log('_response: ', _response.body);
          this.options.amount = _response.body.payload.order.amount;
          this.options.order_id = _response.body.payload.order.id;
          console.log('options: ', this.options);
          this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
          console.log('rzp: ', this.rzp1);
        }
      },
        (_error) => {
          console.log('pay error: ', _error);
        });

    localStorage.setItem('pay_status', '1');
    localStorage.setItem('cost', this.cost);
  }

  payAdvance() {
    let query = {
      appoint_id: this.appoint_id,
      advances_payment_cost: this.advances_payment_cost,
      online_consultation_type: localStorage.getItem('consultation_type')
    };

    this.server.request('GET', '/razorpay_purchase/purchase', query)
      .subscribe((_response: any) => {
        if (_response.status === 200) {
          console.log('_response: ', _response.body);
          this.options.amount = _response.body.payload.order.amount;
          this.options.order_id = _response.body.payload.order.id;
          // console.log('options: ', this.options);
          this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
          console.log('rzp: ', this.rzp1);
          localStorage.setItem('cost', (parseFloat(_response.body.payload.order.amount) / 100).toString());
        }
      },
        (_error) => {
          console.log('pay error: ', _error);
        });

    localStorage.setItem('pay_status', '2');
  }

  verifySignature(response) {
    console.log('response', response);
    let signature = {
      appoint_id: localStorage.getItem('appoint_id'),
      provider_id: sessionStorage.getItem('provider_id'),
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature
    };

    this.server.request('POST', '/razorpay_purchase/signatureVerification', signature)
      .subscribe((_response: any) => {
        // if (_response.status === 200) {
        try {
          console.log('signature verified');
          if (response.razorpay_payment_id) {
            this.redirect_url = '/customer-dashboard/appoint-success';
          } else {
            this.redirect_url = '/customer-dashboard/payment-method';
          }
          window.location.assign(this.redirect_url);
        } catch (err) {
          console.log('error: ', err);
        }

        // }
      },
        (_error) => {

        });
  }

  expandMenu() {
    document.getElementById("main-menu").style.width = "200px";
    document.getElementById("main-menu").style.overflow = "visible";
    document.getElementById("main-menu").style.maxWidth = "16.666667%";
    document.getElementById("container").style.maxWidth = "83.333333%";
  }

  hideMenu() {
    document.getElementById("main-menu").style.width = "60px";
    document.getElementById("main-menu").style.overflow = "hidden";
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }
  getProvider() {
    // this.provider_id = localStorage.getItem('provider_id');
    let queryParms = `?provider_id=${this.provider_id}`;
    // let queryParms = `?provider_name=${this.provider_name}`;
    // let queryParms = `?unique_url_id=${this.provider_name}`;
    let l_userid = `&l_userid=${this.userid}`
    this.ProfileServiceService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          console.log("all details",_response.body[0]);
          if (_response.status === 200) {
            try {
              this.providerInfo = _response.body;
              // this.subCategoriesService = _response.body;

              // this.intro = this.subCategoriesService[0].introduction.slice(this.subCategoriesService[0].introduction.indexOf('intro: '), this.subCategoriesService[0].introduction.indexOf('specialization: '));
              // this.intro = this.intro.slice(6);

              // this.specialization = this.subCategoriesService[0].introduction.slice(this.subCategoriesService[0].introduction.indexOf('specialization: '), this.subCategoriesService[0].introduction.indexOf('workplace: '));
              // this.specialization = this.specialization.slice(15);

              if (_response.body[0].qualification.includes("*")) {
                this.qualifications = _response.body[0].qualification.split('*');
              }
              else {
                this.qualifications = _response.body[0].qualification.split(' ');
              }
              this.qualifications.pop();

              // this.category_name = _response.body[0].category_name;
              let categories = _response.body[0].category_name;
              this.category_name = '';
              for (let i = 0; i < categories.length; i++) {
                this.category_name += categories[i] + ', ';
              }
              this.category_name = this.category_name.slice(0, -2);

            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      )
  }
  //   public initPay():void {
  //     this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
  //     this.rzp1.open();
  //  }

}
