import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GetappointmentbycustomerService {

  constructor(private httpService: HttpClient) { }

  GetappointmentbycustomerService(query) {
    const href = environment.api.getappointmentbycustomer;
    return this.httpService.get<any>(href + query, { 'observe': 'response' });
  }
}
