import { Component, OnInit } from '@angular/core';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject, Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {
  document = 'http://ec2-3-111-34-196.ap-south-1.compute.amazonaws.com:8081/';
  // document = 'https://api.apointt.com:8082/';
  docArray = [];
  urlSafe: SafeResourceUrl;
  documents = [];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private server: JwtTestService,
    private sanitizer: DomSanitizer,
    private toaster: ToastrService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    let query = {
      appoint_id: localStorage.getItem('appoint_id')
    };
    this.server.request('GET', '/providers/getAttachment', query)
      .subscribe(
        (_response: any) => {
          if (_response.status == 200) {
            if (_response.body.length == 0) {
              this.docArray = [];
            } else {
              this.docArray = [];
              this.documents = _response.body;
              if (_response.body[0].server_image_url === null) {
                for (let j = 1; j < _response.body.length; j++) {
                  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.document + _response.body[j].server_image_url.slice(8));
                  this.docArray.push(this.urlSafe);
                }
              } else {
                for (let j = 0; j < _response.body.length; j++) {
                  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.document + _response.body[j].server_image_url.slice(8));
                  this.docArray.push(this.urlSafe);
                }
              }

              console.log('docs: ', this.docArray);
            }
          }
        },
        (_error) => {
          console.log('error: ', _error);
        });
  }

  onNavigate(doc) {
    window.open(doc.changingThisBreaksApplicationSecurity, "_blank");
  }

  changeDocumentStatus() {
    let document_status = {
      appoint_id: localStorage.getItem('appoint_id'),
      document_status: 3
    };

    this.server.request('POST', '/providers/changeDocumentStatus', document_status)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Documents approved');
        },
        (_error) => {
          console.log("error: ", _error);
        });
  }

}
