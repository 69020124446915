import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetAllLanguagesService {

  constructor(private httpService: HttpClient) { }

  GetAllLanguagesService(query?) {
    let href = environment.api.getAllLanguages;
    query ? href += `?language_name=${query.language_name}` : ``;
    return this.httpService.get<any>(href, {'observe': 'response'});
  }
}
