import { Component, OnInit } from '@angular/core';
import { GetclientbypartneridService } from '../../../services/getclientbypartnerid/getclientbypartnerid.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-partner-provider-details',
  templateUrl: './partner-provider-details.component.html',
  styleUrls: ['./partner-provider-details.component.css']
})
export class PartnerProviderDetailsComponent implements OnInit {
  patner_id;
  clientDetails = [];
  clientAvailable = true;
  displayedColumns: string[] = ['provider_name', 'plan_name', 'price', 'start_date', 'end_date', 'contact'];

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private getClientByPartnerId: GetclientbypartneridService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.patner_id = localStorage.getItem('patner_id');

    // let query = `?patner_id=${this.patner_id}`;
    let query = {
      patner_id: this.patner_id
    };

    this.server.request('GET', '/patner/getClientDetailByPatnetId', query)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            if (_response.body.length == 0) {
              this.clientAvailable = false;
            } else {
              this.clientAvailable = true;
              this.clientDetails = _response.body;
            }
          }
        },
        (_error) => {
          this.clientAvailable = false;
        });

    // this.getClientByPartnerId.GetclientbypartneridService(query)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200 && _response.body.length == 0) {
    //         this.clientAvailable = false;
    //       }
    //       else if (_response.status === 200) {
    //         this.clientAvailable = true;
    //         this.clientDetails = _response.body;
    //       }
    //     },
    //     (_error) => {
    //       this.clientAvailable = false;
    //     });
  }

}
