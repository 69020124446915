import { Component, OnInit } from '@angular/core';
import {ContactInfoService} from '../../../services/contact-info/contact-info.service';
import {GetContactInfoService} from '../../../services/contact-info/get-contact-info.service';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';

interface salutation {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css']
})
export class ContactInfoComponent implements OnInit {
  public ownerForm: FormGroup;
  salutation: string[] = ['Mr', 'Mrs', 'Er', 'Adv'];

  
  constructor( private contactInfoService: ContactInfoService ,
   private getContactInfoService :GetContactInfoService  ,
   private formBuilder: FormBuilder  ) { }
   
   ngOnInit() {
    this.ownerForm = new FormGroup({
      provider_id: new FormControl(''),
      userid: new FormControl(''),
      first_name:new FormControl(''),
      middle_name: new FormControl(''),
      last_name: new FormControl(''),
      title: new FormControl(''),
      address_text: new FormControl(''),
      postcode: new FormControl(''),
      salutation: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      mobile_no: new FormControl(''),


    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }
 
  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid) {
      this.companyProfile(ownerFormValue);
    }

  }

  companyProfile(ownerFormValue) {
    let user: any = {
      provider_id: ownerFormValue.provider_id,
     
      userid: ownerFormValue.userid,
      type:ownerFormValue.type,
      first_name: ownerFormValue.first_name,
      middle_name: ownerFormValue.middle_name,
      last_name: ownerFormValue.last_name,
      title: ownerFormValue.title,
      address_text: ownerFormValue.address_text,
      postcode: ownerFormValue.postcode,
      salutation: ownerFormValue.salutation

    };
    this.contactInfoService.ContactInfoService(user)
    .subscribe(
      (_response) => {
        console.log(_response);
        if (_response.status === 200) {
          try {
            this.salutation = _response.body;
            
          } catch (err) {
  
          }
        }
      },
      (_error) => {
  
      }
    )
  console.log('data', 'response');
  
  

    }
  }
