import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UpdateUserProfileService {

  constructor(private httpService: HttpClient) { }

  UpdateUserProfileService(data) {
    const href = environment.api.updateUserProfile;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
