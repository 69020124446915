import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class LoginAdminService {
  constructor(private httpService: HttpClient) { }
  LoginAdminService(user) {
   return this.httpService.post<any>(environment.api.loginadminAPI,user,{'observe':'response'})
 }  
}