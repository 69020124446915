import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormGroupDirective, NgForm, MinLengthValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { ChangepasswordService } from '../../../services/changepassword/changepassword.service';
import { ToastrService } from 'ngx-toastr';
import { CryptoServicesService } from '../../../services/crypto-services/crypto-services.service';
import { JwtTestService } from '../../../services/jwt-test/jwt-test.service';
import { BehaviorSubject } from 'rxjs';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  userid = '';
  ownerForm: FormGroup;
  passwordHide = true;
  newPasswordHide = true;
  confirmPasswordHide = true;
  errorMatcher = new CrossFieldErrorMatcher();
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private Router: Router,
    private formBuilder: FormBuilder,
    private changePasswordService: ChangepasswordService,
    private toaster: ToastrService,
    private cryptoService: CryptoServicesService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');
    this.ownerForm = new FormGroup({
      old_password: new FormControl('', [Validators.required]),
      new_password: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(6), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]),
      confirmpassword: new FormControl({ value: '', disabled: true }, [Validators.required])
    },
      {
        validators: this.passwordValidator
      }
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  }

  public createOwner = (ownerFormValue) => {
    if (this.ownerForm.valid) {
      this.changePassword(ownerFormValue);
    }
  }

  changePassword(ownerFormValue) {
    let password = {
      // current_password: ownerFormValue.old_password,
      // new_password: ownerFormValue.new_password,
      current_password: this.cryptoService.set('secret key 123', ownerFormValue.old_password),
      new_password: this.cryptoService.set('secret key 123', ownerFormValue.new_password),
      userid: this.userid
    };

    this.server.request('POST', '/users/changePassword', password)
      .subscribe(
        (_response: any) => {
          this.toaster.success('Password changed successfully');
        },
        (_error) => {
          if (ownerFormValue.old_password == ownerFormValue.new_password) {
            this.toaster.error('New password cannot be same as old password');
          } else {
            this.toaster.error('Your current password is incorrect');
          }
        });
  }

  passwordValidator(ownerForm: FormGroup) {
    const condition = ownerForm.get('new_password').value !== ownerForm.get('confirmpassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }

  enableInput() {
    if (this.ownerForm.controls['old_password'].value.length > 0) {
      this.ownerForm.controls['new_password'].enable();
      this.ownerForm.controls['confirmpassword'].enable();
    } else if (this.ownerForm.controls['old_password'].value.length == 0) {
      this.ownerForm.controls['new_password'].disable();
      this.ownerForm.controls['confirmpassword'].disable();
    }
  }

}
