import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import{environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ChangeAppointStatusService {

  constructor(private httpService: HttpClient) { }

  ChangeAppointStatusService(data) {
    const href = environment.api.changeappointstatus;
    return this.httpService.post<any>(href, data, {'observe': 'response'});
  }
}
