import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { LoginAdminService } from 'src/app/services/login-admin/login-admin.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/jwt-test/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  public ownerForm: FormGroup;
  hide = true;
  isActive;
  showError = false;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;

  constructor(private formBuilder: FormBuilder,
    private LoginAdminService: LoginAdminService,
    private location: Location,
    private Router: Router,
    private toastr: ToastrService,
    private authService: AuthService) { }

    ngOnInit() {
      this.ownerForm = new FormGroup({
        id: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
      });
  
      localStorage.clear();
  
    }

    public hasError = (controlName: string, errorName: string) => {
      return this.ownerForm.controls[controlName].hasError(errorName);
    }
    public onCancel = () => {
      this.location.back();
    }
    async createOwner(ownerFormValue) {
      this.loginInvalid = false;
      this.formSubmitAttempt = false;
      if (this.ownerForm.valid) {
        try {
          await this.authService.loginadmin(this.ownerForm.value);
          localStorage.setItem('id', ownerFormValue.id);
        } catch(err) {
          this.loginInvalid = true;
        }
      } else {
        this.formSubmitAttempt = true;
      }
    }

    // login(ownerFormValue) {
    //   let user: any = {
    //     id: ownerFormValue.id,
    //     password: ownerFormValue.password,
    //   };
  
    //   this.LoginAdminService.LoginAdminService(user)
    //     .subscribe(
    //       (_response) => {
  
    //         if (_response.status === 200) {
    //           { positionClass: 'toast-custom' };
    //           this.toastr.success('Login successfully' );
    //           alert('Sucessful');
    //           this.Router.navigate(['/admin-dashboard']);
    //           localStorage.setItem('id', _response.body.id);
    //         }
    //       },
    //       (_error) => {
    //         //  alert(' Please check username or password');
    //         alert('invalid username or password');
    //         //this.toastr.error('Please check username or password')
  
    //       }
    //     );
    //   // console.log('data', user);
    // }
  
    goToUrl(): void {
      window.location.assign('https://blacktix.com');
    }

}