import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FavLikeService {

  constructor(private httpService: HttpClient) { }
  FavLikeService( queryParms) {
    return this.httpService.post<any>(environment.api.like, queryParms,{'observe':'response'})
  }
}
