import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetsearchnameService {
  private loggedIn = false;
  private token: string;

  constructor(private httpService: HttpClient) { }

  GetsearchnameService(query) {
    const href = environment.api.CustomerDetails;
    const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    return this.httpService.request('POST', href, {
      body: { userid: query.userid },
      responseType: 'json',
      observe: 'response',
      headers: header
    });
    // return this.httpService.post<any>(href, { userid: query.userid }, { 'observe': 'response' })
  }

  setLoggedIn(loggedIn: boolean, token?: string) {
    this.loggedIn = loggedIn;
    this.token = token;
  }

  GetcustomerdetailService(data?) {
    const href = environment.api.CustomerDetails;
    return this.httpService.post<any>(href, data, { 'observe': 'response' });
  }
}
