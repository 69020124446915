import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ProfileServiceService } from '../../../services/getprofile/profile-service.service';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-qr-download',
  templateUrl: './qr-download.component.html',
  styleUrls: ['./qr-download.component.css']
})
export class QrDownloadComponent implements OnInit {
  provider_id;
  l_userid;
  provider_name;
  qr_url;
  unique_url_id;
  constructor(
    private providerProfileService: ProfileServiceService,
  ) { }

  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.l_userid = localStorage.getItem('userid');

    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${this.l_userid}`;
    this.providerProfileService.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            try {
              // this.provider_name = _response.body[0].provider_name;
              this.unique_url_id = _response.body[0].unique_url_id;
              this.qr_url = 'https://apointt.com/customer-dashboard/profile-details/' + this.unique_url_id.replace(/ /g, "-");
            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      );
  }

  // public captureScreen() {
  //   var data = document.getElementById('contentToConvert');
  //   html2canvas(data, {
  //     height: 1200,
  //     y: 250,
  //   }).then(canvas => {
  //     // Few necessary setting options  
  //     var imgWidth = 208;
  //     var pageHeight = 295;
  //     var imgHeight = canvas.height * imgWidth / canvas.width;
  //     var heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
  //     var position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //     pdf.save('ApointtProfileQR.pdf'); // Generated PDF   
  //   });
  // }

  captureScreen() {
    let docDefinition = {
      content: [
        // {
        //   text: 'APOINTT',
        //   fontSize: 16,
        //   alignment: 'center',
        //   color: '#047886'
        // },
        // {
        //   text: 'QR CODE',
        //   fontSize: 20,
        //   bold: true,
        //   alignment: 'center',
        //   decoration: 'underline',
        //   color: 'skyblue'
        // },
        {
          image: 'logo',
          width: 200,
          alignment: 'center'
        },
        { qr: this.qr_url, alignment: 'center', margin: [0, 10], },
        { text: 'Please scan to view profile and book appointment', alignment: 'center', color: 'gray', margin: [0, 10] }
      ],
      images: {
        logo: 'https://ik.imagekit.io/zi19k9ks6j/Apointt_new_Logo_WUjnycfYX.png'
      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        }
      }
    };

    pdfMake.createPdf(docDefinition).download('ApointtProfileQR.pdf');
  }

}
