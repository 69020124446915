import { Component, OnInit, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent {

  @Input('catId') catId: string;
  @Input('catName') catName: string;
  @Input('catUrl') cardImage: string;
  @Input('button') button: RouterLink;
  @Input('link') link: RouterLink;
  @Input('catChildDesc') catDesc: String;
  @Input('cartObj') cartObj: any
  @Input('subCat') subCat: any[]
  @Input('catCount') catCount: string;
  @Input('catDashboardUrl') catDashboardUrl: string;
  @Input('show') show: boolean;

  constructor(private router: Router
  ) { }

  ngOnInit() {
    if (this.cartObj)
      // this.catId = this.cartObj.catId
      this.catName = this.cartObj.catName

  }
  viewProduct(catName) {
    if (catName == 'CA/CS') {
      this.router.navigate(['customer-dashboard/child-category-view/CA-CS'])
    } else {
      this.router.navigate(['customer-dashboard/child-category-view/' + catName]);
      localStorage.setItem('catId', this.catId);
    }
  }
}
