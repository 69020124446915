import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileServiceService } from '../../services/getprofile/profile-service.service';
// import { GlobalsService } from '../../services/globals/globals.service';
// import { ProvidercountService } from '../../services/providercount/providercount.service';
// import { GetappointmentbyproviderService } from '../../services/getappointmentbyprovider/getappointmentbyprovider.service';
import * as moment from 'moment';
// import { ChangeAppointStatusService } from '../../services/change-appoint-status/change-appoint-status.service';
// import { GetcancelledappointService } from '../../services/getcancelledappoint/getcancelledappoint.service';
import { ToastrService } from 'ngx-toastr';
import { CancelapointfornotificationService } from '../../services/cancelapointfornotification/cancelapointfornotification.service';
import { ChangeviewstatusService } from '../../services/changeviewstatus/changeviewstatus.service';
import { NotificationcountService } from '../../services/notificationcount/notificationcount.service';
import { GetbookedappointfornotifService } from '../../services/getbookedappointfornotif/getbookedappointfornotif.service';
import { GetpendingappointfornotifService } from '../../services/getpendinappointfornotif/getpendingappointfornotif.service';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';
import { JwtTestService } from '../../services/jwt-test/jwt-test.service';

@Component({
  selector: 'app-provider-navbar',
  templateUrl: './provider-navbar.component.html',
  styleUrls: ['./provider-navbar.component.css']
})
export class ProviderNavbarComponent implements OnInit, OnDestroy {

  users: "";
  over = '';
  provider_id = '';
  userid = '';
  subCategoriesService = [];
  navbarOpen = false;
  notif_count = 0;
  prev_notif_count = 0;
  cancel_count = 0;
  prev_cancel_count;
  appointments = [
    // {
    //   userid: '',
    //   appoint_id: '',
    //   start_datetime: '',
    //   end_datetime: '',
    //   name: '',
    //   cost: '',
    //   date: ''
    // }
  ];
  canceledAppointments = [
    // {
    //   userid: '',
    //   appoint_id: '',
    //   start_datetime: '',
    //   end_datetime: '',
    //   name: '',
    //   cost: '',
    //   date: ''
    // }
  ];
  pendingAppointments = [
    // {
    //   userid: '',
    //   appoint_id: '',
    //   start_datetime: '',
    //   end_datetime: '',
    //   name: '',
    //   cost: '',
    //   date: ''
    // }
  ];
  appointmentavailable = true;
  cancelappointment = true;
  today = moment().format('YYYY-MM-DD');
  showNotif = false;
  intervalId;
  popup = false;
  provider_url;
  provider_name;
  qualification;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  @ViewChild('drawer', { static: true }) drawer: MatSidenav;

  constructor(
    private providerProfile: ProfileServiceService,
    private router: Router,
    // private global: GlobalsService,
    // private providerCountService: ProvidercountService,
    // private getappointmentbyproviderService: GetappointmentbyproviderService,
    // private changeAppointStatusService: ChangeAppointStatusService,
    // private getCancelledAppointService: GetcancelledappointService,
    private toaster: ToastrService,
    private cancelApointForNotificationService: CancelapointfornotificationService,
    private changeViewStatusService: ChangeviewstatusService,
    private notificationCountService: NotificationcountService,
    private getBookedAppointForNotif: GetbookedappointfornotifService,
    private getPendingAppointForNotif: GetpendingappointfornotifService,
    private server: JwtTestService,
  ) {
    const userData = localStorage.getItem('user');
    if (userData) {
      console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
      this.server.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;


  }
  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }
  ngOnInit() {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');

    // console.log('apptCount: ', this.global.apptCount);
    // this.notif_count = this.global.apptCount;

    let queryParms = `?provider_id=${this.provider_id}`;
    let l_userid = `&l_userid=${this.userid}`;
    this.providerProfile.ProfileServiceService(queryParms, l_userid)
      .subscribe(
        (_response) => {
          if (_response.status === 200) {
            try {
              this.subCategoriesService = _response.body;
              this.provider_url = _response.body[0].provider_url;
              this.provider_name = _response.body[0].provider_name;
              this.qualification = _response.body[0].qualification;
            } catch (err) {
              console.log(err);
            }
          }
        },
        (_error) => {

        }
      );

    // let countQuery = `?provider_id=${this.provider_id}`;
    // this.providerCountService.ProvidercountService(countQuery)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.notif_count = _response.body.today_appointment;
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
    // let countQuery = `?userid=${this.userid}`;
    let countQuery = {
      userid: this.userid
    };

    this.server.request('GET', '/notification/notificationCount', countQuery)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            this.notif_count = _response.body.notification_count;
          }
        },
        (_error) => {

        }
      );

    // this.notificationCountService.NotificationcountService(countQuery)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.notif_count = _response.body.notification_count;
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );

    this.getAppointments(this.today);
    this.getCancelledAppointments(this.today);

    this.intervalId = setInterval(() => {
      // this.getCancelledAppointments(this.today);

      // let queryParam = `?provider_id=${this.provider_id}&date=${this.today}`;
      // this.getCancelledAppointService.GetcancelledappointService(queryParam)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         this.cancelappointment = true;
      //         this.canceledAppointments = _response.body.data.results;
      //         this.prev_cancel_count = this.cancel_count;
      //         this.cancel_count = _response.body.data.total;
      //         console.log('prev: ', this.prev_cancel_count, ' cancel: ', this.cancel_count);
      //         if (this.cancel_count > this.prev_cancel_count) {
      //           this.toaster.error('Appointment has been cancelled');
      //         }
      //         console.log(this.canceledAppointments);

      //         for (let i = 0; i < this.canceledAppointments.length; i++) {
      //           this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
      //           this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
      //           this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
      //         }
      //       }
      //     },
      //     (_error) => {
      //       if (_error.status === 409) {
      //         this.cancelappointment = false;
      //       }
      //     }
      //   );

      // let queryParam = `?intended_userid=${this.userid}&date=${this.today}`;
      let queryParam = {
        intended_userid: this.userid,
        date: this.today
      };

      this.server.request('GET', '/notification/getCancelAppointmentForNotication', queryParam)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              try {
                this.cancelappointment = true;
                this.canceledAppointments = _response.body.data.results;
                this.prev_cancel_count = this.cancel_count;
                this.cancel_count = _response.body.data.total;
                // console.log('prev: ', this.prev_cancel_count, ' cancel: ', this.cancel_count);
                if (this.cancel_count > this.prev_cancel_count) {
                  this.toaster.error('Appointment has been cancelled');
                }
                // console.log(this.canceledAppointments);

                if (this.canceledAppointments) {
                  for (let i = 0; i < this.canceledAppointments.length; i++) {
                    this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
                    this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
                    this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
                  }
                } else {
                  return;
                }
              } catch (err) {
                console.log('Error: ', err);
              }
            }
          },
          (_error) => {
            if (_error.status === 409) {
              this.cancelappointment = false;
            }
          }
        );

      // this.cancelApointForNotificationService.CancelapointfornotificationService(queryParam)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         try {
      //           this.cancelappointment = true;
      //           this.canceledAppointments = _response.body.data.results;
      //           this.prev_cancel_count = this.cancel_count;
      //           this.cancel_count = _response.body.data.total;
      //           // console.log('prev: ', this.prev_cancel_count, ' cancel: ', this.cancel_count);
      //           if (this.cancel_count > this.prev_cancel_count) {
      //             this.toaster.error('Appointment has been cancelled');
      //           }
      //           // console.log(this.canceledAppointments);

      //           if (this.canceledAppointments) {
      //             for (let i = 0; i < this.canceledAppointments.length; i++) {
      //               this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
      //               this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
      //               this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
      //             }
      //           } else {
      //             return;
      //           }
      //         } catch (err) {
      //           console.log('Error: ', err);
      //         }
      //       }
      //     },
      //     (_error) => {
      //       if (_error.status === 409) {
      //         this.cancelappointment = false;
      //       }
      //     }
      //   );

      this.getAppointments(this.today);

      this.server.request('GET', '/notification/notificationCount', countQuery)
        .subscribe(
          (_response: any) => {
            if (_response.status === 200) {
              this.prev_notif_count = this.notif_count;
              this.notif_count = _response.body.notification_count;
              // console.log('prev: ', this.prev_notif_count, ' book: ', this.notif_count);
              if (this.notif_count > this.prev_notif_count) {
                this.toaster.success('New appointment arrived');
              }
            }
          },
          (_error) => {

          }
        );

      // this.notificationCountService.NotificationcountService(countQuery)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         this.prev_notif_count = this.notif_count;
      //         this.notif_count = _response.body.notification_count;
      //         // console.log('prev: ', this.prev_notif_count, ' book: ', this.notif_count);
      //         if (this.notif_count > this.prev_notif_count) {
      //           this.toaster.success('New appointment arrived');
      //         }
      //       }
      //     },
      //     (_error) => {

      //     }
      //   );
      // this.providerCountService.ProvidercountService(countQuery)
      //   .subscribe(
      //     (_response) => {
      //       if (_response.status === 200) {
      //         this.prev_notif_count = this.notif_count;
      //         this.notif_count = _response.body.today_appointment;
      //         console.log('prev: ', this.prev_notif_count, ' book: ', this.notif_count);
      //         if (this.notif_count > this.prev_notif_count) {
      //           this.toaster.success('New appointment booked');
      //         }
      //       }
      //     },
      //     (_error) => {
      //     }
      //   );
    }, 5000);
  }

  getAppointments(startDate) {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    // let queryParam = `?intended_userid=${this.userid}&date=${startDate}`;
    let queryParam = {
      intended_userid: this.userid,
      date: startDate
    };

    this.server.request('GET', '/notification/getBookedAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.appointments = _response.body.data.results;
              // console.log('booked appointments: ', this.appointments);

              if (this.appointments) {
                for (let i = 0; i < this.appointments.length; i++) {
                  this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
                  this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
                  this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            // console.log('booked appointments: ', this.appointments);
          }
        });

    // this.getBookedAppointForNotif.GetbookedappointfornotifService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         try {
    //           this.appointments = _response.body.data.results;
    //           // console.log('booked appointments: ', this.appointments);

    //           if (this.appointments) {
    //             for (let i = 0; i < this.appointments.length; i++) {
    //               this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
    //               this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
    //               this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
    //             }
    //           } else {
    //             return;
    //           }
    //         } catch (err) {
    //           console.log('Error: ', err);
    //         }
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         // console.log('booked appointments: ', this.appointments);
    //       }
    //     });

    this.server.request('GET', '/notification/getPendingAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.pendingAppointments = _response.body.data.results;
              // console.log(this.pendingAppointments);

              if (this.pendingAppointments) {
                for (let i = 0; i < this.pendingAppointments.length; i++) {
                  this.pendingAppointments[i].date = moment(this.pendingAppointments[i].start_datetime).format('DD MMM YYYY');
                  this.pendingAppointments[i].start_datetime = moment(this.pendingAppointments[i].start_datetime).format('hh:mm A');
                  this.pendingAppointments[i].end_datetime = moment(this.pendingAppointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        }, (_error) => {

        }
      );

    // this.getPendingAppointForNotif.GetpendingappointfornotifService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         try {
    //           this.pendingAppointments = _response.body.data.results;
    //           // console.log(this.pendingAppointments);

    //           if (this.pendingAppointments) {
    //             for (let i = 0; i < this.pendingAppointments.length; i++) {
    //               this.pendingAppointments[i].date = moment(this.pendingAppointments[i].start_datetime).format('DD MMM YYYY');
    //               this.pendingAppointments[i].start_datetime = moment(this.pendingAppointments[i].start_datetime).format('hh:mm A');
    //               this.pendingAppointments[i].end_datetime = moment(this.pendingAppointments[i].end_datetime).format('hh:mm A');
    //             }
    //           } else {
    //             return;
    //           }
    //         } catch (err) {
    //           console.log('Error: ', err);
    //         }
    //       }
    //     }, (_error) => {

    //     }
    //   );

    // this.getappointmentbyproviderService.GetappointmentbyproviderService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         // this.appointmentavailable = true;
    //         this.appointments = _response.body.data.results;
    //         console.log(this.appointments);

    //         for (let i = 0; i < this.appointments.length; i++) {
    //           this.appointments[i].date = moment(this.appointments[i].start_datetime).format('DD MMM YYYY');
    //           this.appointments[i].start_datetime = moment(this.appointments[i].start_datetime).format('hh:mm A');
    //           this.appointments[i].end_datetime = moment(this.appointments[i].end_datetime).format('hh:mm A');
    //         }
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         // this.appointmentavailable = false;
    //       }
    //     }
    //   );
  }

  getCancelledAppointments(startDate) {
    this.provider_id = localStorage.getItem('provider_id');
    this.userid = localStorage.getItem('userid');
    // let queryParam = `?intended_userid=${this.userid}&date=${startDate}`;
    let queryParam = {
      intended_userid: this.userid,
      date: startDate
    };

    this.server.request('GET', '/notification/getCancelAppointmentForNotication', queryParam)
      .subscribe(
        (_response: any) => {
          if (_response.status === 200) {
            try {
              this.cancelappointment = true;
              this.canceledAppointments = _response.body.data.results;
              this.cancel_count = _response.body.data.total;
              // console.log(this.canceledAppointments);

              if (this.canceledAppointments) {
                for (let i = 0; i < this.canceledAppointments.length; i++) {
                  this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
                  this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
                  this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
                }
              } else {
                return;
              }
            } catch (err) {
              console.log('Error: ', err);
            }
          }
        },
        (_error) => {
          if (_error.status === 409) {
            this.cancelappointment = false;
          }
        }
      );

    // this.cancelApointForNotificationService.CancelapointfornotificationService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         try {
    //           this.cancelappointment = true;
    //           this.canceledAppointments = _response.body.data.results;
    //           this.cancel_count = _response.body.data.total;
    //           // console.log(this.canceledAppointments);

    //           if (this.canceledAppointments) {
    //             for (let i = 0; i < this.canceledAppointments.length; i++) {
    //               this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
    //               this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
    //               this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
    //             }
    //           } else {
    //             return;
    //           }
    //         } catch (err) {
    //           console.log('Error: ', err);
    //         }
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         this.cancelappointment = false;
    //       }
    //     }
    //   );
    // this.getCancelledAppointService.GetcancelledappointService(queryParam)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         this.cancelappointment = true;
    //         this.canceledAppointments = _response.body.data.results;
    //         this.cancel_count = _response.body.data.total;
    //         console.log(this.canceledAppointments);

    //         for (let i = 0; i < this.canceledAppointments.length; i++) {
    //           this.canceledAppointments[i].date = moment(this.canceledAppointments[i].start_datetime).format('DD MMM YYYY');
    //           this.canceledAppointments[i].start_datetime = moment(this.canceledAppointments[i].start_datetime).format('hh:mm A');
    //           this.canceledAppointments[i].end_datetime = moment(this.canceledAppointments[i].end_datetime).format('hh:mm A');
    //         }
    //       }
    //     },
    //     (_error) => {
    //       if (_error.status === 409) {
    //         this.cancelappointment = false;
    //       }
    //     }
    //   );
  }

  notificationCount(appoint_id, id) {
    let appointment = {
      appoint_id: appoint_id,
      is_viewed: '1'
    };

    this.server.request('POST', '/notification/changeViewStatus', appointment)
      .subscribe(
        (_response) => {
          // alert('notification read');
          let elem: HTMLElement = document.getElementById(id);
          elem.setAttribute("style", "background-color: white;");
        },
        (_error) => {

        }
      );

    // this.changeViewStatusService.ChangeviewstatusService(appointment)
    //   .subscribe(
    //     (_response) => {
    //       if (_response.status === 200) {
    //         // alert('notification read');
    //         let elem: HTMLElement = document.getElementById(id);
    //         elem.setAttribute("style", "background-color: white;");
    //       }
    //     },
    //     (_error) => {

    //     }
    //   );
  }

  close() {
    this.drawer.close();
  }

  Users = [
    {
      name: "Apointt ",
    }
  ]

}
